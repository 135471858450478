import React from 'react';
import { useIntl } from 'react-intl';
import { getWarehouseApiUrl } from '../../../helpers/url';
import Comments from './Comments';

interface ProducerCommentsProps {
    id: number;
    onAdd: () => void;
}

const url = (id: number) => getWarehouseApiUrl('producers/:id/comments', { params: { id } });

export default ({ id, onAdd }: ProducerCommentsProps) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'comments.producer.title', defaultMessage: 'Producer comments' });

    return <Comments url={url(id)} title={title} onAdd={onAdd} />;
}
