import React, { useState } from 'react';
import { CardColumns } from 'react-bootstrap';
import InventoriesFetched from '../../components/inventories/Inventories/InventoriesFetched';
import InventoriesToFetch from '../../components/inventories/Inventories/InventoriesToFetch';
import Menu from '../../components/inventories/Menu/Menu';
import { useReload } from '../../hooks/use-reload';
import { InventoryProvider } from '../../types/inventories/model';
import { Section } from '../../types/section';

export const PATH = `/${Section.INVENTORY}`;

export default () => {
    const [reload, reloading] = useReload();
    const [fetchedPage, setFetchedPage] = useState<number>(1);

    return <div className="InventoriesMainPage">
        <Menu reload={reload} />
        {reloading ?
            null :
            <CardColumns className="Inventories">
                <InventoriesFetched page={fetchedPage} setPage={setFetchedPage} />
                <InventoriesToFetch provider={InventoryProvider.FAKTUROWNIA} onFetch={() => { setFetchedPage(0) }} />
            </CardColumns>}
    </div>;
};
