import React from 'react';
import { Alert, Card, CardColumns } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getFormattedDate } from '../../../helpers/time';
import { getTrackingApiUrl } from '../../../helpers/url';
import { HttpErrors } from '../../../hooks/use-http-error';
import { useResource } from '../../../hooks/use-resource';
import { Device } from '../../../types/tracking/model';
import Message, { MessageType } from '../../Message/Message';
import DailyRoutes from '../DailyRoutes/DailyRoutes';
import Menu from '../Menu/Menu';
import './Device.css';

interface DeviceProps {
    device?: Device;
    imei: string;
}

export default ({ device: preloadedDevice, imei }: DeviceProps) => {
    const url = getTrackingApiUrl('devices/:imei', { params: { imei } });
    const [device, { error, loading }] = useResource<Device>(url, {}, preloadedDevice);

    if (!device) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                {error === HttpErrors.NOT_FOUND_ERROR ?
                    <FormattedMessage id="device.no-device" defaultMessage="There is no such device." /> :
                    <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />}
            </Alert>;
        }
    }

    return <>
        <Menu />
        <DailyRoutes device={device} renderBefore={extraContent => <CardColumns className="Device">
            <Card>
                <Card.Header>
                    <FormattedMessage defaultMessage="Basic device information"
                        id="table.name.basic-device-information" />
                </Card.Header>
                <Card.Body>
                    <table className="table mb-0">
                        <tbody>
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage defaultMessage="Name:" id="table.label.name" />
                                    </strong>
                                </td>
                                <td className="text-break">{device.name}</td>
                            </tr>
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage defaultMessage="IMEI:" id="table.label.imei" />
                                    </strong>
                                </td>
                                <td className="text-break">{device.imei}</td>
                            </tr>
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage defaultMessage="Works from:" id="table.label.works-from" />
                                    </strong>
                                </td>
                                <td className="text-break">{getFormattedDate(new Date(device.dateStart))}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
            {extraContent}
        </CardColumns>} />
    </>;
}
