import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { MainContext, useUserFromContext } from '../components/Contexts';
import { Capabilities as HomeAutomationCapabilities } from '../types/home-automation/user';
import { Capabilities as InventoryCapabilities } from '../types/inventories/user';
import { Capabilities as PriceTagsCapabilities } from '../types/price-tags/user';
import { Capabilities as TrackingCapabilities } from '../types/tracking/user';
import { Capabilities as WarehouseCapabilities } from '../types/warehouse/user';
import { PATH as HOME_AUTOMATION_PATH } from './home-automation/Main';
import { PATH as INVENTORY_PATH } from './inventories/Main';
import { PATH as PRICE_TAGS_PATH } from './price-tags/Main';
import { PATH as TRACKING_PATH } from './tracking/Main';
import { PATH as WAREHOUSE_PATH } from './warehouse/Main';

export const PATH = '/';

export default () => {
    const { optionsButton } = useContext(MainContext);
    const user = useUserFromContext();
    const optionsUrl = optionsButton ? new URL('/#options', document.location.href) : null;

    return <div className="MainPage mb-3">
        <Row>
            {user.capabilities.includes(WarehouseCapabilities.WAREHOUSE_SHOW) ?
                <Col xs={6} className="mt-3">
                    <Link to={WAREHOUSE_PATH}>
                        <Card>
                            <Card.Body>
                                <FormattedMessage id="dashboard.warehouse" defaultMessage="Warehouse" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Col> :
                null}
            {user.capabilities.includes(HomeAutomationCapabilities.AUTOMATION_SHOW) ?
                <Col xs={6} className="mt-3">
                    <Link to={HOME_AUTOMATION_PATH}>
                        <Card>
                            <Card.Body>
                                <FormattedMessage id="dashboard.home-automation" defaultMessage="Automation" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Col> :
                null}
            {user.capabilities.includes(PriceTagsCapabilities.PRICE_TAGS_SHOW) ?
                <Col xs={6} className="mt-3">
                    <Link to={PRICE_TAGS_PATH}>
                        <Card>
                            <Card.Body>
                                <FormattedMessage id="dashboard.price-tags" defaultMessage="Price tags" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Col> :
                null}
            {user.capabilities.includes(InventoryCapabilities.INVENTORY_SHOW) ?
                <Col xs={6} className="mt-3">
                    <Link to={INVENTORY_PATH}>
                        <Card>
                            <Card.Body>
                                <FormattedMessage id="dashboard.inventory" defaultMessage="Inventory" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Col> :
                null}
            {user.capabilities.includes(TrackingCapabilities.TRACKING_DEVICE_SHOW) ?
                <Col xs={6} className="mt-3">
                    <Link to={TRACKING_PATH}>
                        <Card>
                            <Card.Body>
                                <FormattedMessage id="dashboard.tracking" defaultMessage="Tracking" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Col> :
                null}
            {optionsUrl ?
                <Col xs={6} className="mt-3">
                    <a href={optionsUrl.href}>
                        <Card>
                            <Card.Body>
                                <FormattedMessage id="dashboard.application-options"
                                    defaultMessage="Application options"/>
                            </Card.Body>
                        </Card>
                    </a>
                </Col> :
                null}
        </Row>
    </div>;
}
