import { Field, FieldProps } from 'formik';
import React, { ReactNode } from 'react';
import { FormControl, FormControlProps, FormGroup, FormLabel, InputGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FormFieldProps } from '../../types/form-field';
import './FormField.css';

interface TextInputFieldProps extends FormFieldProps, FormControlProps {
    max?: string;
    min?: string;
    renderAppend?: ReactNode;
    renderPrepend?: ReactNode;
    step?: string;
}

export default (
    { error, fieldId, name, renderAppend, renderPrepend, touched, ...props }: TextInputFieldProps,
) => <Field name={fieldId}>
    {({ field }: FieldProps<string>) => <FormGroup controlId={fieldId} className="FormField">
        {name ? <FormLabel>{name}</FormLabel> : null}
        <InputGroup>
            {renderPrepend ?
                <InputGroup.Prepend>{renderPrepend}</InputGroup.Prepend> :
                null}
            <FormControl value={field.value} onChange={field.onChange}
                className={error ? 'is-invalid' : ''} {...props} />
            {renderAppend ?
                <InputGroup.Append>{renderAppend}</InputGroup.Append> :
                null}
        </InputGroup>
        {error ?
            <div className="invalid-feedback" style={{ display: 'block' }}><FormattedMessage id={error} /></div> :
            null}
    </FormGroup>}
</Field>;
