import { FixedCapacityStorageModel, ProductVariantModel, StorageModel } from '../../types/warehouse/model';

export interface AmountInfo {
    currentMax: number;
    fixedMax: boolean;
    max: number;
    step: number;
    storageAmount?: number;
    unit: string;
    unitSuffix: string;
}

export const MAX_AMOUNT = 100000;

export function getCustomCapacityAmountInfo(amount: number, productVariant: ProductVariantModel): AmountInfo {
    return {
        currentMax: Math.max(Math.min(MAX_AMOUNT, amount * 2), productVariant.step * 10),
        fixedMax: false,
        max: MAX_AMOUNT,
        step: productVariant.step,
        unit: productVariant.unit,
        unitSuffix: '',
    };
}

function getFixedCapacityAmountInfo(amount: number, storage: FixedCapacityStorageModel): AmountInfo {
    const step = getStep(storage.amount);
    const max = Math.max(0, Math.round((storage.amount - storage.amountInUse + amount) / step) / (1 / step));
    return {
        currentMax: max,
        fixedMax: true,
        max,
        step,
        storageAmount: storage.amount,
        unit: storage.unit,
        unitSuffix: ` (${Math.round(max / storage.amount * 100)}%)`,
    };
}

export function getAmountInfo(
    amount: number, productVariant: ProductVariantModel, storage: StorageModel | null,
): AmountInfo {
    return storage && storage.fixedCapacity ?
        getFixedCapacityAmountInfo(amount, storage as FixedCapacityStorageModel) :
        getCustomCapacityAmountInfo(amount, productVariant);
}

function getStep(value: number): number {
    let step = 0.1;
    while (value / step !== Math.round(value / step)) {
        step /= 10;
    }
    return step;
}
