import React from 'react';
import { useIntl } from 'react-intl';
import { formatAmount } from '../../helpers/format';

interface FormatAmountProps {
    decimalPlaces?: number | null;
    unit?: string | null;
    value: number;
}

export default ({ decimalPlaces, unit, value }: FormatAmountProps) => {
    const { locale } = useIntl();
    return <>{formatAmount(locale, value, unit || null, decimalPlaces || null)}</>;
};
