import React from 'react';
import { useIntl } from 'react-intl';
import { ProductVariantModel } from '../../../types/warehouse/model';
import Selector from './Selector';

interface ProductVariantSelectorProps {
    buttonTitle?: string;
    excludedIds?: number[];
    onSelect: (productVariant: ProductVariantModel) => void;
    params?: { [key: string]: any };
}

export default ({ buttonTitle, excludedIds, onSelect, params }: ProductVariantSelectorProps) => {
    const { formatMessage } = useIntl();
    const props = {
        buttonTitle: buttonTitle || formatMessage({
            defaultMessage: 'Select product variant',
            id: 'product-variant-selector.button',
        }),
        excludedIds,
        modalTitle: formatMessage({
            defaultMessage: 'Select product variant',
            id: 'product-variant-selector.name',
        }),
        onSelect,
        params,
        placeholder: formatMessage({
            defaultMessage: 'Search for product variant',
            id: 'product-variant-selector.placeholder',
        }),
        renderItemName: (productVariant: ProductVariantModel) => productVariant.name ?
            <>{productVariant.product.name} <small>({productVariant.name})</small></> : productVariant.product.name,
    };

    return <Selector path="product-variants" {...props} />;
}
