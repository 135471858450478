import React from 'react';
import { useIntl } from 'react-intl';
import { getHomeAutomationApiUrl } from '../../../helpers/url';
import { DailyReport, Device } from '../../../types/home-automation/model';
import ChartBox from '../../Chart/ChartBox';

interface TemperaturesChartProps {
    date: string;
    device: Device;
    report: DailyReport;
}

const url = getHomeAutomationApiUrl('measurement-series');

export default ({ date, device, report }: TemperaturesChartProps) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ defaultMessage: 'Temperatures chart', id: 'temperatures-chart.title' });
    const descriptionY = formatMessage({ defaultMessage: 'temperature [%]', id: 'temperatures-chart.description-y' });

    return <ChartBox params={{ date, device: device.slug, type: 'temperature' }} title={title}
        descriptionY={descriptionY} report={report} url={url}
        getFormattedValue={value => `${Math.round(value * 100) / 100}°C`} />;
}
