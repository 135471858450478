import React from 'react';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import Menu from '../../components/warehouse/Menu/Menu';
import ProductUnit from '../../components/warehouse/ProductUnit/ProductUnit';
import { Section } from '../../types/section';
import { ProductUnitModel } from '../../types/warehouse/model';

export const PATH = `/${Section.WAREHOUSE}/product-units/:productUnitId`;

export default (
    { location, match }: RouteComponentProps<{ productUnitId: string }, { productUnit?: ProductUnitModel }>,
) =>
    <div className="WarehouseProductPage">
        <Menu />
        <ProductUnit id={parseInt(match.params.productUnitId, 10)}
            productUnit={location.state ? location.state.productUnit : undefined} />
    </div>;
