import { Action } from 'redux';
import { User } from '../helpers/auth-request';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_TOKEN_FETCHING = 'USER_TOKEN_FETCHING';
export const USER_GONE_BACK = 'USER_GONE_BACK';

interface UserBasicAction extends Action<string> {
    // nothing to add
}

interface UserLoggedInAction extends UserBasicAction {
    user: User;
}

export function tokenGenerate(): UserBasicAction {
    return {
        type: USER_TOKEN_FETCHING,
    };
}

export function loggedIn(user: User): UserLoggedInAction {
    return {
        type: USER_LOGGED_IN,
        user,
    };
}

export function loggedOut(): UserBasicAction {
    return {
        type: USER_LOGGED_OUT,
    };
}

export function goneBack(): UserBasicAction {
    return {
        type: USER_GONE_BACK,
    };
}
