import { getTimeOffsetByDeviceTimeZone } from '@majpage/raspi-tanks-logic';
import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, ButtonGroup, Card, CardColumns, InputGroup } from 'react-bootstrap';
import { FormattedMessage, IntlShape, useIntl  } from 'react-intl';
import { getFormattedDate } from '../../../helpers/time';
import { ControlField, Controls, useDeviceControls } from '../../../hooks/home-automation/use-device-controls';
import { Device, DeviceCapability } from '../../../types/home-automation/model';
import { Capabilities } from '../../../types/home-automation/user';
import { HomeAutomationTimeOffsetContext, useUserFromContext } from '../../Contexts';
import Message, { MessageType } from '../../Message/Message';
import MetadataSwitchSync from '../Controls/MetadataSwitchSync';
import Menu from '../Menu/Menu';
import {
    RASPI_DOOR_TYPE, RASPI_MIJIA_HUB_TYPE, RASPI_TANKS_TYPE, raspiDoorControlFields, raspiDoorControlsSwitchesRender,
    raspiDoorCustomRender, raspiMijiaHubControlFields, raspiMijiaHubControlsSwitchesRender, raspiMijiaHubCustomRender,
    raspiTanksControlFields, raspiTanksControlsAppendRender, raspiTanksControlsSwitchesRender, raspiTanksCustomRender,
} from '../types';
import './Device.css';

const DEFAULT_TIME_ZONE = 'UTC';

interface DeviceProps {
    device: Device;
}

const typeRender: { [type: string]: (device: Device, canManageDevice: boolean) => React.ReactNode } = {
    [RASPI_DOOR_TYPE]: raspiDoorCustomRender,
    [RASPI_MIJIA_HUB_TYPE]: raspiMijiaHubCustomRender,
    [RASPI_TANKS_TYPE]: raspiTanksCustomRender,
};
const typeFields: { [type: string]: ControlField[] } = {
    [RASPI_DOOR_TYPE]: raspiDoorControlFields,
    [RASPI_MIJIA_HUB_TYPE]: raspiMijiaHubControlFields,
    [RASPI_TANKS_TYPE]: raspiTanksControlFields,
};
const typeSwitchesRender: { [type: string]: ((controls: Controls, intl: IntlShape) => React.ReactNode) | null } = {
    [RASPI_DOOR_TYPE]: raspiDoorControlsSwitchesRender,
    [RASPI_MIJIA_HUB_TYPE]: raspiMijiaHubControlsSwitchesRender,
    [RASPI_TANKS_TYPE]: raspiTanksControlsSwitchesRender,
};
const typeAppendRender: {
    [type: string]: ((controls: Controls, device: Device, loaded: boolean, editable: boolean) => React.ReactNode) |
        null
} = {
    [RASPI_DOOR_TYPE]: null,
    [RASPI_MIJIA_HUB_TYPE]: null,
    [RASPI_TANKS_TYPE]: raspiTanksControlsAppendRender,
};

export default ({ device }: DeviceProps) => {
    const user = useUserFromContext();
    const intl = useIntl();
    const deviceUser = device ? device.users.find(item => item.userUuid === user.uuid) : undefined;
    const canManageDevice = deviceUser ? deviceUser.capability === DeviceCapability.MANAGE : false;
    const canManageConfig = user.capabilities.includes(Capabilities.AUTOMATION_MANAGE) && canManageDevice;
    const controlFields = typeFields[device.type] ? typeFields[device.type] : [];
    const switchesRender = typeSwitchesRender[device.type];
    const appendRender = typeAppendRender[device.type];
    const [editable, setEditable] = useState<boolean>(false);
    const [opened, setOpened] = useState<boolean>(false);
    // @FIXME: Value of timeOffset has to be recalculated at least each hour to handle daylight time change.
    const timeOffset = useMemo(
        () => getTimeOffsetByDeviceTimeZone(device.timeZone || DEFAULT_TIME_ZONE), [device.timeZone],
    );
    const [controls, versions, updateVersion, loaded, transactionalFieldsExist, transactionalFieldsMatch] =
        useDeviceControls(device, controlFields, appendRender ? true : opened);
    const versionsMatch = versions.local === versions.server;

    // @FIXME: Automatic version update takes place after rendering device so after each version update there is config
    //         version mismatch alert blink (versionsMatch is false for a while) which should be fixed
    useEffect(() => {
        if (!editable && !versionsMatch) {
            updateVersion();
        }
    }, [editable, versionsMatch, updateVersion]);

    return <HomeAutomationTimeOffsetContext.Provider value={{ homeAutomationTimeOffset: timeOffset }}>
        <Menu />
        {!versionsMatch ?
            <Alert variant="warning">
                <p>
                    <FormattedMessage id="device.config-version.mismatch"
                        values={{ local: versions.local, server: versions.server }}
                        defaultMessage="Local configuration version (v{local}) doesn't match server one (v{server})." />
                </p>
                <Button variant="warning" onClick={() => { updateVersion() }}>
                    <FormattedMessage id="device.config-version.update"
                        defaultMessage="Update local configuration version" />
                </Button>
            </Alert> :
            null}
        <CardColumns className="Device">
            <Card>
                <Card.Header>
                    <FormattedMessage defaultMessage="Basic device information"
                        id="table.name.basic-device-information" />
                </Card.Header>
                <Card.Body>
                    <table className="table mb-0">
                        <tbody>
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage defaultMessage="Name:" id="table.label.name" />
                                    </strong>
                                </td>
                                <td className="text-break">{device.name || device.slug}</td>
                            </tr>
                            {device.groupName ?
                                <tr>
                                    <td className="text-right">
                                        <strong>
                                            <FormattedMessage defaultMessage="Group name:"
                                                id="table.label.group-name" />
                                        </strong>
                                    </td>
                                    <td className="text-break">{device.groupName}</td>
                                </tr> :
                                null}
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage defaultMessage="Type:" id="table.label.type" />
                                    </strong>
                                </td>
                                <td className="text-break">{device.type}</td>
                            </tr>
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage defaultMessage="Works from:" id="table.label.works-from" />
                                    </strong>
                                </td>
                                <td className="text-break">{getFormattedDate(new Date(device.dateStart))}</td>
                            </tr>
                            {transactionalFieldsExist && canManageConfig ?
                                <tr>
                                    <td className="text-right">
                                        <strong>
                                            <FormattedMessage defaultMessage="Edit mode:" id="table.label.edit-mode" />
                                        </strong>
                                    </td>
                                    <td className="text-break">
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                    {editable ?
                                                        <FormattedMessage defaultMessage="on" id="edit-mode.on" /> :
                                                        <FormattedMessage defaultMessage="off" id="edit-mode.off" />
                                                    }
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            {editable ?
                                                <ButtonGroup>
                                                    <Button variant="success" { ...(versionsMatch ? { onClick: () => {
                                                            if (controls) {
                                                                controls.commit();
                                                            }
                                                            setEditable(false);
                                                        } } : { disabled: true }) }>
                                                        <FormattedMessage defaultMessage="Save" id="edit-mode.save" />
                                                    </Button>
                                                    <Button variant="danger" onClick={() => {
                                                        if (controls) {
                                                            controls.rollback();
                                                        }
                                                        setEditable(false);
                                                        if (!versionsMatch) {
                                                            updateVersion();
                                                        }
                                                    }}>
                                                        <FormattedMessage defaultMessage="Reject"
                                                            id="edit-mode.reject" />
                                                    </Button>
                                                </ButtonGroup> :
                                                <Button { ...(versionsMatch ? { onClick: () => {
                                                    setEditable(true);
                                                } } : { disabled: true }) }>
                                                    <FormattedMessage defaultMessage="Edit" id="edit-mode.edit" />
                                                </Button>}
                                        </InputGroup>
                                        <MetadataSwitchSync synced={transactionalFieldsMatch} />
                                    </td>
                                </tr> :
                                null}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
            {canManageConfig ?
                <>
                    {switchesRender ?
                        <Card className="Controls">
                            <Card.Header className={opened ? 'card-opened' : 'card-closed'} onClick={() => {
                                setOpened(!opened);
                            }}><FormattedMessage defaultMessage="Switches" id="table.name.switches" /></Card.Header>
                            {opened ?
                                <Card.Body>
                                    {!loaded || !controls ?
                                        <Message type={MessageType.FETCHING} /> :
                                        <table className="table mb-0">
                                            <tbody>{switchesRender(controls, intl)}</tbody>
                                        </table>}
                                </Card.Body> :
                                null}
                        </Card> :
                        null}
                    {appendRender && controls ?
                        appendRender(controls, device, loaded, editable) :
                        null}
                </> :
                null}
        </CardColumns>
        {typeRender[device.type] ? typeRender[device.type](device, canManageDevice) : null}
    </HomeAutomationTimeOffsetContext.Provider>;
}
