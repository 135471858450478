import React from 'react';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import Inventory from '../../components/inventories/Inventory/Inventory';
import Menu from '../../components/inventories/Menu/Menu';
import { Section } from '../../types/section';

export const PATH = `/${Section.INVENTORY}/:id`;

export default ({ match }: RouteComponentProps<{ id: string }>) => <div className="InventoriesInventoryPage">
    <Menu />
    <Inventory id={match.params.id} />
</div>;
