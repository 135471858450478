import { PlainObject } from '../collection';

export enum DeviceCapability {
    MANAGE = 'manage',
    VIEW = 'view',
}

export interface DeviceUser {
    capability: DeviceCapability;
    userUuid: string;
}

export interface Device {
    dateStart: string;
    groupName: string | null;
    name: string | null;
    slug: string;
    sync: boolean;
    timeZone: string | null;
    type: string;
    users: DeviceUser[];
}

export enum FailureReportType {
    DEFECT = 'defect',
    NO_CONNECTION = 'no-connection',
    PROBLEM = 'problem',
}

export interface FailureReport {
    description: string;
    id: number;
    timeEnd: string;
    timeStart: string;
    type: FailureReportType;
}

export enum DailyReportType {
    DEFECT = 'defect',
    EMPTY = 'empty',
    OK = 'ok',
    PROBLEM = 'problem',
}

export interface DailyReport {
    date: string;
    device: Device;
    failures: FailureReport[];
    type: DailyReportType;
}

export interface Event {
    id: number;
    params: PlainObject | null;
    time: string;
    type: string;
}

export type MeasurementType = 'humidity' | 'temperature';
export interface Measurement {
    id: number;
    series?: string;
    time: string;
    type: MeasurementType;
    value: number;
}

export interface MeasurementValue {
    timestamp: number;
    value: number;
}

export interface MeasurementSeries {
    data: MeasurementValue[];
    series: string | null;
    type: string;
}

export interface MeasurementSeriesResponse {
    list: MeasurementSeries[];
    timeEnd: number;
    timeStart: number;
}
