import React from 'react';
import Devices from '../../components/home-automation/Devices/Devices';
import Menu from '../../components/home-automation/Menu/Menu';
import { useReload } from '../../hooks/use-reload';
import { Section } from '../../types/section';

export const PATH = `/${Section.HOME_AUTOMATION}`;

export default () => {
    const [reload, reloading] = useReload();

    return <div className="HomeAutomationMainPage">
        <Menu reload={reload} />
        {reloading ?
            null :
            <Devices />}
    </div>;
};
