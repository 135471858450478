import { Field, FieldProps } from 'formik';
import React from 'react';
import { FormCheck, FormGroup } from 'react-bootstrap';
import { FormCheckProps } from 'react-bootstrap/FormCheck';
import { FormattedMessage } from 'react-intl';
import { FormFieldProps } from '../../types/form-field';
import './FormField.css';

interface CheckBoxFieldProps extends FormFieldProps, FormCheckProps {
    // nothing to add
}

export default ({ error, fieldId, name, touched, ...props }: CheckBoxFieldProps) => <Field name={fieldId}>
    {({ field }: FieldProps<boolean>) => <FormGroup controlId={fieldId} className="FormField">
        <FormCheck name={fieldId} type="checkbox" label={name} checked={field.value} onChange={field.onChange}
            className={error ? 'is-invalid' : ''} {...props} />
        {touched && error && <div className="invalid-feedback"><FormattedMessage id={error} /></div>}
    </FormGroup>}
</Field>;
