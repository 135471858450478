import { isEqual, isUndefined } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { Plus, X } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';
import { MatrixItemColumnType } from '../../../types/home-automation/raspi-tanks';
import './MatrixItem.css';

interface AnyColumn {
    title?: string;
    unique?: boolean;
}
interface NumericColumn extends AnyColumn {
    max?: number;
    min?: number;
    type: MatrixItemColumnType.INTEGER | MatrixItemColumnType.NUMBER;
}
interface SelectColumn extends AnyColumn {
    options: { key: any; name: string }[];
    type: MatrixItemColumnType.SELECT;
}
interface ExtendedSelectColumn extends AnyColumn {
    options: { available: boolean, key: any; name: string }[];
    type: MatrixItemColumnType.SELECT;
}
interface StringColumn extends AnyColumn {
    maxLength?: number;
    type: MatrixItemColumnType.STRING;
}
interface TimeColumn extends AnyColumn {
    type: MatrixItemColumnType.TIME;
}
export type Column = NumericColumn | SelectColumn | StringColumn | TimeColumn;
type ExtendedColumn = NumericColumn | ExtendedSelectColumn | StringColumn | TimeColumn;
type Row = any[];
enum FieldType {
    BOOLEAN = 'boolean',
    FLOAT = 'float',
    INTEGER = 'integer',
    STRING = 'string',
}

function setInitialData(columns: Column[]): Row {
    return Array(columns.length).fill(undefined, 0, columns.length);
}

function createOnAdd(
    onChange: (rows: Row[]) => void, rows: Row[], newRow: Row, setNewRow: (newRow: Row) => void,
    columns: ExtendedColumn[],
) {
    return () => {
        onChange([
            ...rows,
            columns.map((column, i) => {
                if (column.type !== MatrixItemColumnType.SELECT) {
                    return newRow[i];
                }
                const j = parseInt(`${newRow[i]}`, 10) - 1;
                return column.options[j] ? column.options[j].key : newRow[i];
            }),
        ]);
        setNewRow(setInitialData(columns));
    }
}

function createOnChange(
    index: number, newRow: Row, setNewRow: (newRow: Row) => void, type: FieldType = FieldType.STRING,
) {
    return (event: any) => {
        const values = [...newRow];
        switch (type) {
            case FieldType.BOOLEAN:
                values[index] = !!event.target.value;
                break;
            case FieldType.FLOAT:
                values[index] = parseFloat(event.target.value);
                break;
            case FieldType.INTEGER:
                values[index] = parseInt(event.target.value, 10);
                break;
            case FieldType.STRING:
            default:
                values[index] = event.target.value;
                break;
        }
        setNewRow(values);
    };
}

function createOnDelete(onChange: (rows: Row[]) => void, rows: Row[], rowId: number) {
    return () => {
        onChange(rows.filter((_, i) => i !== rowId));
    }
}

function getCellName(column: Column, value: any) {
    if (column.type !== MatrixItemColumnType.SELECT) {
        return value;
    }
    const option = column.options.find(({ key }) => isEqual(key, value));
    return option ? option.name : value;
}

interface MatrixItemProps<I, R> {
    canAdd?: boolean;
    columns: Column[];
    errors?: string[];
    item?: I,
    itemReadonly?: I;
    itemToRows: (item: I) => R[];
    maxRows?: number;
    name?: string;
    onChange?: (rows: R[]) => void;
}

// @FIXME: Resolve problem with R/Row types.
export default function MatrixItem<I, R>({
    canAdd, columns, errors, item, itemReadonly, itemToRows, maxRows, name, onChange,
}: MatrixItemProps<I, R>) {
    const [newRow, setNewRow] = useState<Row>(() => setInitialData(columns));
    const hasTitle = useMemo(() => columns.some(({ title }) => !!title), [columns]);
    const rows = useMemo<Row[]>(() => (item ? itemToRows(item) : []) as Row, [item, itemToRows]);
    const rowsReadonly = useMemo<Row[]>(
        () => (itemReadonly ? itemToRows(itemReadonly) : []) as Row, [itemReadonly, itemToRows],
    );
    const extendedColumns = useMemo<ExtendedColumn[]>(() => columns.map((column, i) => {
        if (column.type !== MatrixItemColumnType.SELECT) {
            return column;
        }
        return {
            ...column,
            options: column.options.map(option => ({
                available: !column.unique || !rows.some((row: Row) => isEqual(row[i], option.key)),
                ...option,
            })),
        };
    }), [columns, rows]);
    const canAddRow = useMemo(() => canAdd !== false && (!maxRows || rows.length < maxRows) && columns.every(
        column => column.type !== MatrixItemColumnType.SELECT || !column.unique || rows.length < column.options.length
    ), [columns, canAdd, maxRows, rows.length]);
    const isNewRowReady = useMemo(() => newRow.every(cell => !isUndefined(cell)), [newRow]);

    useEffect(() => {
        const columnChanges = extendedColumns.map((column, i) => {
            if (column.type !== MatrixItemColumnType.SELECT) {
                return null;
            }
            const availableOptionIds = column.options.reduce<string[]>((list, { available }, i) => {
                if (available) {
                    list.push(`${i + 1}`);
                }
                return list;
            }, []);
            return !availableOptionIds.includes(`${newRow[i]}`) && availableOptionIds.length > 0 ?
                availableOptionIds[0] : null;
        });
        if (columnChanges.some(change => change !== null)) {
            setNewRow(newRow.map((value, i) => columnChanges[i] === null ? value : columnChanges[i]));
        }
    }, [extendedColumns, newRow, setNewRow])

    const matrix = <>
        <table>
            {hasTitle ?
                <thead>
                    <tr>
                        {columns.map(({ title }, i) => title ? <th key={i}>{title}</th> : null)}
                    </tr>
                </thead> :
                null}
            <tbody>
                {rowsReadonly.map((row: Row, rowId) => <tr key={rowId} className="text-readonly">
                    {columns.map((column, j) => <td key={j}>
                        {getCellName(column, row[j])}
                    </td>)}
                    <td className="button-cell"></td>
                </tr>)}
                {rows.map((row: Row, rowId) => <tr key={rowId}>
                    {columns.map((column, j) => <td key={j}>
                        {getCellName(column, row[j])}
                    </td>)}
                    <td className="button-cell">
                        {onChange ?
                            <Button size="sm" variant="danger" onClick={createOnDelete(
                                // @FIXME: Remove "as" from here when R/Row types problem will be solved.
                                onChange as () => void, rows, rowId,
                            )}>
                                <X>
                                    <FormattedMessage id="tanks.button.remove" defaultMessage="Remove element" />
                                </X>
                            </Button> :
                            null}
                    </td>
                </tr>)}
                {onChange && canAddRow ?
                    <tr>
                        {extendedColumns.map((column, i) => <td key={i}>
                            {(() => {
                                switch (column.type) {
                                    case MatrixItemColumnType.INTEGER:
                                    case MatrixItemColumnType.NUMBER:
                                        return <FormControl type="number" max={column.max} min={column.min}
                                            onChange={createOnChange(
                                                i, newRow, setNewRow, column.type === MatrixItemColumnType.INTEGER ?
                                                    FieldType.INTEGER : FieldType.FLOAT,
                                            )} value={newRow[i] || 0}/>;

                                    case MatrixItemColumnType.SELECT:
                                        return <FormControl as="select"
                                            onChange={createOnChange(i, newRow, setNewRow)}>
                                            {column.options.map(({ available, name }, j) => available ?
                                                <option key={j} value={j + 1}>{name}</option> :
                                                null)}
                                        </FormControl>;

                                    case MatrixItemColumnType.STRING:
                                        return <FormControl type="text" maxLength={column.maxLength}
                                            onChange={createOnChange(i, newRow, setNewRow)} value={newRow[i] || ''} />;

                                    case MatrixItemColumnType.TIME:
                                        return <FormControl type="time"
                                            onChange={createOnChange(i, newRow, setNewRow)} value={newRow[i] || ''} />;
                                }
                            })()}
                        </td>)}
                        <td className="button-cell">
                            <Button size="sm" variant="success" {...(isNewRowReady ? { onClick: createOnAdd(
                                // @FIXME: Remove "as" from here when R/Row types problem will be solved.
                                onChange as () => void, rows, newRow, setNewRow, extendedColumns,
                            ) } : { disabled: true }) }>
                                <Plus>
                                    <FormattedMessage id="tanks.button.add" defaultMessage="Add element" />
                                </Plus>
                            </Button>
                        </td>
                    </tr> :
                    null}
            </tbody>
        </table>
        {errors ?
            <>
                {errors.map((error, i) => <div key={i} className="invalid-feedback" style={{ display: 'block' }}>
                    {error}
                </div>)}
            </>:
            null}
    </>;
    return name ?
        <FormGroup className="MatrixItem">
            <FormLabel>{name}</FormLabel>
            {matrix}
        </FormGroup> :
        <div className="MatrixItem">{matrix}</div>;
};
