import React from 'react';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { PATH as WAREHOUSE_PRODUCER_PATH } from '../../../routes/warehouse/Producer';
import { ProducerModel } from '../../../types/warehouse/model';

interface ProducerProps {
    producer: ProducerModel;
}

export default ({ producer }: ProducerProps) => <div className="toast show">
    <div className="toast-header">
        <strong className="mr-auto">
            <Link to={{
                pathname: getPath(WAREHOUSE_PRODUCER_PATH, { producerId: producer.id }),
                state: { producer }
            }}>{producer.name}</Link>
        </strong>
        {producer.mark ? <span className="badge badge-warning">{producer.mark}</span> : null}
    </div>
    {producer.description ? <div className="toast-body text-break">{producer.description}</div> : null}
</div>;
