import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, CardColumns } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { HttpMethod, authenticatedRequest } from '../../../helpers/auth-request';
import { getChangedPriceTagsUrl, getPriceTagsApiUrl } from '../../../helpers/url';
import { useResourcesList } from '../../../hooks/use-resources-list';
import { ProductChange } from '../../../types/price-tags/model';
import { Capabilities } from '../../../types/price-tags/user';
import { useUserFromContext } from '../../Contexts';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Message, { MessageType } from '../../Message/Message';
import ProductChangesList from '../ProductChangesList/ProductChangesList';
import './ProductChanges.css';

export default () => {
    const { formatMessage } = useIntl();
    const user = useUserFromContext();
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [applying, setApplying] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    // It would be better to have a real refresh function instead of this workaround.
    const refresh = useCallback(() => { setPage(0); setPage(1) }, [setPage]);
    const listUrl = useMemo(() => getPriceTagsApiUrl('products/changed'), []);
    const applyingUrl = useMemo(() => getPriceTagsApiUrl('products/apply'), []);
    const [changes, { error, isLast, loading }] = useResourcesList<ProductChange>(listUrl, page);
    const onToggle = useCallback((change: ProductChange) => {
        if (selectedIds.includes(change.id)) {
            setSelectedIds(selectedIds.filter(id => id !== change.id));
        } else {
            setSelectedIds([...selectedIds, change.id]);
        }
    }, [selectedIds, setSelectedIds]);
    const selectMany = useCallback((changes: ProductChange[], selected: boolean) => {
        const ids = changes.map(({ id }) => id);
        if (!selected) {
            setSelectedIds(selectedIds.filter(id => !ids.includes(id)));
        } else {
            setSelectedIds([...selectedIds, ...ids]);
        }
    }, [selectedIds, setSelectedIds]);
    const applySelected = useCallback(async () => {
        if (applying || selectedIds.length === 0) {
            return;
        }
        setApplying(true);
        try {
            await authenticatedRequest({ data: { changeIds: selectedIds }, method: HttpMethod.POST, url: applyingUrl });
        } catch (error) {
            // nothing to do
        }
        refresh();
        setApplying(false);
    }, [applying, applyingUrl, refresh, selectedIds, setApplying]);
    const changesToPrint = useMemo(() => changes.filter(({ toPrint }) => toPrint), [changes]);
    const changesNotToPrint = useMemo(() => changes.filter(({ toPrint }) => !toPrint), [changes]);

    if (changes.length === 0) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else if (isLast) {
            return <Message type={MessageType.NO_RESULTS} />;
        }
    }

    return <>
        <ErrorMessage error={error} />
        {user.capabilities.includes(Capabilities.PRICE_TAGS_MANAGE) ?
            <CardColumns className="ProductChangesControls">
                <Card className="ProductChangesList">
                    <Card.Header className="text-center">
                        <Button variant="danger" disabled={applying || selectedIds.length === 0}
                            onClick={applySelected}>
                            <FormattedMessage defaultMessage="Apply selected" id="price-tags.apply-selected" />
                        </Button>
                    </Card.Header>
                </Card>
            </CardColumns> :
            null}
        <CardColumns className="ProductChangesLists">
            {changesToPrint.length > 0 ?
                <ProductChangesList changes={changesToPrint} selectedIds={selectedIds} title={formatMessage({
                    defaultMessage: 'Changes to print', id: 'price-tags.changes.to-print',
                })} id="changes-to-print" onToggle={onToggle} selectMany={selectMany} headerPrepend={
                    <a href={getChangedPriceTagsUrl()} className="btn btn-primary btn-sm float-right" target="_blank"
                        rel="noopener noreferrer">
                        <FormattedMessage defaultMessage="List of price tags to change"
                            id="price-tags.list-to-print" />
                    </a>
                } /> :
                null}
            {changesNotToPrint.length > 0 ?
                <ProductChangesList changes={changesNotToPrint} selectedIds={selectedIds} title={formatMessage({
                    defaultMessage: 'Changes not to print', id: 'price-tags.changes.not-to-print',
                })} id="changes-not-to-print" onToggle={onToggle} selectMany={selectMany} /> :
                null}
        </CardColumns>
    </>;
};
