import React from 'react';
import { IdCodeContext } from '../../components/Contexts';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import Menu from '../../components/warehouse/Menu/Menu';
import ProductVariant from '../../components/warehouse/ProductVariant/ProductVariant';
import { getIdCode } from '../../helpers/id-code';
import { Section } from '../../types/section';
import { ProductVariantModel } from '../../types/warehouse/model';

export const PATH = `/${Section.WAREHOUSE}/product-variants/:productVariantId`;

export default (
    { location, match }: RouteComponentProps<{ productVariantId: string }, { productVariant?: ProductVariantModel }>
) => {
    const idCode = getIdCode(location.search);

    return <IdCodeContext.Provider value={{ idCode }}>
        <div className="WarehouseProductVariantPage">
            <Menu />
            <ProductVariant id={parseInt(match.params.productVariantId, 10)}
                productVariant={location.state ? location.state.productVariant : undefined} />
        </div>
    </IdCodeContext.Provider>;
}
