import React from 'react';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import Device from '../../components/tracking/Device/Device';
import { Device as DeviceModel } from '../../types/tracking/model';
import { Section } from '../../types/section';

export const PATH = `/${Section.TRACKING}/devices/:imei`;

export default ({ location, match }: RouteComponentProps<{ imei: string }, { device?: DeviceModel }>) =>
    <Device device={location.state && location.state.device ? location.state.device : undefined}
        imei={match.params.imei} />
