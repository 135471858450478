import React from 'react';
import { useIntl } from 'react-intl';
import { getHomeAutomationApiUrl } from '../../../helpers/url';
import { Device, Measurement } from '../../../types/home-automation/model';
import List from '../../List/List';

interface BatteryLevelsProps {
    date: string;
    device: Device;
}

const url = getHomeAutomationApiUrl('measurements');

export default ({ date, device }: BatteryLevelsProps) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ defaultMessage: 'Battery levels', id: 'battery-levels.title' });

    return <List params={{ date, device: device.slug, type: 'battery' }} title={title} url={url}
        renderItem={(measurement: Measurement) => <div key={measurement.id} className="toast show">
            <div className="toast-header">
                <strong className="mr-auto">
                    {measurement.value}%
                    {measurement.series ? <em className="ml-2">{measurement.series}</em> : null}
                </strong>
                <small className="text-muted">{(new Date(measurement.time)).toLocaleTimeString()}</small>
            </div>
        </div>} />;
}
