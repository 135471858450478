import React from 'react';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { getProductUnitName } from '../../../helpers/warehouse/names';
import { PATH as WAREHOUSE_PRODUCT_UNIT_PATH } from '../../../routes/warehouse/ProductUnit';
import { ProductUnitModel } from '../../../types/warehouse/model';

interface ProductUnitProps {
    productUnit: ProductUnitModel;
}

export default ({ productUnit }: ProductUnitProps) => <div className="toast show">
    <div className="toast-header">
        <strong className="mr-auto">
            <Link to={{
                pathname: getPath(WAREHOUSE_PRODUCT_UNIT_PATH, { productUnitId: productUnit.id }),
                state: { productUnit }
            }}>{getProductUnitName(productUnit)}</Link>
        </strong>
    </div>
    {productUnit.description ? <div className="toast-body text-break">{productUnit.description}</div> : null}
</div>;
