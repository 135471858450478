import React from 'react';
import { Form, FormGroup, FormLabel, InputGroup } from 'react-bootstrap';
import './SwitchItem.css';

interface SwitchItemProps {
    disabled?: boolean;
    errors?: string[];
    id: string;
    label?: string;
    name?: string;
    onChange?: (value: boolean) => void;
    value: boolean;
}

export default (
    { errors, name, onChange, value, ...props }: SwitchItemProps,
) => <FormGroup className="SwitchItem">
    {name ? <FormLabel>{name}</FormLabel> : null}
    <InputGroup>
        <Form.Check {...{
            checked: value,
            ...(onChange ? { onChange: (event: any) => { onChange(event.target.checked) } } : {}),
            type: 'switch',
            ...props,
        }} />
    </InputGroup>
    {errors ?
        <>
            {errors.map((error, i) => <div key={i} className="invalid-feedback" style={{ display: 'block' }}>
                {error}
            </div>)}
        </>:
        null}
</FormGroup>;
