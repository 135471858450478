import React from 'react';
import { Alert, Card, CardColumns } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { getFormattedDate } from '../../../helpers/time';
import { getProductVariantName } from '../../../helpers/warehouse/names';
import { HttpErrors } from '../../../hooks/use-http-error';
import { useProductUnit } from '../../../hooks/warehouse/use-resources';
import { PATH as WAREHOUSE_PRODUCER_PATH } from '../../../routes/warehouse/Producer';
import { PATH as WAREHOUSE_PRODUCT_VARIANT_PATH } from '../../../routes/warehouse/ProductVariant';
import { PATH as WAREHOUSE_STORAGE_PATH } from '../../../routes/warehouse/Storage';
import { ProductUnitModel } from '../../../types/warehouse/model';
import Message, { MessageType } from '../../Message/Message';
import ProductComments from '../Comments/ProductComments';
import Mark from '../Mark/Mark';
import ProductBindings from '../ProductBindings/ProductBindings';
import ProductTags from '../ProductTags/ProductTags';
import './ProductUnit.css';
import ProductUnitRentals from '../ProductUnitRentals/ProductUnitRentals';
import ProductResources from '../Resources/ProductResources';
import ProductVariantResources from '../Resources/ProductVariantResources';

interface ProductUnitProps {
    id: number;
    productUnit?: ProductUnitModel;
}

export default ({ id, productUnit: preloadedProductUnit }: ProductUnitProps) => {
    const [productUnit, { error, loading }, reload] = useProductUnit(
        { id }, preloadedProductUnit && preloadedProductUnit.productVariant ? preloadedProductUnit : undefined,
    );

    if (!productUnit) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                {error === HttpErrors.NOT_FOUND_ERROR ?
                    <FormattedMessage id="product-unit.no-product-unit"
                        defaultMessage="There is no such product unit." /> :
                    <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />}
            </Alert>;
        }
    }

    const { productVariant } = productUnit;
    const { product } = productVariant;
    const { producer } = product;
    return <CardColumns className="ProductUnit">
        <Card>
            <Card.Header>
                <FormattedMessage id="product-unit.title" defaultMessage="Product unit info" />
                <Mark value={product.mark} />
            </Card.Header>
            <Card.Body>
                <table className="table mb-0">
                    <tbody>
                        {productUnit.name ?
                            <tr>
                                <td className="text-right">
                                    <strong><FormattedMessage id="table.label.name" defaultMessage="Name:" /></strong>
                                </td>
                                <td className="text-break">{productUnit.name}</td>
                            </tr> :
                            null}
                        <tr>
                            <td className="text-right">
                                <strong><FormattedMessage id="table.label.product" defaultMessage="Product:" /></strong>
                            </td>
                            <td className="text-break">
                                <Link to={{
                                    pathname: getPath(
                                        WAREHOUSE_PRODUCT_VARIANT_PATH, { productVariantId: productVariant.id },
                                    ),
                                    state: { productVariant }
                                }}>{getProductVariantName(productVariant)}</Link>
                            </td>
                        </tr>
                        {producer ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.producer" defaultMessage="Producer:" />
                                    </strong>
                                </td>
                                <td className="text-break">
                                    <Link to={{
                                        pathname: getPath(WAREHOUSE_PRODUCER_PATH, { producerId: producer.id }),
                                        state: { producer }
                                    }}>{producer.name}</Link>
                                </td>
                            </tr>:
                            null}
                        {productUnit.description ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.description" defaultMessage="Description:" />
                                    </strong>
                                </td>
                                <td className="text-break">{productUnit.description}</td>
                            </tr>:
                            null}
                        {productUnit.serialNumber ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.serial-number"
                                            defaultMessage="Serial number:" />
                                    </strong>
                                </td>
                                <td className="text-break">{productUnit.serialNumber}</td>
                            </tr>:
                            null}
                        {productUnit.storageId && productUnit.storage ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.storage" defaultMessage="Storage:" />
                                    </strong>
                                </td>
                                <td className="text-break">
                                    <Link to={{
                                        pathname: getPath(
                                            WAREHOUSE_STORAGE_PATH, { storageId: productUnit.storageId },
                                        ),
                                        state: { storage: productUnit.storage }
                                    }}>{productUnit.storage.name}</Link>
                                </td>
                            </tr>:
                            null}
                        {productUnit.inServiceFrom ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.in-service-from"
                                            defaultMessage="In service from:" />
                                    </strong>
                                </td>
                                <td className="text-break">{getFormattedDate(new Date(productUnit.inServiceFrom))}</td>
                            </tr>:
                            null}
                        {productUnit.qrCode ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.qr-code" defaultMessage="QR code:" />
                                    </strong>
                                </td>
                                <td className="text-break">{productUnit.qrCode}</td>
                            </tr> :
                            null}
                        {productUnit.barcode ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.barcode" defaultMessage="Barcode:" />
                                    </strong>
                                </td>
                                <td className="text-break">{productUnit.barcode}</td>
                            </tr> :
                            null}
                        {productUnit.nfcTag ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.nfc-tag" defaultMessage="NFC tag:" />
                                    </strong>
                                </td>
                                <td className="text-break">{productUnit.nfcTag}</td>
                            </tr> :
                            null}
                        {productUnit.rfidTag ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.rfid-tag" defaultMessage="RFID tag:" />
                                    </strong>
                                </td>
                                <td className="text-break">{productUnit.rfidTag}</td>
                            </tr> :
                            null}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
        <ProductBindings product={product} onAdd={reload} />
        <ProductTags productId={product.id} />
        {productUnit.rentalsAvailable ? <ProductUnitRentals id={productUnit.id} /> : null}
        <ProductResources id={product.id} />
        <ProductVariantResources id={productVariant.id} />
        <ProductComments id={product.id} onAdd={reload} />
    </CardColumns>;
}
