import { TankType } from '@majpage/raspi-tanks-logic';
import { addOrEditById, removeById } from './collections';

export function changeTankType(tankTypes: TankType[], newTankType: TankType): TankType[] {
    return addOrEditById<TankType>(tankTypes, newTankType);
}

export function deleteTankType(tankTypes: TankType[], oldTankType: TankType): TankType[] {
    return removeById<TankType>(tankTypes, oldTankType);
}
