import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { useResourcesList } from '../../../hooks/use-resources-list';
import { ProductUnitModel, ProductVariantModel, StorageModel } from '../../../types/warehouse/model';
import { Capabilities } from '../../../types/warehouse/user';
import { useIdCodeFromContext, useUserFromContext } from '../../Contexts';
import ListTemplate from '../../List/ListTemplate';
import ProductUnitAddForm, { ID_CODE_FORM } from '../ProductUnitAddForm/ProductUnitAddForm';
import ProductUnit from './ProductUnit';

const url = getWarehouseApiUrl('product-units');

interface ProductUnitsProps {
    baseUrl: string;
    productVariant?: ProductVariantModel;
    storage?: StorageModel;
}

export default ({ baseUrl, productVariant, storage }: ProductUnitsProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const idCode = useIdCodeFromContext(data => data.form === ID_CODE_FORM);
    const params: { productVariantId?: number, storageId?: number } = {};
    if (productVariant) {
        params.productVariantId = productVariant.id;
    }
    if (storage) {
        params.storageId = storage.id;
    }
    const [items, { addItem, error, isLast, loading, total }] = useResourcesList<any>(url, page, params);
    const user = useUserFromContext();
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (idCode) {
            setOpened(true);
            setPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(idCode)]);

    const title = formatMessage({ defaultMessage: 'Product units', id: 'product-units.title' });
    const listPrefix = user.capabilities.includes(Capabilities.UNITS_MANAGE) ?
        <ProductUnitAddForm baseUrl={baseUrl} idCode={idCode} productVariant={productVariant} storage={storage}
            onAdd={addItem} /> : null;
    const renderItem = (productUnit: ProductUnitModel) =>
        <ProductUnit key={productUnit.id} productUnit={productUnit} />;
    const listProps = {
        error, isLast, items, listPrefix, loading, opened, page, renderItem, setOpened, setPage, title, total,
    };

    return <ListTemplate {...listProps} />;
};
