import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useProductBindings } from '../../../hooks/warehouse/use-resources';
import { ProductModel } from '../../../types/warehouse/model';
import { Capabilities } from '../../../types/warehouse/user';
import { useUserFromContext } from '../../Contexts';
import ListTemplate from '../../List/ListTemplate';
import ProductBindingAddForm from '../ProductBindingAddForm/ProductBindingAddForm';
import Product from '../Products/Product';

interface ProductBindingsProps {
    onAdd: () => void;
    product: ProductModel;
}

export default ({ onAdd, product }: ProductBindingsProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [items, { addItem, error, isLast, loading, total }] = useProductBindings(product.id, page);
    const user = useUserFromContext();
    const { formatMessage } = useIntl();

    const title = formatMessage({ defaultMessage: 'Product bindings list', id: 'product-bindings.title' });
    const listPrefix = user.capabilities.includes(Capabilities.PRODUCTS_MANAGE) ?
        <ProductBindingAddForm onAdd={otherProduct => { addItem(otherProduct); onAdd() }} product={product}
            excludedIds={items.map(({ id }) => id)}/> :
        null;
    const renderItem = (product: ProductModel) => <Product key={product.id} product={product} />;
    const listProps = {
        error, isLast, items, listPrefix, loading, opened, page, renderItem, setOpened, setPage, title, total,
    };

    return <ListTemplate {...listProps} />;
};
