import { useEffect, useState } from 'react';
import { authenticatedRequest, HttpMethod } from '../helpers/auth-request';
import { ifNotAborted } from '../helpers/request';
import { PlainObject } from '../types/collection';
import { HttpErrors, useHttpError } from './use-http-error';

const COUNTER_INITIAL_STATE = 1;

export function useResource<U>(
    url: string | null, params: PlainObject = {}, preloadedItem: U | null = null,
): [U | null, { error: HttpErrors; loading: boolean }, () => void] {
    const [item, setItem] = useState<U | null>(preloadedItem);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, catchError, resetError] = useHttpError();
    const [counter, setCounter] = useState<number>(COUNTER_INITIAL_STATE);
    const usePreloadedItem = counter === COUNTER_INITIAL_STATE && preloadedItem;

    const paramsJson = JSON.stringify(params);
    useEffect(() => {
        if (!url || usePreloadedItem) {
            return;
        }
        setLoading(true);
        const request = new AbortController();
        authenticatedRequest<U>({
            method: HttpMethod.GET,
            params,
            url,
        }, request.signal).then(ifNotAborted<(item: U) => void>(request, item => {
            setItem(item);
            resetError();
        })).finally(ifNotAborted(request, () => {
            setLoading(false);
        })).catch(ifNotAborted(request, catchError));
        return () => request.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, paramsJson, counter]);

    useEffect(() => {
        if (usePreloadedItem) {
            setItem(preloadedItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preloadedItem ? JSON.stringify(preloadedItem) : null]);

    function reload() {
        setCounter(counter + 1);
    }

    return [item, { error, loading }, reload];
}
