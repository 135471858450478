import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import MetadataSwitchSync from './MetadataSwitchSync';

interface MetadataSwitchProps {
    id: string;
    onChange: (newValue: any) => void;
    switched: boolean;
    synced: boolean;
}

export default ({ id, onChange, switched, synced }: MetadataSwitchProps) => <Form.Check id={id} type="switch"
    className="ControlsSwitch">
    <Form.Check.Input onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
        checked={switched} />
    <Form.Check.Label>
        {switched ?
            <FormattedMessage defaultMessage="switched on" id="controls.switched-on"/> :
            <FormattedMessage defaultMessage="switched off" id="controls.switched-off"/>}
    </Form.Check.Label>
    <MetadataSwitchSync synced={synced} />
</Form.Check>;
