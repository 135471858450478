import { Relay, TankType, TankTypeName } from '@majpage/raspi-tanks-logic';
import { uniq } from 'lodash';
import React, { useState } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { changeTankType, deleteTankType } from '../../../helpers/home-automation/tank-types';
import { Control } from '../../../hooks/home-automation/use-device-controls';
import Message, { MessageType } from '../../Message/Message';
import TankForm from '../Tank/TankForm';
import TankTypeComponent from '../TankType/TankType';

interface TankTypesProps {
    editable: boolean;
    loaded: boolean;
    relays: Control<Relay[]>;
    tankTypes: Control<TankType[]>;
}

export default ({ editable, loaded, relays, tankTypes }: TankTypesProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const relaysList = relays.modifiedValue || relays.value || [];
    const tankTypesInUse = uniq(relaysList.reduce<TankTypeName[]>((list, relay) => {
        list.push(...relay.tanks.reduce<TankTypeName[]>((subList, tank) => {
            if (tank.types) {
                subList.push(...tank.types);
            }
            return subList;
        }, []));
        return list;
    }, []));
    const tankTypesList = tankTypes.modifiedValue || tankTypes.value || [];
    const tankTypeNamesInUse = tankTypesList.map(tankType => tankType.name);

    return <Card className="TankTypes">
        <Card.Header className={opened ? 'card-opened' : 'card-closed'} onClick={() => {
            setOpened(!opened);
        }}><FormattedMessage defaultMessage="Tank types" id="table.name.tank-types" /></Card.Header>
        {opened ?
            <Card.Body>
                {!loaded ?
                    <Message type={MessageType.FETCHING} /> :
                    <>
                        <ListGroup>
                            {tankTypesList.map(tankType => <TankTypeComponent onChange={newTankType => {
                                tankTypes.change(changeTankType(tankTypesList, newTankType));
                            }} tankType={tankType} tankTypesInUse={tankTypesInUse} onDelete={oldTankType => {
                                tankTypes.change(deleteTankType(tankTypesList, oldTankType));
                            }} namesInUse={tankTypeNamesInUse.filter(name => {
                                return name !== tankType.name;
                            })} editable={editable} key={tankType.id} />)}
                        </ListGroup>
                        {editable ?
                            <TankForm renderItem={setModalShown => <Button variant="primary" size="sm" onClick={() => {
                                setModalShown(true);
                            }} className="mt-2" block>
                                <FormattedMessage id="form.tank-type-add" defaultMessage="Add tank type" />
                            </Button>} onChange={(newTankType) => {
                                tankTypes.change(changeTankType(tankTypesList, newTankType));
                            }} isType={true} namesInUse={tankTypeNamesInUse} /> :
                            null}
                    </>}
            </Card.Body> :
            null}
    </Card>;
};
