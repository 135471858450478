import { decode } from 'jsonwebtoken';
import { useEffect, useState } from 'react';
import { authenticatedRequest, HttpMethod } from '../../helpers/auth-request';
import { ifNotAborted } from '../../helpers/request';
import { SECOND_IN_MS } from '../../helpers/time';
import { getWarehouseApiUrl } from '../../helpers/url';

const SESSION_STORAGE_NAME = 'localJwts';

interface LocalJwt {
    exp: number;
    token: string;
}

interface LocalJwts {
    [type: string]: LocalJwt;
}

function getLocalJwts(): LocalJwts {
    const jwtsJson = sessionStorage.getItem(SESSION_STORAGE_NAME);
    if (!jwtsJson) {
        return {};
    }
    const jwts = JSON.parse(jwtsJson);
    const now = Date.now();
    return Object.entries<LocalJwt>(jwts).reduce<LocalJwts>((filteredJwts, [type, currentJwt]) => {
        if (currentJwt.exp > now) {
            filteredJwts[type] = currentJwt;
        }
        return filteredJwts;
    }, {});
}

function getLocalJwt(type: string): LocalJwt | null {
    const jwts = getLocalJwts();
    return jwts[type] || null;
}

function getLocalJwtToken(type: string): string | null {
    const localJwt = getLocalJwt(type);
    return localJwt ? localJwt.token : null;
}

function setForType(type: string, token: string) {
    const { exp: expSec } = decode(token) as { exp: number };
    const exp = expSec * SECOND_IN_MS;
    const now = Date.now();
    if (exp <= now) {
        return;
    }
    const jwts = getLocalJwts();
    jwts[type] = { exp, token };
    sessionStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify(jwts));
}

export function useLocalJwt(type: string): [string | null, () => void] {
    const [status, setStatus] = useState<boolean | null>(!!getLocalJwt(type));

    useEffect(() => {
        if (status !== null) {
            return;
        }
        if (!!getLocalJwt(type)) {
            setStatus(true);
            return;
        }
        const request = new AbortController();
        authenticatedRequest<{ token: string }>({
            method: HttpMethod.GET,
            url: getWarehouseApiUrl('tokens/:type', { params: { type } }),
        }, request.signal).then(ifNotAborted<(data: { token: string }) => void>(request, ({ token }) => {
            setForType(type, token);
            setStatus(true);
        }));
        return () => request.abort();
    }, [status, type]);

    return [getLocalJwtToken(type), () => {
        if (status === false || (status === true && !getLocalJwt(type))) {
            setStatus(null);
        }
    }];
}
