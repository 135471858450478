import { Relay } from '@majpage/raspi-tanks-logic';
import { FormatMessage } from '../../types/react-intl';
import { addOrEditById, removeById } from './collections';

export function getRelayChannelOptions(formatMessage: FormatMessage) {
    return [
        { key: '', name: '' },
        ...Array
            .from(Array(8).keys())
            .map(i => {
                const no = i + 1;
                return {
                    key: `${no}`,
                    name: formatMessage({ defaultMessage: 'channel {no}', id: 'relay.channel' }, { no }),
                };
            }),
    ];
}

export function changeRelay(relays: Relay[], newRelay: Relay): Relay[] {
    return addOrEditById<Relay>(relays, newRelay);
}

export function deleteRelay(relays: Relay[], oldRelay: Relay): Relay[] {
    return removeById<Relay>(relays, oldRelay);
}

export function getRelayName(relay: Relay, formatMessage: FormatMessage): string {
    if (relay.inUse !== false) {
        return relay.name;
    }
    return `${relay.name} (${formatMessage({ defaultMessage: 'switched off', id: 'item.switched-off' })})`;
}
