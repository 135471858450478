import React from 'react';
import { useIntl } from 'react-intl';
import { StorageModel } from '../../../types/warehouse/model';
import Selector from './Selector';

interface StorageSelectorProps {
    buttonTitle?: string;
    excludedIds?: number[];
    onSelect: (item: StorageModel) => void;
    params?: { [key: string]: any };
}

export default ({ buttonTitle, excludedIds, onSelect, params }: StorageSelectorProps) => {
    const { formatMessage } = useIntl();
    const props = {
        buttonTitle: buttonTitle || formatMessage({
            defaultMessage: 'Select storage',
            id: 'storage-selector.button',
        }),
        excludedIds,
        modalTitle: formatMessage({
            defaultMessage: 'Select storage',
            id: 'storage-selector.name',
        }),
        onSelect,
        params,
        placeholder: formatMessage({
            defaultMessage: 'Search for storage',
            id: 'storage-selector.placeholder',
        }),
    };

    return <Selector path="storages" {...props} />;
}
