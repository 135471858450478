import { mergeTankConfig, Relay, RelayId, Tank, TankType } from '@majpage/raspi-tanks-logic';
import { FormatMessage } from '../../types/react-intl';
import { addOrEditById, removeById } from './collections';

export function changeTankInRelay(
    relaysList: Relay[], oldRelayId: RelayId | null, newRelayId: RelayId, tank: Tank,
): Relay[] {
    return relaysList.map(relay => {
        if (relay.id !== oldRelayId && relay.id !== newRelayId) {
            return relay;
        }
        return {
            ...relay,
            tanks: relay.id === newRelayId ? addOrEditById<Tank>(relay.tanks, tank) :
                removeById<Tank>(relay.tanks, tank),
        };
    });
}

export function deleteTankInRelay(relaysList: Relay[], relayId: RelayId, tank: Tank): Relay[] {
    return relaysList.map(relay => {
        if (relay.id !== relayId) {
            return relay;
        }
        return {
            ...relay,
            tanks: removeById<Tank>(relay.tanks, tank),
        };
    });
}

export function getUnifiedTankConfig(tank: Tank, tankTypes: TankType[], includeTank: boolean): Tank {
    const simpleTank = includeTank ? tank : { id: '', name: '' };
    if (!tank.types || tank.types.length === 0 || tankTypes.length === 0) {
        return mergeTankConfig(simpleTank);
    }
    const typesForTank = tank.types.reduce<TankType[]>((list, currentId) => {
        const tankType = tankTypes.find(({ id }) => id === currentId);
        if (tankType) {
            list.push(tankType);
        }
        return list;
    }, []);
    return mergeTankConfig(simpleTank, typesForTank);
}

export function getTankName(tank: Tank, formatMessage: FormatMessage): string {
    if (tank.inUse !== false) {
        return tank.name;
    }
    return `${tank.name} (${formatMessage({ defaultMessage: 'switched off', id: 'item.switched-off' })})`;
}
