import { getMeasurementsStatus, MeasurementSets, Relay, Tank, TankType } from '@majpage/raspi-tanks-logic';
import React, { useMemo } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { getMeasurementsByType, getMeasurementSet } from '../../../helpers/home-automation/measurements';
import { getRelayName } from '../../../helpers/home-automation/relays';
import { getTankName, getUnifiedTankConfig } from '../../../helpers/home-automation/tanks';
import { Measurement } from '../../../types/home-automation/model';
import { useHomeAutomationTimeOffsetFromContext } from '../../Contexts';
import MeasurementsStatus from '../Measurements/MeasurementsStatus';
import TankDetails from './TankDetails';
import TankForm from './TankForm';

interface TankProps {
    availableRelays: Relay[];
    editable: boolean;
    measurements: Measurement[];
    namesInUse: [string, string][];
    onChange: (newTank: Tank, relay: Relay | null) => void;
    onDelete: (tank: Tank) => void;
    relay: Relay;
    tank: Tank;
    tankTypes: TankType[];
}

export default ({
    availableRelays, editable, measurements, namesInUse, onChange, onDelete, relay, tank, tankTypes,
}: TankProps) => {
    const timeOffset = useHomeAutomationTimeOffsetFromContext();
    const { formatMessage } = useIntl();
    const unifiedTank = useMemo(() => getUnifiedTankConfig(tank, tankTypes, true), [tank, tankTypes]);
    const measurementSets = useMemo<MeasurementSets>(() => ({
        humidity: getMeasurementSet(getMeasurementsByType('humidity', measurements)),
        temperatures: getMeasurementSet(getMeasurementsByType('temperature', measurements)),
    }), [measurements]);

    return <ListGroup.Item key={tank.id} className="d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
            <div>
                {editable ?
                    <TankForm {...{
                        renderItem: setModalShown => <span onClick={() => {
                            setModalShown(true);
                        }} className="btn-link">{getTankName(tank, formatMessage)}</span>,
                        availableRelays,
                        isType: false,
                        item: tank,
                        namesInUse,
                        onChange,
                        relay,
                        tankTypes,
                    }} /> :
                    <TankDetails {...{
                        measurementSets,
                        renderItem: setModalShown => <span className="btn-link" onClick={() => {
                            setModalShown(true);
                        }}>{getTankName(tank, formatMessage)}</span>,
                        tank: unifiedTank,
                    }} />}
            </div>
            <div className="font-italic text-secondary">
                <FormattedMessage id="tanks.relay" defaultMessage="Relay: {relay}"
                    values={{ relay: getRelayName(relay, formatMessage) }} />
            </div>
        </div>
        {editable ?
            <Button size="sm" variant="danger" onClick={() => {
                onDelete(tank);
            }}>
                <X>
                    <FormattedMessage id="tanks.button.remove" defaultMessage="Remove element" />
                </X>
            </Button> :
            <MeasurementsStatus status={getMeasurementsStatus(unifiedTank, measurementSets, timeOffset)} />}
    </ListGroup.Item>;
};
