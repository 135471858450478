import Leaflet from 'leaflet';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Map, Marker, Pane, Polyline, Popup, Rectangle, TileLayer } from 'react-leaflet';
import { getFormattedDate, getFormattedTime } from '../../../helpers/time';
import { getCoordsFromPosition, getTimeFromPosition } from '../../../helpers/tracking/positions';
import { Area, Highlight, HighlightType, Position } from '../../../types/tracking/model';

const DEFAULT_ZOOM = 13;
const NO_BOUNDS_ZOOM = 2;
Leaflet.Marker.prototype.options.icon = Leaflet.icon({
    iconAnchor: [13, 41],
    iconSize: [25, 41],
    iconUrl,
    popupAnchor: [2, -40],
    shadowUrl,
});
const stopPropagation = (event: any) => {
    if (event.stopPropagation) {
        event.stopPropagation();
    }
}
const getDateMessageValues = (date: Date) => ({ date: getFormattedDate(date), time: getFormattedTime(date) });

interface DailyRoutesMapProps {
    area?: Area | null;
    date?: string;
    highlight?: Highlight | null;
    positions: Position[];
    setArea?: (area: Area | null) => void;
    setHighlight?: (highlight: Highlight | null) => void;
}

export default ({ area, date, highlight, positions, setArea, setHighlight }: DailyRoutesMapProps) => {
    const mapRef = useRef<Map>(null);
    useEffect(() => {
        if (mapRef) {
            const map = mapRef.current!.leafletElement;
            if (highlight && highlight.coords) {
                map.fitBounds([highlight.coords]);
                map.setZoom(DEFAULT_ZOOM);
            } else if (highlight && highlight.area) {
                map.fitBounds(highlight.area);
            } else if (positions.length > 0) {
                map.fitBounds(positions.map(getCoordsFromPosition));
                if (positions.length === 1) {
                    map.setZoom(DEFAULT_ZOOM);
                }
            } else {
                map.setView([0, 0], NO_BOUNDS_ZOOM);
            }
        }
    }, [highlight, positions]);

    const firstPosition = !area && positions.length > 1 ? {
        ...getDateMessageValues(getTimeFromPosition(positions[positions.length - 1])),
        coords: getCoordsFromPosition(positions[positions.length - 1]),
    } : null;
    const lastPosition = !area && positions.length > 0 ? {
        ...getDateMessageValues(getTimeFromPosition(positions[0])),
        coords: getCoordsFromPosition(positions[0]),
        isCurrent: true,
    } : null;
    if (lastPosition && date && lastPosition.date !== date) {
        lastPosition.isCurrent = false;
    }

    // @ts-ignore
    return <Map scrollWheelZoom={true} ref={mapRef} onClick={(event: { latlng?: { lat: number, lng: number } }) => {
            if (setHighlight && highlight) {
                setHighlight(null);
            }
            if (area && setArea && event.latlng) {
                const { lat, lng } = event.latlng;
                if (area.latA && area.lonA && !area.latB && !area.lonB) {
                    setArea({ ...area, isReady: true, latB: lat, lonB: lng });
                } else {
                    setArea({ isReady: false, latA: lat, lonA: lng, latB: null, lonB: null });
                }
            }
        }} className="mb-3" style={{ height: `${Math.max(Math.round(window.innerHeight / 2), 300)}px` }}>
        <TileLayer
            attribution={'&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors'}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" detectRetina={true}
        />
        <Polyline positions={positions.map(getCoordsFromPosition)} />
        {!highlight && firstPosition ?
            <Marker position={firstPosition.coords}>
                <Popup>
                    <FormattedMessage id="map.first-position" defaultMessage="First position at {date}, {time}"
                        values={{ date: firstPosition.date, time: firstPosition.time }} />
                </Popup>
            </Marker> :
            null}
        {!highlight && lastPosition ?
            <Marker position={lastPosition.coords}>
                <Popup>
                    <FormattedMessage id={lastPosition.isCurrent ? 'map.last-position' : 'map.last-known-position'}
                        defaultMessage={lastPosition.isCurrent ? 'Last position at {date}, {time}' :
                            'Last known position at {date}, {time}'}
                        values={{ date: lastPosition.date, time: lastPosition.time }} />
                </Popup>
            </Marker> :
            null}
        {!highlight && area ? renderAreaMarker(area, 'latA', 'lonA', setArea) : null}
        {!highlight && area ? renderAreaMarker(area, 'latB', 'lonB', setArea) : null}
        {!highlight && area && area.latA && area.lonA && area.latB && area.lonB ?
            <Pane style={{ zIndex: 499 }}>
                <Rectangle bounds={[[area.latA, area.lonA], [area.latB, area.lonB]]} />
            </Pane> :
            null}
        {highlight && highlight.coords ?
            <Marker position={highlight.coords}>
                {highlight.type === HighlightType.MESSAGE_POINT ?
                    <Popup>
                        <FormattedMessage id="map.highlight.message-point"
                            defaultMessage="Point from message from {date}, {time}"
                            values={getDateMessageValues(highlight.time)} />
                    </Popup> :
                    highlight.type === HighlightType.MESSAGE_CELL ?
                        <Popup>
                            <FormattedMessage id="map.highlight.message-cell"
                                defaultMessage="Cell from message from {date}, {time}"
                                values={getDateMessageValues(highlight.time)} />
                        </Popup> :
                        null}
            </Marker> :
            highlight && highlight.area ?
                <Pane style={{ zIndex: 499 }}>
                    <Rectangle bounds={highlight.area}>
                        {highlight.type === HighlightType.COMMAND_AREA ?
                            <Popup>
                                <FormattedMessage id="map.highlight.command-area"
                                    defaultMessage="Area from command from {date}, {time}"
                                    values={getDateMessageValues(highlight.time)} />
                            </Popup> :
                            null}
                    </Rectangle>
                </Pane> :
                null}
    </Map>;
};

const renderAreaMarker = (
    area: Area, latKey: 'latA' | 'latB', lonKey: 'lonA' | 'lonB', setArea?: (area: Area | null) => void,
) => area[latKey] && area[lonKey] ?
    <Marker position={[area[latKey] as number, area[lonKey] as number]} draggable={true} ondragend={(event: any) => {
        if (setArea) {
            const { lat, lng } = event.target.getLatLng();
            setArea({ ...area, [latKey]: lat, [lonKey]: lng });
        }
    }} onclick={stopPropagation}>
        <Popup>
            <FormattedMessage id="map.latitude" defaultMessage="Latitude: {lat}" values={{ lat: area[latKey] }} /><br />
            <FormattedMessage id="map.longitude" defaultMessage="Longitude: {lon}" values={{ lon: area[lonKey] }} />
        </Popup>
    </Marker> :
    null;
