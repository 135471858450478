import React from 'react';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface MessageProps {
    type: MessageType;
}

export enum MessageType {
    FETCHING = 'fetching',
    NO_RESULTS = 'no-results',
    NOT_FOUND_HTTP_ERROR = 'not-found-http-error',
    REQUEST_HTTP_ERROR = 'request-http-error',
    RESPONSE_HTTP_ERROR = 'response-http-error',
    UNKNOWN_HTTP_ERROR = 'unknown-http-error',
}

export default ({ type }: MessageProps) => {
    switch (type) {
        case MessageType.FETCHING:
            return <Alert variant="secondary">
                <FormattedMessage id="list.fetching" defaultMessage="Fetching..." />
            </Alert>;
        case MessageType.NO_RESULTS:
            return <Alert variant="primary">
                <FormattedMessage id="results.no-results" defaultMessage="No results" />
            </Alert>;
        case MessageType.NOT_FOUND_HTTP_ERROR:
            return <Alert variant="danger">
                <FormattedMessage id="errors.http.not-found" defaultMessage="Required HTTP resource doesn't exist." />
            </Alert>;
        case MessageType.REQUEST_HTTP_ERROR:
            return <Alert variant="danger">
                <FormattedMessage id="errors.http.request" defaultMessage="An HTTP request error occurred." />
            </Alert>;
        case MessageType.RESPONSE_HTTP_ERROR:
            return <Alert variant="danger">
                <FormattedMessage id="errors.http.response" defaultMessage="An HTTP response error occurred." />
            </Alert>;
        case MessageType.UNKNOWN_HTTP_ERROR:
            return <Alert variant="danger">
                <FormattedMessage id="errors.http.unknown" defaultMessage="An unknown HTTP error occurred." />
            </Alert>;
        default: return null;
    }
};
