import React from 'react';
import Menu from '../../components/price-tags/Menu/Menu';
import ProductChanges from '../../components/price-tags/ProductChanges/ProductChanges';
import { useReload } from '../../hooks/use-reload';
import { Section } from '../../types/section';

export const PATH = `/${Section.PRICE_TAGS}`;

export default () => {
    const [reload, reloading] = useReload();

    return <div className="PriceTagsMainPage">
        <Menu reload={reload} />
        {reloading ?
            null :
            <ProductChanges />}
    </div>;
};
