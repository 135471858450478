import * as H from 'history';
import React, { ComponentClass } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { StaticContext } from 'react-router';
import { Link, Route, RouteComponentProps as CoreRouteComponentProps, RouteProps } from 'react-router-dom';
import { useAuthentication } from '../hooks/use-authentication';
import { PATH as MAIN_PATH } from '../routes/Main';
import { UserContext } from './Contexts';
import Header from './Header/Header';

interface PrivateRouteProps extends RouteProps {
    capability?: string;
}

export interface RouteComponentProps<
    Params extends { [K in keyof Params]?: string } = {},
    S = H.LocationState
> extends CoreRouteComponentProps<Params, StaticContext, S> {
    // nothing to add
}

export default function AuthenticatedRoute({ capability, component, ...routeProps }: PrivateRouteProps) {
    const [user, logOut, goBack, isCapable, noAccess ] = useAuthentication(capability);

    return <Route {...routeProps} render={props => {
        if (user && isCapable) {
            const RouteComponent = component as ComponentClass<RouteComponentProps>;
            return <UserContext.Provider value={{ user }}>
                <Header logOut={logOut} />
                <Container>
                    <RouteComponent {...props} />
                </Container>
            </UserContext.Provider>
        }
        const noAccessPage = noAccess || !isCapable;
        return <Container>
            {noAccessPage ?
                <Row>
                    <Col sm={{ offset: 1, span: 10 }} className="text-center mt-5 mb-2">
                        <h1><FormattedMessage defaultMessage="No access" id="no-access.title" /></h1>
                    </Col>
                </Row> :
                null}
            <Row>
                <Col sm={{ offset: 1, span: 10 }} md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}
                     xl={{ offset: 4, span: 4 }} className="mb-5">
                    <div className="text-center mx-1 my-1 pt-5 pb-5">
                        {noAccessPage ?
                            <Link to={MAIN_PATH} onClick={goBack} role="button" className="btn btn-primary">
                                <FormattedMessage defaultMessage="Back" id="no-access.back-link"/>
                            </Link> :
                            <Spinner animation="border" role="status">
                                <span className="sr-only">
                                    <FormattedMessage defaultMessage="Redirecting..." id="redirecting"/>
                                </span>
                            </Spinner>}
                    </div>
                </Col>
            </Row>
        </Container>;
    }}/>;
}
