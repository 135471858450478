import React from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { ArrowClockwise, ListTask } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PATH as INVENTORY_MAIN_PATH } from '../../../routes/inventories/Main';

interface MenuProps {
    children?: React.ReactNode;
    reload?: () => void;
}

export default ({ children, reload }: MenuProps) => <div className="menu inventory-menu">
    <InputGroup size="lg" className="mt-3 mb-3">
        <InputGroup.Prepend>
            <InputGroup.Text>
                <FormattedMessage id="menu.inventory" defaultMessage="Inventory" />
            </InputGroup.Text>
        </InputGroup.Prepend>
        <InputGroup.Append>
            {reload ?
                <Button variant="secondary" size="lg" onClick={reload}>
                    <ArrowClockwise>
                        <FormattedMessage id="menu.reload" defaultMessage="Reload" />
                    </ArrowClockwise>
                </Button> :
                <Link to={INVENTORY_MAIN_PATH} role="button" className="btn btn-secondary btn-lg">
                    <ListTask>
                        <FormattedMessage id="menu.inventory.list" defaultMessage="List" />
                    </ListTask>
                </Link>}
        </InputGroup.Append>
        {children || null}
    </InputGroup>
</div>;
