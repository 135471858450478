import { getMeasurementsStatus, MeasurementSet, Relay, Thermometer } from '@majpage/raspi-tanks-logic';
import React, { useMemo } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    getMeasurementsByType, getMeasurementSet, getMeasurementsForRelay,
} from '../../../helpers/home-automation/measurements';
import { getRelayName } from '../../../helpers/home-automation/relays';
import { RelayTankName } from '../../../types/home-automation/raspi-tanks';
import { useHomeAutomationMeasurementsFromContext, useHomeAutomationTimeOffsetFromContext } from '../../Contexts';
import MeasurementsStatus from '../Measurements/MeasurementsStatus';
import RelayDetails from './RelayDetails';
import RelayForm from './RelayForm';

interface RelayProps {
    allTankNames: RelayTankName[];
    deviceIdsInUse: string[];
    editable: boolean;
    namesInUse: string[];
    onChange: (newRelay: Relay) => void;
    onDelete: (relay: Relay) => void;
    relay: Relay;
}

export default ({ allTankNames, editable, namesInUse, onChange, onDelete, deviceIdsInUse, relay }: RelayProps) => {
    const measurements = useHomeAutomationMeasurementsFromContext();
    const timeOffset = useHomeAutomationTimeOffsetFromContext();
    const { formatMessage } = useIntl();
    const thermometerName = formatMessage({ defaultMessage: 'Thermometer', id: 'form.value.thermometer' });
    const temperatures = useMemo<MeasurementSet>(
        () => getMeasurementSet(getMeasurementsByType('temperature', getMeasurementsForRelay(
            relay.id, measurements.list, thermometerName, relay.thermometer,
        ))),
        [measurements, relay.id, relay.thermometer, thermometerName],
    );
    const thermometers = useMemo<Thermometer[]>(
        () => relay.thermometer ? [{ ...relay.thermometer, name: relay.thermometer.name || thermometerName }] : [],
        [relay.thermometer, thermometerName],
    );

    return <ListGroup.Item key={relay.id} className="d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
            <div>
                {editable ?
                    <RelayForm renderItem={setModalShown => <span className="btn-link" onClick={() => {
                        setModalShown(true);
                    }}>{getRelayName(relay, formatMessage)}</span>} {...{
                        allTankNames, namesInUse, deviceIdsInUse, onChange, relay,
                    }} /> :
                    <RelayDetails {...{
                        renderItem: setModalShown => <span className="btn-link" onClick={() => {
                            setModalShown(true);
                        }}>{getRelayName(relay, formatMessage)}</span>,
                        relay,
                        temperatures,
                        thermometers,
                    }} />}
            </div>
            <div className="font-italic text-secondary">
                <FormattedMessage id="tanks.device-id" defaultMessage="Device ID: {id}" values={{ id: relay.deviceId }} />
            </div>
        </div>
        {editable ?
            <Button size="sm" variant="danger" onClick={() => {
                onDelete(relay);
            }}>
                <X>
                    <FormattedMessage id="tanks.button.remove" defaultMessage="Remove element" />
                </X>
            </Button> :
            <MeasurementsStatus status={getMeasurementsStatus(
                { ...relay, thermometers }, { humidity: {}, temperatures }, timeOffset,
            )} />}
    </ListGroup.Item>;
};
