import React from 'react';
import { Alert, Card, CardColumns } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { HttpErrors } from '../../../hooks/use-http-error';
import { useProductVariant } from '../../../hooks/warehouse/use-resources';
import { PATH as WAREHOUSE_PRODUCER_PATH } from '../../../routes/warehouse/Producer';
import { PATH as WAREHOUSE_PRODUCT_PATH } from '../../../routes/warehouse/Product';
import { PATH as WAREHOUSE_PRODUCT_VARIANT_PATH } from '../../../routes/warehouse/ProductVariant';
import { ProductVariantModel } from '../../../types/warehouse/model';
import Message, { MessageType } from '../../Message/Message';
import ProductVariantActualStates from '../ActualStates/ProductVariantActualStates';
import ProductComments from '../Comments/ProductComments';
import Mark from '../Mark/Mark';
import ProductBindings from '../ProductBindings/ProductBindings';
import ProductTags from '../ProductTags/ProductTags';
import ProductUnits from '../ProductUnits/ProductUnits';
import ProductResources from '../Resources/ProductResources';
import ProductVariantResources from '../Resources/ProductVariantResources';
import './ProductVariant.css';

interface ProductVariantProps {
    id: number;
    productVariant?: ProductVariantModel;
}

export default ({ id, productVariant: preloadedProductVariant }: ProductVariantProps) => {
    const [productVariant, { error, loading }, reload] = useProductVariant(
        { id }, preloadedProductVariant && preloadedProductVariant.product ? preloadedProductVariant : undefined,
    );

    if (!productVariant) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                {error === HttpErrors.NOT_FOUND_ERROR ?
                    <FormattedMessage id="product-variant.no-product-variant"
                        defaultMessage="There is no such product variant." /> :
                    <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />}
            </Alert>;
        }
    }

    const { product } = productVariant;
    const { producer } = product;
    return <CardColumns className="ProductVariant">
        <Card>
            <Card.Header>
                <FormattedMessage id="product.title" defaultMessage="Product info" />
                <Mark value={product.mark} />
            </Card.Header>
            <Card.Body>
                <table className="table mb-0">
                    <tbody>
                    <tr>
                        <td className="text-right">
                            <strong><FormattedMessage id="table.label.name" defaultMessage="Name:" /></strong>
                        </td>
                        <td className="text-break">
                            <Link to={{
                                pathname: getPath(WAREHOUSE_PRODUCT_PATH, { productId: product.id }),
                                state: { product }
                            }}>{product.name}</Link>
                        </td>
                    </tr>
                    {productVariant.name ?
                        <tr>
                            <td className="text-right">
                                <strong><FormattedMessage id="table.label.variant" defaultMessage="Variant:" /></strong>
                            </td>
                            <td className="text-break">{productVariant.name}</td>
                        </tr> :
                        null}
                    {producer ?
                        <tr>
                            <td className="text-right">
                                <strong>
                                    <FormattedMessage id="table.label.producer" defaultMessage="Producer:" />
                                </strong>
                            </td>
                            <td className="text-break">
                                <Link to={{
                                    pathname: getPath(WAREHOUSE_PRODUCER_PATH, { producerId: producer.id }),
                                    state: { producer }
                                }}>{producer.name}</Link>
                            </td>
                        </tr>:
                        null}
                    {product.description ?
                        <tr>
                            <td className="text-right">
                                <strong>
                                    <FormattedMessage id="table.label.description" defaultMessage="Description:" />
                                </strong>
                            </td>
                            <td className="text-break">{product.description}</td>
                        </tr>:
                        null}
                    <tr>
                        <td className="text-right">
                            <strong>
                                <FormattedMessage id="table.label.amount" defaultMessage="Amount:" />
                            </strong>
                        </td>
                        <td className="text-break">
                            {productVariant.amount} {productVariant.unit} <FormattedMessage id="table.value.step"
                                defaultMessage="(step {step})" values={{ step: productVariant.step }} />
                        </td>
                    </tr>
                    {product.validityPeriod ?
                        <tr>
                            <td className="text-right">
                                <strong>
                                    <FormattedMessage id="table.label.validity-period"
                                        defaultMessage="Validity period:" />
                                </strong>
                            </td>
                            <td className="text-break">{product.validityPeriod}</td>
                        </tr>:
                        null}
                    {productVariant.eanCode ?
                        <tr>
                            <td className="text-right">
                                <strong>
                                    <FormattedMessage id="table.label.ean-code" defaultMessage="EAN code:" />
                                </strong>
                            </td>
                            <td className="text-break">{productVariant.eanCode}</td>
                        </tr> :
                        null}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
        <ProductBindings product={product} onAdd={reload} />
        {productVariant.unitsAvailable ?
            <ProductUnits baseUrl={getPath(WAREHOUSE_PRODUCT_VARIANT_PATH, {
                productVariantId: productVariant.id,
            })} productVariant={productVariant} /> :
            null}
        {productVariant.statesAvailable ? <ProductVariantActualStates productVariant={productVariant} /> : null}
        <ProductTags productId={product.id} />
        <ProductResources id={product.id} />
        <ProductVariantResources id={productVariant.id} />
        <ProductComments id={product.id} onAdd={reload} />
    </CardColumns>;
}
