import React, { ReactNode } from 'react';
import { FormControl, FormGroup, FormLabel, InputGroup } from 'react-bootstrap';

interface InputItemProps {
    disabled?: boolean;
    errors?: string[];
    max?: string;
    min?: string;
    name: string;
    onChange?: (value: any) => void;
    options?: { key: string; name: string }[];
    renderAppend?: ReactNode;
    renderPrepend?: ReactNode;
    required?: boolean;
    step?: string;
    type?: string;
    value: any;
}

export default (
    { errors, name, onChange, options, renderAppend, renderPrepend, value, ...props }: InputItemProps,
) => <FormGroup className="FormField">
    {name ? <FormLabel>{name}</FormLabel> : null}
    <InputGroup>
        {renderPrepend ?
            <InputGroup.Prepend>{renderPrepend}</InputGroup.Prepend> :
            null}
        <FormControl {...{
            className: errors ? 'is-invalid' : '',
            value: value || '',
            ...(onChange ? { onChange: (event: any) => { onChange(event.target.value) } } : {}),
            ...(options ? { as: 'select' } : {}),
            ...props,
        }}>
            {options ?
                options.map(({ key, name }, j) => <option key={j} value={key}>{name}</option>) :
                null}
        </FormControl>
        {renderAppend ?
            <InputGroup.Append>{renderAppend}</InputGroup.Append> :
            null}
    </InputGroup>
    {errors ?
        <>
            {errors.map((error, i) => <div key={i} className="invalid-feedback" style={{ display: 'block' }}>
                {error}
            </div>)}
        </>:
        null}
</FormGroup>;
