import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useResource } from '../../hooks/use-resource';
import { DailyReport, FailureReportType, MeasurementSeriesResponse } from '../../types/home-automation/model';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Message, { MessageType } from '../Message/Message';
import Chart from './Chart';

interface ChartBoxProps {
    className?: string;
    descriptionY: string;
    getFormattedValue: (value: number) => string;
    params?: { [key: string]: any };
    report: DailyReport;
    title: string;
    url: string;
}

export default ({ className, descriptionY, getFormattedValue, params, report, title, url }: ChartBoxProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [loadingUrl, setLoadingUrl] = useState<string | null>(null);
    const [seriesResponse, { error, loading }] = useResource<MeasurementSeriesResponse>(loadingUrl, params || {});

    return <Card className={className || 'ChartBox'}>
        <Card.Header className={opened ? 'card-opened' : 'card-closed'} onClick={() => {
            setOpened(!opened);
            if (!loadingUrl) {
                setLoadingUrl(url);
            }
        }}>
            {title}
        </Card.Header>
        {opened ?
            <Card.Body>
                <ErrorMessage error={error} />
                {loading ?
                    <Message type={MessageType.FETCHING} /> :
                    !seriesResponse ?
                        null :
                        seriesResponse.list.length === 0 ?
                            <Message type={MessageType.NO_RESULTS} /> :
                            <Chart {...{
                                descriptionY, getFormattedValue, report, problemsX: report.failures
                                    .filter(failure => failure.type === FailureReportType.PROBLEM)
                                    .map(failure => ([
                                        (new Date(failure.timeStart)).getTime(), (new Date(failure.timeEnd)).getTime(),
                                    ])),
                                seriesList: seriesResponse.list, timeEnd: seriesResponse.timeEnd,
                                timeStart: seriesResponse.timeStart,
                            }} />}
            </Card.Body> :
            null}
    </Card>;
};
