import React from 'react';

interface ControlsRowProps {
    children: React.ReactNode;
    title: string;
}

export default ({ children, title }: ControlsRowProps) => {
    return <tr>
        <td className="text-right">
            <strong>{title}</strong>
        </td>
        <td className="text-break">
            {children}
        </td>
    </tr>;
}
