import { Field, FieldProps, FormikTouched } from 'formik';
import React from 'react';
import { FormControlProps, FormGroup, FormLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import './FormField.css';

interface FileInputFieldProps extends FormControlProps {
    error?: any;
    fieldId: string;
    name?: string;
    touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
}

export default (
    { error, fieldId, name, onChange, touched }: FileInputFieldProps,
) => <Field name={fieldId}>
    {({ field }: FieldProps<File>) => <FormGroup controlId={fieldId} className="FormField">
        {name ? <FormLabel>{name}</FormLabel> : null}
        <input type="file" onChange={(event: any) => {
            field.onChange(event);
            if (onChange) {
                onChange(event);
            }
        }} className={'form-control' + (error ? ' is-invalid' : '')} id={fieldId} />
        {touched && error ?
            <div className="invalid-feedback" style={{ display: 'block' }}><FormattedMessage id={error} /></div> :
            null}
    </FormGroup>}
</Field>;
