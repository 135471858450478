import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getPath } from '../../../helpers/path';
import { getProductUnitName } from '../../../helpers/warehouse/names';
import { PATH as WAREHOUSE_PRODUCT_UNIT_PATH } from '../../../routes/warehouse/ProductUnit';
import { ProductUnitRentalModel } from '../../../types/warehouse/model';
import ProductUnitRentalEditForm from './ProductUnitRentalEditForm';

interface ProductUnitRentalProps {
    canManageRentals: boolean;
    onEdit: (productUnitRental: ProductUnitRentalModel) => void;
    productUnitRental: ProductUnitRentalModel;
}

export default ({ canManageRentals, onEdit, productUnitRental }: ProductUnitRentalProps) => {
    const bodyParts = [];
    const { comments, productUnit, rentantName } = productUnitRental;
    if (productUnit) {
        bodyParts.push(<>
            <strong><FormattedMessage id="table.label.product-unit" defaultMessage="Product unit:" /></strong>{' '}
            <Link to={{
                pathname: getPath(WAREHOUSE_PRODUCT_UNIT_PATH, { productUnitId: productUnit.id }),
                state: { productUnit }
            }}>{getProductUnitName(productUnit)}</Link>
        </>);
    }
    if (rentantName) {
        bodyParts.push(<>
            <strong><FormattedMessage id="table.label.rentant-name" defaultMessage="Rentant:"/></strong>{' '}
            {rentantName}
        </>);
    }
    if (comments) {
        bodyParts.push(<>
            <strong><FormattedMessage id="table.label.comments" defaultMessage="Comments:"/></strong>{' '}
            {comments}
        </>);
    }

    return <div className="toast show">
        <div className="toast-header">
            <strong className="mr-auto">
                <span>{productUnitRental.startDate}</span>
                {productUnitRental.endDate ?
                    <> - <span>{productUnitRental.endDate}</span></> :
                    <>
                        {productUnitRental.expectedEndDate ?
                            <> - <span className={productUnitRental.isExtended ? 'text-danger' : 'text-success'}>
                                {productUnitRental.expectedEndDate}
                            </span></> :
                            null}
                        {' '}<span className="text-primary">
                            <FormattedMessage id="product-unit-rental.to-return" defaultMessage="(to return)" />
                        </span>
                    </>}
            </strong>
            {canManageRentals && productUnitRental.endDate === null ?
                <ProductUnitRentalEditForm productUnitRental={productUnitRental} onEdit={onEdit} /> :
                null}
        </div>
        {bodyParts.length > 0 ?
            <div className="toast-body text-break">{bodyParts.map((item, index) => <p key={index}>{item}</p>)}</div> :
            null}
    </div>;
};
