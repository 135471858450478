import { Form, FormikProps, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getFormControlAttributes, Target } from '../../../helpers/id-code';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { getStorageSimpleModel } from '../../../helpers/warehouse/models';
import { getProductVariantName } from '../../../helpers/warehouse/names';
import { useProductVariantByIdCode, useStorageByIdCode } from '../../../hooks/warehouse/use-resources';
import { IdCode, IdCodeFamily } from '../../../types/id-code';
import {
    ProductUnitModel, ProductVariantModel, StorageModel, StorageSimpleModel,
} from '../../../types/warehouse/model';
import { getDataFromIdCode, getDataSetFromIdCode, getIdCodeIfData, getIdCodeValueIfDataTarget } from '../../Contexts';
import CheckBoxField from '../../FormField/CheckBoxField';
import TextAreaField from '../../FormField/TextAreaField';
import TextInputField from '../../FormField/TextInputField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';
import IdCodeScanner from '../../IdCodeScanner/IdCodeScanner';
import ProductVariantSelector from '../Selector/ProductVariantSelector';
import StorageSelector from '../Selector/StorageSelector';

export const ID_CODE_FORM = 'product-unit';
const PRODUCT_VARIANT_TARGET = 'product-variant';
const STORAGE_TARGET = 'storage';
interface ProductVariantSimpleModel {
    id: number;
    name: string;
}
function getProductVariantSimpleModel(productVariant?: ProductVariantModel): ProductVariantSimpleModel | null {
    return productVariant ? {
        id: productVariant.id,
        name: getProductVariantName(productVariant),
    } : null;
}
interface IdCodeData {
    form: string;
    productVariant?: ProductVariantSimpleModel;
    storage?: StorageSimpleModel | false | null;
    target: string;
}

interface FormValues {
    barcode: string;
    description: string;
    inServiceFrom: string;
    name: string;
    nfcTag: string;
    qrCode: string;
    rentalsAvailable: boolean;
    rfidTag: string;
    serialNumber: string;
}

const Schema = Yup.object().shape({
    barcode: Yup.string()
        .matches(/^\d+$/, 'form.string.digits-only')
        .max(32, 'form.string.too-long'),
    description: Yup.string(),
    inServiceFrom: Yup.string()
        .matches(/^([0-9]{4}(-[0-9]{2}){2})?$/, 'form.string.doesnt-match'),
    name: Yup.string()
        .max(128, 'form.string.too-long'),
    nfcTag: Yup.string()
        .max(64, 'form.string.too-long'),
    qrCode: Yup.string()
        .max(32, 'form.string.too-long'),
    rentalsAvailable: Yup.boolean(),
    rfidTag: Yup.string()
        .max(64, 'form.string.too-long'),
    serialNumber: Yup.string()
        .max(128, 'form.string.too-long'),
});

interface PureFormProps {
    baseUrl: string;
    error: string | null;
    idCode: IdCode | null;
    onHide: () => void;
    predefinedProductVariant?: ProductVariantModel;
    predefinedStorage?: StorageModel;
    productVariant: ProductVariantSimpleModel | false | null;
    setModalShown: (modalShown: boolean) => void;
    setProductVariant: (productVariant: ProductVariantSimpleModel | false | null) => void;
    setShowError: (showError: boolean) => void;
    setShowRelationError: (showError: boolean) => void;
    setStorage: (storage: StorageSimpleModel | false | null) => void;
    showError: boolean;
    showRelationError: boolean;
    storage: StorageSimpleModel | false | null;
}

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();
    const codeScannerData = {
        ...props.values,
        productVariant: props.productVariant,
        storage: props.storage,
        form: ID_CODE_FORM,
    };
    const { productVariantId: productVariantIdError } = errors as any; // there is no productVariantId field

    return <Form>
        <Modal.Body>
            <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
            {props.idCode && (
                (!props.predefinedStorage && props.storage === false) ||
                (!props.predefinedProductVariant && props.productVariant === false)
            ) ?
                <Alert variant="danger" show={props.showRelationError}
                    onClose={() => props.setShowRelationError(false)} dismissible>
                    <FormattedMessage id="form.error.code-not-exists" values={{ code: props.idCode.value }}
                        defaultMessage={`Code "{code}" doesn't exist.`} />
                </Alert> :
                null}
            <TextInputField fieldId="storage" disabled
                value={props.storage ? props.storage.name : ''}
                name={formatMessage({ defaultMessage: 'Storage', id: 'form.label.storage' })}
                renderAppend={!props.predefinedStorage ?
                    <>
                        <StorageSelector onSelect={storage => props.setStorage(getStorageSimpleModel(storage))}
                            buttonTitle={formatMessage({ defaultMessage: 'Select', id: 'selector.button' })}
                            params={{ units: true }}/>
                        <IdCodeScanner result={props.baseUrl} data={{ ...codeScannerData, target: STORAGE_TARGET }} />
                        {props.storage ?
                            <Button onClick={() => { props.setStorage(null) }}>×</Button> :
                            null}
                    </> :
                    null} />
            <TextInputField fieldId="productVariantId" error={productVariantIdError} disabled
                value={props.productVariant && props.productVariant.name ? props.productVariant.name : ''}
                name={formatMessage({ defaultMessage: 'Product variant', id: 'form.label.product-variant' })}
                renderAppend={!props.predefinedProductVariant ?
                    <>
                        <ProductVariantSelector params={{ units: true }}
                            onSelect={productVariant => props.setProductVariant(
                                getProductVariantSimpleModel(productVariant)
                            )}
                            buttonTitle={formatMessage({ defaultMessage: 'Select', id: 'selector.button' })} />
                        <IdCodeScanner result={props.baseUrl} families={[IdCodeFamily.BARCODE]}
                            data={{ ...codeScannerData, target: PRODUCT_VARIANT_TARGET }} />
                    </> :
                    null} />
            <TextInputField fieldId="name" touched={touched.name} error={errors.name}
                name={formatMessage({ defaultMessage: 'Name', id: 'form.label.name' })} />
            <TextAreaField fieldId="description" touched={touched.description} error={errors.description}
                name={formatMessage({ defaultMessage: 'Description', id: 'form.label.description' })} />
            <TextInputField fieldId="serialNumber" touched={touched.serialNumber} error={errors.serialNumber}
                name={formatMessage({ defaultMessage: 'Serial number', id: 'form.label.serial-number' })} />
            <TextInputField fieldId="inServiceFrom" touched={touched.inServiceFrom} error={errors.inServiceFrom}
                name={formatMessage({ defaultMessage: 'In service from', id: 'form.label.in-service-from' })}
                type="date" />
            <TextInputField fieldId="qrCode" touched={touched.qrCode} error={errors.qrCode}
                name={formatMessage({ defaultMessage: 'QR code', id: 'form.label.qr-code' })}
                {...getFormControlAttributes(IdCodeFamily.QR_CODE)} renderAppend={
                    <IdCodeScanner result={props.baseUrl} families={[IdCodeFamily.QR_CODE]}
                        data={{ ...codeScannerData, target: Target.QR_CODE }} />
                } />
            <TextInputField fieldId="barcode" touched={touched.barcode} error={errors.barcode}
                name={formatMessage({ defaultMessage: 'Barcode', id: 'form.label.barcode' })}
                {...getFormControlAttributes(IdCodeFamily.BARCODE)} renderAppend={
                    <IdCodeScanner result={props.baseUrl} families={[IdCodeFamily.BARCODE]}
                        data={{ ...codeScannerData, target: Target.BARCODE }} />
                } />
            <TextInputField fieldId="nfcTag" touched={touched.nfcTag} error={errors.nfcTag}
                name={formatMessage({ defaultMessage: 'NFC tag', id: 'form.label.nfc-tag' })}
                {...getFormControlAttributes(IdCodeFamily.NFC_TAG)} renderAppend={
                    <IdCodeScanner result={props.baseUrl} families={[IdCodeFamily.NFC_TAG]}
                        data={{ ...codeScannerData, target: Target.NFC_TAG }} />
                } />
            <TextInputField fieldId="rfidTag" touched={touched.rfidTag} error={errors.rfidTag}
                name={formatMessage({ defaultMessage: 'RFID tag', id: 'form.label.rfid-tag' })}
                {...getFormControlAttributes(IdCodeFamily.RFID_TAG)} renderAppend={
                    <IdCodeScanner result={props.baseUrl} families={[IdCodeFamily.RFID_TAG]}
                        data={{ ...codeScannerData, target: Target.RFID_TAG }} />
                } />
            <CheckBoxField fieldId="rentalsAvailable" touched={touched.rentalsAvailable} error={errors.rentalsAvailable}
                name={formatMessage({ defaultMessage: 'Rentals available', id: 'form.label.rentals-available' })} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
                <FormattedMessage id="modal.close" defaultMessage="Close" />
            </Button>
            <Button variant="primary" type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
                <FormattedMessage id="form.save" defaultMessage="Save" />
            </Button>
        </Modal.Footer>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    formValues: FormValues;
    onAdd: (productUnit: ProductUnitModel) => void;
    setError: (text: string | null) => void;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);
            props.setShowRelationError(true);
            const productUnit = await authenticatedRequest<ProductUnitModel>({
                data: {
                    barcode: values.barcode || null,
                    description: values.description || null,
                    inServiceFrom: values.inServiceFrom || null,
                    name: values.name || null,
                    nfcTag: values.nfcTag || null,
                    productVariantId: props.predefinedProductVariant ? props.predefinedProductVariant.id :
                        props.productVariant ? props.productVariant.id : null,
                    qrCode: values.qrCode || null,
                    rentalsAvailable: values.rentalsAvailable,
                    rfidTag: values.rfidTag || null,
                    serialNumber: values.serialNumber || null,
                    storageId: props.predefinedStorage ? props.predefinedStorage.id :
                        props.storage ? props.storage.id : null,
                },
                method: HttpMethod.POST,
                url: getWarehouseApiUrl('product-units'),
            });
            resetForm();
            props.onAdd(productUnit);
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: ({ formValues }) => formValues,
    validate: (_values, props) => {
        const errors: { [key: string]: string } = {};
        if (!props.predefinedProductVariant && !props.productVariant) {
            errors.productVariantId = 'form.error.product-variant-empty';
        }
        return errors;
    },
    validationSchema: Schema,
})(PureForm);

interface ProductUnitAddFormProps {
    baseUrl: string;
    idCode: IdCode<IdCodeData> | null;
    onAdd: (productUnit: ProductUnitModel) => void;
    productVariant?: ProductVariantModel;
    storage?: StorageModel;
}

export default ({
    baseUrl, idCode, onAdd: onAddToParent, productVariant: predefinedProductVariant, storage: predefinedStorage,
}: ProductUnitAddFormProps) => {
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(true);
    const [showRelationError, setShowRelationError] = useState(true);
    const [currentIdCode, setCurrentIdCode] = useState<IdCode<IdCodeData> | null>(idCode);
    const [modalShown, setModalShown] = useState<boolean>(!!currentIdCode);
    const { formatMessage } = useIntl();

    const formValues = getDataSetFromIdCode<FormValues>(currentIdCode, [
        'barcode', 'description', 'inServiceFrom', 'name', 'nfcTag', 'qrCode', 'rentalsAvailable', 'rfidTag',
        'serialNumber',
    ], {
        barcode: '', description: '', inServiceFrom: '', name: '', nfcTag: '', qrCode: '', rentalsAvailable: false,
        rfidTag: '', serialNumber: '',
    });
    formValues.barcode = getIdCodeValueIfDataTarget(currentIdCode, Target.BARCODE, formValues.barcode);
    formValues.nfcTag = getIdCodeValueIfDataTarget(currentIdCode, Target.NFC_TAG, formValues.nfcTag);
    formValues.qrCode = getIdCodeValueIfDataTarget(currentIdCode, Target.QR_CODE, formValues.qrCode);
    formValues.rfidTag = getIdCodeValueIfDataTarget(currentIdCode, Target.RFID_TAG, formValues.rfidTag);

    const [productVariant, setProductVariant] = useState<ProductVariantSimpleModel | false | null>(
        getDataFromIdCode(currentIdCode, 'productVariant') ||
        getProductVariantSimpleModel(predefinedProductVariant),
    );
    const [productVariantFromIdCode] = useProductVariantByIdCode(
        getIdCodeIfData(currentIdCode, data => data.target === PRODUCT_VARIANT_TARGET),
    );

    const [storage, setStorage] = useState<StorageSimpleModel | false | null>(
        getDataFromIdCode(currentIdCode, 'storage') || getStorageSimpleModel(predefinedStorage),
    );
    const [storageFromIdCode] = useStorageByIdCode(
        getIdCodeIfData(currentIdCode, data => data.target === STORAGE_TARGET),
    );

    const onHide = () => {
        setModalShown(false);
        if (!predefinedProductVariant) {
            setProductVariant(null);
        }
        if (!predefinedStorage) {
            setStorage(null);
        }
    };
    const onAdd = (productUnit: ProductUnitModel) => {
        onHide();
        onAddToParent(productUnit);
    };
    const stateProps: FormikFormProps = {
        baseUrl, error, formValues, idCode: currentIdCode, onAdd, onHide, predefinedProductVariant, predefinedStorage,
        productVariant, setError, setModalShown, setProductVariant, setShowError, setShowRelationError, setStorage,
        storage, showError, showRelationError,
    };

    useEffect(() => {
        if (!predefinedProductVariant && productVariantFromIdCode !== null) {
            if (!productVariantFromIdCode) {
                setProductVariant(false);
            } else if (!productVariantFromIdCode.unitsAvailable) {
                setProductVariant(false);
                setError(formatMessage({
                    defaultMessage: 'This product variant does not accept product units.',
                    id: 'product-variant.no-product-units',
                }));
            } else {
                setProductVariant(getProductVariantSimpleModel(productVariantFromIdCode));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(productVariantFromIdCode)]);

    useEffect(() => {
        if (!predefinedStorage && storageFromIdCode !== null) {
            if (!storageFromIdCode) {
                setStorage(false);
            } else if (!storageFromIdCode.unitsAvailable) {
                setStorage(false);
                setError(formatMessage({
                    defaultMessage: 'This storage does not accept product units.', id: 'storage.no-product-units',
                }));
            } else {
                setStorage(getStorageSimpleModel(storageFromIdCode));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(storageFromIdCode)]);

    return <>
        <Button size="sm" onClick={() => {
            setError(null);
            setShowError(true);
            setShowRelationError(true);
            setCurrentIdCode(null);
            setModalShown(true);
        }} className="mb-3" block>
            <FormattedMessage id="product-units.button.add" defaultMessage="Add product unit" />
        </Button>
        <Modal show={modalShown} onHide={onHide} animation={false} className="ProductUnitModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="product-units.modal.add" defaultMessage="Add product unit" />
                </Modal.Title>
            </Modal.Header>
            <FormikForm {...stateProps} />
        </Modal>
    </>;
}
