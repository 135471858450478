import React, { useState } from 'react';
import { Alert, CardColumns, Navbar } from 'react-bootstrap';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { getFormattedDate } from '../../../helpers/time';
import { getHomeAutomationApiUrl } from '../../../helpers/url';
import { HttpErrors } from '../../../hooks/use-http-error';
import { useResource } from '../../../hooks/use-resource';
import { DailyReport, DailyReportType, Device, FailureReportType } from '../../../types/home-automation/model';
import DateSelector from '../../DateSelector/DateSelector';
import Message, { MessageType } from '../../Message/Message';
import './DailyReport.css';
import ReportFailuresList from './ReportFailuresList';

const url = getHomeAutomationApiUrl('daily-report');

type RenderFunction = (date: string, options: { canManage: boolean, report: DailyReport }) => JSX.Element

interface DailyReportProps {
    canManage: boolean;
    device: Device;
    renderAfter?: RenderFunction;
    renderAfterColumns?: RenderFunction;
    renderBefore?: RenderFunction;
    renderBeforeColumns?: RenderFunction;
}

export default (
    { canManage, device, renderAfter, renderAfterColumns, renderBefore, renderBeforeColumns }: DailyReportProps,
) => {
    const [date, setDate] = useState<string>(() => getFormattedDate(new Date()));
    const [report, { error, loading }, reload] = useResource<DailyReport>(url, { date, device: device.slug });
    const intl = useIntl();
    const { formatMessage } = intl;
    const loadDifferentReport = loading && (!report || getFormattedDate(new Date(report.date)) !== date);

    if (!report) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                {error === HttpErrors.NOT_FOUND_ERROR ?
                    <FormattedMessage id="daily-report.no-daily-report"
                        defaultMessage="There is no such daily report." /> :
                    <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />}
            </Alert>;
        }
    }

    return <div className="DailyReport">
        <Navbar bg="light" className="mb-3">
            <Navbar.Collapse>
                <div className="report-title">
                    <FormattedMessage defaultMessage="Daily report:" id="daily-report.title" />{' '}
                    {renderReportType(report, intl)}
                </div>
                <DateSelector {...{ date, dateMin: device.dateStart, loading, setDate }} />
            </Navbar.Collapse>
        </Navbar>
        {loadDifferentReport ?
            <Message type={MessageType.FETCHING} /> :
            <>
                {renderBeforeColumns ? renderBeforeColumns(date, { canManage, report }) : null}
                <CardColumns>
                    {renderBefore ? renderBefore(date, { canManage, report }) : null}
                    {report.type !== DailyReportType.EMPTY ?
                        <>
                            <ReportFailuresList canManage={canManage} onChange={reload} title={formatMessage({
                                defaultMessage: 'Big losses list', id: 'daily-report.losses-big.title',
                            })} failures={report.failures.filter(({ type }) => type === FailureReportType.PROBLEM)} />
                            <ReportFailuresList canManage={canManage} onChange={reload} title={formatMessage({
                                defaultMessage: 'Small losses list', id: 'daily-report.losses-small.title',
                            })} failures={report.failures.filter(({ type }) => type === FailureReportType.DEFECT)} />
                            <ReportFailuresList canManage={canManage} onChange={reload} title={formatMessage({
                                defaultMessage: 'No connection list', id: 'daily-report.no-connection.title',
                            })} failures={report.failures.filter(
                                ({ type }) => type === FailureReportType.NO_CONNECTION
                            )} />
                        </> :
                        null}
                    {renderAfter ? renderAfter(date, { canManage, report }) : null}
                </CardColumns>
                {renderAfterColumns ? renderAfterColumns(date, { canManage, report }) : null}
            </>}
    </div>;
}

function renderReportType(report: DailyReport, { formatMessage }: IntlShape) {
    const { style, text } = (() => {
        switch (report.type) {
            case DailyReportType.PROBLEM:
                return {
                    style: { color: 'red' },
                    text: formatMessage({ defaultMessage: 'Problem', id: 'daily-report.type.problem' }),
                };
            case DailyReportType.DEFECT:
                return {
                    style: { color: 'orange' },
                    text: formatMessage({ defaultMessage: 'Defect', id: 'daily-report.type.defect' }),
                };
            case DailyReportType.EMPTY:
                return {
                    style: { color: 'blue' },
                    text: formatMessage({ defaultMessage: 'Empty', id: 'daily-report.type.empty' }),
                };
            default:
                return {
                    style: { color: 'green' },
                    text: formatMessage({ defaultMessage: 'OK', id: 'daily-report.type.ok' }),
                };
        }
    })();
    return <strong style={style}>{text}</strong>;
}
