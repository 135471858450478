import React, { useMemo, useState } from 'react';
import { Button, Card, CardColumns } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { getHomeAutomationApiUrl } from '../../../helpers/url';
import { useResourcesList } from '../../../hooks/use-resources-list';
import { PATH as HOME_AUTOMATION_DEVICE_PATH } from '../../../routes/home-automation/Device';
import { Device } from '../../../types/home-automation/model';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Message, { MessageType } from '../../Message/Message';
import './Devices.css';

const url = getHomeAutomationApiUrl('devices');

export default () => {
    const [page, setPage] = useState<number>(1);
    const [devices, { error, isLast, loading }] = useResourcesList<Device>(url, page);
    const devicesInGroups = useMemo(
        () => devices.reduce<{ name: string | null; devices: Device[] }[]>((groups, device) => {
            let group = groups.find(({ name }) => name === device.groupName);
            if (!group) {
                group = { devices: [], name: device.groupName };
                groups.push(group);
            }
            group.devices.push(device);
            return groups;
        }, []), [devices],
    );

    if (devices.length === 0) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else if (isLast) {
            return <Message type={MessageType.NO_RESULTS} />;
        }
    }

    return <>
        <ErrorMessage error={error} />
        {devicesInGroups.map((group, i) => <div key={i} className="Devices">
            {group.name ?
                <h4>{group.name}</h4> :
                devicesInGroups.length > 1 ?
                    <h4><FormattedMessage id="list.other-devices" defaultMessage="Other devices" /></h4> :
                    null}
            <CardColumns>
                {group.devices.map(device => <Card key={device.slug}>
                    <Link to={{
                        pathname: getPath(HOME_AUTOMATION_DEVICE_PATH, { deviceSlug: device.slug }),
                        state: { device }
                    }}>
                        <Card.Body>{device.name || device.slug}</Card.Body>
                    </Link>
                </Card>)}
            </CardColumns>
        </div>)}
        {!isLast ?
            <Button variant="light" size="sm" onClick={() => { setPage(page + 1) }} block>
                <FormattedMessage id="list.more" defaultMessage="More" />
            </Button> :
            null}
    </>;
}
