import { useCallback, useEffect, useMemo, useState } from 'react';
import { authenticatedRequest, HttpMethod } from '../../helpers/auth-request';
import { SECOND_IN_MS } from '../../helpers/time';
import { getHomeAutomationApiUrl } from '../../helpers/url';
import { Device } from '../../types/home-automation/model';

const METADATA_INTERVAL = 10 * SECOND_IN_MS;
const INTERVALS_TO_OMIT = 5; // Number of intervals to omit if device is not during synchronization process

interface ValueAndMatch<V = any> {
    initialValue: V;
    match: boolean;
    modifiedValue: V | null;
    transactional: boolean;
    value: V;
}
interface ValuesAndMatches {
    [name: string]: ValueAndMatch;
}
interface ValuesAndMatchesResponse {
    metadata: ValuesAndMatches;
    version: number;
}
export interface Controls {
    commit: () => void;
    find: <V>(name: string) => Control<V> | null;
    rollback: () => void;
}
export interface Control<V = any> extends ValueAndMatch<V> {
    change: (value: V) => void;
}
export interface ControlField {
    initialValue: any;
    name: string;
    transactional?: boolean;
}
interface Versions {
    local: number;
    server: number;
}

const getUrl = getHomeAutomationApiUrl('metadata');
const patchUrl = getHomeAutomationApiUrl('metadata/desired');

function getValuesAndMatches(controlFields: ControlField[], valuesAndMatches?: ValuesAndMatches) {
    return controlFields.reduce<ValuesAndMatches>((list, { initialValue, name, transactional }) => {
        list[name] = {
            ...(valuesAndMatches && valuesAndMatches[name] ? {
                ...valuesAndMatches[name],
            } : {
                match: false,
                modifiedValue: null,
                value: initialValue,
            }),
            initialValue,
            transactional: transactional || false,
        };
        return list;
    }, {});
}

function getValuesAndMatchesWithNoChanges(valuesAndMatches: ValuesAndMatches) {
    return Object.entries(valuesAndMatches).reduce<ValuesAndMatches>((list, [name, value]) => {
        list[name] = { ...value, modifiedValue: null };
        return list;
    }, {});
}

function flagNotMatch(valuesAndMatches: ValuesAndMatches, name: string, match: boolean): boolean {
    return valuesAndMatches[name] && match !== valuesAndMatches[name].match;
}

function reloadValuesAndMatches(
    { metadata, version }: ValuesAndMatchesResponse, valuesAndMatches: ValuesAndMatches,
    setValuesAndMatches: (valuesAndMatches: ValuesAndMatches) => void, versions: Versions,
    setVersions: (versions: Versions) => void, clearModifiedValuesFor: string[] = [],
) {
    const entries = Object.entries(metadata);
    if (versions.server !== version) {
        setValuesAndMatches(entries.reduce((list, [name, { match, value }]) => {
            if (valuesAndMatches[name]) {
                const { modifiedValue, ...valuesAndMatch } = valuesAndMatches[name];
                list[name] = {
                    ...valuesAndMatch, match,
                    modifiedValue: clearModifiedValuesFor.includes(name) ? null : modifiedValue, value,
                };
            }
            return list;
        }, { ...valuesAndMatches }));
        setVersions({ ...versions, server: version });
    } else if (entries.some(([name, { match }]) => flagNotMatch(valuesAndMatches, name, match))) {
        setValuesAndMatches(entries.reduce((list, [name, { match, value }]) => {
            if (flagNotMatch(valuesAndMatches, name, match)) {
                list[name] = { ...valuesAndMatches[name], match, value };
            }
            return list;
        }, { ...valuesAndMatches }));
    }
}

export function useDeviceControls(
    device: Device, controlFields: ControlField[], opened: boolean,
): [Controls | null, Versions, () => void, boolean, boolean, boolean] {
    const [valuesAndMatches, setValuesAndMatches] = useState<ValuesAndMatches>({});
    const [loaded, setLoaded] = useState<boolean>(false);
    const [versions, setVersions] = useState<Versions>({ local: 0, server: 0 });
    const loadRequired = loaded || opened;
    const loadMetadata = useCallback(async (request: AbortController) => {
        if (!request) {
            request = new AbortController();
        }
        try {
            const data = await authenticatedRequest<ValuesAndMatchesResponse>({
                method: HttpMethod.GET,
                params: { device: device.slug },
                url: getUrl,
            }, request.signal);
            if (!request.signal.aborted) {
                reloadValuesAndMatches(data, valuesAndMatches, setValuesAndMatches, versions, setVersions);
            }
            if (!loaded) {
                setLoaded(true);
            }
        } catch (error) {
            // nothing to do
        }
    }, [device.slug, valuesAndMatches, loaded, versions]);
    const saveMetadata = useCallback(async (metadata, version) => {
        const request = new AbortController();
        try {
            const data = await authenticatedRequest<ValuesAndMatchesResponse>({
                data: { metadata, version },
                method: HttpMethod.PATCH,
                params: { device: device.slug },
                url: patchUrl,
            }, request.signal);
            if (!request.signal.aborted) {
                reloadValuesAndMatches(
                    data, valuesAndMatches, setValuesAndMatches, versions, setVersions, Object.keys(metadata),
                );
            }
        } catch (error) {
            // nothing to do
        }
    }, [device.slug, valuesAndMatches, versions]);
    const updateVersion = useCallback(() => {
        setVersions({ ...versions, local: versions.server });
        setValuesAndMatches(getValuesAndMatchesWithNoChanges(valuesAndMatches));
    }, [valuesAndMatches, versions]);
    const controls = useMemo(() => (loaded ? {
        commit: () => {
            const metadata = Object.entries(valuesAndMatches)
                .reduce<ValuesAndMatches>((list, [name, { modifiedValue, transactional }]) => {
                    if (transactional && modifiedValue !== null) {
                        list[name] = modifiedValue;
                    }
                    return list;
                }, {});
            if (Object.keys(metadata).length > 0) {
                saveMetadata(metadata, versions.local);
            }
        },
        find: <T = any>(name: string) => {
            const valueAndMatch = valuesAndMatches[name];
            return valueAndMatch ? {
                ...valueAndMatch,
                change: (value: T) => {
                    if (valueAndMatch.transactional) {
                        setValuesAndMatches({
                            ...valuesAndMatches,
                            [name]: { ...valueAndMatch, modifiedValue: value },
                        });
                    } else {
                        setValuesAndMatches({
                            ...valuesAndMatches,
                            [name]: { ...valueAndMatch, modifiedValue: null, value },
                        });
                        saveMetadata({ [name]: value }, versions.local);
                    }
                },
            } : null;
        },
        rollback: () => {
            setValuesAndMatches(getValuesAndMatchesWithNoChanges(valuesAndMatches));
        },
    } : null), [valuesAndMatches, versions.local, loaded, saveMetadata]);
    const duringSyncProcess = useMemo(() => {
        return versions.local !== versions.server || Object.values(valuesAndMatches).some(({ match }) => !match);
    }, [valuesAndMatches, versions]);
    const transactionalFieldsExist = useMemo(() => {
        return controlFields.some(({ transactional }) => transactional);
    }, [controlFields]);
    const transactionalFieldsMatch = useMemo(() => {
        return Object.values(valuesAndMatches).filter(({ transactional }) => transactional).every(({ match }) => match);
    }, [valuesAndMatches]);

    useEffect(() => {
        setValuesAndMatches(getValuesAndMatches(controlFields, valuesAndMatches));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlFields]);

    useEffect(() => {
        if (!loadRequired) {
            return;
        }
        let request = new AbortController();
        if (!loaded) {
            // @FIXME: This GET request should takes place only once - currently it's triggered 3 times at the start.
            loadMetadata(request);
        }
        let intervalsToOmit = INTERVALS_TO_OMIT;
        const interval = setInterval(() => {
            if (intervalsToOmit === 0 || duringSyncProcess) {
                intervalsToOmit = INTERVALS_TO_OMIT;
                request = new AbortController();
                loadMetadata(request);
            } else {
                intervalsToOmit--;
            }
        }, METADATA_INTERVAL);
        return () => {
            request.abort();
            clearInterval(interval);
        };
    }, [device.slug, loaded, loadRequired, loadMetadata, duringSyncProcess]);

    return [controls, versions, updateVersion, loaded, transactionalFieldsExist, transactionalFieldsMatch];
}
