import { Form, FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getHomeAutomationApiUrl } from '../../../helpers/url';
import { FailureReport } from '../../../types/home-automation/model';
import { Capabilities } from '../../../types/home-automation/user';
import { useUserFromContext } from '../../Contexts';
import TextInputField from '../../FormField/TextInputField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';

interface FormValues {
    description: string | null;
}

const Schema = Yup.object().shape({
    description: Yup.string()
        .max(64, 'form.string.too-long')
        .nullable(),
});

interface PureFormProps {
    error: string | null;
    setModalShown: (modalShown: boolean) => void;
    setShowError: (showError: boolean) => void;
    showError: boolean;
}

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();

    return <Form>
        <Modal.Body>
            <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
            <TextInputField fieldId="description" touched={touched.description} error={errors.description}
                name={formatMessage({ defaultMessage: 'Description', id: 'form.label.description' })} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { props.setModalShown(false) }}>
                <FormattedMessage id="modal.close" defaultMessage="Close" />
            </Button>
            <Button variant="primary" type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
                <FormattedMessage id="form.save" defaultMessage="Save" />
            </Button>
        </Modal.Footer>
    </Form>;
}

interface FormikFormProps extends PureFormProps {
    description: string | null;
    failureId: number;
    onChange: (description: string | null) => void;
    setError: (text: string | null) => void;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);
            await authenticatedRequest({
                data: {
                    description: values.description,
                },
                method: HttpMethod.PATCH,
                url: getHomeAutomationApiUrl('failure-reports/:id', { params: {
                    id: props.failureId,
                } }),
            });
            resetForm();
            props.onChange(values.description || null);
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: ({ description }) => ({
        description: description || '',
    }),
    validationSchema: Schema,
})(PureForm);

interface ReportFailureFormProps {
    canManage: boolean;
    failure: FailureReport;
    onChange: () => void;
}

export default ({ canManage, failure, onChange }: ReportFailureFormProps) => {
    const user = useUserFromContext();
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const [description, setDescription] = useState<string | null>(failure.description);
    const stateProps = { error, setError, setModalShown, setShowError, showError };
    const isEditable = user.capabilities.includes(Capabilities.AUTOMATION_MANAGE) && canManage && failure.id;

    return <div className="toast show">
        <div className="toast-header" style={isEditable ? { cursor: 'pointer' } : {}} onClick={() => {
            if (isEditable) {
                setError(null);
                setShowError(true);
                setModalShown(true);
            }
        }}>
            <small className="text-muted">
                {failure.timeStart === failure.timeEnd ?
                    (new Date(failure.timeStart)).toLocaleTimeString() :
                    (new Date(failure.timeStart)).toLocaleTimeString() + ' - ' +
                    (new Date(failure.timeEnd)).toLocaleTimeString()}
            </small>
            {description ? <small className="text-muted">&nbsp;- {description}</small> : null}
        </div>
        <Modal show={modalShown} onHide={() => {
            setModalShown(false);
        }} animation={false} className="ReportFailureModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="daily-report.failure-modal.set" defaultMessage="Set failure's description" />
                </Modal.Title>
            </Modal.Header>
            <FormikForm failureId={failure.id} description={description} onChange={description => {
                setModalShown(false);
                setDescription(description);
                onChange();
            }} {...stateProps} />
        </Modal>
    </div>;
}
