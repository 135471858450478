export enum IdCodeFamily {
    BARCODE = 'barcode',
    NFC_TAG = 'nfc_tag',
    QR_CODE = 'qr_code',
    RFID_TAG = 'rfid_tag',
}

export enum IdCodeFormat {
    EAN_8 = 'EAN-8',
    EAN_13 = 'EAN-13',
    NFC_TAG = 'NFC-TAG',
    QR_CODE = 'QR-CODE',
    RFID_TAG = 'RFID-TAG',
}

export enum IdCodeType {
    ISBN = 'ISBN',
    PHONE = 'PHONE',
    PRODUCT = 'PRODUCT',
    TEXT = 'TEXT',
    URL = 'URL',
}

export interface IdCode<D = any> {
    data: D | null;
    family: IdCodeFamily;
    format: IdCodeFormat;
    type: IdCodeType;
    value: string;
}
