import React from 'react';
import Menu from '../../components/warehouse/Menu/Menu';
import Search from '../../components/warehouse/Search/Search';
import { Section } from '../../types/section';
import { PATH as SEARCH_PATH } from './Search';

export const PATH = `/${Section.WAREHOUSE}`;

export default () => <div className="WarehouseMainPage">
    <Menu />
    <Search redirectTo={SEARCH_PATH} />
</div>
