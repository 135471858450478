import React from 'react';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import Menu from '../../components/warehouse/Menu/Menu';
import Producer from '../../components/warehouse/Producer/Producer';
import { Section } from '../../types/section';
import { ProducerModel } from '../../types/warehouse/model';

export const PATH = `/${Section.WAREHOUSE}/producers/:producerId`;

export default ({ location, match }: RouteComponentProps<{ producerId: string }, { producer?: ProducerModel }>) =>
    <div className="WarehouseProducerPage">
        <Menu/>
        <Producer id={parseInt(match.params.producerId, 10)}
            producer={location.state ? location.state.producer : undefined} />
    </div>;
