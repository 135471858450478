import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { PATH as WAREHOUSE_STORAGE_PATH } from '../../../routes/warehouse/Storage';
import { StorageModel } from '../../../types/warehouse/model';
import FormatAmount from '../../Format/FormatAmount';

interface StorageProps {
    storage: StorageModel;
}

export default ({ storage }: StorageProps) => <div className="toast show">
    <div className="toast-header">
        <strong className="mr-auto">
            <Link to={{
                pathname: getPath(WAREHOUSE_STORAGE_PATH, { storageId: storage.id }),
                state: { storage }
            }}>{storage.name}</Link>
        </strong>
        {storage.fixedCapacity && storage.amount !== null ?
            <small className="text-muted">
                <FormattedMessage id="storages.capacity" defaultMessage="Capacity:" />{' '}
                <FormatAmount value={storage.amount} unit={storage.unit} />
            </small> :
            null}
    </div>
    {storage.description ? <div className="toast-body text-break">{storage.description}</div> : null}
</div>;
