import {
    ColorLight, ColorLightWeekdays, getNumberFrom, Relay, Tank, TankId, TankName, TimePeriod, Weekday, WEEKDAY_NAMES,
    WeekPeriod,
} from '@majpage/raspi-tanks-logic';
import { get, isBoolean, omit } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessages, Errors, GENERAL_ERROR_TYPE, getErrorsFromMessages, hasErrors } from '../../../helpers/error';
import { generateId } from '../../../helpers/home-automation/ids';
import { getRelayChannelOptions } from '../../../helpers/home-automation/relays';
import { getMinutesFromTime, getTimeFromMinutes } from '../../../helpers/time';
import { MatrixItemColumnType, RelayTankName } from '../../../types/home-automation/raspi-tanks';
import { FormatMessage } from '../../../types/react-intl';
import ErrorsComponent from '../../Errors/Errors';
import InputItem from '../FormItem/InputItem';
import MatrixItem from '../FormItem/MatrixItem';
import SwitchInUseItemStatus from '../FormItem/SwitchInUseItemStatus';

type ColorLightPeriodRow = [WeekPeriod | Weekday, string, string];

function setInitialData(relay?: Relay): Relay {
    return relay || { id: generateId('relay'), name: '', deviceId: '', powerPinName: '', tanks: [] };
}

const emptyError = { defaultMessage: 'Value has to be defined.', id: 'form.error.value-empty' };
function getErrors(item: Relay, namesInUse: string[], deviceIdsInUse: string[], formatMessage: FormatMessage): Errors {
    const messages: ErrorMessages = [];
    if (!item.name) {
        messages.push({ ...emptyError, type: 'name' });
    }
    if (namesInUse.includes(item.name)) {
        messages.push({ defaultMessage: 'This name is already in use.', id: 'form.error.name-in-use', type: 'name' });
    }
    if (deviceIdsInUse.includes(item.deviceId)) {
        messages.push({
            defaultMessage: 'This device ID is already in use.', id: 'form.error.device-id-in-use', type: 'deviceId',
        });
    }
    if (!item.deviceId) {
        messages.push({ ...emptyError, type: 'deviceId' });
    }
    if ('inUse' in item && !isBoolean(item.inUse)) {
        messages.push({ ...emptyError, type: 'inUse' });
    }
    if (!item.powerPinName) {
        messages.push({ ...emptyError, type: 'powerPinName' });
    }
    if (item.thermometer && !item.thermometer.name) {
        messages.push({ ...emptyError, type: 'thermometerName' });
    }
    // @TODO: Add other errors logic!
    return getErrorsFromMessages(messages, formatMessage);
}

interface RelayFormProps {
    allTankNames: RelayTankName[];
    deviceIdsInUse: string[];
    relay?: Relay;
    namesInUse: string[];
    onChange: (newRelay: Relay) => void;
    renderItem: (setModalShown: (modalShown: boolean) => void) => ReactNode;
}

export default ({ allTankNames, deviceIdsInUse, relay, namesInUse, onChange, renderItem }: RelayFormProps) => {
    const { formatMessage } = useIntl();
    const [modifiedRelay, setModifiedRelay] = useState<Relay>(() => setInitialData(relay));
    const [errors, setErrors] = useState<Errors>({});
    const [modalShown, setModalShown] = useState<boolean>(false);

    useEffect(() => {
        setErrors({});
    }, [modifiedRelay]);

    useEffect(() => {
        if (modalShown) {
            setModifiedRelay(setInitialData(relay));
        }
    }, [modalShown, relay]);

    return <>
        {renderItem(setModalShown)}
        <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false} className="RelayModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id={relay ? 'form.relay-edit' : 'form.relay-add'}
                        defaultMessage={relay ? 'Edit relay' : 'Add relay'} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ErrorsComponent messages={errors[GENERAL_ERROR_TYPE]} onClose={() => setErrors({})} />
                <InputItem {...{
                    errors: errors.name,
                    name: formatMessage({ defaultMessage: 'Name', id: 'form.label.name' }),
                    onChange: name => { setModifiedRelay({ ...modifiedRelay, name }) },
                    required: true,
                    value: modifiedRelay.name,
                }} />
                <Row>
                    <Col>
                        <InputItem {...{
                            disabled: true,
                            name: formatMessage({ defaultMessage: 'ID', id: 'form.label.id' }),
                            required: true,
                            value: modifiedRelay.id,
                        }} />
                    </Col>
                    <Col>
                        <SwitchInUseItemStatus<Relay> {...{
                            errors: errors.inUse,
                            id: 'relay-in-use',
                            item: relay,
                            modifiedItem: modifiedRelay,
                            setModifiedItem: setModifiedRelay,
                        }} />
                    </Col>
                </Row>
                <InputItem {...{
                    errors: errors.deviceId,
                    name: formatMessage({ defaultMessage: 'Device ID', id: 'form.label.device-id' }),
                    onChange: deviceId => {
                        setModifiedRelay({ ...modifiedRelay, deviceId });
                    },
                    required: true,
                    value: modifiedRelay.deviceId,
                }} />
                {relay && modifiedRelay.tanks.length > 0 ?
                    <MatrixItem<Tank[], [TankName]> {...{
                        canAdd: false,
                        columns: [
                            {
                                type: MatrixItemColumnType.STRING,
                                unique: true,
                            },
                        ],
                        errors: errors.tanks,
                        item: modifiedRelay.tanks,
                        itemToRows: tanks => tanks.map(({ name }) => [name]),
                        name: formatMessage({ defaultMessage: 'Tanks', id: 'form.label.tanks' }),
                        onChange: rows => {
                            const tankNames = rows.map(([name]) => name);
                            setModifiedRelay({
                                ...modifiedRelay,
                                tanks: modifiedRelay.tanks.filter(({ name }) => tankNames.includes(name)),
                            });
                        },
                    }} /> :
                    null}
                <MatrixItem<TankId[], [TankId]> {...{
                    columns: [
                        {
                            options: allTankNames.map(({ id, name, relayName }) => ({
                                key: id,
                                name: modifiedRelay.name === relayName ? name : `${name} (${relayName})`,
                            })),
                            type: MatrixItemColumnType.SELECT,
                            unique: true,
                        },
                    ],
                    errors: errors.sprinklerTanks,
                    item: modifiedRelay.sprinklerTanks,
                    itemToRows: sprinklerTanks => sprinklerTanks.map(sprinklerTank => ([sprinklerTank])),
                    name: formatMessage({ defaultMessage: 'Sprinkler tanks', id: 'form.label.sprinkler-tanks' }),
                    onChange: rows => {
                        const { sprinklerTanks, ...itemParams } = modifiedRelay;
                        setModifiedRelay(rows.length === 0 ? itemParams : {
                            ...itemParams,
                            sprinklerTanks: rows.map(([sprinklerTank]) => sprinklerTank),
                        });
                    },
                }} />
                <InputItem {...{
                    errors: errors.colorLightPrograms,
                    name: formatMessage({
                        defaultMessage: 'Color light program', id: 'form.label.color-light-program'
                    }),
                    onChange: value => {
                        const { colorLight, ...itemParams } = modifiedRelay;
                        if (!colorLight && !value) {
                            return;
                        }
                        const newColorLight = omit<ColorLight>(colorLight || {}, ['program']);
                        if (value) {
                            newColorLight.program = value;
                        }
                        setModifiedRelay(
                            Object.keys(newColorLight).length === 0 ? itemParams :
                                { ...itemParams, colorLight: newColorLight },
                        );
                    },
                    // @TODO: Add options here when available!
                    value: modifiedRelay.colorLight ? modifiedRelay.colorLight.program : '',
                }} />
                <MatrixItem<ColorLight, ColorLightPeriodRow> {...{
                    columns: [
                        {
                            options: [
                                { key: WeekPeriod.DAILY, name: formatMessage({
                                    defaultMessage: 'daily', id: 'form.value.daily',
                                }) },
                                { key: WeekPeriod.WORKDAY, name: formatMessage({
                                    defaultMessage: 'workday', id: 'form.value.workday',
                                }) },
                                { key: Weekday.MONDAY, name: formatMessage({
                                    defaultMessage: 'Monday', id: 'form.value.monday',
                                }) },
                                { key: Weekday.TUESDAY, name: formatMessage({
                                    defaultMessage: 'Tuesday', id: 'form.value.tuesday',
                                }) },
                                { key: Weekday.WEDNESDAY, name: formatMessage({
                                    defaultMessage: 'Wednesday', id: 'form.value.wednesday',
                                }) },
                                { key: Weekday.THURSDAY, name: formatMessage({
                                    defaultMessage: 'Thursday', id: 'form.value.thursday',
                                }) },
                                { key: Weekday.FRIDAY, name: formatMessage({
                                    defaultMessage: 'Friday', id: 'form.value.friday',
                                }) },
                                { key: Weekday.SATURDAY, name: formatMessage({
                                    defaultMessage: 'Saturday', id: 'form.value.saturday',
                                }) },
                                { key: Weekday.SUNDAY, name: formatMessage({
                                    defaultMessage: 'Sunday', id: 'form.value.sunday',
                                }) },
                            ],
                            title: formatMessage({ defaultMessage: 'name', id: 'form.value.name' }),
                            type: MatrixItemColumnType.SELECT,
                            unique: true,
                        },
                        {
                            max: 86399,
                            min: 0,
                            title: formatMessage({ defaultMessage: 'sunrise', id: 'form.value.sunrise' }),
                            type: MatrixItemColumnType.TIME,
                        },
                        {
                            max: 86399,
                            min: 0,
                            title: formatMessage({ defaultMessage: 'sunset', id: 'form.value.sunset' }),
                            type: MatrixItemColumnType.TIME,
                        },
                    ],
                    errors: errors.colorLightPeriods,
                    item: modifiedRelay.colorLight,
                    itemToRows: colorLight => {
                        if (!colorLight.weekdays) {
                            return [];
                        }
                        return WEEKDAY_NAMES.reduce<ColorLightPeriodRow[]>((list, weekdayName) => {
                            const firstPeriod: TimePeriod | undefined = get(colorLight, `weekdays.${weekdayName}[0]`);
                            if (firstPeriod) {
                                const { from, to } = firstPeriod;
                                list.push([weekdayName, getTimeFromMinutes(from), getTimeFromMinutes(to)]);
                            }
                            return list;
                        }, []);
                    },
                    name: formatMessage({
                        defaultMessage: 'Color light periods', id: 'form.label.color-light-periods',
                    }),
                    onChange: rows => {
                        const { colorLight, ...itemParams } = modifiedRelay;
                        if (!colorLight && rows.length === 0) {
                            return;
                        }
                        const weekdays = rows.reduce<ColorLightWeekdays>((list, [name, fromString, toString]) => {
                            const from = getMinutesFromTime(fromString);
                            const to = Math.max(from, getMinutesFromTime(toString, true));
                            list[name] = [{ from, to }];
                            return list;
                        }, {});
                        const newColorLight = omit<ColorLight>(colorLight || {}, ['weekdays']);
                        if (Object.keys(weekdays).length > 0) {
                            newColorLight.weekdays = weekdays;
                        }
                        setModifiedRelay(
                            Object.keys(newColorLight).length === 0 ? itemParams :
                                { ...itemParams, colorLight: newColorLight },
                        );
                    },
                }} />
                <InputItem {...{
                    errors: errors.powerPinName,
                    name: formatMessage({ defaultMessage: 'Power pin name', id: 'form.label.power-pin-name' }),
                    onChange: powerPinName => {
                        setModifiedRelay({ ...modifiedRelay, powerPinName });
                    },
                    required: true,
                    value: modifiedRelay.powerPinName,
                }} />
                <InputItem {...{
                    errors: errors.sprinklerRelayChannel,
                    name: formatMessage({
                        defaultMessage: 'Sprinkler relay channel', id: 'form.label.sprinkler-relay-channel',
                    }),
                    onChange: value => {
                        const { sprinklerRelayChannel, ...itemParams } = modifiedRelay;
                        const channel = value === '' ? null : `${value}`;
                        setModifiedRelay(
                            channel === null ? itemParams : { ...itemParams, sprinklerRelayChannel: channel },
                        );
                    },
                    options: getRelayChannelOptions(formatMessage),
                    value: modifiedRelay.sprinklerRelayChannel,
                }} />
                <InputItem {...{
                    errors: errors.thermometerAddress,
                    name: formatMessage({
                        defaultMessage: 'Internal thermometer address', id: 'form.label.internal-thermometer-address',
                    }),
                    onChange: value => {
                        const { temperature, thermometer, ...itemParams } = modifiedRelay;
                        if (!value) {
                            setModifiedRelay(itemParams);
                        } else {
                            setModifiedRelay({
                                ...itemParams,
                                ...(temperature ? { temperature } : {}),
                                thermometer: { name: '', ...thermometer, address: value },
                            });
                        }
                    },
                    value: modifiedRelay.thermometer ? modifiedRelay.thermometer.address : '',
                }} />
                <InputItem {...{
                    disabled: !modifiedRelay.thermometer,
                    errors: errors.thermometerName,
                    name: formatMessage({
                        defaultMessage: 'Internal thermometer name', id: 'form.label.internal-thermometer-name',
                    }),
                    onChange: value => {
                        const { thermometer, ...itemParams } = modifiedRelay;
                        if (!thermometer || !thermometer.address) {
                            setModifiedRelay(itemParams);
                        } else {
                            setModifiedRelay({
                                ...itemParams,
                                thermometer: { ...thermometer, name: value },
                            });
                        }
                    },
                    value: modifiedRelay.thermometer ? modifiedRelay.thermometer.name : '',
                }} />
                <InputItem {...{
                    disabled: !modifiedRelay.thermometer,
                    errors: errors.thermometerTemperatureMax,
                    name: formatMessage({
                        defaultMessage: 'Internal thermometer max temperature',
                        id: 'form.label.internal-thermometer-temperature-max',
                    }),
                    onChange: value => {
                        const { temperature, ...itemParams } = modifiedRelay;
                        const floatValue = parseFloat(value);
                        const maxTemperature = isNaN(floatValue) ? null : floatValue;
                        if (!itemParams.thermometer || maxTemperature === null) {
                            setModifiedRelay(itemParams);
                        } else {
                            setModifiedRelay({
                                ...itemParams,
                                temperature: { critical: { max: maxTemperature } },
                            });
                        }
                    },
                    type: 'number',
                    value: (() => {
                        const maxTemperature = getNumberFrom(modifiedRelay.temperature, 'critical.max');
                        return maxTemperature === null ? '' : `${maxTemperature}`
                    })(),
                }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setModalShown(false);
                }}><FormattedMessage id="modal.close" defaultMessage="Close" /></Button>
                <Button variant="primary" onClick={() => {
                    const errors = getErrors(modifiedRelay, namesInUse, deviceIdsInUse, formatMessage);
                    setErrors(errors);
                    if (!hasErrors(errors)) {
                        onChange(modifiedRelay);
                        setModalShown(false);
                    }
                }} disabled={hasErrors(errors)}>
                    {relay ?
                        <FormattedMessage id="form.change" defaultMessage="Change"/> :
                        <FormattedMessage id="form.save" defaultMessage="Save"/>}
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
};
