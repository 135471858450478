import React, { useState } from 'react';
import { Badge, Button, ButtonGroup, ButtonToolbar, InputGroup, ToggleButton } from 'react-bootstrap';
import { ArrowsAngleExpand } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';
import {
    FlexibleWidthXYPlot, Highlight, Hint, LineSeries, MarkSeries, VerticalRectSeries, XAxis, YAxis,
} from 'react-vis';
import 'react-vis/dist/style.css';
import { getName, getCoordsListsAndYDomain } from '../../helpers/series';
import { getFormattedTime, SECOND_IN_MS } from '../../helpers/time';
import { PlainObject } from '../../types/collection';
import { MeasurementSeries } from '../../types/home-automation/model';
import { Coords } from '../../types/series';
import './Chart.css';

interface ChartProps {
    descriptionY: string;
    getFormattedValue: (value: number) => string;
    problemsX: [number, number][];
    seriesList: MeasurementSeries[];
    timeEnd: number;
    timeStart: number;
}

export default ({ descriptionY, getFormattedValue, problemsX, seriesList, timeEnd, timeStart }: ChartProps) => {
    const [point, setPoint] = useState<any>(null);
    const [timePeriodChange, setTimePeriodChange] = useState<boolean>(false);

    const defaultXDomain: [number, number] = [timeStart * SECOND_IN_MS, timeEnd * SECOND_IN_MS];
    const [xDomain, setXDomain] = useState<[number, number]>(defaultXDomain);
    const isXDomainDefault = (): boolean => xDomain[0] === defaultXDomain[0] && xDomain[1] === defaultXDomain[1];

    const [checkedSeries, setCheckedSeries] = useState<PlainObject<boolean>>(
        seriesList.reduce<PlainObject<boolean>>((list, series, i) => {
            list[getName(series, i)] = true;
            return list;
        }, {}),
    );
    const isChecked = (series: MeasurementSeries, i: number): boolean => checkedSeries[getName(series, i)] === true;
    const setChecked = (series: MeasurementSeries, i: number, checked: boolean): void =>
        setCheckedSeries({ ...checkedSeries, [getName(series, i)]: checked });
    const checkedSeriesNames = Object.entries(checkedSeries).filter(([, value]) => value).map(([name]) => name);
    const [coordsLists, yDomain] = getCoordsListsAndYDomain(seriesList.filter(isChecked), xDomain);
    const seriesFilter = seriesList.length > 1 && seriesList.length > checkedSeriesNames.length ? checkedSeriesNames :
        null;

    return <div className="Chart">
        <FlexibleWidthXYPlot animation xDomain={xDomain} yDomain={yDomain} xType="time" onMouseLeave={() => {
            setPoint(null);
        }} onClick={() => {
            setPoint(null);
        }} height={300} className="plot">
            <XAxis tickFormat={time => `${getFormattedTime(time)}`} />
            <YAxis title={descriptionY} />
            {problemsX.length > 0 ?
                <VerticalRectSeries color="red" opacity={.3} data={problemsX.map(([problemStart, problemEnd]) => ({
                    x0: problemStart, x: problemEnd, y0: yDomain[0], y: yDomain[1],
                }))} /> :
                null}
            {coordsLists.map((data, i) => <LineSeries data={data} key={`series-graph-${i + 1}`} />)}
            <MarkSeries data={coordsLists.reduce<Coords[]>((list, data) => {
                list.push(...data);
                return list;
            }, [])} onValueClick={(datapoint, event: any) => {
                const realEvent: React.MouseEvent<HTMLElement, MouseEvent> =
                    event.stopPropagation ? event : event.event;
                realEvent.stopPropagation();
                setPoint(datapoint);
            }} onValueMouseOut={() => {
                setPoint(null);
                // @ts-ignore
            }} size={3} />
            {point ?
                <Hint value={point}>
                    <div className="rv-hint__content">
                        {getFormattedTime(new Date(point.x))} - {getFormattedValue(point.y)}
                    </div>
                </Hint> :
                null}
            {timePeriodChange ?
                <Highlight enableY={false} onBrushEnd={area => {
                    if (!area || !area.left || !area.right) {
                        return;
                    }
                    const currentXDomain = ([area.left, area.right] as [Date | number, Date | number])
                        .map(value => value instanceof Date ? value.getTime() : value)
                        .sort() as [number, number];
                    setXDomain(currentXDomain);
                }} /> :
                null}
        </FlexibleWidthXYPlot>
        <div className="d-flex justify-content-between">
            <ButtonToolbar>
                <ButtonGroup toggle>
                    {seriesList.map((series, i) => {
                        return <ToggleButton checked={isChecked(series, i)} onChange={() => {
                            const checked = isChecked(series, i);
                            if (!checked || checkedSeriesNames.length > 1) {
                                setChecked(series, i, !checked);
                            }
                        }} key={`series-button-${i + 1}`} type="checkbox" variant="secondary" value={series.series}>
                            {series.series}
                        </ToggleButton>;
                    })}
                </ButtonGroup>
                {!isXDomainDefault() ?
                    <InputGroup className="filter">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FormattedMessage id="chart-filters.time-period" defaultMessage="time period" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Button variant="outline-secondary">
                            {getFormattedTime(new Date(xDomain[0]))} - {getFormattedTime(new Date(xDomain[1]))}{' '}
                            <Badge variant="light" onClick={() => {
                                setXDomain(defaultXDomain);
                            }}>×</Badge>
                        </Button>
                    </InputGroup> :
                    null}
                {seriesFilter !== null ?
                    <InputGroup className="filter">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FormattedMessage id="chart-filters.data-series" defaultMessage="data series" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Button variant="outline-secondary">
                            {seriesFilter.join(', ')}{' '}
                            <Badge variant="light" onClick={() => {
                                setCheckedSeries(
                                    Object.keys(checkedSeries).reduce<PlainObject<boolean>>((list, name) => {
                                        list[name] = true;
                                        return list;
                                    }, {}),
                                );
                            }}>×</Badge>
                        </Button>
                    </InputGroup> :
                    null}
            </ButtonToolbar>
            <div>
                <Button type="button" variant={timePeriodChange ? 'secondary' : 'outline-secondary'} onClick={() => {
                    setTimePeriodChange(!timePeriodChange);
                }}>
                    <ArrowsAngleExpand />
                </Button>
            </div>
        </div>
    </div>;
};
