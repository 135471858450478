import { Field, FieldProps } from 'formik';
import React from 'react';
import { FormControl, FormControlProps, FormGroup, FormLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FormFieldProps } from '../../types/form-field';
import './FormField.css';

interface SelectFieldProps extends FormFieldProps, FormControlProps {
    children: React.ReactNode;
}

export default ({ children, error, fieldId, name, touched, ...props }: SelectFieldProps) => <Field name={fieldId}>
    {({ field }: FieldProps<string>) => <FormGroup controlId={fieldId} className="FormField">
        {name ? <FormLabel>{name}</FormLabel> : null}
        <FormControl as="select" value={field.value} onChange={field.onChange} className={error ? 'is-invalid' : ''}
            {...props}>
            {children}
        </FormControl>
        {touched && error && <div className="invalid-feedback"><FormattedMessage id={error} /></div>}
    </FormGroup>}
</Field>;
