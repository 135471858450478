import React from 'react';
import { Alert, Card, CardColumns } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { HttpErrors } from '../../../hooks/use-http-error';
import { useResource } from '../../../hooks/use-resource';
import { PATH as WAREHOUSE_PRODUCER_PATH } from '../../../routes/warehouse/Producer';
import { ProductModel } from '../../../types/warehouse/model';
import Message, { MessageType } from '../../Message/Message';
import ProductComments from '../Comments/ProductComments';
import Mark from '../Mark/Mark';
import ProductBindings from '../ProductBindings/ProductBindings';
import ProductTags from '../ProductTags/ProductTags';
import ProductVariants from '../ProductVariants/ProductVariants';
import ProductResources from '../Resources/ProductResources';
import './Product.css';

interface ProductProps {
    id: number;
    product?: ProductModel;
}

export default ({ id, product: preloadedProduct }: ProductProps) => {
    const url = getWarehouseApiUrl('products/:id', { params: { id: `${id}` } });
    const [product, { error, loading }, reload] = useResource<ProductModel>(url, {}, preloadedProduct);

    if (!product) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                {error === HttpErrors.NOT_FOUND_ERROR ?
                    <FormattedMessage id="product.no-product" defaultMessage="There is no such product." /> :
                    <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />}
            </Alert>;
        }
    }

    return <CardColumns className="Product">
        <Card>
            <Card.Header>
                <FormattedMessage id="product.title" defaultMessage="Product info" />
                <Mark value={product.mark} />
            </Card.Header>
            <Card.Body>
                <table className="table mb-0">
                    <tbody>
                    <tr>
                        <td className="text-right">
                            <strong><FormattedMessage id="table.label.name" defaultMessage="Name:" /></strong>
                        </td>
                        <td className="text-break">{product.name}</td>
                    </tr>
                    {product.producer ?
                        <tr>
                            <td className="text-right">
                                <strong>
                                    <FormattedMessage id="table.label.producer" defaultMessage="Producer:" />
                                </strong>
                            </td>
                            <td className="text-break">
                                <Link to={{
                                    pathname: getPath(WAREHOUSE_PRODUCER_PATH, { producerId: product.producer.id }),
                                    state: { producer: product.producer }
                                }}>{product.producer.name}</Link>
                            </td>
                        </tr>:
                        null}
                    {product.description ?
                        <tr>
                            <td className="text-right">
                                <strong>
                                    <FormattedMessage id="table.label.description" defaultMessage="Description:" />
                                </strong>
                            </td>
                            <td className="text-break">{product.description}</td>
                        </tr>:
                        null}
                    {product.validityPeriod ?
                        <tr>
                            <td className="text-right">
                                <strong>
                                    <FormattedMessage id="table.label.validity-period"
                                        defaultMessage="Validity period:" />
                                </strong>
                            </td>
                            <td className="text-break">{product.validityPeriod}</td>
                        </tr>:
                        null}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
        <ProductBindings product={product} onAdd={reload} />
        <ProductVariants productId={product.id} />
        <ProductTags productId={product.id} />
        <ProductResources id={product.id} />
        <ProductComments id={product.id} onAdd={reload} />
    </CardColumns>;
};
