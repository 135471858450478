import React from 'react';
import { useIntl } from 'react-intl';
import { Device } from '../../../types/home-automation/model';
import MeasurementsList from '../Measurements/MeasurementsList';

interface EnergyUsageProps {
    date: string;
    device: Device;
}

export default ({ date, device }: EnergyUsageProps) => {
    const { formatMessage } = useIntl();

    return <MeasurementsList {...{
        date, device,
        title: formatMessage({ defaultMessage: 'Electricity meter state', id: 'electricity-meter-state.title' }),
        type: 'energy-kwh', unit: ' kWh',
    }} />;
}
