import React from 'react';
import { Alert, CardColumns } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { HttpErrors } from '../../../hooks/use-http-error';
import { useResource } from '../../../hooks/use-resource';
import { ProducerModel } from '../../../types/warehouse/model';
import Message, { MessageType } from '../../Message/Message';
import ProducerComments from '../Comments/ProducerComments';
import ProducerInfo from '../ProducerInfo/ProducerInfo';
import Products from '../Products/Products';
import './Producer.css';

interface ProducerProps {
    id: number;
    producer?: ProducerModel;
}

export default ({ id, producer: preloadedProducer }: ProducerProps) => {
    const url = getWarehouseApiUrl('producers/:id', { params: { id: `${id}` } });
    const [producer, { error, loading }, reload] = useResource<ProducerModel>(url, {}, preloadedProducer);

    if (!producer) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                {error === HttpErrors.NOT_FOUND_ERROR ?
                    <FormattedMessage id="producer.no-producer" defaultMessage="There is no such producer."/> :
                    <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />}
            </Alert>;
        }
    }

    return <CardColumns className="Producer">
        <ProducerInfo producer={producer} />
        <Products producerId={producer.id} />
        <ProducerComments id={producer.id} onAdd={reload} />
    </CardColumns>;
};
