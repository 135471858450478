import React from 'react';
import { Alert, Card, CardColumns } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { HttpErrors } from '../../../hooks/use-http-error';
import { useResource } from '../../../hooks/use-resource';
import { TagModel } from '../../../types/warehouse/model';
import Message, { MessageType } from '../../Message/Message';
import TagActualStates from '../ActualStates/TagActualStates';
import Products from '../Products/Products';
import './Tag.css';

interface TagProps {
    slug: string;
    tag?: TagModel;
}

export default ({ slug, tag: preloadedTag }: TagProps) => {
    const url = getWarehouseApiUrl('tags/:slug', { params: { slug } });
    const [tag, { error, loading }] = useResource<TagModel>(url, {}, preloadedTag);

    if (!tag) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                {error === HttpErrors.NOT_FOUND_ERROR ?
                    <FormattedMessage id="tag.no-tag" defaultMessage="There is no such tag." /> :
                    <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />}
            </Alert>;
        }
    }

    return <CardColumns className="Tag">
        <Card>
            <Card.Header>
                <FormattedMessage id="tag.title" defaultMessage="Tag info" />
            </Card.Header>
            <Card.Body>
                <table className="table mb-0">
                    <tbody>
                    <tr>
                        <td className="text-right">
                            <strong><FormattedMessage id="table.label.name" defaultMessage="Name:" /></strong>
                        </td>
                        <td className="text-break">{tag.name}</td>
                    </tr>
                    {tag.description ?
                        <tr>
                            <td className="text-right">
                                <strong>
                                    <FormattedMessage id="table.label.description" defaultMessage="Description:" />
                                </strong>
                            </td>
                            <td className="text-break">{tag.description}</td>
                        </tr>:
                        null}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
        <Products tagSlug={tag.slug} />
        <TagActualStates tag={tag} />
    </CardColumns>;
};
