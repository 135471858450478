import React from 'react';
import { CardColumns } from 'react-bootstrap';
import { IntlShape } from 'react-intl';
import { Controls } from '../../../hooks/home-automation/use-device-controls';
import { Device } from '../../../types/home-automation/model';
import BatteryLevels from '../BatteryLevels/BatteryLevels';
import BatteryVoltage from '../BatteryVoltage/BatteryVoltage';
import MetadataRow from '../Controls/ControlsRow';
import MetadataSwitch from '../Controls/MetadataSwitch';
import DailyReport from '../DailyReport/DailyReport';
import Events from '../Events/Events';
import HumidityChart from '../Humidity/HumidityChart';
import TemperaturesChart from '../Temperatures/TemperaturesChart';

export const TYPE = 'raspi-mijia-hub';

export function customRender(device: Device, canManage: boolean) {
    return <DailyReport device={device} renderBeforeColumns={(date, { report }) => <CardColumns className="columns-1">
        <TemperaturesChart date={date} device={device} report={report} />
        <HumidityChart date={date} device={device} report={report} />
    </CardColumns>} renderBefore={date => <>
        <Events date={date} device={device} />
        <BatteryLevels date={date} device={device} />
        <BatteryVoltage date={date} device={device} />
    </>} canManage={canManage} />;
}

export const controlFields = [{ name: 'debug', initialValue: false }];
export function controlsSwitchesRender(controls: Controls, { formatMessage }: IntlShape) {
    const debug = controls.find<boolean>('debug');
    return <>
        {debug ?
            <MetadataRow title={formatMessage({ defaultMessage: 'Debug mode:', id: 'table.label.debug' })}>
                <MetadataSwitch id="raspi-mijia-hub-switch-debug" onChange={debug.change}
                    switched={debug.value} synced={debug.match} />
            </MetadataRow> :
            null}
    </>;
}
