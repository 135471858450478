import { ActionType, InventoryAction } from '../../types/inventories/model';
import { FormatMessage } from '../../types/react-intl';

export function getActionMessage(action: InventoryAction, formatMessage: FormatMessage) {
    switch (action.type) {
        case ActionType.INVENTORY_SAVE:
            return formatMessage({
                defaultMessage: 'Inventory saved.', id: 'inventories.action.inventory-save',
            });
        case ActionType.INVENTORY_SYNC:
            return formatMessage({
                defaultMessage: 'Inventory synchronized.', id: 'inventories.action.inventory-sync',
            });
        case ActionType.PRODUCT_CREATE:
            return formatMessage({
                defaultMessage: 'Product "{name}" created.', id: 'inventories.action.product-create',
            }, { name: action.name });
        case ActionType.PRODUCT_UPDATE:
            return formatMessage({
                defaultMessage: 'Product "{name}" updated.', id: 'inventories.action.product-update',
            }, { name: action.name });
        case ActionType.QUANTITY_CHANGE:
            return formatMessage({
                defaultMessage: 'Quantity of product "{name}" changed to {quantity}.',
                id: 'inventories.action.quantity-change',
            }, { name: action.name, quantity: action.value });
        default:
            return null;
    }
}
