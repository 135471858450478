import React from 'react';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { PATH as INVENTORIES_INVENTORY_PATH } from '../../../routes/inventories/Inventory';
import {
    Inventory as InventoryModel, InventoryFetched as InventoryFetchedModel, Status as InventoryStatus,
} from '../../../types/inventories/model';
import InventoryDeleteModal from './InventoryDeleteModal';
import InventoryReloadModal from './InventoryReloadModal';

interface InventoryFetchedProps {
    inventory: InventoryFetchedModel;
    onDelete?: () => void;
    onFetch?: (inventory: InventoryModel | null) => void;
}

export default ({ inventory, onDelete, onFetch }: InventoryFetchedProps) => <div className="InventoryShow toast show">
    <div className="toast-header">
        {inventory.status === InventoryStatus.OPENED ?
            <>
                <Link to={{ pathname: getPath(INVENTORIES_INVENTORY_PATH, { id: inventory.id }) }}
                    className="mr-auto">
                    {inventory.number}
                </Link>
                <InventoryReloadModal inventory={inventory} onDelete={onDelete} onFetch={onFetch} />
            </> :
            <span className="mr-auto">{inventory.number}</span>}
        <InventoryDeleteModal inventory={inventory} onDelete={onDelete} />
    </div>
</div>;
