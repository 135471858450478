import {
    DAY_IN_MINUTES, MeasurementPeriodsConfig, MeasurementSet, Relay, Thermometer,
} from '@majpage/raspi-tanks-logic';
import React, { ReactNode, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { getRelayName } from '../../../helpers/home-automation/relays';
import TankDetailsMeasurements from '../Tank/TankDetailsMeasurements';

interface RelayDetailsProps {
    renderItem: (setModalShown: (modalShown: boolean) => void) => ReactNode;
    relay: Relay;
    temperatures: MeasurementSet;
    thermometers: Thermometer[];
}

export default ({ renderItem, relay, temperatures, thermometers }: RelayDetailsProps) => {
    const { formatMessage } = useIntl();
    const [modalShown, setModalShown] = useState<boolean>(false);
    const temperatureConfig = useMemo<MeasurementPeriodsConfig>(
        () => relay.temperature ? { periods: [{ ...relay.temperature, from: 0, to: DAY_IN_MINUTES }] } : {},
        [relay.temperature],
    );

    if (!relay.thermometer) {
        return <>{getRelayName(relay, formatMessage)}</>;
    }
    return <>
        {renderItem(setModalShown)}
        <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false}
            className="RelayDetailsModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="form.relay-details" defaultMessage="Relay details" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TankDetailsMeasurements {...{
                    config: temperatureConfig,
                    measurements: temperatures,
                    sensors: thermometers,
                    title: <FormattedMessage id="tanks.details.temperature" defaultMessage="Temperature" />,
                    unit: '°C',
                }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setModalShown(false);
                }}><FormattedMessage id="modal.close" defaultMessage="Close" /></Button>
            </Modal.Footer>
        </Modal>
    </>;
};
