import { Field, FieldProps } from 'formik';
import React from 'react';
import { FormControl, FormControlProps, FormGroup, FormLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FormFieldProps } from '../../types/form-field';
import './FormField.css';

interface TextAreaFieldProps extends FormFieldProps, FormControlProps {
    rows?: number;
}

export default ({ error, fieldId, name, touched, ...props }: TextAreaFieldProps) => <Field name={fieldId}>
    {({ field }: FieldProps<string>) => <FormGroup controlId={fieldId} className="FormField">
        {name ? <FormLabel>{name}</FormLabel> : null}
        <FormControl as="textarea" value={field.value} onChange={field.onChange}
            className={error ? 'is-invalid' : ''} {...props} />
        {touched && error && <div className="invalid-feedback"><FormattedMessage id={error} /></div>}
    </FormGroup>}
</Field>;
