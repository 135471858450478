import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useStorageActualStates } from '../../../hooks/warehouse/use-resources';
import { ActualStateModel, StorageModel } from '../../../types/warehouse/model';
import ListTemplate from '../../List/ListTemplate';
import ActualState, { ActualStateContext } from '../ActualState/ActualState';

interface StorageActualStatesProps {
    storage: StorageModel;
}

export default ({ storage }: StorageActualStatesProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [items, { error, isLast, loading, total }] = useStorageActualStates(storage.id, page);

    useEffect(() => {
        setPage(0);
        setOpened(false);
    }, [storage.id]);

    const { formatMessage } = useIntl();
    const title = formatMessage({ defaultMessage: 'Actual states list', id: 'actual-states.title' });
    const renderItem = (state: ActualStateModel) =>
        <ActualState key={state.id} context={ActualStateContext.STORAGE} state={state} />;
    const listProps = { error, isLast, items, loading, opened, page, renderItem, setOpened, setPage, title, total };

    return <ListTemplate {...listProps} />;
};
