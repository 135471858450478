import React from 'react';
import { Alert, Card, CardColumns } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { HttpErrors } from '../../../hooks/use-http-error';
import { useStorage } from '../../../hooks/warehouse/use-resources';
import { PATH as WAREHOUSE_STORAGE_PATH } from '../../../routes/warehouse/Storage';
import Message, { MessageType } from '../../Message/Message';
import StorageActualStates from '../ActualStates/StorageActualStates';
import ProductUnits from '../ProductUnits/ProductUnits';
import StorageResources from '../Resources/StorageResources';
import Storages from '../Storages/Storages';
import './Storage.css';

interface StorageProps {
    id: number;
}

export default ({ id }: StorageProps) => {
    // It doesn't use preloaded storages on purpose because it doesn't have parent storages.
    const [storage, { error, loading }] = useStorage({ id });

    if (!storage) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                {error === HttpErrors.NOT_FOUND_ERROR ?
                    <FormattedMessage id="storage.no-storage" defaultMessage="There is no such storage." /> :
                    <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />}
            </Alert>;
        }
    }

    return <CardColumns className="Storage">
        <Card>
            <Card.Header>
                <FormattedMessage id="storage.title" defaultMessage="Storage info" />
            </Card.Header>
            <Card.Body>
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td className="text-right">
                                <strong>
                                    <FormattedMessage id="table.label.name" defaultMessage="Name:" />
                                </strong>
                            </td>
                            <td className="text-break">{storage.name}</td>
                        </tr>
                        {storage.description ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.description" defaultMessage="Description:" />
                                    </strong>
                                </td>
                                <td className="text-break">{storage.description}</td>
                            </tr> :
                            null}
                        {storage.parent ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.parent"
                                            defaultMessage="Parent:" />
                                    </strong>
                                </td>
                                <td className="text-break">
                                    <Link to={{
                                        pathname: getPath(WAREHOUSE_STORAGE_PATH, { storageId: storage.parent.id }),
                                        state: { storage: storage.parent }
                                    }}>{storage.parent.name}</Link>
                                </td>
                            </tr> :
                            null}
                        {storage.fixedCapacity ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.capacity" defaultMessage="Capacity:" />
                                    </strong>
                                </td>
                                <td className="text-break">
                                    {storage.amount} {storage.unit}
                                </td>
                            </tr> :
                            null}
                        {storage.fixedCapacity && storage.amount && storage.amountInUse !== null ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.capacity-in-use"
                                            defaultMessage="Capacity in use:" />
                                    </strong>
                                </td>
                                <td className="text-break">
                                    {storage.amountInUse} {storage.unit}{' '}
                                    ({Math.round(storage.amountInUse / storage.amount * 100)}%)
                                </td>
                            </tr> :
                            null}
                        {storage.qrCode ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.qr-code" defaultMessage="QR code:" />
                                    </strong>
                                </td>
                                <td className="text-break">{storage.qrCode}</td>
                            </tr> :
                            null}
                        {storage.barcode ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.barcode" defaultMessage="Barcode:" />
                                    </strong>
                                </td>
                                <td className="text-break">{storage.barcode}</td>
                            </tr> :
                            null}
                        {storage.nfcTag ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.nfc-tag" defaultMessage="NFC tag:" />
                                    </strong>
                                </td>
                                <td className="text-break">{storage.nfcTag}</td>
                            </tr> :
                            null}
                        {storage.rfidTag ?
                            <tr>
                                <td className="text-right">
                                    <strong>
                                        <FormattedMessage id="table.label.rfid-tag" defaultMessage="RFID tag:" />
                                    </strong>
                                </td>
                                <td className="text-break">{storage.rfidTag}</td>
                            </tr> :
                            null}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
        <Storages parentId={storage.id} />
        {storage.unitsAvailable ?
            <ProductUnits baseUrl={getPath(WAREHOUSE_STORAGE_PATH, { storageId: storage.id })} storage={storage} /> :
            null}
        {storage.statesAvailable ? <StorageActualStates storage={storage} /> : null}
        <StorageResources id={storage.id} />
    </CardColumns>;
};
