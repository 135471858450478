import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRentals } from '../../../hooks/warehouse/use-resources';
import { ProductUnitRentalModel } from '../../../types/warehouse/model';
import { Capabilities } from '../../../types/warehouse/user';
import { useUserFromContext } from '../../Contexts';
import ListTemplate from '../../List/ListTemplate';
import ProductUnitRental from '../ProductUnitRentals/ProductUnitRental';
import './Rentals.css';

interface RentalsProps {
    extended?: true | null;
}

export default ({ extended }: RentalsProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [items, { editItem, error, isLast, loading, total }] = useRentals(extended || null, page);
    const user = useUserFromContext();
    const canManageRentals = user.capabilities.includes(Capabilities.RENTALS_MANAGE);

    const { formatMessage } = useIntl();
    const title = extended === true ?
        formatMessage({ defaultMessage: 'Extended rentals', id: 'rentals.extended.title' }) :
        formatMessage({ defaultMessage: 'All rentals', id: 'rentals.all.title' });
    const renderItem = (productUnitRental: ProductUnitRentalModel, index: number) =>
        <ProductUnitRental key={productUnitRental.id} onEdit={item => { editItem(item, index) }}
            productUnitRental={productUnitRental} canManageRentals={canManageRentals} />;
    const listProps = {
        error, isLast, items, loading, opened, page, renderItem, setOpened, setPage, title, total,
    };

    return <ListTemplate {...listProps} />;
};
