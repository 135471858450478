export function formatAmount(
    locale: string, amount: number, unit: string | null = null, decimalPlaces: number | null = null
) {
    const options = decimalPlaces ? { maximumFractionDigits: decimalPlaces, minimumFractionDigits: decimalPlaces } : {};
    return amount.toLocaleString(locale, options) + (unit ? ` ${unit}` : '');
}

export function formatPrice(
    locale: string, price: number, currency: string, unit: string | null = null, decimalPlaces: number | null = 2
) {
    return formatAmount(locale, price, currency + (unit ? `/${unit}` : ''), decimalPlaces);
}
