import React from 'react';
import { useIntl } from 'react-intl';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { Capabilities } from '../../../types/warehouse/user';
import Resources from './Resources';

interface ProductVariantResourcesProps {
    id: number;
}

const getUrl = (id: number) => getWarehouseApiUrl('product-variants/:id/resources', { params: { id } });
const getResourceUrl = (id: number) => (resourceId: number) =>
    getWarehouseApiUrl('product-variants/:id/resources/:resourceId', { params: { id, resourceId } });

export default ({ id }: ProductVariantResourcesProps) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'resources.product-variant.title', defaultMessage: 'Product variant resources' });

    return <Resources url={getUrl(id)} getResourceUrl={getResourceUrl(id)} title={title}
        capability={Capabilities.VARIANTS_MANAGE} />;
}
