import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getInventoryApiUrl } from '../../../helpers/url';
import { InventoryFetched } from '../../../types/inventories/model';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';

interface InventoryDeleteModalProps {
    inventory: InventoryFetched;
    onDelete?: (inventory: InventoryFetched) => void;
}

export default ({ inventory, onDelete }: InventoryDeleteModalProps) => {
    const [error, setError] = useState<unknown>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const url = useMemo(() => getInventoryApiUrl('inventories/:id', { params: { id: inventory.id } }), [inventory.id]);

    return <>
        <Button size="sm" onClick={() => {
            setError(null);
            setShowError(true);
            setModalShown(true);
        }} className="ml-1" variant="light">
            <TrashFill>
                <FormattedMessage id="inventories.button.delete" defaultMessage="Delete inventory" />
            </TrashFill>
        </Button>
        <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false}
            className="InventoryDeleteModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="inventories.modal.delete" defaultMessage="Delete inventory" />
                </Modal.Title>
            </Modal.Header>
            {error && showError ?
                <Modal.Body>
                    <FormSubmitError error={error} setShowError={setShowError} showError={showError} />
                </Modal.Body> :
                null}
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setModalShown(false) }}>
                    <FormattedMessage id="modal.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="primary" onClick={async () => {
                    try {
                        setError(null);
                        setShowError(true);
                        await authenticatedRequest({ method: HttpMethod.DELETE, url });
                        if (onDelete) {
                            onDelete(inventory);
                        } else {
                            setModalShown(false);
                        }
                    } catch (error) {
                        setError(error);
                    }
                }}>
                    <FormattedMessage id="modal.ok" defaultMessage="OK" />
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}
