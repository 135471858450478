import React, { useState } from 'react';
import { useResources } from '../../../hooks/warehouse/use-resources';
import { ResourceModel } from '../../../types/warehouse/model';
import { Capabilities } from '../../../types/warehouse/user';
import { useUserFromContext } from '../../Contexts';
import ListTemplate from '../../List/ListTemplate';
import Resource from './Resource';
import ResourceManageForm from './ResourceManageForm';

interface ResourcesProps {
    capability: Capabilities;
    getResourceUrl: (resourceId: number) => string;
    title: string;
    url: string;
}

export default ({ capability, getResourceUrl, title, url }: ResourcesProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [items, { addItem, editItem, error, isLast, loading, total }] = useResources(url, page);
    const user = useUserFromContext();

    const listPrefix = user.capabilities.includes(capability) ?
        <ResourceManageForm url={url} onAdd={resource => { addItem(resource) }} /> :
        null;
    const renderItem = (resource: ResourceModel, index: number) => <Resource key={resource.id}
        onEdit={item => { editItem(item, index) }} resource={resource} url={getResourceUrl(resource.id)} />;
    const listProps = {
        error, isLast, items, listPrefix, loading, opened, page, renderItem, setOpened, setPage, title, total,
    };

    return <ListTemplate {...listProps} />;
};
