import React, { useEffect, useState } from 'react';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { SECOND_IN_MS } from '../../../helpers/time';
import { getHomeAutomationApiUrl } from '../../../helpers/url';
import { Device, Measurement } from '../../../types/home-automation/model';
import { HomeAutomationMeasurementsContext } from '../../Contexts';

const MEASUREMENTS_INTERVAL = 30 * SECOND_IN_MS;
const url = getHomeAutomationApiUrl('current-measurements');

interface MeasurementsProps {
    children: React.ReactNode;
    device: Device;
}

export default ({ children, device }: MeasurementsProps) => {
    const [measurements, setMeasurements] = useState<{ last: number, list: Measurement[] }>({ last: 0, list: [] });

    useEffect(() => {
        let request = new AbortController();
        const timeout = setTimeout(() => {
            authenticatedRequest<{ list : Measurement[] }>({
                method: HttpMethod.GET,
                params: { device: device.slug },
                url,
            }, request.signal).then(data => {
                if (!request.signal.aborted) {
                    setMeasurements({ last: Date.now(), list: data.list });
                }
            }).catch(() => {
                setMeasurements({ ...measurements, last: Date.now() });
            });
        }, measurements.last ? Math.max(0, measurements.last + MEASUREMENTS_INTERVAL - Date.now()) : 0);
        return () => {
            request.abort();
            clearTimeout(timeout);
        }
    }, [device.slug, measurements]);

    return <HomeAutomationMeasurementsContext.Provider value={{ homeAutomationMeasurements: measurements }}>
        {children}
    </HomeAutomationMeasurementsContext.Provider>;
};
