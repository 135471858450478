import React from 'react';
import { useIntl } from 'react-intl';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { ProductVariantModel } from '../../../types/warehouse/model';
import List from '../../List/List';
import ProductVariant from './ProductVariant';

const url = getWarehouseApiUrl('product-variants');

interface ProductVariantsProps {
    productId?: number;
}

export default (props: ProductVariantsProps) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ defaultMessage: 'Product variants', id: 'product-variants.title' });
    const renderItem = (productVariant: ProductVariantModel) =>
        <ProductVariant key={productVariant.id} productVariant={productVariant} showProductDescription={false} />;

    return <List params={props} title={title} url={url} renderItem={renderItem} />;
};
