import { isString } from 'lodash';

export function getString(value: any, defaultValue: string = ''): string {
    if (!value) {
        return defaultValue;
    }
    return `${value}`;
}

export function getStringOrError(value: any, errorMessage?: string): string {
    if (!value) {
        throw new Error(errorMessage);
    }
    return `${value}`;
}

export function getInteger(value: any, defaultValue: number = 0): number {
    if (!value && value !== 0) {
        return defaultValue;
    }
    value = parseInt(`${value}` || '', 10);
    return isNaN(value) ? defaultValue : value;
}

export function getFloat(value: any, defaultValue: number = 0): number {
    if (!value && value !== 0) {
        return defaultValue;
    }
    value = parseFloat(`${value}` || '');
    return isNaN(value) ? defaultValue : value;
}

export function getBoolean(value: any, defaultValue: boolean = false) {
    if (isString(value)) {
        value = value.toLowerCase();
    }
    if (value === true || value === 1 || value === 'true') {
        return true;
    }
    if (value === false || value === 0 || value === 'false') {
        return false;
    }
    return defaultValue;
}
