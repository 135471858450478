import React from 'react';
import { Card } from 'react-bootstrap';
import { FailureReport } from '../../../types/home-automation/model';
import './DailyReport.css';
import ReportFailureForm from './ReportFailureForm';

interface ReportFailuresListProps {
    canManage: boolean;
    failures: FailureReport[];
    onChange: () => void;
    title: string;
}

export default ({ canManage, failures, onChange, title }: ReportFailuresListProps) => failures.length > 0 ?
    <Card>
        <Card.Header>{title}</Card.Header>
        <Card.Body>
            {failures.map((failure, key) =>
                <ReportFailureForm key={key} canManage={canManage} failure={failure} onChange={onChange} />)}
        </Card.Body>
    </Card> :
    null;
