import { Relay, TankType } from '@majpage/raspi-tanks-logic';
import React from 'react';
import { IntlShape } from 'react-intl';
import { Controls } from '../../../hooks/home-automation/use-device-controls';
import { Device } from '../../../types/home-automation/model';
import MetadataRow from '../Controls/ControlsRow';
import MetadataSwitch from '../Controls/MetadataSwitch';
import DailyReport from '../DailyReport/DailyReport';
import Events from '../Events/Events';
import Measurements from '../Measurements/Measurements';
import Relays from '../Relays/Relays';
import Tanks from '../Tanks/Tanks';
import TankTypes from '../TankTypes/TankTypes';

export const TYPE = 'raspi-tanks';

export const TANKS_IN_RELAY_MAX = 8;

export function customRender(device: Device, canManage: boolean) {
    return <DailyReport device={device} canManage={canManage} renderBefore={date => <>
        <Events date={date} device={device} />
    </>} />;
}

export const controlFields = [
    { name: 'debug', initialValue: false },
    { name: 'protectRelays', initialValue: false },
    { name: 'relays', initialValue: [], transactional: true },
    { name: 'tankTypes', initialValue: [], transactional: true },
];
export function controlsSwitchesRender(controls: Controls, { formatMessage }: IntlShape) {
    const debug = controls.find<boolean>('debug');
    const protectRelays = controls.find<boolean>('protectRelays');
    return <>
        {debug ?
            <MetadataRow title={formatMessage({ defaultMessage: 'Debug mode:', id: 'table.label.debug' })}>
                <MetadataSwitch id="raspi-tanks-switch-debug" onChange={debug.change}
                    switched={debug.value} synced={debug.match} />
            </MetadataRow> :
            null}
        {protectRelays ?
            <MetadataRow title={formatMessage({ defaultMessage: 'Protect relays:', id: 'table.label.protect-relays' })}>
                <MetadataSwitch id="raspi-tanks-switch-protect-relays" onChange={protectRelays.change}
                    switched={protectRelays.value} synced={protectRelays.match} />
            </MetadataRow> :
            null}
    </>;
}
export function controlsAppendRender(controls: Controls, device: Device, loaded: boolean, editable: boolean) {
    const relays = controls.find<Relay[]>('relays');
    const tankTypes = controls.find<TankType[]>('tankTypes');
    return relays && tankTypes ?
        <Measurements device={device}>
            <Relays editable={editable} loaded={loaded} relays={relays} />
            <TankTypes editable={editable} loaded={loaded} relays={relays} tankTypes={tankTypes} />
            <Tanks editable={editable} loaded={loaded} relays={relays}
                tankTypes={tankTypes.modifiedValue || tankTypes.value || []} />
        </Measurements> :
        null;
}
