import { MeasurementSeries } from '../types/home-automation/model';
import { Coords } from '../types/series';
import { SECOND_IN_MS } from './time';

export function getName(series: MeasurementSeries, i: number): string {
    return series.series || `series-${i + 1}`;
}

export function getCoordsListsAndYDomain(
    seriesLists: MeasurementSeries[], xDomain: [number, number],
): [Coords[][], [number, number]] {
    const xDomainSec = [Math.abs(xDomain[0] / SECOND_IN_MS), Math.abs(xDomain[1] / SECOND_IN_MS)];
    const yDomain: number[] = [];
    const seriesList = seriesLists.map(series => {
        return series.data
            .filter(({ timestamp }, i, list) => {
                const isLast = list.length - 1 === i;
                return (timestamp >= xDomainSec[0] && timestamp <= xDomainSec[1]) ||
                    (timestamp < xDomainSec[0] && !isLast && list[i + 1].timestamp > xDomainSec[0]) ||
                    (timestamp > xDomainSec[1] && i > 0 && list[i - 1].timestamp < xDomainSec[1]);
            })
            .map(({ timestamp, value }) => {
                if (yDomain.length === 0) {
                    yDomain[0] = value;
                    yDomain[1] = value;
                } else if (value < yDomain[0]) {
                    yDomain[0] = value;
                } else if (value > yDomain[1]) {
                    yDomain[1] = value;
                }
                return { x: timestamp * SECOND_IN_MS, y: value };
            });
    });
    if (yDomain.length !== 2) {
        return [seriesList, [0, 0]];
    }
    const padding = Math.abs(yDomain[1] - yDomain[0]) * .15;
    return [seriesList, [yDomain[0] - padding, yDomain[1] + padding]];
}
