import { CLIENT_JWT_STORAGE_NAME } from './auth-request';
import { getPath } from './path';

interface ApiUrlOptions {
    params?: { [key: string]: string | number | boolean };
    version?: string;
}

function getApiUrl(baseUrl: string, path: string, { params, version }: ApiUrlOptions = {}): string {
    return `${baseUrl}/${version || 'v1'}${path.startsWith('/') ? '' : '/'}${getPath(path, params || {})}`;
}

export function getHomeAutomationApiUrl(path: string, options: ApiUrlOptions = {}): string {
    return getApiUrl(`${process.env.REACT_APP_HOME_AUTOMATION_API_URL}`, path, options);
}

export function getInventoryApiUrl(path: string, options: ApiUrlOptions = {}): string {
    return getApiUrl(`${process.env.REACT_APP_INVENTORY_API_URL}`, path, options);
}

export function getPriceTagsApiUrl(path: string, options: ApiUrlOptions = {}): string {
    return getApiUrl(`${process.env.REACT_APP_PRICE_TAGS_API_URL}`, path, options);
}

export function getChangedPriceTagsUrl(): string {
    const token = sessionStorage.getItem(CLIENT_JWT_STORAGE_NAME);
    return `${process.env.REACT_APP_PRICE_TAGS_URL}/changed-price-tags?access_token=${token}`;
}

export function getTrackingApiUrl(path: string, options: ApiUrlOptions = {}): string {
    return getApiUrl(`${process.env.REACT_APP_TRACKING_API_URL}`, path, options);
}

export function getTrackingSocketsUrl(): string {
    return `${process.env.REACT_APP_TRACKING_SOCKETS_URL}`;
}

export function getWarehouseApiUrl(path: string, options: ApiUrlOptions = {}): string {
    return getApiUrl(`${process.env.REACT_APP_WAREHOUSE_API_URL}`, path, options);
}
