import { NOT_FOUND } from 'http-status-codes';
import { useState } from 'react';
import { RequestError, ResponseError } from '../helpers/auth-request';

export enum HttpErrors {
    NOT_FOUND_ERROR = NOT_FOUND,
    OK = 0,
    REQUEST_ERROR = 1,
    RESPONSE_ERROR = 2,
    UNKNOWN_ERROR = 3,
}

export function useHttpError(): [HttpErrors, (error: any) => void, () => void] {
    const [error, setError] = useState<HttpErrors>(HttpErrors.OK);
    const catchError = (caughtError: any) => {
        if (caughtError instanceof RequestError) {
            console.error(caughtError);
            setError(HttpErrors.REQUEST_ERROR);
        } else if (caughtError instanceof ResponseError) {
            switch (caughtError.status) {
                case NOT_FOUND:
                    setError(HttpErrors.NOT_FOUND_ERROR);
                    break;
                default:
                    setError(HttpErrors.RESPONSE_ERROR);
                    break;
            }
        } else {
            console.error(caughtError);
            setError(HttpErrors.UNKNOWN_ERROR);
        }
    };
    const resetError = () => {
        setError(HttpErrors.OK);
    };

    return [error, catchError, resetError];
}
