import { Form, FormikProps, withFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonProps, InputGroup, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getInventoryApiUrl } from '../../../helpers/url';
import { InventoryProduct } from '../../../types/inventories/model';
import TextInputField from '../../FormField/TextInputField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';

interface FormValues {
    barcode: string | null;
    code: string | null;
    currency: string | null;
    name: string;
    priceGross: number;
    tax: number;
    unit: string | null;
}

const barcodePattern = /^[0-9]{8,32}$/;
const Schema = Yup.object().shape({
    barcode: Yup.string().typeError('form.string.type-incorrect')
        .matches(barcodePattern, 'form.string.barcode-incorrect')
        .max(32, 'form.string.too-long')
        .nullable(),
    code: Yup.string().typeError('form.string.type-incorrect')
        .max(128, 'form.string.too-long')
        .nullable(),
    currency: Yup.string().typeError('form.string.type-incorrect')
        .max(8, 'form.string.too-long')
        .nullable(),
    name: Yup.string().typeError('form.string.type-incorrect')
        .max(128, 'form.string.too-long')
        .required(),
    priceGross: Yup.number().typeError('form.number.type-incorrect')
        .min(0, 'form.number.too-low')
        .max(100000, 'form.number.too-high')
        .required(),
    tax: Yup.number().typeError('form.number.type-incorrect')
        .min(0, 'form.number.too-low')
        .max(100000, 'form.number.too-high')
        .required(),
    unit: Yup.string().typeError('form.string.type-incorrect')
        .max(32, 'form.string.too-long')
        .nullable(),
});

interface PureFormProps {
    error: unknown;
    onModalClose: () => void;
    setShowError: (showError: boolean) => void;
    showError: boolean;
}

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();

    return <Form>
        <Modal.Body>
            <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
            <TextInputField fieldId="name" touched={touched.name} error={errors.name}
                name={formatMessage({ defaultMessage: 'Name', id: 'form.label.name' })} />
            <TextInputField fieldId="code" touched={touched.code} error={errors.code}
                name={formatMessage({ defaultMessage: 'Producer code', id: 'form.label.producer-code' })} />
            <TextInputField fieldId="barcode" touched={touched.barcode} error={errors.barcode}
                name={formatMessage({ defaultMessage: 'Barcode', id: 'form.label.barcode' })} />
            <TextInputField fieldId="priceGross" touched={touched.priceGross} error={errors.priceGross} type="number"
                name={formatMessage({ defaultMessage: 'Gross price', id: 'form.label.price-gross' })}
                renderAppend={props.values.currency ?
                    <InputGroup.Text>{props.values.currency}</InputGroup.Text> :
                    null} />
            <TextInputField fieldId="tax" touched={touched.tax} error={errors.tax} type="number"
                name={formatMessage({ defaultMessage: 'Tax', id: 'form.label.tax' })}
                renderAppend={<InputGroup.Text>%</InputGroup.Text>} />
            <TextInputField fieldId="currency" touched={touched.currency} error={errors.currency}
                name={formatMessage({ defaultMessage: 'Currency', id: 'form.label.currency' })} />
            <TextInputField fieldId="unit" touched={touched.unit} error={errors.unit}
                name={formatMessage({ defaultMessage: 'Unit', id: 'form.label.unit' })} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onModalClose}>
                <FormattedMessage id="modal.close" defaultMessage="Close" />
            </Button>
            <Button variant="primary" type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
                <FormattedMessage id="form.save" defaultMessage="Save" />
            </Button>
        </Modal.Footer>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    method: HttpMethod;
    onSave: (product: InventoryProduct) => void;
    product?: InventoryProduct;
    search?: string;
    setError: (text: unknown) => void;
    url: string;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);
            const product = await authenticatedRequest<InventoryProduct>({
                data: {
                    barcode: values.barcode || null,
                    code: values.code || null,
                    currency: values.currency || null,
                    name: values.name,
                    priceGross: values.priceGross,
                    tax: values.tax,
                    unit: values.unit || null,
                },
                method: props.method,
                url: props.url,
            });
            resetForm();
            props.onSave(product);
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: ({ product, search }) => {
        const isSearchBarcode = search ? barcodePattern.test(search) : false;
        return {
            barcode: product ? (product.barcode || '') : (search && isSearchBarcode ? search : ''),
            code: product ? (product.code || '') : '',
            currency: product ? (product.currency || '') : '',
            name: product ? (product.name || '') : (search && !isSearchBarcode ? search : ''),
            priceGross: product ? (product.priceGross || 0) : 0,
            tax: product ? (product.tax || 0) : 0,
            unit: product ? (product.unit || '') : '',
        };
    },
    validationSchema: Schema,
})(PureForm);

interface ProductFormProps {
    buttonTitle: JSX.Element;
    buttonVariant?: ButtonProps['variant'];
    inventoryId: number;
    onClose?: () => void;
    onSave?: (product: InventoryProduct) => void;
    product?: InventoryProduct;
    search?: string;
}

export default ({ buttonTitle, buttonVariant, inventoryId, onClose, onSave, product, search }: ProductFormProps) => {
    const [error, setError] = useState<unknown>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const url = useMemo(
        () => product ? getInventoryApiUrl('inventories/:id/products/:productId', {
            params: { id: inventoryId, productId: product.id },
        }) : getInventoryApiUrl('inventories/:id/products', { params: { id: inventoryId } }),
        [inventoryId, product],
    );
    const onModalClose = useCallback(() => {
        setModalShown(false);
        if (onClose) {
            onClose();
        }
    }, [onClose, setModalShown]);
    const stateProps = { error, onModalClose, product, search, setError, setShowError, showError };

    return <>
        <Button variant={buttonVariant} onClick={() => {
            setError(null);
            setShowError(true);
            setModalShown(true);
        }}>{buttonTitle}</Button>
        <Modal show={modalShown} onHide={onModalClose} animation={false} className="ProductModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {product ?
                        <FormattedMessage id="inventories.modal.product-edit" defaultMessage="Edit product" /> :
                        <FormattedMessage id="inventories.modal.product-add" defaultMessage="Add product" />}
                </Modal.Title>
            </Modal.Header>
            <FormikForm url={url} method={product ? HttpMethod.PATCH : HttpMethod.POST} onSave={product => {
                if (onSave) {
                    onSave(product);
                }
                onModalClose();
            }} {...stateProps} />
        </Modal>
    </>;
}
