import React from 'react';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import Menu from '../../components/warehouse/Menu/Menu';
import Results from '../../components/warehouse/Results/Results';
import { getIdCode } from '../../helpers/id-code';
import { Section } from '../../types/section';

export const PATH = `/${Section.WAREHOUSE}/results`;

export default ({ location }: RouteComponentProps) => {
    const idCode = getIdCode(location.search);

    return <div className="WarehouseResultsPage">
        <Menu />
        {idCode ?
            <Results idCode={idCode} /> :
            <Alert variant="danger"><FormattedMessage id="results.no-results" defaultMessage="No results" /></Alert>}
    </div>;
}
