import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useStorages } from '../../../hooks/warehouse/use-resources';
import { StorageModel } from '../../../types/warehouse/model';
import ListTemplate from '../../List/ListTemplate';
import Storage from './Storage';

interface StoragesProps {
    parentId: number;
}

export default ({ parentId }: StoragesProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [items, { error, isLast, loading, total }] = useStorages(parentId, page);

    useEffect(() => {
        setPage(0);
        setOpened(false);
    }, [parentId]);

    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'storages.children.title', defaultMessage: 'Child storages list' });
    const renderItem = (storage: StorageModel) => <Storage key={storage.id} storage={storage} />;
    const listProps = { error, isLast, items, loading, opened, page, renderItem, setOpened, setPage, title, total };

    return <ListTemplate {...listProps} />;
};
