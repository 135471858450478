import React from 'react';
import { FormControl } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface MetadataSwitchSyncProps {
    synced: boolean;
}

export default ({ synced }: MetadataSwitchSyncProps) => <FormControl.Feedback type="invalid"
    style={{ display: 'block', ...(synced ? { height: 0, margin: 0, overflow: 'hidden', padding: 0 } : {}) }}>
    <FormattedMessage defaultMessage="Synchronizing with device..." id="controls.synchronizing" />
</FormControl.Feedback>;
