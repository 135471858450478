import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PlainObject } from '../../types/collection';
import { Highlight, HighlightType, Message, MessageType } from '../../types/tracking/model';

const messages: PlainObject<{ name: JSX.Element, warning?: true }> = {
    [MessageType.ALARM_GEOFENCE_CANCEL]: {
        name: <FormattedMessage id="tracking-messages.alarm-geofence-cancel"
            defaultMessage="Geofence alarm cancelled" />,
    },
    [MessageType.ALARM_GEOFENCE_SET]: {
        name: <FormattedMessage id="tracking-messages.alarm-geofence-set" defaultMessage="Geofence alarm set" />,
    },
    [MessageType.ALARM_MOVE_CANCEL]: {
        name: <FormattedMessage id="tracking-messages.alarm-move-cancel" defaultMessage="Move alarm cancelled" />,
    },
    [MessageType.ALARM_MOVE_SET]: {
        name: <FormattedMessage id="tracking-messages.alarm-move-set" defaultMessage="Move alarm set" />,
    },
    [MessageType.ALARM_NO_GPS]: {
        name: <FormattedMessage id="tracking-messages.alarm-no-gps" defaultMessage="No GPS alarm!" />,
        warning: true,
    },
    [MessageType.ALARM_OVER_SPEED]: {
        name: <FormattedMessage id="tracking-messages.alarm-over-speed" defaultMessage="Over speed alarm!" />,
        warning: true,
    },
    [MessageType.ALARM_OVER_SPEED_SET]: {
        name: <FormattedMessage id="tracking-messages.alarm-over-speed-set" defaultMessage="Over speed alarm set" />,
    },
    [MessageType.ALARM_STOP]: {
        name: <FormattedMessage id="tracking-messages.alarm-stop" defaultMessage="Stop alarm!" />,
        warning: true,
    },
    [MessageType.ALARM_VIBRATION]: {
        name: <FormattedMessage id="tracking-messages.alarm-vibration" defaultMessage="Vibration alarm!" />,
        warning: true,
    },
    [MessageType.ALARM_VIBRATION_CANCEL]: {
        name: <FormattedMessage id="tracking-messages.alarm-vibration-cancel"
            defaultMessage="Vibration alarm cancelled" />,
    },
    [MessageType.ALARM_VIBRATION_SET]: {
        name: <FormattedMessage id="tracking-messages.alarm-vibration-set" defaultMessage="Vibration alarm set" />,
    },
    [MessageType.CONNECTION_ERROR]: {
        name: <FormattedMessage id="tracking-messages.connection-error" defaultMessage="Connection error!" />,
        warning: true,
    },
    [MessageType.CONNECTION_LOST]: {
        name: <FormattedMessage id="tracking-messages.connection-lost" defaultMessage="Connection lost" />,
    },
    [MessageType.ENGINE_START]: {
        name: <FormattedMessage id="tracking-messages.engine-start" defaultMessage="Engine started" />,
    },
    [MessageType.ENGINE_STOP]: {
        name: <FormattedMessage id="tracking-messages.engine-stop" defaultMessage="Engine stopped" />,
    },
    [MessageType.HEARTBEAT]: {
        name: <FormattedMessage id="tracking-messages.heartbeat" defaultMessage="Heartbeat" />,
    },
    [MessageType.INIT]: {
        name: <FormattedMessage id="tracking-messages.init" defaultMessage="Connection initiated" />,
    },
    [MessageType.TRACKING_CANCEL]: {
        name: <FormattedMessage id="tracking-messages.tracking-cancel" defaultMessage="Tracking cancelled" />,
    },
    [MessageType.TRACKING_SINGLE]: {
        name: <FormattedMessage id="tracking-messages.tracking-single" defaultMessage="Single tracking" />,
    },
    [MessageType.TIME_SET]: {
        name: <FormattedMessage id="tracking-messages.time-set" defaultMessage="Time set" />,
    },
};

export function getMessageName(message: Message): JSX.Element {
    return !message.type ? <FormattedMessage id="tracking-messages.undefined-type" defaultMessage="Undefined type" /> :
        !messages[message.type] ? <>{message.type}</> :
            messages[message.type].warning ? <span style={{ color: 'red' }}>{messages[message.type].name}</span> :
                messages[message.type].name;
}

export function getMessageOnClick(
    message: Message, setHighlight: (highlight: Highlight | null) => void,
): ((event: any) => void) | undefined {
    if (message.coords) {
        return () => {
            setHighlight({
                coords: [message.coords!.lat, message.coords!.lon],
                time: new Date(message.time || message.createdAt),
                type: HighlightType.MESSAGE_POINT,
            });
        };
    } else if (message.cell && message.cell.coords) {
        return () => {
            setHighlight({
                coords: [message.cell!.coords!.lat, message.cell!.coords!.lon],
                time: new Date(message.time || message.createdAt),
                type: HighlightType.MESSAGE_CELL,
            });
        };
    }
}
