import { useState } from 'react';

export function useTimeLimit(date: string): [number, (timeLimit: number) => void, Date, number] {
    const timeEnd = 24 * 3600 - 1;
    const [timeLimit, setTimeLimit] = useState<number>(timeEnd);
    const time = new Date(date);
    time.setSeconds(timeLimit + time.getTimezoneOffset() * 60);

    return [timeLimit, setTimeLimit, time, timeEnd];
}
