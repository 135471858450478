import {
    getListGroupItemCssClassForStatus, getMeasurementLimits, getMeasurementParams, getMeasurementStatus,
    getSensorNamesWithoutMeasurements, MeasurementItem, MeasurementPeriodsConfig, MeasurementSet,
} from '@majpage/raspi-tanks-logic';
import React, { ReactNode, useMemo } from 'react';
import { FormGroup, FormLabel, ListGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHomeAutomationTimeOffsetFromContext } from '../../Contexts';
import MeasurementsStatus from '../Measurements/MeasurementsStatus';

interface TankDetailsMeasurementsProps {
    config: MeasurementPeriodsConfig;
    measurements: MeasurementSet;
    sensors: { name: string }[];
    title: ReactNode;
    unit: string;
}

export default ({ config, measurements, sensors, title, unit }: TankDetailsMeasurementsProps) => {
    const timeOffset = useHomeAutomationTimeOffsetFromContext();
    const { formatMessage } = useIntl();
    const limits = useMemo(() => {
        return getMeasurementLimits({
            criticalMax: formatMessage({ defaultMessage: 'critical maximum', id: 'form.value.critical-maximum' }),
            criticalMin: formatMessage({ defaultMessage: 'critical minimum', id: 'form.value.critical-minimum', }),
            max: formatMessage({ defaultMessage: 'maximum', id: 'form.value.maximum' }),
            min: formatMessage({ defaultMessage: 'minimum', id: 'form.value.minimum' }),
        }, config, measurements, timeOffset);
    }, [config, measurements, formatMessage, timeOffset]);
    const sensorNamesWithoutMeasurements = useMemo<string[]>(
        () => getSensorNamesWithoutMeasurements(sensors, measurements), [sensors, measurements],
    );
    const params = useMemo<MeasurementItem[]>(() => getMeasurementParams({
        periodMin: formatMessage({
            defaultMessage: 'minimal measurement period', id: 'form.value.minimal-measurement-period',
        }),
        tolerance: formatMessage({ defaultMessage: 'tolerance', id: 'form.value.tolerance' }),
    }, config), [config, formatMessage]);

    return limits.length > 0 || sensorNamesWithoutMeasurements.length > 0 ?
        <FormGroup className="MatrixItem">
            <FormLabel className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">{title}</div>
                <MeasurementsStatus status={getMeasurementStatus(
                    config, measurements, sensorNamesWithoutMeasurements.length > 0, timeOffset,
                )} />
            </FormLabel>
            <ListGroup>
                {limits.map(({ name, status, value }, index) => {
                    return <ListGroup.Item key={`value-${index}`} className={getListGroupItemCssClassForStatus(status)}>
                        {name}: {value}{unit}
                    </ListGroup.Item>;
                })}
                {sensorNamesWithoutMeasurements.map((name, index) => <ListGroup.Item key={`sensor-${index}`}
                    className="list-group-item-info">
                    {name}: <FormattedMessage id="tanks.details.no-measurements" defaultMessage="no measurements" />
                </ListGroup.Item>)}
                {params.map(({ name, value }, index) => <ListGroup.Item key={`param-${index}`}
                    className="list-group-item-light">
                    {name}: {value}{unit}
                </ListGroup.Item>)}
            </ListGroup>
        </FormGroup> :
        null;
};
