import React from 'react';
import { Alert } from 'react-bootstrap';

interface ErrorMessageProps {
    messages?: string[];
    onClose?: () => void;
}

export default ({ messages, onClose }: ErrorMessageProps) => {
    return messages && messages.length > 0 ?
        <Alert variant="danger" show={true} onClose={onClose} dismissible={!!onClose}>
            {messages.map((message, i) => <p key={i}>{message}</p>)}
        </Alert> :
        null;
};
