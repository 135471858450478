import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getInventoryApiUrl } from '../../../helpers/url';
import { InventoryFetched } from '../../../types/inventories/model';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';
import Tooltip from '../../Tooltip/Tooltip';

interface InventorySaveModalProps {
    inventory: InventoryFetched;
    onClose?: () => void;
    onSave?: (inventory: InventoryFetched) => void;
    quantities: { [productId: string]: number };
}

export default ({ inventory, onClose, onSave, quantities }: InventorySaveModalProps) => {
    const [error, setError] = useState<unknown>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const disabled = useMemo(() => Object.keys(quantities).length === 0, [quantities]);
    const tooltipRef = useRef<HTMLButtonElement>(null);
    const [tooltipShow, setTooltipShow] = useState(false);
    const url = useMemo(() => getInventoryApiUrl('inventories/:id', {
        params: { id: inventory.id },
    }), [inventory.id]);
    const onModalClose = useCallback(() => {
        setModalShown(false);
        if (onClose) {
            onClose();
        }
    }, [onClose, setModalShown]);

    return <>
        <button ref={tooltipRef} className={`btn btn-primary${disabled ? ' disabled' : ''}`} onClick={() => {
            if (disabled) {
                setTooltipShow(!tooltipShow);
            } else {
                setError(null);
                setShowError(true);
                setModalShown(true);
                setTooltipShow(false);
            }
        }}>
            <FormattedMessage id="form.save" defaultMessage="Save" />
        </button>
        <Tooltip target={tooltipRef.current} show={disabled && tooltipShow} placement="bottom"
            onMouseOver={() => { setTooltipShow(false) }} variant="warning">
            <FormattedMessage id="inventories.tooltip.save-disabled"
                defaultMessage="Modify quantity of some products to save it." />
        </Tooltip>
        <Modal show={modalShown} onHide={onModalClose} animation={false}
            className="InventorySaveModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="inventories.modal.save" defaultMessage="Save inventory" />
                </Modal.Title>
            </Modal.Header>
            {error && showError ?
                <Modal.Body>
                    <FormSubmitError error={error} setShowError={setShowError} showError={showError} />
                </Modal.Body> :
                null}
            <Modal.Footer>
                <Button variant="secondary" onClick={onModalClose}>
                    <FormattedMessage id="modal.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="primary" onClick={async () => {
                    try {
                        setError(null);
                        setShowError(true);
                        const savedInventory = await authenticatedRequest<InventoryFetched>({
                            data: { quantities }, method: HttpMethod.PATCH, url,
                        });
                        if (onSave) {
                            onSave(savedInventory);
                        }
                        onModalClose();
                    } catch (error) {
                        setError(error);
                    }
                }}>
                    <FormattedMessage id="modal.ok" defaultMessage="OK" />
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}
