import React from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';

interface MenuProps {
    children?: React.ReactNode;
    reload?: () => void;
}

export default ({ children, reload }: MenuProps) => <div className="menu price-tags-menu">
    <InputGroup size="lg" className="mt-3 mb-3">
        <InputGroup.Prepend>
            <InputGroup.Text>
                <FormattedMessage id="menu.price-tags" defaultMessage="Price tags" />
            </InputGroup.Text>
        </InputGroup.Prepend>
        {reload ?
            <InputGroup.Append>
                <Button variant="secondary" size="lg" onClick={reload}>
                    <ArrowClockwise>
                        <FormattedMessage id="menu.reload" defaultMessage="Reload" />
                    </ArrowClockwise>
                </Button>
            </InputGroup.Append> :
            null}
        {children || null}
    </InputGroup>
</div>;
