import React from 'react';
import { CardColumns } from 'react-bootstrap';
import Menu from '../../components/warehouse/Menu/Menu';
import Rentals from '../../components/warehouse/Rentals/Rentals';
import { Section } from '../../types/section';

export const PATH = `/${Section.WAREHOUSE}/rentals`;

export default () =>
    <div className="WarehouseRentalsPage">
        <Menu />
        <CardColumns className="Rentals">
            <Rentals extended={true} />
            <Rentals />
        </CardColumns>
    </div>;
