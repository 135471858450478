import { Form, FormikProps, withFormik } from 'formik';
import { isString } from 'lodash';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { CommentModel } from '../../../types/warehouse/model';
import SelectField from '../../FormField/SelectField';
import TextAreaField from '../../FormField/TextAreaField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';

interface FormValues {
    content: string;
    mark: number;
}

const MARK_MAX = 5;
const Schema = Yup.object().shape({
    content: Yup.string(),
    mark: Yup.number().typeError('form.number.type-incorrect')
        .integer('form.number.not-integer')
        .min(0, 'form.number.too-low')
        .max(MARK_MAX, 'form.number.too-high'),
});
const getMarks = () => {
    const marks = [];
    for (let i = MARK_MAX; i > 0; i--) {
        marks.push(i);
    }
    return marks;
};
const normalizeMark = (mark: string | number) => isString(mark) ? parseInt(mark, 10) : mark;

interface PureFormProps {
    error: string | null;
    setModalShown: (modalShown: boolean) => void;
    setShowError: (showError: boolean) => void;
    showError: boolean;
}

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();

    return <Form>
        <Modal.Body>
            <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
            <SelectField fieldId="mark" touched={touched.mark} error={errors.mark}
                name={formatMessage({ defaultMessage: 'Mark', id: 'form.label.mark' })}>
                <option key={0} value={0}>
                    {formatMessage({ defaultMessage: 'not selected', id: 'form.list.not-selected' })}
                </option>
                {getMarks().map(mark => <option key={mark} value={mark}>{mark}</option>)}
            </SelectField>
            <TextAreaField fieldId="content" touched={touched.content} error={errors.content}
                name={formatMessage({ defaultMessage: 'Content', id: 'form.label.content' })} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { props.setModalShown(false) }}>
                <FormattedMessage id="modal.close" defaultMessage="Close" />
            </Button>
            <Button variant="primary" type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
                <FormattedMessage id="form.save" defaultMessage="Save" />
            </Button>
        </Modal.Footer>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    onAdd: (comment: CommentModel) => void;
    setError: (text: string | null) => void;
    url: string;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);
            const comment = await authenticatedRequest<CommentModel>({
                data: {
                    content: values.content || null,
                    mark: normalizeMark(values.mark) || null,
                },
                method: HttpMethod.POST,
                url: props.url,
            });
            resetForm();
            props.onAdd(comment);
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: () => ({
        content: '',
        mark: 0,
    }),
    validate: values => {
        const errors: { [key: string]: string } = {};
        if (!values.content && !normalizeMark(values.mark)) {
            errors.content = 'form.error.comment-empty';
        }
        return errors;
    },
    validationSchema: Schema,
})(PureForm);

interface CommentAddFormProps {
    onAdd: (comment: CommentModel) => void;
    url: string;
}

export default ({ onAdd, url }: CommentAddFormProps) => {
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const stateProps = { error, setError, setModalShown, setShowError, showError };

    return <>
        <Button size="sm" onClick={() => {
            setError(null);
            setShowError(true);
            setModalShown(true);
        }} className="mb-3" block>
            <FormattedMessage id="comments.button.add" defaultMessage="Add comment" />
        </Button>
        <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false} className="CommentModal">
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id="comments.modal.add" defaultMessage="Add comment" /></Modal.Title>
            </Modal.Header>
            <FormikForm url={url} onAdd={comment => {
                setModalShown(false);
                onAdd(comment);
            }} {...stateProps} />
        </Modal>
    </>;
}
