import React from 'react';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import { IdCodeContext } from '../../components/Contexts';
import Menu from '../../components/warehouse/Menu/Menu';
import StorageAddForm from '../../components/warehouse/StorageAddForm/StorageAddForm';
import { getIdCode } from '../../helpers/id-code';
import { Section } from '../../types/section';

export const PATH = `/${Section.WAREHOUSE}/storage`;

export default ({ location, match }: RouteComponentProps) => {
    const idCode = getIdCode(location.search);

    return <IdCodeContext.Provider value={{ idCode }}>
        <div className="WarehouseStorageAddPage">
            <Menu/>
            <StorageAddForm baseUrl={PATH} idCode={idCode}/>
        </div>
    </IdCodeContext.Provider>;
};
