import React, { ReactNode, useMemo } from 'react';
import { Card, Form, ListGroup } from 'react-bootstrap';
import { ProductChange } from '../../../types/price-tags/model';
import FormatPrice from '../../Format/FormatPrice';
import './ProductChangesList.css';

interface ProductChangesListProps {
    changes: ProductChange[];
    headerPrepend?: ReactNode;
    id: string;
    onToggle: (change: ProductChange) => void;
    selectedIds: number[];
    selectMany: (changes: ProductChange[], selected: boolean) => void;
    title: string;
}

export default ({ changes, headerPrepend, id, onToggle, selectedIds, selectMany, title }: ProductChangesListProps) => {
    const allSelected = useMemo(() => !changes.some(({ id }) => !selectedIds.includes(id)), [changes, selectedIds]);

    return <Card className="ProductChangesList">
        <Card.Header>
            {headerPrepend}
            <Form.Check id={id} label={title} checked={allSelected} onChange={(event: any) => {
                selectMany(changes, !!event.target.checked);
            }} />
        </Card.Header>
        <ListGroup variant="flush">
            {changes.map(change => <ListGroup.Item key={change.id} onClick={() => { onToggle(change) }}
                className={selectedIds.includes(change.id) ? 'bg-warning' : ''}>
                <p className="mb-0">{change.name}</p>
                {change.eanCode ?
                    <small className="text-muted ml-2 mr-2">{change.eanCode}</small> :
                    null}
                <small className="text-muted ml-2 mr-2">
                    <strong>
                        <FormatPrice value={change.itemPrice} currency={change.currency} unit={change.itemUnit} />
                    </strong>
                </small>
                {change.basePrice ?
                    <small className="text-muted ml-2 mr-2">
                        <FormatPrice value={change.basePrice} currency={change.currency} unit={change.baseUnit} />
                    </small> :
                    null}
            </ListGroup.Item>)}
        </ListGroup>
    </Card>;
};
