import {
    MeasurementConfig, MeasurementPeriodsConfig, Tank, TankType, TimePeriod, timePeriodsOverlap,
} from '@majpage/raspi-tanks-logic';
import { isUndefined, pick } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Button, InputGroup, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { Plus, X } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { getMinutesFromTime, getTimeFromMinutes } from '../../../helpers/time';
import { MeasurementType } from '../../../types/home-automation/model';
import { MatrixItemColumnType } from '../../../types/home-automation/raspi-tanks';
import { FormatMessage } from '../../../types/react-intl';
import MatrixItem, { Column } from './MatrixItem';
import './PeriodMatrixItems.css';

enum MeasurementsConfigTypes {
    CRITICAL_MAX = 'criticalMax',
    CRITICAL_MIN = 'criticalMin',
    MAX = 'max',
    MIN = 'min',
    PERIOD_MIN = 'periodMin',
    TOLERANCE = 'tolerance',
}
enum Tabs {
    DEFAULT = 'default',
    INHERITED = 'inherited',
}
interface NewPeriod {
    from: number | null;
    to: number | null;
}
type MeasurementsConfigRow = [MeasurementsConfigTypes, number];
function addMeasurementsConfigRowIfExits(
    rows: MeasurementsConfigRow[], config: MeasurementsConfigTypes, value?: number,
) {
    if (!isUndefined(value)) {
        rows.push([config, value]);
    }
}
function sortByTimePeriods(periodA: TimePeriod, periodB: TimePeriod): number {
    return periodA.from < periodB.from || (periodA.from === periodB.from && periodA.to < periodB.to) ? -1 : 1;
}
function getMeasurementPeriodsOptions(formatMessage: FormatMessage) {
    return [
        { key: MeasurementsConfigTypes.MIN, name: formatMessage({
            defaultMessage: 'minimum', id: 'form.value.minimum',
        }) },
        { key: MeasurementsConfigTypes.MAX, name: formatMessage({
            defaultMessage: 'maximum', id: 'form.value.maximum',
        }) },
        { key: MeasurementsConfigTypes.CRITICAL_MIN, name: formatMessage({
            defaultMessage: 'critical minimum', id: 'form.value.critical-minimum',
        }) },
        { key: MeasurementsConfigTypes.CRITICAL_MAX, name: formatMessage({
            defaultMessage: 'critical maximum', id: 'form.value.critical-maximum',
        }) },
    ];
}
function getMeasurementsOptions(formatMessage: FormatMessage) {
    return [
        ...getMeasurementPeriodsOptions(formatMessage),
        { key: MeasurementsConfigTypes.PERIOD_MIN, name: formatMessage({
            defaultMessage: 'minimal measurement period', id: 'form.value.minimal-measurement-period',
        }) },
        { key: MeasurementsConfigTypes.TOLERANCE, name: formatMessage({
            defaultMessage: 'tolerance', id: 'form.value.tolerance',
        }) },
    ];
}
function getMeasurementsColumns(formatMessage: FormatMessage, options: { key: any; name: string }[]): Column[] {
    return [
        {
            options,
            title: formatMessage({ defaultMessage: 'name', id: 'form.value.name' }),
            type: MatrixItemColumnType.SELECT,
            unique: true,
        },
        {
            title: formatMessage({ defaultMessage: 'value', id: 'form.value.value' }),
            type: MatrixItemColumnType.NUMBER,
        },
    ];
}
function measurementPeriodItemsToRows({ critical, max, min }: MeasurementConfig) {
    const rows: MeasurementsConfigRow[] = [];
    addMeasurementsConfigRowIfExits(rows, MeasurementsConfigTypes.MIN, min);
    addMeasurementsConfigRowIfExits(rows, MeasurementsConfigTypes.MAX, max);
    if (critical) {
        addMeasurementsConfigRowIfExits(rows, MeasurementsConfigTypes.CRITICAL_MIN, critical.min);
        addMeasurementsConfigRowIfExits(rows, MeasurementsConfigTypes.CRITICAL_MAX, critical.max);
    }
    return rows;
}
function measurementItemsToRows({ periodMin, tolerance, ...config }: MeasurementConfig) {
    const rows = measurementPeriodItemsToRows(config);
    addMeasurementsConfigRowIfExits(rows, MeasurementsConfigTypes.PERIOD_MIN, periodMin);
    addMeasurementsConfigRowIfExits(rows, MeasurementsConfigTypes.TOLERANCE, tolerance);
    return rows;
}
function onChangeEvent(
    measurementType: MeasurementType, modifiedItem: Tank | TankType, setModifiedItem: (item: Tank | TankType) => void,
    timePeriod?: TimePeriod,
): (rows: MeasurementsConfigRow[]) => void {
    return rows => {
        const { [measurementType]: measurement, ...itemParams } = modifiedItem;
        if (!measurement && rows.length === 0) {
            return;
        }
        const item = rows.reduce<MeasurementConfig>((list, [name, value]) => {
            if (name === MeasurementsConfigTypes.CRITICAL_MAX || name === MeasurementsConfigTypes.CRITICAL_MIN) {
                if (!list.critical) {
                    list.critical = {};
                }
                if (name === MeasurementsConfigTypes.CRITICAL_MAX) {
                    list.critical.max = value;
                } else if (name === MeasurementsConfigTypes.CRITICAL_MIN) {
                    list.critical.min = value;
                }
            } else {
                list[name] = value;
            }
            return list;
        }, {});
        // eslint-disable-next-line no-mixed-operators
        const periods = timePeriod ? (measurement && measurement.periods || []).map(
            ({ from, to, ...currentItem }) => from === timePeriod.from && to === timePeriod.to ? { ...item, from, to } :
                { ...currentItem, from, to }
        ) : [];
        const defaultItem = getMeasurementsDefault(timePeriod ? measurement : item);
        setModifiedItem(Object.keys(defaultItem).length === 0 && periods.length === 0 ? itemParams : {
            ...itemParams,
            [measurementType]: { ...defaultItem, periods },
        });
    };
}
function getNameFromTimePeriod({ from, to }: TimePeriod): string {
    return `${getTimeFromMinutes(from)} - ${getTimeFromMinutes(to)}`;
}
function getMeasurementsDefault(measurements?: MeasurementPeriodsConfig) {
    return pick(measurements || {}, ['critical', 'max', 'min', 'periodMin', 'tolerance']);
}
function setNewPeriodValue(type: keyof NewPeriod, newPeriod: NewPeriod, setNewPeriod: (newPeriod: NewPeriod) => void) {
    return (event: any) => {
        setNewPeriod({
            ...newPeriod,
            [type]: event.target.value === '' ? null : getMinutesFromTime(event.target.value, type === 'to'),
        });
    };
}

interface PeriodMatrixItemsProps {
    errors?: string[];
    measurements?: MeasurementPeriodsConfig;
    measurementsReadonly?: MeasurementPeriodsConfig;
    measurementType: MeasurementType;
    modifiedItem: Tank | TankType;
    name: string;
    setModifiedItem: (item: Tank | TankType) => void;
}

export default function PeriodMatrixItems({
    errors, measurements, measurementsReadonly, measurementType, modifiedItem, name, setModifiedItem,
}: PeriodMatrixItemsProps) {
    const { formatMessage } = useIntl();
    const [openedTab, setOpenedTab] = useState<string | null>(Tabs.DEFAULT);
    const [newPeriod, setNewPeriod] = useState<NewPeriod>({ from: null, to: null });
    const newPeriodCanBeAdded = useMemo(() => {
        return newPeriod.from !== null && newPeriod.to !== null && newPeriod.from < newPeriod.to && (
            !measurements || !measurements.periods ||
            !timePeriodsOverlap({ from: newPeriod.from, to: newPeriod.to }, measurements.periods)
        );
    }, [newPeriod, measurements]);
    const measurementNames = useMemo(() => getMeasurementsOptions(formatMessage)
        .reduce<{ [key: string]: string }>((list, { key, name }) => {
            list[key] = name;
            return list;
        }, {}), [formatMessage]);

    return <FormGroup className="PeriodMatrixItems">
        <FormLabel>{name}</FormLabel>
        {/* @TODO: Switch to proper Accordion component when possible! */}
        <div className="accordion">
            {measurementsReadonly ?
                <div key={Tabs.INHERITED} className="card">
                    <div className="card-header">
                        <Button variant="link" className="text-left" onClick={() => {
                            setOpenedTab(openedTab === Tabs.INHERITED ? null : Tabs.INHERITED);
                        }} block>
                            <FormattedMessage id="form.value.tank-types-config" defaultMessage="tank types configuration" />
                        </Button>
                    </div>
                    {openedTab === Tabs.INHERITED ?
                        <div className="collapse show">
                            <div className="card-body">
                                <table>
                                    <thead>
                                        <tr>
                                            <th><FormattedMessage id="form.value.period" defaultMessage="period" /></th>
                                            <th><FormattedMessage id="form.value.name" defaultMessage="name" /></th>
                                            <th><FormattedMessage id="form.value.value" defaultMessage="value" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {measurementItemsToRows(measurementsReadonly).map(([type, value]) => <tr>
                                            <td>
                                                <FormattedMessage id="form.value.default" defaultMessage="default" />
                                            </td>
                                            <td>{measurementNames[type] || ''}</td>
                                            <td>{value}</td>
                                        </tr>)}
                                        {measurementsReadonly.periods && measurementsReadonly.periods.length > 0 ?
                                            measurementsReadonly.periods.map(period => {
                                                const rows = measurementPeriodItemsToRows(period);
                                                const periodName = getNameFromTimePeriod(period);
                                                return rows.map(([type, value]) => <tr>
                                                    <td>{periodName}</td>
                                                    <td>{measurementNames[type] || ''}</td>
                                                    <td>{value}</td>
                                                </tr>);
                                            }) :
                                            null}
                                    </tbody>
                                </table>
                            </div>
                        </div> :
                        null}
                </div> :
                null}
            <div key={Tabs.DEFAULT} className="card">
                <div className="card-header">
                    <Button variant="link" className="text-left" onClick={() => {
                        setOpenedTab(openedTab === Tabs.DEFAULT ? null : Tabs.DEFAULT);
                    }} block>
                        <FormattedMessage id="form.value.period-default" defaultMessage="default period" />
                    </Button>
                </div>
                {openedTab === Tabs.DEFAULT ?
                    <div className="collapse show">
                        <div className="card-body">
                            <MatrixItem<MeasurementConfig, MeasurementsConfigRow> {...{
                                columns: getMeasurementsColumns(formatMessage, getMeasurementsOptions(formatMessage)),
                                item: measurements,
                                itemToRows: measurementItemsToRows,
                                onChange: onChangeEvent(measurementType, modifiedItem, setModifiedItem),
                            }} />
                        </div>
                    </div> :
                    null}
            </div>
            {measurements && measurements.periods ?
                measurements.periods.map(({ from, to, ...measurementPeriods }) => {
                    const periodName = getNameFromTimePeriod({ from, to });
                    return <div key={periodName} className="card">
                        <div className="card-header card-header-period">
                            <Button variant="link" className="text-left" onClick={() => {
                                setOpenedTab(openedTab === periodName ? null : periodName);
                            }} block>
                                <FormattedMessage id="form.value.period-named" defaultMessage="period: {periodName}"
                                    values={{ periodName }} />
                            </Button>
                            <Button size="sm" variant="danger" className="button-remove" onClick={() => {
                                const { [measurementType]: measurement, ...itemParams } = modifiedItem;
                                if (!measurement || !measurement.periods) {
                                    return;
                                }
                                const { periods, ...measurementWithoutPeriods } = measurement;
                                const otherPeriods = periods.filter(period => period.from !== from || period.to !== to);
                                if (otherPeriods.length === periods.length - 1) {
                                    setModifiedItem({
                                        ...itemParams,
                                        [measurementType]: otherPeriods.length === 0 ? measurementWithoutPeriods :
                                            { ...measurementWithoutPeriods, periods: otherPeriods },
                                    });
                                }
                            }}>
                                <X>
                                    <FormattedMessage id="tanks.button.remove" defaultMessage="Remove element" />
                                </X>
                            </Button>
                        </div>
                        {openedTab === periodName ?
                            <div className="collapse show">
                                <div className="card-body">
                                    <MatrixItem<MeasurementConfig, MeasurementsConfigRow> {...{
                                        columns: getMeasurementsColumns(
                                            formatMessage, getMeasurementPeriodsOptions(formatMessage),
                                        ),
                                        item: measurementPeriods,
                                        itemToRows: measurementPeriodItemsToRows,
                                        onChange: onChangeEvent(
                                            measurementType, modifiedItem, setModifiedItem, { from, to },
                                        ),
                                    }} />
                                </div>
                            </div> :
                            null}
                    </div>;
                }) :
                null}
            <div key="add" className="card">
                <div className="card-header card-header-input">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FormattedMessage id="form.value.period-new" defaultMessage="new period:" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl type="time" onChange={setNewPeriodValue('from', newPeriod, setNewPeriod)}
                            value={newPeriod.from === null ? '' : getTimeFromMinutes(newPeriod.from)} />
                        <FormControl type="time" onChange={setNewPeriodValue('to', newPeriod, setNewPeriod)}
                            value={newPeriod.to === null ? '' : getTimeFromMinutes(newPeriod.to)} />
                        <InputGroup.Append>
                            <Button size="sm" variant="success" {...(newPeriodCanBeAdded ? { onClick: () => {
                                if (!newPeriodCanBeAdded || newPeriod.from === null || newPeriod.to === null) {
                                    return;
                                }
                                const { [measurementType]: measurementIfExist, ...itemParams } = modifiedItem;
                                const measurement = measurementIfExist || {};
                                const periods = measurement.periods || [];
                                periods.push({ from: newPeriod.from, to: newPeriod.to });
                                setModifiedItem({
                                    ...itemParams,
                                    [measurementType]: { ...measurement, periods: periods.sort(sortByTimePeriods) },
                                });
                                setNewPeriod({ from: null, to: null });
                            } } : { disabled: true }) }>
                                <Plus>
                                    <FormattedMessage id="tanks.button.add" defaultMessage="Add element" />
                                </Plus>
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </div>
            </div>
        </div>
        {errors ?
            <>
                {errors.map((error, i) => <div key={i} className="invalid-feedback" style={{ display: 'block' }}>
                    {error}
                </div>)}
            </>:
            null}
    </FormGroup>;
};
