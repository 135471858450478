import { Form, FormikProps, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, FormGroup, FormLabel, InputGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import {
    getCommandRequestValidator, getCommandRequestValues, getCommandTypeOptions, isAreaFetchingCommandType,
    renderCommandTypeFields,
} from '../../../helpers/tracking/commands';
import { Area, CommandType } from '../../../types/tracking/model';
import SelectField from '../../FormField/SelectField';
import TextInputField from '../../FormField/TextInputField';

enum MessageType {
    ERROR = 'error',
    SUCCESS = 'success',
}
interface Message {
    content?: string;
    type?: MessageType;
}

interface FormValues {
    date?: Date,
    distance?: number,
    speed?: number,
    time?: string,
    timeOffset?: number,
    type: CommandType | '';
}

interface PureFormProps {
    area?: Area | null;
    commandType: CommandType | null;
    message: Message;
    setArea?: (area: Area | null) => void;
    setCommandType: (commandType: CommandType | null) => void;
    setMessage: (message: Message) => void;
}

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (props.commandType) {
            props.setValues({ ...getCommandRequestValues(props.commandType), ...props.values }, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.commandType]);

    return <Form>
        <Alert variant={props.message.type === MessageType.ERROR ? 'danger' : 'success'} onClose={() => {
            props.setMessage({});
        }} show={!!props.message.type} dismissible>
            {props.message.type === MessageType.ERROR ?
                <>
                    <p>
                        <FormattedMessage defaultMessage="An error occurred during form data sending."
                            id="form.error.request-failedw" />
                    </p>
                    <hr />
                    <p className="mb-0">{props.message.content}</p>
                </> :
                <FormattedMessage id="form.success.sent"
                    defaultMessage="Form data has been successfully sent." />}
        </Alert>
        <SelectField fieldId="type" touched={touched.type} error={errors.type}
            name={formatMessage({ defaultMessage: 'Type', id: 'form.label.type' })}
            onChange={event => {
                const commandType = (event as any).target.value as CommandType;
                props.setCommandType(commandType);
                if (props.setArea) {
                    if (isAreaFetchingCommandType(commandType)) {
                        props.setArea({ isReady: false, latA: null, lonA: null, latB: null, lonB: null });
                    } else if (props.area) {
                        props.setArea(null);
                    }
                }
                props.setMessage({});
                props.handleChange(event);
            }}>
            <option key={0} value={''}>
                {formatMessage({ defaultMessage: 'not selected', id: 'form.list.not-selected' })}
            </option>
            {getCommandTypeOptions()}
        </SelectField>
        {renderCommandTypeFields(
            props.commandType,
            (name, key, values) => <SelectField key={key} fieldId={key} name={formatMessage(name)}
                touched={touched[key as keyof FormValues]} error={errors[key as keyof FormValues]}>
                {values.map(({ name, value }) => <option key={`${key}-${value}`} value={value}>{name}</option>)}
            </SelectField>,
            (name, key) => <FormGroup controlId={key} className="FormField">
                <FormLabel>{formatMessage(name)}</FormLabel>
                <InputGroup>
                    {props.area && props.area.isReady ?
                        <FormattedMessage id="tracking.area.finished"
                            defaultMessage="Area has been marked on the map."/> :
                        <FormattedMessage id="tracking.area.not-finished"
                            defaultMessage="Indicate area by marking it on the map."/>}
                </InputGroup>
            </FormGroup>,
            (name, key) => <TextInputField fieldId={key} type="date" name={formatMessage(name)}
                touched={touched[key as keyof FormValues]} error={errors[key as keyof FormValues]} />,
        )}
        <Button variant="primary" size="sm" type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}
            block>
            <FormattedMessage id="form.send" defaultMessage="Send" />
        </Button>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    url: string;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        const { area, setCommandType, setArea, setMessage, url } = props;
        try {
            const data = !isAreaFetchingCommandType(values.type || null) ? values :
                { ...values, latA: area!.latA, lonA: area!.lonA, latB: area!.latB, lonB: area!.lonB };
            setMessage({});
            await authenticatedRequest({
                data,
                method: HttpMethod.POST,
                url,
            });
            setMessage({ type: MessageType.SUCCESS });
            setCommandType(null);
            if (setArea) {
                setArea(null);
            }
            resetForm();
        } catch (error) {
            setMessage({ content: error.toString(), type: MessageType.ERROR });
            setSubmitting(false);
        }
    },
    mapPropsToValues: ({ commandType }) => getCommandRequestValues(commandType) as FormValues,
    validate: (values, { area }) => {
        const errors: { [key: string]: string } = {};
        if (isAreaFetchingCommandType(values.type || null) && area && !area.isReady) {
            errors.type = 'form.error.area-empty';
        }
        return errors;
    },
    validationSchema: ({ commandType }: FormikFormProps) => getCommandRequestValidator(commandType),
})(PureForm);

interface CommandAddFormProps {
    area?: Area | null;
    isShowed: boolean;
    onShowClick: () => void;
    setArea?: (area: Area | null) => void;
    url: string;
}

export default ({ area, isShowed, onShowClick, setArea, url }: CommandAddFormProps) => {
    const [commandType, setCommandType] = useState<CommandType | null>(null);
    const [message, setMessage] = useState<Message>({});
    const stateProps = { area, commandType, message, setArea, setCommandType, setMessage, url };

    return <Card>
        <Card.Header>
            <FormattedMessage defaultMessage="Send command" id="tracking-commands.form.title" />
        </Card.Header>
        <Card.Body>
            {isShowed ?
                <FormikForm {...stateProps} /> :
                <div className="text-center">
                    <p>
                        <FormattedMessage defaultMessage="Sending commands is possible for current day only."
                            id="tracking.form.current-day-info" />
                    </p>
                    <Button variant="primary" onClick={onShowClick}>
                        <FormattedMessage defaultMessage="Switch to current day" id="tracking.form.to-current-day" />
                    </Button>
                </div>}
        </Card.Body>
    </Card>;
}
