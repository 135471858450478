import { Form, FormikProps, getIn, withFormik } from 'formik';
import React, { useState } from 'react';
import { Button, FormCheck, FormGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getPath } from '../../../helpers/path';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { MAX_AMOUNT } from '../../../helpers/warehouse/actual-states';
import { getProductName } from '../../../helpers/warehouse/names';
import { PATH as WAREHOUSE_PRODUCT_VARIANT_PATH } from '../../../routes/warehouse/ProductVariant';
import { ProducerModel, ProductModel, ProductVariantModel } from '../../../types/warehouse/model';
import CheckBoxField from '../../FormField/CheckBoxField';
import TextAreaField from '../../FormField/TextAreaField';
import TextInputField from '../../FormField/TextInputField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';
import ProducerSelector from '../Selector/ProducerSelector';
import ProductSelector from '../Selector/ProductSelector';

const MAX_STEP = Math.round(MAX_AMOUNT / 10);

interface FormValues {
    producer: {
        description?: string;
        name: string;
        notForeignConsortium: boolean;
        productionInFatherland: boolean;
        registeredInFatherland: boolean;
        researchInFatherland: boolean;
    };
    product: {
        description?: string;
        name: string;
        statesAvailable: boolean;
        validityPeriod?: number;
    };
    productVariant: {
        amount: number;
        eanCode?: string;
        name?: string;
        statesAvailable: boolean;
        step: number;
        unit: string;
        unitsAvailable: boolean;
    };
}

const ProducerSchema = Yup.object().shape({
    description: Yup.string(),
    name: Yup.string()
        .max(128, 'form.string.too-long')
        .required('form.element.required'),
    notForeignConsortium: Yup.boolean(),
    productionInFatherland: Yup.boolean(),
    registeredInFatherland: Yup.boolean(),
    researchInFatherland: Yup.boolean(),
});
const ProductSchema = Yup.object().shape({
    description: Yup.string(),
    name: Yup.string()
        .max(128, 'form.string.too-long')
        .required('form.element.required'),
    statesAvailable: Yup.boolean(),
    validityPeriod: Yup.number().typeError('form.number.type-incorrect')
        .integer('form.number.not-integer')
        .min(0, 'form.number.too-low')
        .max(65535, 'form.number.too-high'),
});
const ProductVariantSchema = Yup.object().shape({
    amount: Yup.number().typeError('form.number.type-incorrect')
        .min(0, 'form.number.too-low')
        .max(MAX_AMOUNT, 'form.number.too-high')
        .required('form.element.required'),
    eanCode: Yup.string(),
    name: Yup.string()
        .max(128, 'form.string.too-long'),
    statesAvailable: Yup.boolean(),
    step: Yup.number().typeError('form.number.type-incorrect')
        .moreThan(0, 'form.number.too-low')
        .max(MAX_STEP, 'form.number.too-high')
        .required('form.element.required'),
    unit: Yup.string()
        .max(8, 'form.string.too-long')
        .required('form.element.required'),
    unitsAvailable: Yup.boolean(),
});

interface PureFormProps {
    error: string | null;
    ifUseProducer: (useProduct: boolean) => void;
    producer: ProducerModel | null;
    product: ProductModel | null;
    setProducer: (producer: ProducerModel | null) => void;
    setProduct: (product: ProductModel | null) => void;
    setShowError: (showError: boolean) => void;
    showError: boolean;
    useProducer: boolean;
}
const isProducerOnlyDisabled = (props: PureFormProps): boolean => props.producer !== null || props.product !== null;
const isProducerDisabled = (props: PureFormProps) => isProducerOnlyDisabled(props) || !props.useProducer;
const isProductDisabled = (props: PureFormProps): boolean => props.product !== null;

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const producerDisabled = isProducerDisabled(props);
    const producerOnlyDisabled = isProducerOnlyDisabled(props);
    const productDisabled = isProductDisabled(props);
    const { formatMessage } = useIntl();

    return <Form className="mb-3">
        <h2><FormattedMessage id="form.title.producer" defaultMessage="Producer" /></h2>
        <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
        <TextInputField fieldId="producer" disabled
            value={props.producer ? props.producer.name : ''}
            name={formatMessage({ defaultMessage: 'Existing producer', id: 'form.label.existing-producer' })}
            renderAppend={<>
                <ProducerSelector onSelect={producer => {
                    props.setProducer(producer);
                    props.setProduct(null);
                }} buttonTitle={formatMessage({ defaultMessage: 'Select', id: 'selector.button' })}
                onImport={value => {
                    props.setProducer(null);
                    props.setProduct(null);
                    props.setFieldValue('producer.name', value);
                }} />
                {props.producer ?
                    <Button onClick={() => { props.setProducer(null) }}>×</Button> :
                    null}
            </>} />
        <FormGroup controlId="use-producer">
            <FormCheck type="checkbox" checked={props.useProducer} disabled={producerOnlyDisabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.ifUseProducer(event.target.checked)}
                label={formatMessage({ defaultMessage: 'Use producer', id: 'form.label.use-producer' })} />
        </FormGroup>
        <TextInputField fieldId="producer.name" touched={getIn(touched.producer, 'name')}
            error={getIn(errors.producer, 'name')} disabled={producerDisabled}
            name={formatMessage({ defaultMessage: 'Name', id: 'form.label.name' })} />
        <TextAreaField fieldId="producer.description" touched={getIn(touched.producer, 'description')}
            error={getIn(errors.producer, 'description')} disabled={producerDisabled}
            name={formatMessage({ defaultMessage: 'Description', id: 'form.label.description' })} />
        <CheckBoxField fieldId="producer.notForeignConsortium" touched={getIn(touched.producer, 'notForeignConsortium')}
            type="checkbox" error={getIn(errors.producer, 'notForeignConsortium')} disabled={producerDisabled}
            name={formatMessage({ defaultMessage: 'Not foreign consortium', id: 'form.label.not-foreign-consortium' })} />
        <CheckBoxField fieldId="producer.productionInFatherland" touched={getIn(touched.producer, 'productionInFatherland')}
            type="checkbox" error={getIn(errors.producer, 'productionInFatherland')} disabled={producerDisabled}
            name={formatMessage({ defaultMessage: 'Production in fatherland', id: 'form.label.production-in-fatherland' })} />
        <CheckBoxField fieldId="producer.researchInFatherland" touched={getIn(touched.producer, 'researchInFatherland')}
            type="checkbox" error={getIn(errors.producer, 'researchInFatherland')} disabled={producerDisabled}
            name={formatMessage({ defaultMessage: 'Research in fatherland', id: 'form.label.research-in-fatherland' })} />
        <CheckBoxField fieldId="producer.registeredInFatherland" touched={getIn(touched.producer, 'registeredInFatherland')}
            type="checkbox" error={getIn(errors.producer, 'registeredInFatherland')} disabled={producerDisabled}
            name={formatMessage({ defaultMessage: 'Registered in fatherland', id: 'form.label.registered-in-fatherland' })} />

        <h2><FormattedMessage id="form.title.product" defaultMessage="Product" /></h2>
        <TextInputField fieldId="product" disabled
            value={props.product ? getProductName(props.product) : ''}
            name={formatMessage({ defaultMessage: 'Existing product', id: 'form.label.existing-product' })}
            renderAppend={<>
                <ProductSelector onSelect={product => {
                    props.setProducer(null);
                    props.setProduct(product);
                }} buttonTitle={formatMessage({ defaultMessage: 'Select', id: 'selector.button' })}
                onImport={value => {
                    props.setProducer(null);
                    props.setProduct(null);
                    props.setFieldValue('product.name', value);
                }} />
                {props.product ?
                    <Button onClick={() => { props.setProduct(null) }}>×</Button> :
                    null}
            </>} />
        <TextInputField fieldId="product.name" touched={getIn(touched.product, 'name')}
            error={getIn(errors.product, 'name')} disabled={productDisabled}
            name={formatMessage({ defaultMessage: 'Name', id: 'form.label.name' })} />
        <TextAreaField fieldId="product.description" touched={getIn(touched.product, 'description')}
            error={getIn(errors.product, 'description')} disabled={productDisabled}
            name={formatMessage({ defaultMessage: 'Description', id: 'form.label.description' })} />
        <TextInputField fieldId="product.validityPeriod" touched={getIn(touched.product, 'validityPeriod')}
            error={getIn(errors.product, 'validityPeriod')} disabled={productDisabled}
            name={formatMessage({ defaultMessage: 'Validity period', id: 'form.label.validity-period' })}
            type="number" min="0" max="65535" step="1" />
        <CheckBoxField fieldId="product.statesAvailable" touched={getIn(touched.product, 'statesAvailable')}
            error={getIn(errors.product, 'statesAvailable')} name={formatMessage({
                defaultMessage: 'Actual states available', id: 'form.label.actual-states-available',
            })} />

        <h2><FormattedMessage id="form.title.product-variant" defaultMessage="Product variant" /></h2>
        <TextInputField fieldId="productVariant.name" touched={getIn(touched.productVariant, 'name')}
            error={getIn(errors.productVariant, 'name')}
            name={formatMessage({ defaultMessage: 'Name', id: 'form.label.name' })} />
        <TextInputField fieldId="productVariant.unit" touched={getIn(touched.productVariant, 'unit')}
            error={getIn(errors.productVariant, 'unit')}
            name={formatMessage({ defaultMessage: 'Unit', id: 'form.label.unit' })} />
        <TextInputField fieldId="productVariant.amount" touched={getIn(touched.productVariant, 'amount')}
            error={getIn(errors.productVariant, 'amount')} type="number" min="0" max={`${MAX_AMOUNT}`} step="any"
            name={formatMessage({ defaultMessage: 'Amount', id: 'form.label.amount' })} />
        <TextInputField fieldId="productVariant.step" touched={getIn(touched.productVariant, 'step')}
            error={getIn(errors.productVariant, 'step')} type="number" min="0" max={`${MAX_STEP}`} step="any"
            name={formatMessage({ defaultMessage: 'Step', id: 'form.label.step' })} />
        <TextInputField fieldId="productVariant.eanCode" disabled
            name={formatMessage({ defaultMessage: 'EAN code', id: 'form.label.ean-code' })} />
        <CheckBoxField fieldId="productVariant.statesAvailable"
            touched={getIn(touched.productVariant, 'statesAvailable')}
            error={getIn(errors.productVariant, 'statesAvailable')} name={formatMessage({
                defaultMessage: 'Actual states available', id: 'form.label.actual-states-available',
            })} />
        <CheckBoxField fieldId="productVariant.unitsAvailable" touched={getIn(touched.productVariant, 'unitsAvailable')}
            error={getIn(errors.productVariant, 'unitsAvailable')} name={formatMessage({
                defaultMessage: 'Product units available', id: 'form.label.product-units-available',
            })} />

        <Button type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
            <FormattedMessage id="form.save" defaultMessage="Save" />
        </Button>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    defaultUnit: string;
    eanCode: string;
    setError: (error: string | null) => void;
    setProductVariant: (productVariant: ProductVariantModel) => void;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);

            let producer: ProducerModel | null = null;
            if (props.useProducer && !props.product) {
                try {
                    producer = props.producer || await authenticatedRequest<ProducerModel>({
                        data: {
                            description: values.producer.description || null,
                            name: values.producer.name,
                            notForeignConsortium: values.producer.notForeignConsortium,
                            productionInFatherland: values.producer.productionInFatherland,
                            registeredInFatherland: values.producer.registeredInFatherland,
                            researchInFatherland: values.producer.researchInFatherland,
                        },
                        method: HttpMethod.POST,
                        url: getWarehouseApiUrl('producers'),
                    });
                } catch (error) {
                    if (!props.producer) {
                        props.setProducer(producer);
                    }
                    throw error;
                }
            }

            let product: ProductModel | null = null;
            try {
                product = props.product || await authenticatedRequest<ProductModel>({
                    data: {
                        description: values.product.description || null,
                        name: values.product.name,
                        producerId: producer ? producer.id : null,
                        statesAvailable: values.product.statesAvailable,
                        validityPeriod: values.product.validityPeriod || null,
                    },
                    method: HttpMethod.POST,
                    url: getWarehouseApiUrl('products'),
                });
            } catch (error) {
                if (!props.product) {
                    props.setProduct(product);
                }
                throw error;
            }

            try {
                const productVariant = await authenticatedRequest<ProductVariantModel>({
                    data: {
                        amount: values.productVariant.amount,
                        eanCode: values.productVariant.eanCode || null,
                        name: values.productVariant.name || null,
                        productId: product ? product.id : null,
                        statesAvailable: values.productVariant.statesAvailable,
                        step: values.productVariant.step,
                        unit: values.productVariant.unit,
                        unitsAvailable: values.productVariant.unitsAvailable,
                    },
                    method: HttpMethod.POST,
                    url: getWarehouseApiUrl('product-variants'),
                });
                resetForm();
                props.setError(null);
                props.setProductVariant(productVariant);
            } catch (error) {
                props.setProduct(product);
                throw error;
            }
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: props => ({
        producer: {
            description: '',
            name: '',
            notForeignConsortium: false,
            productionInFatherland: false,
            registeredInFatherland: false,
            researchInFatherland: false,
        },
        product: {
            description: '',
            name: '',
            statesAvailable: true,
            validityPeriod: 0,
        },
        productVariant: {
            amount: 1,
            eanCode: props.eanCode,
            name: '',
            statesAvailable: true,
            step: 0.1,
            unit: props.defaultUnit,
            unitsAvailable: false,
        },
    }),
    validationSchema: (props: FormikFormProps) => {
        const objectSchema: { [section: string]: any } = {};
        if (!isProducerDisabled(props)) {
            objectSchema.producer = ProducerSchema;
        }
        if (!isProductDisabled(props)) {
            objectSchema.product = ProductSchema;
        }
        objectSchema.productVariant = ProductVariantSchema;
        return Yup.object().shape(objectSchema);
    },
})(PureForm);

interface ProductVariantAddFormProps {
    eanCode: string;
}

export default ({ eanCode }: ProductVariantAddFormProps) => {
    const [useProducer, ifUseProducer] = useState<boolean>(true);
    const [producer, setProducer] = useState<ProducerModel | null>(null);
    const [product, setProduct] = useState<ProductModel | null>(null);
    const [productVariant, setProductVariant] = useState<ProductVariantModel | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(true);
    const { formatMessage } = useIntl();

    if (productVariant) {
        return <Redirect to={getPath(WAREHOUSE_PRODUCT_VARIANT_PATH, { productVariantId: productVariant.id })} />;
    }

    return <FormikForm {...{
        defaultUnit: formatMessage({ defaultMessage: 'pcs', id: 'form.default.unit' }), eanCode, error, ifUseProducer,
        producer, product, setError, setProducer, setProduct, setProductVariant, setShowError, showError, useProducer,
    }} />;
}
