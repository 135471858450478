import React from 'react';
import { useIntl } from 'react-intl';
import { Device } from '../../../types/home-automation/model';
import MeasurementsList from '../Measurements/MeasurementsList';

interface TemperaturesProps {
    date: string;
    device: Device;
}

export default ({ date, device }: TemperaturesProps) => {
    const { formatMessage } = useIntl();

    return <MeasurementsList {...{
        date, device, title: formatMessage({ defaultMessage: 'Temperatures', id: 'temperatures.title' }),
        type: 'temperature', unit: '°C',
    }} />;
}
