import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { getHomeAutomationApiUrl } from '../../../helpers/url';
import { PlainObject } from '../../../types/collection';
import { Device, Event } from '../../../types/home-automation/model';
import List from '../../List/List';
import './Events.css';

interface EventsListProps {
    date: string;
    device: Device;
}

const url = getHomeAutomationApiUrl('events');

export default ({ date, device }: EventsListProps) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ defaultMessage: 'Events list', id: 'events-list.title' });

    return <List params={{ date, device: device.slug }} title={title} url={url}
        renderItem={(event: Event) => <div key={event.id} className="Events toast show">
            <div className="toast-header">
                <strong className="mr-auto">
                    <FormattedMessage
                        id={`event.${event.type}${event.params && Object.keys(event.params).length > 0 ?
                            `.${Object.keys(event.params).sort().join(',')}` :
                            ''}`}
                        values={event.params ?
                            Object.entries(event.params).reduce<PlainObject>((list, [key, value]) => {
                                list[key] = Array.isArray(value) ? value.join(', ') : value;
                                return list;
                            }, {}) : {}} />
                </strong>
                <small className="text-muted">{(new Date(event.time)).toLocaleTimeString()}</small>
            </div>
        </div>} />;
}
