import { Form, FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getFormattedDate } from '../../../helpers/time';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { ProductUnitRentalModel } from '../../../types/warehouse/model';
import TextAreaField from '../../FormField/TextAreaField';
import TextInputField from '../../FormField/TextInputField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';

interface FormValues {
    comments: string;
    expectedEndDate: string;
    rentantName: string;
}

const Schema = Yup.object().shape({
    comments: Yup.string(),
    expectedEndDate: Yup.date(),
    rentantName: Yup.string()
        .max(128, 'form.string.too-long')
        .required('form.element.required'),
});

interface PureFormProps {
    error: string | null;
    id: number;
    onHide: () => void;
    setModalShown: (modalShown: boolean) => void;
    setShowError: (showError: boolean) => void;
    showError: boolean;
}

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();

    return <Form>
        <Modal.Body>
            <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
            <TextInputField fieldId="rentantName" touched={touched.rentantName} error={errors.rentantName}
                name={formatMessage({ defaultMessage: 'Rentant name', id: 'form.label.rentant-name' })} />
            <TextInputField fieldId="expectedEndDate" touched={touched.expectedEndDate} error={errors.expectedEndDate}
                name={formatMessage({ defaultMessage: 'Expected end date', id: 'form.label.expected-end-date' })}
                type="date" min={getFormattedDate(new Date())} />
            <TextAreaField fieldId="comments" touched={touched.comments} error={errors.comments}
                name={formatMessage({ defaultMessage: 'Comments', id: 'form.label.comments' })} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
                <FormattedMessage id="modal.close" defaultMessage="Close" />
            </Button>
            <Button variant="primary" type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
                <FormattedMessage id="form.save" defaultMessage="Save" />
            </Button>
        </Modal.Footer>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    onAdd: (productUnitRental: ProductUnitRentalModel) => void;
    setError: (text: string | null) => void;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);
            const productUnitRental = await authenticatedRequest<ProductUnitRentalModel>({
                data: {
                    comments: values.comments || null,
                    expectedEndDate: values.expectedEndDate ? getFormattedDate(new Date(values.expectedEndDate)) : null,
                    rentantName: values.rentantName || null,
                },
                method: HttpMethod.POST,
                url: getWarehouseApiUrl('product-units/:productUnitId/rentals', { params: {
                    productUnitId: props.id,
                } }),
            });
            resetForm();
            props.onAdd(productUnitRental);
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: () => ({
        comments: '',
        expectedEndDate: '',
        rentantName: '',
    }),
    validationSchema: Schema,
})(PureForm);

interface ProductUnitRentalAddFormProps {
    id: number;
    onAdd: (productUnitRental: ProductUnitRentalModel) => void;
}

export default ({ id, onAdd }: ProductUnitRentalAddFormProps) => {
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);

    const onHide = () => {
        setModalShown(false);
    };
    const stateProps = {
        error, id, onHide, setError, setModalShown, setShowError, showError,
    };

    return <>
        <Button size="sm" onClick={() => {
            setError(null);
            setShowError(true);
            setModalShown(true);
        }} className="mb-3" block>
            <FormattedMessage id="product-unit-rentals.button.add" defaultMessage="Add product unit rental" />
        </Button>
        <Modal show={modalShown} onHide={onHide} animation={false} className="ProductUnitRentalModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="product-unit-rentals.modal.add" defaultMessage="Add product unit rental" />
                </Modal.Title>
            </Modal.Header>
            <FormikForm onAdd={productUnitRental => {
                onHide();
                onAdd(productUnitRental);
            }} {...stateProps} />
        </Modal>
    </>;
}
