import React from 'react';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import { IdCodeContext } from '../../components/Contexts';
import Menu from '../../components/warehouse/Menu/Menu';
import Storage from '../../components/warehouse/Storage/Storage';
import { getIdCode } from '../../helpers/id-code';
import { Section } from '../../types/section';

export const PATH = `/${Section.WAREHOUSE}/storage/:storageId`;

export default ({ location, match }: RouteComponentProps<{ storageId: string }>) => {
    const idCode = getIdCode(location.search);

    return <IdCodeContext.Provider value={{ idCode }}>
        <div className="WarehouseStoragePage">
            <Menu/>
            <Storage id={parseInt(match.params.storageId, 10)}/>
        </div>
    </IdCodeContext.Provider>;
};
