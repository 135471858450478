import { Base64 } from 'js-base64';
import { getWarehouseApiUrl } from '../../helpers/url';
import { IdCode, IdCodeFamily, IdCodeFormat } from '../../types/id-code';
import {
    ActualStateModel, CommentModel, ProductModel, ProductUnitModel, ProductUnitRentalModel, ProductVariantModel,
    ResourceModel, StorageModel,
} from '../../types/warehouse/model';
import { useResource } from '../use-resource';
import { useResourcesList } from '../use-resources-list';

export function useProductBindings(productId: number, page: number = 0) {
    const url = getWarehouseApiUrl('products/:productId/bindings', { params: { productId } });
    return useResourcesList<ProductModel>(url, page);
}

interface ProductVariantHookProps {
    eanCode?: string;
    id?: number;
}
export function useProductVariant(
    { eanCode, id }: ProductVariantHookProps, preloadedProductVariant?: ProductVariantModel,
) {
    let url = null;
    if (id) {
        url = getWarehouseApiUrl('product-variants/:id', { params: { id: `${id}` } });
    } else if (eanCode) {
        url = getWarehouseApiUrl('product-variants/ean/:eanCode', { params: { eanCode } });
    }
    return useResource<ProductVariantModel>(url, {}, preloadedProductVariant);
}

export function useProductVariantByIdCode(idCode: IdCode | null): [ProductVariantModel | false | null] {
    let props: ProductVariantHookProps = {};
    if (idCode) {
        switch (idCode.family) {
            case IdCodeFamily.BARCODE:
                if (idCode.format === IdCodeFormat.EAN_13) {
                    props = { eanCode: idCode.value };
                }
                break;
            default:
                // nothing to do
        }
    }
    const [item, { error }] = useProductVariant(props);
    return [item || (error ? false : null)];
}

interface ProductUnitHookProps {
    barcode?: string;
    id?: number;
    nfcTag?: string;
    qrCode?: string;
    rfidTag?: string;
}
export function useProductUnit(
    { barcode, id, nfcTag, qrCode, rfidTag }: ProductUnitHookProps, preloadedProductUnit?: ProductUnitModel,
) {
    let url = null;
    if (id) {
        url = getWarehouseApiUrl('product-units/:id', { params: { id: `${id}` } });
    } else if (qrCode) {
        url = getWarehouseApiUrl('product-units/qr/:qrCode', { params: { qrCode: Base64.encodeURI(qrCode) } });
    } else if (barcode) {
        url = getWarehouseApiUrl('product-units/bar/:barcode', { params: { barcode } });
    } else if (nfcTag) {
        url = getWarehouseApiUrl('product-units/nfc/:nfcTag', { params: { nfcTag } });
    } else if (rfidTag) {
        url = getWarehouseApiUrl('product-units/rfid/:rfidTag', { params: { rfidTag } });
    }
    return useResource<ProductUnitModel>(url, {}, preloadedProductUnit);
}

export function useProductUnitRentals(productUnitId: number, page: number = 0) {
    const url = getWarehouseApiUrl('product-units/:productUnitId/rentals', { params: { productUnitId } });
    return useResourcesList<ProductUnitRentalModel>(url, page);
}

export function useRentals(extended: true | null, page: number = 0) {
    const url = getWarehouseApiUrl('rentals');
    return useResourcesList<ProductUnitRentalModel>(url, page, { extended });
}

interface StorageHookProps {
    barcode?: string;
    id?: number;
    nfcTag?: string;
    qrCode?: string;
    rfidTag?: string;
}
export function useStorage({ barcode, id, nfcTag, qrCode, rfidTag }: StorageHookProps) {
    let url = null;
    if (id) {
        url = getWarehouseApiUrl('storages/:id', { params: { id: `${id}` } });
    } else if (qrCode) {
        url = getWarehouseApiUrl('storages/qr/:qrCode', { params: { qrCode: Base64.encodeURI(qrCode) } });
    } else if (barcode) {
        url = getWarehouseApiUrl('storages/bar/:barcode', { params: { barcode } });
    } else if (nfcTag) {
        url = getWarehouseApiUrl('storages/nfc/:nfcTag', { params: { nfcTag } });
    } else if (rfidTag) {
        url = getWarehouseApiUrl('storages/rfid/:rfidTag', { params: { rfidTag } });
    }
    return useResource<StorageModel>(url, { extended: true });
}

export function useStorageByIdCode(idCode: IdCode | null): [StorageModel | false | null] {
    let props: StorageHookProps = {};
    if (idCode) {
        switch (idCode.family) {
            case IdCodeFamily.BARCODE:
                props = { barcode: idCode.value };
                break;
            case IdCodeFamily.NFC_TAG:
                props = { nfcTag: idCode.value };
                break;
            case IdCodeFamily.QR_CODE:
                props = { qrCode: idCode.value };
                break;
            case IdCodeFamily.RFID_TAG:
                props = { rfidTag: idCode.value };
                break;
            default:
                // nothing to do
        }
    }
    const [item, { error }] = useStorage(props);
    return [item || (error ? false : null)];
}

export function useComments(url: string, page: number = 0) {
    return useResourcesList<CommentModel>(url, page);
}

export function useProductVariantActualStates(productVariantId: number, page: number = 0) {
    const url = getWarehouseApiUrl(
        'product-variants/:productVariantId/actual-states', { params: { productVariantId } },
    );
    return useResourcesList<ActualStateModel>(url, page, { extended: true });
}

export function useResources(url: string, page: number = 0) {
    return useResourcesList<ResourceModel>(url, page);
}

export function useStorageActualStates(storageId: number, page: number = 0) {
    const url = getWarehouseApiUrl('storages/:storageId/actual-states', { params: { storageId } });
    return useResourcesList<ActualStateModel>(url, page, { extended: true });
}

export function useStorages(parentId: number, page: number = 0) {
    const url = getWarehouseApiUrl('storages');
    return useResourcesList<StorageModel>(url, page, { parentId });
}

export function useTagActualStates(tagSlug: string, page: number = 0) {
    const url = getWarehouseApiUrl('tags/:tagSlug/actual-states', { params: { tagSlug } });
    return useResourcesList<ActualStateModel>(url, page, { extended: true });
}
