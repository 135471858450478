import React, { useState } from 'react';
import { Button, Card, CardColumns } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { getTrackingApiUrl } from '../../../helpers/url';
import { useResourcesList } from '../../../hooks/use-resources-list';
import { PATH as TRACKING_DEVICE_PATH } from '../../../routes/tracking/Device';
import { Device } from '../../../types/tracking/model';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Message, { MessageType } from '../../Message/Message';
import './Devices.css';

const url = getTrackingApiUrl('devices');

export default () => {
    const [page, setPage] = useState<number>(1);
    const [devices, { error, isLast, loading }] = useResourcesList<Device>(url, page);

    if (devices.length === 0) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else if (isLast) {
            return <Message type={MessageType.NO_RESULTS} />;
        }
    }

    return <>
        <ErrorMessage error={error} />
        <CardColumns className="Devices">
            {devices.map(device => <Card key={device.imei}>
                <Link to={{
                    pathname: getPath(TRACKING_DEVICE_PATH, { imei: device.imei }),
                    state: { device }
                }}>
                    <Card.Body>{device.name}</Card.Body>
                </Link>
            </Card>)}
        </CardColumns>
        {!isLast ?
            <Button variant="light" size="sm" onClick={() => { setPage(page + 1) }} block>
                <FormattedMessage id="list.more" defaultMessage="More" />
            </Button> :
            null}
    </>;
}
