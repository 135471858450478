import React from 'react';
import { useIntl } from 'react-intl';
import { getHomeAutomationApiUrl } from '../../../helpers/url';
import { DailyReport, Device } from '../../../types/home-automation/model';
import ChartBox from '../../Chart/ChartBox';

interface HumidityChartProps {
    date: string;
    device: Device;
    report: DailyReport;
}

const url = getHomeAutomationApiUrl('measurement-series');

export default ({ date, device, report }: HumidityChartProps) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ defaultMessage: 'Humidity chart', id: 'humidity-chart.title' });
    const descriptionY = formatMessage({ defaultMessage: 'humidity [%]', id: 'humidity-chart.description-y' });

    return <ChartBox params={{ date, device: device.slug, type: 'humidity' }} title={title} descriptionY={descriptionY}
        report={report} url={url} getFormattedValue={value => `${Math.round(value * 10) / 10}%`} />;
}
