import React from 'react';
import { Badge } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface MarkProps {
    value: number | null;
}

export default (props: MarkProps) => {
    if (!props.value) {
        return null;
    }
    return <Badge pill variant="warning" className="float-right">
        <FormattedMessage id="mark.title" values={{ mark: props.value }} defaultMessage="Mark: {mark}" />
    </Badge>;
}
