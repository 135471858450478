import { Relay, sortByName, Tank, TankName, TankType } from '@majpage/raspi-tanks-logic';
import React, { useMemo } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getMeasurementsForTank } from '../../../helpers/home-automation/measurements';
import { changeTankInRelay, deleteTankInRelay } from '../../../helpers/home-automation/tanks';
import { Control } from '../../../hooks/home-automation/use-device-controls';
import { useHomeAutomationMeasurementsFromContext } from '../../Contexts';
import Message, { MessageType } from '../../Message/Message';
import TankComponent from '../Tank/Tank';
import TankForm from '../Tank/TankForm';
import { TANKS_IN_RELAY_MAX } from '../types/raspi-tanks';

interface TanksProps {
    editable: boolean;
    loaded: boolean;
    relays: Control<Relay[]>;
    tankTypes: TankType[];
}

export default ({ editable, loaded, relays, tankTypes }: TanksProps) => {
    const measurements = useHomeAutomationMeasurementsFromContext();
    const relaysList = useMemo(() => (relays.modifiedValue || relays.value || []).sort(sortByName), [relays]);
    const tanksList = useMemo(() => relaysList.reduce<{ name: TankName, relay: Relay, tank: Tank }[]>((list, relay) => {
        list.push(...relay.tanks.map(tank => ({ name: tank.name, relay, tank })));
        return list;
    }, []).sort(sortByName), [relaysList]);
    const availableRelays = relaysList.filter(relay => relay.tanks.length < TANKS_IN_RELAY_MAX);
    const namesInUse = relaysList.reduce<[string, string][]>((list, relay) => {
        list.push(...relay.tanks.map<[string, string]>(tank => [relay.name, tank.name]));
        return list;
    }, []);

    return <Card className="Tanks">
        <Card.Header><FormattedMessage defaultMessage="Tanks" id="table.name.tanks" /></Card.Header>
        <Card.Body>
            {!loaded ?
                <Message type={MessageType.FETCHING} /> :
                <>
                    <ListGroup>
                        {tanksList.map(({ relay, tank }) => <TankComponent {...{
                            availableRelays,
                            editable,
                            key: tank.id,
                            measurements: getMeasurementsForTank(tank.id, measurements.list),
                            namesInUse: namesInUse.filter(([relayName, tankName]) => {
                                return relayName !== relay.name || tankName !== tank.name;
                            }),
                            onChange: (newTank, newRelay) => {
                                relays.change(changeTankInRelay(
                                    relaysList, relay.id, newRelay ? newRelay.id : '', newTank,
                                ));
                            },
                            onDelete: oldTank => {
                                relays.change(deleteTankInRelay(relaysList, relay.id, oldTank));
                            },
                            relay,
                            tank,
                            tankTypes,
                        }} />)}
                    </ListGroup>
                    {editable && availableRelays.length > 0 ?
                        <TankForm {...{
                            availableRelays,
                            isType: false,
                            namesInUse,
                            onChange: (newTank, relay) => {
                                relays.change(changeTankInRelay(relaysList, null, relay ? relay.id : '', newTank));
                            },
                            renderItem: setModalShown => <Button variant="primary" size="sm" onClick={() => {
                                setModalShown(true);
                            }} className="mt-2" block>
                                <FormattedMessage id="form.tank-add" defaultMessage="Add tank" />
                            </Button>,
                            tankTypes
                        }} /> :
                        editable ?
                            <Button variant="secondary" size="sm" className="mt-2" block disabled>
                                <FormattedMessage id="form.tank-add" defaultMessage="Add tank" />
                            </Button> :
                            null}
                </>}
        </Card.Body>
    </Card>;
};
