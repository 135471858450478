import React from 'react';
import { useIntl } from 'react-intl';
import { formatPrice } from '../../helpers/format';

interface FormatPriceProps {
    currency: string;
    decimalPlaces?: number | null;
    unit?: string | null;
    value: number;
}

export default ({ currency, unit, value, ...props }: FormatPriceProps) => {
    const { locale } = useIntl();
    return <>
        {formatPrice(locale, value, currency, unit || null, 'decimalPlaces' in props ? props.decimalPlaces : 2)}
    </>;
};
