import { Field, FieldProps } from 'formik';
import React from 'react';
import { Button, FormControl, FormControlProps, FormGroup, FormLabel, InputGroup } from 'react-bootstrap';
import { FormFieldProps } from '../../types/form-field';
import './FormField.css';

interface AmountInputFieldProps extends FormFieldProps, FormControlProps {
    max: number;
    nameSuffix?: string;
    onIncrease?: () => void;
    step: number;
    unit: string;
}

export default (
    { error, fieldId, max, name, nameSuffix, onIncrease, step, touched, unit, ...props }: AmountInputFieldProps,
) => <Field name={fieldId}>
    {({ field }: FieldProps<string>) => <FormGroup controlId={fieldId} className="FormField">
        {name ? <FormLabel>{name}{nameSuffix}</FormLabel> : null}
        <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>0</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl type="range" min="0" max={`${max}`} step={`${step}`} value={field.value}
                onChange={field.onChange} className={error ? 'is-invalid' : ''} {...props} />
            <InputGroup.Append>
                <InputGroup.Text>{max} {unit}</InputGroup.Text>
                {onIncrease ? <Button variant="secondary" onClick={onIncrease}>+</Button> : null}
            </InputGroup.Append>
        </InputGroup>
    </FormGroup>}
</Field>;
