import { MeasurementSets, Tank } from '@majpage/raspi-tanks-logic';
import React, { ReactNode, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import TankDetailsMeasurements from './TankDetailsMeasurements';

interface TankDetailsProps {
    measurementSets: MeasurementSets;
    renderItem: (setModalShown: (modalShown: boolean) => void) => ReactNode;
    tank: Tank;
}

export default ({ measurementSets, renderItem, tank }: TankDetailsProps) => {
    const [modalShown, setModalShown] = useState<boolean>(false);

    return <>
        {renderItem(setModalShown)}
        <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false} className="TankDetailsModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="form.tank-details" defaultMessage="Tank details" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TankDetailsMeasurements {...{
                    config: tank.temperature || {},
                    measurements: measurementSets.temperatures,
                    sensors: tank.thermometers || [],
                    title: <FormattedMessage id="tanks.details.temperature" defaultMessage="Temperature" />,
                    unit: '°C',
                }} />
                <TankDetailsMeasurements {...{
                    config: tank.humidity || {},
                    measurements: measurementSets.humidity,
                    sensors: tank.hygrometers || [],
                    title: <FormattedMessage id="tanks.details.humidity" defaultMessage="Humidity" />,
                    unit: '%',
                }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setModalShown(false);
                }}><FormattedMessage id="modal.close" defaultMessage="Close" /></Button>
            </Modal.Footer>
        </Modal>
    </>;
};
