import { Base64 } from 'js-base64';
import React, { useContext, useState } from 'react';
import { Button, Form, FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getFormControlAttributes } from '../../helpers/id-code';
import { useIdCodeFormatAndTypeLists } from '../../hooks/warehouse/use-id-code-format-and-type-lists';
import { IdCodeFamily, IdCodeFormat, IdCodeType } from '../../types/id-code';
import { Section } from '../../types/section';
import { MainContext } from '../Contexts';
import './IdCodeScanner.css';

interface IdCodeScannerProps<D = any> {
    back?: string;
    data?: D;
    families?: IdCodeFamily[];
    renderButton?: (onClick: () => void) => JSX.Element;
    renderLink?: (url: string) => JSX.Element;
    result: string;
}

function encodeData(data: any) {
    return Base64.encodeURI(JSON.stringify(data));
}

export default ({ back, data, families, renderButton, renderLink, result }: IdCodeScannerProps) => {
    const [format, setFormat] = useState<IdCodeFormat>(IdCodeFormat.EAN_13);
    const [show, setShow] = useState<boolean>(false);
    const [type, setType] = useState<IdCodeType>(IdCodeType.PRODUCT);
    const [value, setValue] = useState<string>('');
    const { scannerLink, scanners, source } = useContext(MainContext);
    const reducedFamilies = Array.isArray(families) ? families.filter(family => scanners.includes(family)) : scanners;

    if (reducedFamilies.length === 0) {
        return null;
    }

    const closeModal = () => {
        if (back) {
            const url = new URL(back, document.location.href);
            if (data) {
                url.searchParams.append('data', encodeData(data));
            }
            if (source) {
                url.searchParams.append('source', source);
            }
            // Page reload made on purpose to let it behaves similar to Android application.
            document.location.href = url.href;
        } else {
            setShow(false);
        }
    };
    const onSubmit = (event: any): void => {
        event.target.checkValidity();
        event.preventDefault();
        const url = new URL(result, document.location.href);
        url.searchParams.append('format', format);
        url.searchParams.append('type', type);
        url.searchParams.append('value', value);
        if (data) {
            url.searchParams.append('data', encodeData(data));
        }
        if (source) {
            url.searchParams.append('source', source);
        }
        // Page reload made on purpose to let it behaves similar to Android application.
        document.location.href = url.href;
    };

    if (scannerLink) {
        const codeUrl = new URL(`/${Section.WAREHOUSE}#scanner`, document.location.href);
        codeUrl.searchParams.append('result', result);
        if (data) {
            codeUrl.searchParams.append('data', encodeData(data));
        }
        reducedFamilies.forEach(family => {
            codeUrl.searchParams.append('types', family);
        });
        return renderLink ?
            renderLink(codeUrl.href) :
            <a href={codeUrl.href} className="btn btn-primary">
                <FormattedMessage id="menu.warehouse.scan" defaultMessage="Scan" />
            </a>;
    }
    const [formatsList, typesList] = useIdCodeFormatAndTypeLists(format, setFormat, type, setType, reducedFamilies);
    return <>
        {renderButton ?
            renderButton(() => { setShow(true) }) :
            <Button onClick={() => { setShow(true) }}>
                <FormattedMessage id="menu.warehouse.scan" defaultMessage="Scan" />
            </Button>}
        <Modal show={show} onHide={closeModal} animation={false} className="IdCodeScanner">
            <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="id-code.name" defaultMessage="Scanner" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup controlId="formBasicEmail">
                        <FormLabel><FormattedMessage id="id-code.format" defaultMessage="Format:" /></FormLabel>
                        <FormControl as="select" value={format}
                            onChange={(event: any) => {
                                setFormat(event.target.value as IdCodeFormat);
                            }}>
                            {Object.entries(formatsList)
                                .map(([value, message]) => <option key={value} value={value}>{message}</option>)}
                        </FormControl>
                    </FormGroup>
                    <FormGroup controlId="formBasicEmail">
                        <FormLabel><FormattedMessage id="id-code.type" defaultMessage="Type:" /></FormLabel>
                        <FormControl as="select" value={type}
                            onChange={(event: any) => {
                                setType(event.target.value as IdCodeType);
                            }}>
                            {Object.entries(typesList)
                                .map(([value, message]) => <option key={value} value={value}>{message}</option>)}
                        </FormControl>
                    </FormGroup>
                    <FormGroup controlId="formBasicEmail">
                        <FormLabel><FormattedMessage id="id-code.value" defaultMessage="Value:" /></FormLabel>
                        <FormControl type="text" value={value} {...getFormControlAttributes(format)} required autoFocus
                            onChange={(event: any) => {
                                setValue(event.target.value);
                            }} />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        <FormattedMessage id="modal.close" defaultMessage="Close" />
                    </Button>
                    <Button type="submit" variant="primary">
                        <FormattedMessage id="modal.send" defaultMessage="Send" />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>;
};
