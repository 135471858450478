import React from 'react';
import { useIntl } from 'react-intl';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { ProductModel } from '../../../types/warehouse/model';
import List from '../../List/List';
import Product from './Product';

const url = getWarehouseApiUrl('products');

interface ProductsProps {
    producerId?: number;
    tagSlug?: string;
}

export default (props: ProductsProps) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ defaultMessage: 'Products', id: 'products.title' });
    const renderItem = (product: ProductModel) => <Product key={product.id} product={product} />;

    return <List params={props} title={title} url={url} renderItem={renderItem} />;
};
