import React from 'react';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import Menu from '../../components/warehouse/Menu/Menu';
import Product from '../../components/warehouse/Product/Product';
import { Section } from '../../types/section';
import { ProductModel } from '../../types/warehouse/model';

export const PATH = `/${Section.WAREHOUSE}/products/:productId`;

export default ({ location, match }: RouteComponentProps<{ productId: string }, { product?: ProductModel }>) =>
    <div className="WarehouseProductPage">
        <Menu />
        <Product id={parseInt(match.params.productId, 10)}
            product={location.state ? location.state.product : undefined} />
    </div>;
