import React from 'react';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import DeviceComponent from '../../components/home-automation/Device/Device';
import { HttpErrors } from '../../hooks/use-http-error';
import { Device } from '../../types/home-automation/model';
import Message, { MessageType } from '../../components/Message/Message';
import { getHomeAutomationApiUrl } from '../../helpers/url';
import { useResource } from '../../hooks/use-resource';
import { Device as DeviceModel } from '../../types/home-automation/model';
import { Section } from '../../types/section';

export const PATH = `/${Section.HOME_AUTOMATION}/devices/:deviceSlug`;

export default ({ location, match }: RouteComponentProps<{ deviceSlug: string }, { device?: DeviceModel }>) => {
    const url = getHomeAutomationApiUrl('devices/:deviceSlug', { params: { deviceSlug: match.params.deviceSlug } });
    const [device, { error, loading }] = useResource<Device>(
        url, {}, location.state && location.state.device ? location.state.device : undefined,
    );

    if (!device) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                {error === HttpErrors.NOT_FOUND_ERROR ?
                    <FormattedMessage id="device.no-device" defaultMessage="There is no such device." /> :
                    <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />}
            </Alert>;
        }
    }

    return <DeviceComponent device={device} />
};

