import { Form, FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { getProductName } from '../../../helpers/warehouse/names';
import { ProductModel } from '../../../types/warehouse/model';
import TextInputField from '../../FormField/TextInputField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';
import ProductSelector from '../Selector/ProductSelector';

interface ProductSimpleModel {
    id: number;
    name: string;
}
function getProductSimpleModel(product?: ProductModel): ProductSimpleModel | null {
    return product ? {
        id: product.id,
        name: getProductName(product),
    } : null;
}

interface FormValues {}

const Schema = Yup.object().shape({});

interface PureFormProps {
    error: string | null;
    excludedIds?: number[];
    onHide: () => void;
    otherProduct: ProductSimpleModel | false | null;
    product: ProductModel;
    setModalShown: (modalShown: boolean) => void;
    setOtherProduct: (otherProduct: ProductSimpleModel | false | null) => void;
    setShowError: (showError: boolean) => void;
    showError: boolean;
}

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();
    const { otherProductId: otherProductIdError } = errors as any; // there is no productVariantId field

    return <Form>
        <Modal.Body>
            <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
            <TextInputField fieldId="productId" disabled value={props.product.name}
                name={formatMessage({ defaultMessage: 'Current product', id: 'form.label.current-product' })} />
            <TextInputField fieldId="otherProductId" error={otherProductIdError} disabled
                value={props.otherProduct && props.otherProduct.name ? props.otherProduct.name : ''}
                name={formatMessage({ defaultMessage: 'Other product', id: 'form.label.other-product' })}
                renderAppend={<ProductSelector excludedIds={[...(props.excludedIds || []), props.product.id]}
                    onSelect={product => props.setOtherProduct(getProductSimpleModel(product))}
                    buttonTitle={formatMessage({ defaultMessage: 'Select', id: 'selector.button' })} />} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
                <FormattedMessage id="modal.close" defaultMessage="Close" />
            </Button>
            <Button variant="primary" type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
                <FormattedMessage id="form.save" defaultMessage="Save" />
            </Button>
        </Modal.Footer>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    formValues: FormValues;
    onAdd: (otherProduct: ProductModel) => void;
    setError: (text: string | null) => void;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);
            const otherProduct = await authenticatedRequest<ProductModel>({
                data: {
                    otherProductId: props.otherProduct ? props.otherProduct.id : null,
                },
                method: HttpMethod.POST,
                url: getWarehouseApiUrl('products/:productId/bindings', { params: { productId: props.product.id } }),
            });
            resetForm();
            props.onAdd(otherProduct);
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: ({ formValues }) => formValues,
    validate: (values, props) => {
        const errors: { [key: string]: string } = {};
        if (!props.otherProduct) {
            errors.otherProductId = 'form.error.product-empty';
        }
        return errors;
    },
    validationSchema: Schema,
})(PureForm);

interface ProductBindingAddFormProps {
    excludedIds?: number[];
    onAdd: (otherProduct: ProductModel) => void;
    product: ProductModel;
}

export default ({ excludedIds, onAdd, product }: ProductBindingAddFormProps) => {
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);

    const [otherProduct, setOtherProduct] = useState<ProductSimpleModel | false | null>(null);

    const onHide = () => {
        setModalShown(false);
        setOtherProduct(null);
    };
    const stateProps = {
        error, formValues: {}, excludedIds, onHide, otherProduct, product, setError, setModalShown, setOtherProduct,
        setShowError, showError,
    };

    return <>
        <Button size="sm" onClick={() => {
            setError(null);
            setShowError(true);
            setModalShown(true);
        }} className="mb-3" block>
            <FormattedMessage id="product-bindings.button.add" defaultMessage="Add product binding" />
        </Button>
        <Modal show={modalShown} onHide={onHide} animation={false} className="ProductBindingModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="product-bindings.modal.add" defaultMessage="Add product binding" />
                </Modal.Title>
            </Modal.Header>
            <FormikForm onAdd={otherProduct => {
                onHide();
                onAdd(otherProduct);
            }} {...stateProps} />
        </Modal>
    </>;
}
