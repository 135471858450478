import { MeasurementSet, RelayId, TankId, Thermometer } from '@majpage/raspi-tanks-logic';
import { Measurement } from '../../types/home-automation/model';

export function getMeasurementSet(measurements: Measurement[]): MeasurementSet {
    return measurements
        .reduce<MeasurementSet>((list, { series, value }) => series ? { ...list, [series]: value } : list, {});
}

export function getMeasurementsForRelay(
    relayId: RelayId, measurements: Measurement[], thermometerDefaultName: string, thermometer?: Thermometer,
): Measurement[] {
    const seriesPrefix = `${relayId}/`;
    return measurements.filter(({ series }) => series && (series === relayId || series.startsWith(seriesPrefix)))
        .map(({ series, ...measurement }) => ({
            ...measurement,
            series: series && series.startsWith(seriesPrefix) ? series.substring(seriesPrefix.length) :
                (thermometer ? thermometer.name : thermometerDefaultName),
        }));
}

export function getMeasurementsForTank(tankId: TankId, measurements: Measurement[]): Measurement[] {
    const seriesPrefix = `${tankId}/`;
    return measurements.filter(({ series }) => series && series.startsWith(seriesPrefix))
        .map(({ series, ...measurement }) => series ? {
            ...measurement,
            series: series.substring(seriesPrefix.length),
        } : measurement);
}

export function getMeasurementsByType(type: 'temperature' | 'humidity', measurements: Measurement[]): Measurement[] {
    return measurements.filter(measurement => measurement.type === type);
}
