import React, { useCallback, useState } from 'react';
import { Button, FormControl, ListGroup, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { authenticatedTraceableRequest, HttpMethod } from '../../../helpers/auth-request';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { Pagination } from '../../../types/pagination';
import './Selector.css';

interface SelectorProps {
    buttonTitle: string;
    excludedIds?: number[];
    modalTitle: string;
    onImport?: (value: string) => void;
    onSelect: (item: any) => void;
    params?: { [key: string]: any };
    path: string;
    placeholder: string;
    renderItemName?: (item: any) => React.ReactNode | string;
}

export default ({ excludedIds, onImport, onSelect, params, path, ...props }: SelectorProps) => {
    const [value, setValue] = useState<string>('');
    const [modalShown, setModalShown] = useState<boolean>(false);
    const [list, setList] = useState<any[]>([]);
    const [requestNo, setRequestNo] = useState<number>(0);
    const showModal = useCallback(() => {
        setValue('');
        setList([]);
        setModalShown(true);
    }, [setList, setModalShown]);
    const hideModal = useCallback(() => {
        setModalShown(false);
    }, [setModalShown]);
    const selectItem = useCallback((item: any) => {
        onSelect(item);
        hideModal();
    }, [hideModal, onSelect]);
    const importValue = useCallback(() => {
        if (onImport) {
            onImport(value);
            hideModal();
        }
    }, [hideModal, onImport, value]);
    const fetchList = useCallback(async (event: any) => {
        const search = event.target.value;
        setValue(search);
        if (search.length < 2) {
            setList([]);
            return;
        }
        try {
            const excludedIdsList = excludedIds || [];
            setRequestNo(requestNo + 1);
            const [paginator, id] = await authenticatedTraceableRequest<Pagination<any>, number>({
                method: HttpMethod.GET,
                params: { ...(params || {}), pack: 10 + Math.min(excludedIdsList.length, 20), page: 1, search },
                url: getWarehouseApiUrl(path),
            }, requestNo);
            if (id === requestNo) {
                setList(paginator.list.filter(item => !excludedIdsList.includes(item.id)).slice(0, 10));
            }
        } catch (error) {
            // nothing to do
        }
    }, [excludedIds, params, path, requestNo, setList, setRequestNo, setValue]);

    return <>
        <Button onClick={showModal}>{props.buttonTitle}</Button>
        <Modal show={modalShown} onHide={hideModal} animation={false} className="SelectorModal">
            <Modal.Header closeButton>
                <Modal.Title>{props.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormControl type="text" value={value} placeholder={props.placeholder} onChange={fetchList} autoFocus />
                {list.length > 0 ?
                    <ListGroup>
                        {list.map(item => <ListGroup.Item key={item.id} onClick={() => { selectItem(item) }}>
                            {props.renderItemName ? props.renderItemName(item) : item.name}
                        </ListGroup.Item>)}
                    </ListGroup> :
                    null}
            </Modal.Body>
            <Modal.Footer>
                {onImport ?
                    <Button variant="outline-secondary" onClick={importValue}>
                        <FormattedMessage id="modal.import-value" defaultMessage="Import value" />
                    </Button> :
                    null}
                <Button variant="secondary" onClick={hideModal}>
                    <FormattedMessage id="modal.close" defaultMessage="Close" />
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
};
