import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { ResourceModel } from '../../../types/warehouse/model';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';

interface ResourceDeleteModalProps {
    onDelete?: (resource: ResourceModel) => void;
    resource: ResourceModel;
    url: string;
}

export default ({ onDelete, resource, url }: ResourceDeleteModalProps) => {
    const [error, setError] = useState<unknown>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);

    return <>
        <Button size="sm" onClick={() => {
            setError(null);
            setShowError(true);
            setModalShown(true);
        }} className="ml-1" variant="light">
            <TrashFill>
                <FormattedMessage id="resources.button.delete" defaultMessage="Delete resource" />
            </TrashFill>
        </Button>
        <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false}
            className="ResourceDeleteModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="resources.modal.delete" defaultMessage="Delete resource" />
                </Modal.Title>
            </Modal.Header>
            {error && showError ?
                <Modal.Body>
                    <FormSubmitError error={error} setShowError={setShowError} showError={showError} />
                </Modal.Body> :
                null}
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setModalShown(false) }}>
                    <FormattedMessage id="modal.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="primary" onClick={async () => {
                    try {
                        setError(null);
                        setShowError(true);
                        await authenticatedRequest({
                            method: HttpMethod.DELETE,
                            url,
                        });
                        if (onDelete) {
                            onDelete(resource);
                        } else {
                            setModalShown(false);
                        }
                    } catch (error) {
                        setError(error);
                    }
                }}>
                    <FormattedMessage id="modal.ok" defaultMessage="OK" />
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}
