import React from 'react';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import Menu from '../../components/warehouse/Menu/Menu';
import Search from '../../components/warehouse/Search/Search';
import { SearchContext } from '../../components/warehouse/SearchList/SearchList';
import { Section } from '../../types/section';

export const PATH = `/${Section.WAREHOUSE}/search`;

export default ({ location }: RouteComponentProps<{}, { contexts?: SearchContext[], query?: string }>) =>
    <div className="WarehouseSearchPage">
        <Menu />
        <Search contextsFromRoute={location.state && location.state.contexts}
            searchFromRoute={location.state && location.state.query ? location.state.query : ''} />
    </div>;
