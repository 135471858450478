import { Form, FormikProps, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getFormControlAttributes, Target } from '../../../helpers/id-code';
import { getPath } from '../../../helpers/path';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { MAX_AMOUNT } from '../../../helpers/warehouse/actual-states';
import { getStorageSimpleModel } from '../../../helpers/warehouse/models';
import { useStorageByIdCode } from '../../../hooks/warehouse/use-resources';
import { PATH as WAREHOUSE_STORAGE_PATH } from '../../../routes/warehouse/Storage';
import { IdCode, IdCodeFamily } from '../../../types/id-code';
import { StorageModel, StorageSimpleModel } from '../../../types/warehouse/model';
import { getDataFromIdCode, getDataSetFromIdCode, getIdCodeIfData, getIdCodeValueIfDataTarget } from '../../Contexts';
import CheckBoxField from '../../FormField/CheckBoxField';
import TextAreaField from '../../FormField/TextAreaField';
import TextInputField from '../../FormField/TextInputField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';
import IdCodeScanner from '../../IdCodeScanner/IdCodeScanner';
import StorageSelector from '../Selector/StorageSelector';

export const ID_CODE_FORM = 'storage';
const STORAGE_TARGET = 'storage';
interface IdCodeData {
    form: string;
    parent?: StorageSimpleModel | false | null;
    target: string;
}

interface FormValues {
    amount: number;
    barcode: string;
    description: string;
    fixedCapacity: boolean;
    name: string;
    nfcTag: string;
    parent: StorageSimpleModel | false | null;
    qrCode: string;
    rfidTag: string;
    statesAvailable: boolean;
    unit: string;
    unitsAvailable: boolean;
}

const Schema = Yup.object().shape({
    amount: Yup.number().typeError('form.number.type-incorrect')
        .min(0, 'form.number.too-low')
        .max(MAX_AMOUNT, 'form.number.too-high'),
    barcode: Yup.string()
        .matches(/^\d+$/, 'form.string.digits-only')
        .max(32, 'form.string.too-long'),
    description: Yup.string(),
    fixedCapacity: Yup.boolean()
        .required('form.element.required'),
    name: Yup.string()
        .max(128, 'form.string.too-long')
        .required('form.element.required'),
    nfcTag: Yup.string()
        .max(64, 'form.string.too-long'),
    qrCode: Yup.string()
        .max(32, 'form.string.too-long'),
    rfidTag: Yup.string()
        .max(64, 'form.string.too-long'),
    unit: Yup.string()
        .max(8, 'form.string.too-long'),
    statesAvailable: Yup.boolean(),
    unitsAvailable: Yup.boolean(),
});

interface PureFormProps {
    baseUrl: string;
    error: string | null;
    idCode: IdCode | null;
    parent: StorageSimpleModel | false | null;
    setParent: (parent: StorageSimpleModel | false | null) => void;
    setShowError: (showError: boolean) => void;
    setShowRelationError: (showError: boolean) => void;
    showError: boolean;
    showRelationError: boolean;
}

const PureForm = ({ touched, errors, values, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();
    const codeScannerData = {
        ...values,
        form: ID_CODE_FORM,
        parent: props.parent,
    };

    return <Form className="mb-3">
        <h2><FormattedMessage id="form.title.storage" defaultMessage="Storage" /></h2>
        <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
        {props.idCode && props.parent === false ?
            <Alert variant="danger" show={props.showRelationError}
                onClose={() => props.setShowRelationError(false)} dismissible>
                <FormattedMessage id="form.error.code-not-exists" values={{ code: props.idCode.value }}
                    defaultMessage={`Code "{code}" doesn't exist.`} />
            </Alert> :
            null}
        <TextInputField fieldId="parent" disabled
            value={props.parent ? props.parent.name : ''}
            name={formatMessage({ defaultMessage: 'Parent storage', id: 'form.label.parent-storage' })}
            renderAppend={<>
                <StorageSelector onSelect={storage => { props.setParent(getStorageSimpleModel(storage)) }}
                    buttonTitle={formatMessage({ defaultMessage: 'Select', id: 'selector.button' })}
                    params={{ units: true }}/>
                <IdCodeScanner result={props.baseUrl} data={{ ...codeScannerData, target: STORAGE_TARGET }} />
                {props.parent ?
                    <Button onClick={() => { props.setParent(null) }}>×</Button> :
                    null}
            </>} />
        <TextInputField fieldId="name" touched={touched.name} error={errors.name}
            name={formatMessage({ defaultMessage: 'Name', id: 'form.label.name' })} />
        <TextAreaField fieldId="description" touched={touched.description} error={errors.description}
            name={formatMessage({ defaultMessage: 'Description', id: 'form.label.description' })} />
        <CheckBoxField fieldId="fixedCapacity" touched={touched.fixedCapacity} error={errors.fixedCapacity}
            name={formatMessage({ defaultMessage: 'Fixed capacity', id: 'form.label.fixed-capacity' })}
            type="checkbox" />
        <TextInputField fieldId="unit" touched={touched.unit} error={errors.unit} disabled={!values.fixedCapacity}
            name={formatMessage({ defaultMessage: 'Unit', id: 'form.label.unit' })} />
        <TextInputField fieldId="amount" touched={touched.amount} error={errors.amount} disabled={!values.fixedCapacity}
            type="number" min="0" max={`${MAX_AMOUNT}`} step="any"
            name={formatMessage({ defaultMessage: 'Amount', id: 'form.label.amount' })} />
        <TextInputField fieldId="qrCode" touched={touched.qrCode} error={errors.qrCode}
            name={formatMessage({ defaultMessage: 'QR code', id: 'form.label.qr-code' })}
            {...getFormControlAttributes(IdCodeFamily.QR_CODE)} renderAppend={
                <IdCodeScanner result={props.baseUrl} families={[IdCodeFamily.QR_CODE]}
                    data={{ ...codeScannerData, target: Target.QR_CODE }} />
            } />
        <TextInputField fieldId="barcode" touched={touched.barcode} error={errors.barcode}
            name={formatMessage({ defaultMessage: 'Barcode', id: 'form.label.barcode' })}
            {...getFormControlAttributes(IdCodeFamily.BARCODE)} renderAppend={
                <IdCodeScanner result={props.baseUrl} families={[IdCodeFamily.BARCODE]}
                    data={{ ...codeScannerData, target: Target.BARCODE }} />
            } />
        <TextInputField fieldId="nfcTag" touched={touched.nfcTag} error={errors.nfcTag}
            name={formatMessage({ defaultMessage: 'NFC tag', id: 'form.label.nfc-tag' })}
            {...getFormControlAttributes(IdCodeFamily.NFC_TAG)} renderAppend={
                <IdCodeScanner result={props.baseUrl} families={[IdCodeFamily.NFC_TAG]}
                    data={{ ...codeScannerData, target: Target.NFC_TAG }} />
            } />
        <TextInputField fieldId="rfidTag" touched={touched.rfidTag} error={errors.rfidTag}
            name={formatMessage({ defaultMessage: 'RFID tag', id: 'form.label.rfid-tag' })}
            {...getFormControlAttributes(IdCodeFamily.RFID_TAG)} renderAppend={
                <IdCodeScanner result={props.baseUrl} families={[IdCodeFamily.RFID_TAG]}
                    data={{ ...codeScannerData, target: Target.RFID_TAG }} />
            } />
        <CheckBoxField fieldId="statesAvailable" touched={touched.statesAvailable} error={errors.statesAvailable}
            name={formatMessage({
                defaultMessage: 'Actual states available', id: 'form.label.actual-states-available',
            })} />
        <CheckBoxField fieldId="unitsAvailable" touched={touched.unitsAvailable} error={errors.unitsAvailable}
            name={formatMessage({
                defaultMessage: 'Product units available', id: 'form.label.product-units-available',
            })} />
        <Button type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
            <FormattedMessage id="form.save" defaultMessage="Save" />
        </Button>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    formValues: FormValues;
    setError: (error: string | null) => void;
    setStorage: (storage: StorageModel) => void;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);
            props.setShowRelationError(true);
            const storage = await authenticatedRequest<StorageModel>({
                data: {
                    amount: values.fixedCapacity ? values.amount : null,
                    barcode: values.barcode || null,
                    description: values.description || null,
                    fixedCapacity: values.fixedCapacity,
                    name: values.name,
                    nfcTag: values.nfcTag || null,
                    parentId: props.parent ? props.parent.id : null,
                    qrCode: values.qrCode || null,
                    rfidTag: values.rfidTag || null,
                    statesAvailable: values.statesAvailable,
                    unit: values.fixedCapacity ? values.unit : null,
                    unitsAvailable: values.unitsAvailable,
                },
                method: HttpMethod.POST,
                url: getWarehouseApiUrl('storages'),
            });
            resetForm();
            props.setError(null);
            props.setStorage(storage);
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: ({ formValues }) => formValues,
    validate: values => {
        const errors: { [key: string]: string } = {};
        if (values.fixedCapacity && !values.unit) {
            errors.unit = 'form.element.required';
        }
        if (values.fixedCapacity && !values.amount) {
            errors.amount = 'form.element.required';
        }
        return errors;
    },
    validationSchema: Schema,
})(PureForm);

interface ActualStateAddFormProps {
    baseUrl: string;
    idCode: IdCode<IdCodeData> | null;
}

export default ({ baseUrl, idCode }: ActualStateAddFormProps) => {
    const [storage, setStorage] = useState<StorageModel | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(true);
    const [showRelationError, setShowRelationError] = useState(true);

    const formValues = getDataSetFromIdCode<FormValues>(idCode, [
        'amount', 'barcode', 'description', 'fixedCapacity', 'name', 'nfcTag', 'parent', 'qrCode', 'rfidTag',
        'statesAvailable', 'unit', 'unitsAvailable',
    ], {
        amount: 0, barcode: '', description: '', fixedCapacity: false, name: '', nfcTag: '', parent: null, qrCode: '',
        rfidTag: '', statesAvailable: true, unit: '', unitsAvailable: true,
    });
    formValues.barcode = getIdCodeValueIfDataTarget(idCode, Target.BARCODE, formValues.barcode);
    formValues.nfcTag = getIdCodeValueIfDataTarget(idCode, Target.NFC_TAG, formValues.nfcTag);
    formValues.qrCode = getIdCodeValueIfDataTarget(idCode, Target.QR_CODE, formValues.qrCode);
    formValues.rfidTag = getIdCodeValueIfDataTarget(idCode, Target.RFID_TAG, formValues.rfidTag);

    const [parent, setParent] = useState<StorageSimpleModel | false | null>(
        getDataFromIdCode(idCode, 'parent') || formValues.parent || null,
    );
    const [parentFromIdCode] = useStorageByIdCode(
        getIdCodeIfData(idCode, data => data.target === STORAGE_TARGET),
    );

    useEffect(() => {
        if (parentFromIdCode !== null) {
            setParent(parentFromIdCode ? getStorageSimpleModel(parentFromIdCode) : false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(parentFromIdCode)]);

    if (storage) {
        return <Redirect to={getPath(WAREHOUSE_STORAGE_PATH, { storageId: storage.id })} />;
    }

    return <FormikForm {...{
        baseUrl, error, formValues, idCode, parent, setError, setParent, setShowError, setShowRelationError,
        setStorage, showError, showRelationError,
    }} />;
}
