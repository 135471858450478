import React from 'react';
import { IntlShape } from 'react-intl';
import { Controls } from '../../../hooks/home-automation/use-device-controls';
import { Device } from '../../../types/home-automation/model';
import MetadataRow from '../Controls/ControlsRow';
import MetadataDoorSignalSwitch from '../Controls/MetadataDoorSignalSwitch';
import MetadataSwitch from '../Controls/MetadataSwitch';
import DailyReport from '../DailyReport/DailyReport';
import EnergyUsage from '../EnergyUsage/EnergyUsage';
import Events from '../Events/Events';
import Temperatures from '../Temperatures/Temperatures';

export const TYPE = 'raspi-door';

export function customRender(device: Device, canManage: boolean) {
    return <DailyReport device={device} canManage={canManage} renderBefore={date => <>
        <Events date={date} device={device} />
        <Temperatures date={date} device={device} />
        <EnergyUsage date={date} device={device} />
    </>} />;
}

export const controlFields = [
    { name: 'armed', initialValue: false },
    { name: 'debug', initialValue: false },
    { name: 'doorSignal', initialValue: { melody: '', on: false, rate: 1, volume: 100 } },
];
export function controlsSwitchesRender(controls: Controls, { formatMessage }: IntlShape) {
    const armed = controls.find<boolean>('armed');
    const debug = controls.find<boolean>('debug');
    const doorSignal = controls.find<{ melody: string; on: boolean; rate: number; volume: number }>('doorSignal');
    return <>
        {armed ?
            <MetadataRow title={formatMessage({ defaultMessage: 'Armament:', id: 'table.label.armed' })}>
                <MetadataSwitch id="raspi-door-switch-armed" onChange={armed.change}
                    switched={armed.value} synced={armed.match} />
            </MetadataRow> :
            null}
        {debug ?
            <MetadataRow title={formatMessage({ defaultMessage: 'Debug mode:', id: 'table.label.debug' })}>
                <MetadataSwitch id="raspi-door-switch-debug" onChange={debug.change}
                    switched={debug.value} synced={debug.match} />
            </MetadataRow> :
            null}
        {doorSignal ?
            <MetadataRow title={formatMessage({
                defaultMessage: 'Door opening signal:', id: 'table.label.door-opening-signal',
            })}>
                <MetadataDoorSignalSwitch id="raspi-door-switch-door-opening-signal" doorSignal={doorSignal} />
            </MetadataRow> :
            null}
    </>;
}
