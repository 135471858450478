import React from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { PATH as WAREHOUSE_PRODUCER_PATH } from '../../../routes/warehouse/Producer';
import { ProducerModel } from '../../../types/warehouse/model';
import Mark from '../Mark/Mark';

interface ProducerInfoProps {
    addLink?: boolean;
    producer: ProducerModel;
}

export default ({ addLink = false, producer }: ProducerInfoProps) => <Card>
    <Card.Header>
        <FormattedMessage id="producer.title" defaultMessage="Producer info" />
        <Mark value={producer.mark} />
    </Card.Header>
    <Card.Body>
        <table className="table mb-0">
            <tbody>
                <tr>
                    <td className="text-right">
                        <strong><FormattedMessage id="table.label.name" defaultMessage="Name:" /></strong>
                    </td>
                    <td className="text-break">
                        {addLink ?
                            <Link to={{
                                pathname: getPath(WAREHOUSE_PRODUCER_PATH, { producerId: producer.id }),
                                state: { producer }
                            }}>{producer.name}</Link> :
                            producer.name}
                    </td>
                </tr>
                {producer.description ?
                    <tr>
                        <td className="text-right">
                            <strong>
                                <FormattedMessage id="table.label.description" defaultMessage="Description:" />
                            </strong>
                        </td>
                        <td className="text-break">{producer.description}</td>
                    </tr>:
                    null}
                <tr>
                    <td className="text-right">
                        <strong>
                            <FormattedMessage id="table.label.others" defaultMessage="Others:" />
                        </strong>
                    </td>
                    <td>
                        <ul className="mb-0 pl-3">
                            {producer.notForeignConsortium ?
                                <li className="text-success">
                                    <FormattedMessage id="producer.not-foreign-consortium.yes"
                                        defaultMessage="Not a part of foreign consortium" />
                                </li> :
                                <li className="text-danger">
                                    <FormattedMessage id="producer.not-foreign-consortium.no"
                                        defaultMessage="A part of foreign consortium" />
                                </li>}
                            {producer.registeredInFatherland ?
                                <li className="text-success">
                                    <FormattedMessage id="producer.registered-in-fatherland.yes"
                                        defaultMessage="Registered in fatherland" />
                                </li> :
                                <li className="text-danger">
                                    <FormattedMessage id="producer.registered-in-fatherland.no"
                                        defaultMessage="Not registered in fatherland" />
                                </li>}
                            {producer.productionInFatherland ?
                                <li className="text-success">
                                    <FormattedMessage id="producer.production-in-fatherland.yes"
                                        defaultMessage="Production in fatherland" />
                                </li> :
                                <li className="text-danger">
                                    <FormattedMessage id="producer.production-in-fatherland.no"
                                        defaultMessage="No production in fatherland" />
                                </li>}
                            {producer.researchInFatherland ?
                                <li className="text-success">
                                    <FormattedMessage id="producer.research-in-fatherland.yes"
                                        defaultMessage="Research in fatherland" />
                                </li> :
                                <li className="text-danger">
                                    <FormattedMessage id="producer.research-in-fatherland.no"
                                        defaultMessage="No research in fatherland" />
                                </li>}
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
    </Card.Body>
</Card>;
