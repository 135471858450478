import React from 'react';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface FormErrorProps {
    error: unknown;
    setShowError: (showError: boolean) => void;
    showError: boolean;
}

export default ({ error, setShowError, showError }: FormErrorProps) => {
    if (!error) {
        return null;
    }

    return <Alert variant="danger" show={showError} onClose={() => { setShowError(false) }} dismissible>
        <p>
            <FormattedMessage defaultMessage="An error occurred during form data sending."
                id="form.error.request-failed"/>
        </p>
        <hr />
        <p className="mb-0">{`${error}`}</p>
    </Alert>;
}
