import React, { useState } from 'react';
import { useResourcesList } from '../../hooks/use-resources-list';
import ListTemplate from './ListTemplate';

interface ListProps<I> {
    className?: string;
    open?: boolean;
    params?: { [key: string]: any };
    renderItem: (item: I) => JSX.Element;
    title: string;
    url: string;
}

export default function <I = any>({ className, open, params, renderItem, title, url }: ListProps<I>) {
    const [opened, setOpened] = useState<boolean>(open === true);
    // @TODO: Make sure that page is set to 0 each time new list has to be loaded (e.g. on date change)
    const [page, setPage] = useState<number>(opened ? 1 : 0);
    const [items, { error, isLast, loading, total }] = useResourcesList<I>(url, page, params || {});
    const listProps = {
        className, error, isLast, items, loading, opened, page, renderItem, setOpened, setPage, title, total,
    };

    return <ListTemplate {...listProps} />;
};
