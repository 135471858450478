import React from 'react';
import { Inventory as InventoryModel } from '../../../types/inventories/model';
import InventoryFetchModal from './InventoryFetchModal';

interface InventoryToFetchProps {
    inventory: InventoryModel;
    onDelete?: () => void;
    onFetch?: (inventory: InventoryModel | null) => void;
}

export default ({ inventory, onDelete, onFetch }: InventoryToFetchProps) =>
    <div className="InventoryToFetch toast show">
        <div className="toast-header">
            <span className="mr-auto">{inventory.number}</span>
            <InventoryFetchModal inventory={inventory} onDelete={onDelete} onFetch={onFetch} />
        </div>
    </div>;
