import { NOT_FOUND } from 'http-status-codes';
import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';
import { authenticatedRequest, HttpMethod, ResponseError } from '../../../helpers/auth-request';
import { getInventoryApiUrl } from '../../../helpers/url';
import { InventoryFetched } from '../../../types/inventories/model';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';

interface InventoryFetchModalProps {
    inventory: InventoryFetched;
    onDelete?: () => void;
    onFetch?: (inventory: InventoryFetched | null) => void;
}

export default ({ inventory, onDelete, onFetch }: InventoryFetchModalProps) => {
    const [error, setError] = useState<unknown>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const url = useMemo(() => getInventoryApiUrl('inventories/:id', {
        params: { id: inventory.id },
    }), [inventory.id]);

    return <>
        <Button size="sm" onClick={() => {
            setError(null);
            setShowError(true);
            setModalShown(true);
        }} className="ml-1" variant="light">
            <ArrowRepeat>
                <FormattedMessage id="inventories.button.reload" defaultMessage="Reload inventory" />
            </ArrowRepeat>
        </Button>
        <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false}
            className="InventoryFetchModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="inventories.modal.reload" defaultMessage="Reload inventory" />
                </Modal.Title>
            </Modal.Header>
            {error && showError ?
                <Modal.Body>
                    <FormSubmitError error={error} setShowError={setShowError} showError={showError} />
                </Modal.Body> :
                null}
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setModalShown(false) }}>
                    <FormattedMessage id="modal.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="primary" onClick={async () => {
                    try {
                        setError(null);
                        setShowError(true);
                        const fetchedInventory = await authenticatedRequest<InventoryFetched>({
                            method: HttpMethod.GET, params: { fetch: 'true' }, url,
                        });
                        if (onFetch) {
                            onFetch(fetchedInventory);
                        }
                        setModalShown(false);
                    } catch (error) {
                        if (!(error instanceof ResponseError) || error.status !== NOT_FOUND) {
                            setError(error);
                        } else if (onDelete) {
                            onDelete();
                        }
                    }
                }}>
                    <FormattedMessage id="modal.ok" defaultMessage="OK" />
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}
