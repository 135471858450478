import { Form, FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import { Button, FormControl, FormGroup, FormLabel, Modal, Tab, Tabs } from 'react-bootstrap';
import { PencilFill } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { ResourceModel, ResourceTarget } from '../../../types/warehouse/model';
import FileInputField from '../../FormField/FileInputField';
import SelectField from '../../FormField/SelectField';
import TextAreaField from '../../FormField/TextAreaField';
import TextInputField from '../../FormField/TextInputField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';

interface FormValues {
    description: string | null;
    file: File | null;
    internal: boolean;
    name: string;
    target: ResourceTarget;
    url: string | null;
}

const Schema = Yup.object().shape({
    description: Yup.string()
        .nullable(),
    file: Yup.object()
        .nullable(),
    internal: Yup.boolean()
        .required('form.element.required'),
    name: Yup.string()
        .max(128, 'form.string.too-long')
        .required('form.element.required'),
    target: Yup.string()
        .oneOf(Object.values(ResourceTarget), 'form.enum.unknown')
        .required('form.element.required'),
    url: Yup.string()
        .url('form.url.invalid')
        .max(255, 'form.string.too-long')
        .nullable(),
});

interface PureFormProps {
    error: string | null;
    resource: ResourceModel | null;
    setModalShown: (modalShown: boolean) => void;
    setShowError: (showError: boolean) => void;
    showError: boolean;
}

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();

    const targets = {
        [ResourceTarget.BLANK]: formatMessage({ defaultMessage: 'new window', id: 'target.blank' }),
        [ResourceTarget.SELF]: formatMessage({ defaultMessage: 'same window', id: 'target.self' }),
        [ResourceTarget.TOP]: formatMessage({ defaultMessage: 'whole window', id: 'target.top' }),
    };

    return <Form>
        <Modal.Body>
            <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
            <TextInputField fieldId="name" touched={touched.name} error={errors.name}
                name={formatMessage({ defaultMessage: 'Name', id: 'form.label.name' })} />
            <TextAreaField fieldId="description" touched={touched.description} error={errors.description}
                name={formatMessage({ defaultMessage: 'Description', id: 'form.label.description' })} />
            {!props.resource ?
                <Tabs defaultActiveKey={props.values.internal ? 'file' : 'url'} onSelect={(key: string) => {
                    props.setFieldValue('internal', key === 'file');
                }} id="uncontrolled-tab-example">
                    <Tab eventKey="file" title={formatMessage({ defaultMessage: 'File', id: 'form.label.file' })}>
                        <div className="mt-4">
                            <FileInputField fieldId="file" touched={touched.file} error={errors.file}
                                onChange={(event: any) => {
                                    props.setFieldValue(
                                        'file', event.target.files.length > 0 ? event.target.files[0] : null
                                    );
                                }}/>
                        </div>
                    </Tab>
                    <Tab eventKey="url" title={formatMessage({ defaultMessage: 'URL', id: 'form.label.url' })}>
                        <div className="mt-4">
                            <TextInputField fieldId="url" touched={touched.url} error={errors.url} />
                        </div>
                    </Tab>
                </Tabs> :
                props.resource.url ?
                    <FormGroup className="FormField">
                        <FormLabel><FormattedMessage id="form.label.url" defaultMessage="URL" /></FormLabel>
                        <FormControl value={props.resource.url} disabled />
                    </FormGroup> :
                    null}
            <SelectField fieldId="target" touched={touched.target} error={errors.target}
                name={formatMessage({ defaultMessage: 'Open resource in', id: 'form.label.target' })}>
                {Object.entries(targets).map(([value, name]) => <option key={value} value={value}>{name}</option>)}
            </SelectField>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { props.setModalShown(false) }}>
                <FormattedMessage id="modal.close" defaultMessage="Close" />
            </Button>
            <Button variant="primary" type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
                <FormattedMessage id="form.save" defaultMessage="Save" />
            </Button>
        </Modal.Footer>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    onAdd: (resource: ResourceModel) => void;
    onEdit: (resource: ResourceModel) => void;
    resource: ResourceModel | null;
    setError: (text: string | null) => void;
    url: string;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);
            let data;
            if (props.resource) {
                data = { description: values.description || null, name: values.name, target: values.target };
            } else if (values.internal) {
                data = new FormData();
                data.append('name', values.name);
                if (values.description) {
                    data.append('description', values.description);
                }
                if (values.file) {
                    data.append('file', values.file);
                }
                data.append('target', values.target || ResourceTarget.SELF);
            } else {
                data = {
                    description: values.description || null, name: values.name, target: values.target, url: values.url,
                };
            }
            const resource = await authenticatedRequest<ResourceModel>({
                data,
                method: props.resource ? HttpMethod.PATCH : HttpMethod.POST,
                url: props.url,
            });
            resetForm();
            if (props.resource) {
                props.onEdit(resource);
            } else {
                props.onAdd(resource);
            }
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: ({ resource }) => ({
        description: resource && resource.description ? resource.description : '',
        file: null,
        internal: !resource || resource.internal !== false,
        name: resource && resource.name ? resource.name : '',
        target: resource && resource.target ? resource.target : ResourceTarget.SELF,
        url: '',
    }),
    validate: (values, { resource }) => {
        const errors: { [key: string]: string } = {};
        if (!resource) {
            if (values.internal && !values.file) {
                errors.file = 'form.error.file-empty';
            } else if (!values.internal && !values.url) {
                errors.url = 'form.error.url-empty';
            }
        }
        return errors;
    },
    validationSchema: Schema,
})(PureForm);

interface ResourceManageFormProps {
    onAdd?: (resource: ResourceModel) => void;
    onEdit?: (resource: ResourceModel) => void;
    resource?: ResourceModel;
    url: string;
}

export default ({ onAdd, onEdit, resource, url }: ResourceManageFormProps) => {
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const stateProps = { error, setError, setModalShown, setShowError, showError };

    return <>
        <Button size="sm" onClick={() => {
            setError(null);
            setShowError(true);
            setModalShown(true);
        }} className={resource ? 'ml-1' : 'mb-3'} block={!resource} variant={resource ? 'light' : 'primary'}>
            {resource ?
                <PencilFill>
                    <FormattedMessage id="resources.button.edit" defaultMessage="Edit resource" />
                </PencilFill> :
                <FormattedMessage id="resources.button.add" defaultMessage="Add resource" />}
        </Button>
        <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false}
            className="ResourceManageModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {resource ?
                        <FormattedMessage id="resources.modal.edit" defaultMessage="Edit resource" /> :
                        <FormattedMessage id="resources.modal.add" defaultMessage="Add resource" />}
                </Modal.Title>
            </Modal.Header>
            <FormikForm url={url} resource={resource || null} onAdd={resource => {
                setModalShown(false);
                if (onAdd) {
                    onAdd(resource);
                }
            }} onEdit={resource => {
                setModalShown(false);
                if (onEdit) {
                    onEdit(resource);
                }
            }} {...stateProps} />
        </Modal>
    </>;
}
