import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goneBack, loggedIn, loggedOut, tokenGenerate } from '../actions/user';
import {
    AUTH_TOKEN_STORAGE_NAME, getUserFromToken, logOutRequest, redirectToAuthService, tokenGenerateRequest, User,
} from '../helpers/auth-request';
import { State } from '../reducers';

export function useAuthentication(capability?: string): [User | null, () => void, () => void, boolean, boolean] {
    const loading = useSelector<State, boolean>(state => state.user.loading);
    const noAccess = useSelector<State, boolean>(state => state.user.noAccess);
    const user = useSelector<State, User | null>(state => state.user.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user && !noAccess && !loading) {
            logIn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, noAccess, loading]);

    const isCapable = !user || !capability || user.capabilities.includes(capability);

    function loggingIn() {
        const user = getUserFromToken();
        if (user) {
            dispatch(loggedIn(user));
        } else {
            loggingOut();
        }
    }

    function loggingOut() {
        dispatch(loggedOut());
    }

    async function logIn() {
        const token = sessionStorage.getItem(AUTH_TOKEN_STORAGE_NAME);
        if (token) {
            sessionStorage.removeItem(AUTH_TOKEN_STORAGE_NAME);
            dispatch(tokenGenerate());
            try {
                await tokenGenerateRequest(token);
                loggingIn();
            } catch (error) {
                loggingOut();
            }
        } else {
            redirectToAuthService();
        }
    }

    async function logOut() {
        loggingOut();
        await logOutRequest();
    }

    function goBack() {
        dispatch(goneBack());
    }

    return [user, logOut, goBack, isCapable, noAccess];
}
