import { NOT_FOUND } from 'http-status-codes';
import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';
import { authenticatedRequest, HttpMethod, ResponseError } from '../../../helpers/auth-request';
import { getInventoryApiUrl } from '../../../helpers/url';
import { Inventory } from '../../../types/inventories/model';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';

interface InventoryFetchModalProps {
    inventory: Inventory;
    onDelete?: () => void;
    onFetch?: (inventory: Inventory | null) => void;
}

export default ({ inventory, onDelete, onFetch }: InventoryFetchModalProps) => {
    const [error, setError] = useState<unknown>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const url = useMemo(() => getInventoryApiUrl('providers/:provider/inventories/:id', {
        params: { id: inventory.providerInventoryId, provider: inventory.provider },
    }), [inventory.provider, inventory.providerInventoryId]);

    return <>
        <Button size="sm" onClick={() => {
            setError(null);
            setShowError(true);
            setModalShown(true);
        }} className="ml-1" variant="light">
            <Download>
                <FormattedMessage id="inventories.button.fetch" defaultMessage="Fetch inventory" />
            </Download>
        </Button>
        <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false}
            className="InventoryFetchModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="inventories.modal.fetch" defaultMessage="Fetch inventory" />
                </Modal.Title>
            </Modal.Header>
            {error && showError ?
                <Modal.Body>
                    <FormSubmitError error={error} setShowError={setShowError} showError={showError} />
                </Modal.Body> :
                null}
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setModalShown(false) }}>
                    <FormattedMessage id="modal.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="primary" onClick={async () => {
                    try {
                        setError(null);
                        setShowError(true);
                        const fetchedInventory = await authenticatedRequest<Inventory>({ method: HttpMethod.GET, url });
                        if (onFetch) {
                            onFetch(fetchedInventory);
                        }
                        setModalShown(false);
                    } catch (error) {
                        if (!(error instanceof ResponseError) || error.status !== NOT_FOUND) {
                            setError(error);
                        } else {
                            if (onDelete) {
                                onDelete();
                            }
                            setModalShown(false);
                        }
                    }
                }}>
                    <FormattedMessage id="modal.ok" defaultMessage="OK" />
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}
