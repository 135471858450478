import { Base64 } from 'js-base64';
import { parse } from 'query-string';
import { IdCode, IdCodeFamily, IdCodeFormat, IdCodeType } from '../types/id-code';

export enum Target {
    BARCODE = 'barcode',
    NFC_TAG = 'nfc-tag',
    QR_CODE = 'qr-code',
    RFID_TAG = 'rfid-tag',
}
const CODE_FAMILY_TO_TARGET_MAPPING: { [family: string]: Target } = {
    [IdCodeFamily.BARCODE]: Target.BARCODE,
    [IdCodeFamily.NFC_TAG]: Target.NFC_TAG,
    [IdCodeFamily.QR_CODE]: Target.QR_CODE,
    [IdCodeFamily.RFID_TAG]: Target.RFID_TAG,
};
function getTargetByFamily(family: IdCodeFamily): Target {
    return CODE_FAMILY_TO_TARGET_MAPPING[family] || Target.BARCODE;
}

const CODE_FORMAT_TO_FAMILY_MAPPING: { [format: string]: IdCodeFamily } = {
    [IdCodeFormat.EAN_13]: IdCodeFamily.BARCODE,
    [IdCodeFormat.EAN_8]: IdCodeFamily.BARCODE,
    [IdCodeFormat.NFC_TAG]: IdCodeFamily.NFC_TAG,
    [IdCodeFormat.QR_CODE]: IdCodeFamily.QR_CODE,
    [IdCodeFormat.RFID_TAG]: IdCodeFamily.RFID_TAG,
};
function getFamilyByFormat(format: string): IdCodeFamily {
    return CODE_FORMAT_TO_FAMILY_MAPPING[format] || IdCodeFamily.BARCODE;
}

export function getIdCode<D = any>(queryString: string): IdCode<D> | null {
    const { data, format, type, value } = parse(queryString);
    if (!format || !type || !value) {
        return null;
    }

    const unifiedFormat = Array.isArray(format) ? format[0] : format;
    const unifiedType = Array.isArray(type) ? type[0] : type;
    const unifiedValue = Array.isArray(value) ? value[0] : value;
    if (
        !Object.values<string>(IdCodeFormat).includes(unifiedFormat) ||
        !Object.values<string>(IdCodeType).includes(unifiedType) || !unifiedValue
    ) {
        return null;
    }

    const unifiedData = Array.isArray(data) ? data[0] : data;
    const decodedData = unifiedData ? JSON.parse(Base64.decode(unifiedData)) : null;
    const family = getFamilyByFormat(unifiedFormat);

    return {
        data: decodedData || { target: getTargetByFamily(family) },
        family,
        format: unifiedFormat as IdCodeFormat,
        type: unifiedType as IdCodeType,
        value: unifiedValue,
    };
}

export function getFormControlAttributes(type: IdCodeFamily | IdCodeFormat): { maxLength?: number; pattern?: string } {
    const attributes: { maxLength?: number; pattern?: string } = {};
    switch (type) {
        case IdCodeFamily.BARCODE:
            attributes.maxLength = 32;
            break;
        case IdCodeFormat.EAN_13:
            attributes.maxLength = 13;
            break;
        case IdCodeFormat.EAN_8:
            attributes.maxLength = 8;
            break;
    }
    switch (type) {
        case IdCodeFamily.BARCODE:
            attributes.pattern = '^[0-9]{0,32}$';
            break;
        case IdCodeFormat.EAN_13:
            attributes.pattern = '^[0-9]{13}$';
            break;
        case IdCodeFormat.EAN_8:
            attributes.pattern = '^[0-9]{8}$';
            break;
    }
    return attributes;
}
