import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { useLocalJwt } from '../../../hooks/warehouse/use-local-jwt';
import { ResourceModel, ResourceTarget } from '../../../types/warehouse/model';
import './Resource.css';
import ResourceDeleteModal from './ResourceDeleteModal';
import ResourceManageForm from './ResourceManageForm';

interface ResourceProps {
    onEdit: (resource: ResourceModel) => void;
    resource: ResourceModel;
    url: string;
}

export default ({ onEdit, resource, url }: ResourceProps) => {
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const [localJwt, loadLocalJwt] = useLocalJwt('resource');

    if (isDeleted) {
        return null;
    }

    return <>
        <div className="ResourceShow toast show">
            <div className="toast-header">
                <Button size="sm" variant="link" onClick={resource.target === ResourceTarget.SELF ? () => {
                    setModalShown(true);
                    loadLocalJwt();
                } : undefined} className="mr-auto">
                    {resource.target === ResourceTarget.SELF || !localJwt ?
                        <strong>{resource.name}</strong> :
                        <a href={`${getWarehouseApiUrl(resource.path)}?access_token=${localJwt}`}
                            target={resource.target === ResourceTarget.BLANK ? '_blank' : undefined}>
                            <strong>{resource.name}</strong>
                        </a>}
                </Button>
                <ResourceManageForm resource={resource} url={url} onEdit={onEdit} />
                <ResourceDeleteModal resource={resource} url={url} onDelete={() => { setIsDeleted(true) }} />
            </div>
            {resource.description ? <div className="toast-body text-break">{resource.description}</div> : null}
        </div>
        {resource.target === ResourceTarget.SELF ?
            <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false}
                className="ResourceShowModal">
                <Modal.Header closeButton>
                    <Modal.Title>{resource.name}</Modal.Title>
                </Modal.Header>
                {localJwt ?
                    <iframe src={`${getWarehouseApiUrl(resource.path)}?access_token=${localJwt}`}
                        title="ResourceShowModal"/> :
                    <Spinner animation="border" role="status">
                        <span className="sr-only">
                            <FormattedMessage defaultMessage="Loading..." id="loading" />
                        </span>
                    </Spinner>}
            </Modal> :
            null}
    </>;
}
