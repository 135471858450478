import { isString } from 'lodash';
import { FormatMessage } from '../types/react-intl';

export const GENERAL_ERROR_TYPE = 'general';

export interface Errors {
    [fieldName: string]: string[];
}
interface ErrorMessage {
    defaultMessage?: string;
    id: string;
    type?: string;
}
export type ErrorMessages = (ErrorMessage | string)[];

export function getErrorsFromMessages(errorMessages: (ErrorMessage | string)[], formatMessage?: FormatMessage): Errors {
    return errorMessages.reduce<Errors>((errors, message) => {
        const id = !isString(message) ? message.id : message;
        const defaultMessage = !isString(message) ? (message.defaultMessage || id) : message;
        const type = !isString(message) && message.type ? message.type : GENERAL_ERROR_TYPE;
        if (!errors[type]) {
            errors[type] = [];
        }
        errors[type].push(formatMessage ? formatMessage({ defaultMessage, id }) : defaultMessage);
        return errors;
    }, {});
}

export function hasErrors(errors: Errors) {
    return Object.keys(errors).length > 0;
}
