import { PlainObject } from '../collection';

export type Position = [string, number, number];

export interface Device {
    dateStart: string;
    imei: string;
    name: string;
}

export enum CommandType {
    ALARM_GEOFENCE_CANCEL = 'alarm_geofence_cancel',
    ALARM_GEOFENCE_SET = 'alarm_geofence_set',
    ALARM_MOVE_CANCEL = 'alarm_move_cancel',
    ALARM_MOVE_SET = 'alarm_move_set',
    ALARM_OVER_SPEED_SET = 'alarm_over_speed_set',
    ALARM_STOP = 'alarm_stop',
    ALARM_VIBRATION_CANCEL = 'alarm_vibration_cancel',
    ALARM_VIBRATION_SET = 'alarm_vibration_set',
    ARM = 'arm',
    DATA_UPLOAD_CANCEL = 'data_upload_cancel',
    DATA_UPLOAD_SET = 'data_upload_set',
    DISARM = 'disarm',
    ENGINE_START = 'engine_start',
    ENGINE_STOP = 'engine_stop',
    // PHOTO_REQUEST = 'photo_request',
    SAVE_GPRS_CANCEL = 'save_gprs_cancel',
    SAVE_GPRS_SET = 'save_gprs_set',
    TIME_SET = 'time_set',
    TRACKING_CANCEL = 'tracking_cancel',
    TRACKING_DISTANCE_SET = 'tracking_distance_set',
    TRACKING_SINGLE_SET = 'tracking_single_set',
    TRACKING_TIME_SET = 'tracking_time_set',
}

export interface Command {
    createdAt: string;
    params: PlainObject | null;
    type: CommandType;
}

export enum MessageType {
    ALARM_GEOFENCE_CANCEL = 'alarm_geofence_cancel',
    ALARM_GEOFENCE_SET = 'alarm_geofence_set',
    ALARM_MOVE_CANCEL = 'alarm_move_cancel',
    ALARM_MOVE_SET = 'alarm_move_set',
    ALARM_NO_GPS = 'alarm_no_gps',
    ALARM_OVER_SPEED = 'alarm_over_speed',
    ALARM_OVER_SPEED_SET = 'alarm_over_speed_set',
    ALARM_STOP = 'alarm_stop',
    ALARM_VIBRATION = 'alarm_vibration',
    ALARM_VIBRATION_CANCEL = 'alarm_vibration_cancel',
    ALARM_VIBRATION_SET = 'alarm_vibration_set',
    CONNECTION_ERROR = 'connection_error', // internal
    CONNECTION_LOST = 'connection_lost', // internal
    ENGINE_START = 'engine_start',
    ENGINE_STOP = 'engine_stop',
    HEARTBEAT = 'heartbeat',
    INIT = 'init',
    TRACKING_CANCEL = 'tracking_cancel',
    TRACKING_SINGLE = 'tracking_single',
    TIME_SET = 'time_set',
}

export interface Message {
    cell?: { cid: string; coords?: { lat: number; lon: number }; lac: string };
    coords?: { lat: number; lon: number };
    createdAt: string;
    time?: string;
    type?: MessageType;
}

export interface Area {
    isReady: boolean;
    latA: number | null;
    lonA: number | null;
    latB: number | null;
    lonB: number | null;
}

export enum HighlightType {
    COMMAND_AREA = 'command-area',
    MESSAGE_CELL = 'message-cell',
    MESSAGE_POINT = 'message-point',
}

export interface Highlight {
    area?: [[number, number], [number, number]];
    coords?: [number, number];
    time: Date;
    type: HighlightType;
}
