import md5 from 'md5';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Image, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { ArrowLeftCircle } from 'react-bootstrap-icons';
import NavLink from 'react-bootstrap/NavLink';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { PATH as MAIN_PATH } from '../../routes/Main';
import { MainContext, useUserFromContext } from '../Contexts';
import './Header.css';

interface HeaderProps {
    logOut: () => void;
}

export default ({ logOut }: HeaderProps) => {
    const history = useHistory();
    const { backButton } = useContext(MainContext);
    const [backButtonActive, setBackButtonActive] = useState<boolean>(false);
    useEffect(() => history.listen(({ key }) => {
        if (backButtonActive && !key) {
            setBackButtonActive(false);
        } else if (!backButtonActive && key) {
            setBackButtonActive(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [backButtonActive]);
    const user = useUserFromContext();

    // @TODO: Cache gravatar.
    const gravatarThumbnail = `https://www.gravatar.com/avatar/${md5(user.email.toLowerCase())}?s=32&d=mm&r=g`;
    return <Navbar bg="dark" variant="dark" className="Header">
        {backButton && backButtonActive ?
            <div onClick={history.goBack} className="history-back">
                <ArrowLeftCircle><FormattedMessage id="header.back" defaultMessage="Back" /></ArrowLeftCircle>
            </div> :
            null}
        <Navbar.Brand>
            <Link to={MAIN_PATH}>
                <FormattedMessage id="header.title" defaultMessage="Home" />
            </Link>
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto" />
            <Nav>
                <Dropdown as={NavItem}>
                    <Dropdown.Toggle id="collasible-nav-dropdown" as={NavLink}>
                        <Image src={gravatarThumbnail} className="gravatar-thumbnail" thumbnail />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                        <span className="dropdown-item">{user.name || user.email}</span>
                        <NavDropdown.Item onClick={logOut}>
                            <FormattedMessage id="menu.log-out" defaultMessage="Log out" />
                        </NavDropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Nav>
        </Navbar.Collapse>
    </Navbar>;
}
