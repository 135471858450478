import { useEffect, useState } from 'react';
import { authenticatedRequest, HttpMethod } from '../helpers/auth-request';
import { ifNotAborted } from '../helpers/request';
import { PlainObject } from '../types/collection';
import { Pagination } from '../types/pagination';
import { HttpErrors, useHttpError } from './use-http-error';

interface ResourcesListProps<U> {
    addItem: (item: U) => void;
    editItem: (item: U, index: number) => void;
    error: HttpErrors;
    isLast: boolean;
    loading: boolean;
    total: number | null;
}

export function useResourcesList<U>(
    url: string | null, page: number = 1, params: PlainObject = {},
): [U[], ResourcesListProps<U>] {
    const context = JSON.stringify({ params, url });
    const [lastContext, setLastContext] = useState<string>(context);
    const [items, setItems] = useState<U[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, catchError, resetError] = useHttpError();
    const [isLast, setIsLast] = useState<boolean>(false);
    const [total, setTotal] = useState<number | null>(null);
    const reset = () => {
        if (items.length > 0) {
            items.length = 0;
            setItems(items);
        }
        if (isLast) {
            setIsLast(false);
        }
        if (total !== null) {
            setTotal(null);
        }
    };

    useEffect(() => {
        if (!url || !page) {
            reset();
            return;
        }
        if (context !== lastContext) {
            reset();
        } else if (isLast) {
            return;
        }
        setLoading(true);
        const request = new AbortController();
        authenticatedRequest<Pagination<U> | U[]>({
            method: HttpMethod.GET,
            params: { page, ...params },
            url,
        }, request.signal).then(paginator => {
            setLastContext(context);
            if (!Array.isArray(paginator)) {
                setItems([...items, ...paginator.list]);
                setIsLast(paginator.isLast);
                if (paginator.total !== null && total !== paginator.total) {
                    setTotal(paginator.total);
                }
            } else {
                setItems([...items, ...paginator]);
                setIsLast(true);
                setTotal((total || 0) + paginator.length);
            }
            resetError();
        }).finally(ifNotAborted(request, () => {
            setLoading(false);
        })).catch(ifNotAborted(request, catchError));
        return () => request.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, page]);

    const addItem = (item: U) => {
        setItems([item, ...(isLast ? items : items.slice(0, -1))]);
        if (total !== null) {
            setTotal(total + 1);
        }
    }

    const editItem = (item: U, index: number) => {
        if (index >= 0 && index < items.length) {
            setItems([...items.slice(0, index), item, ...items.slice(index + 1)]);
        }
    };

    return [items, { addItem, editItem, error, isLast, loading, total }];
}
