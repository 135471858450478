import { combineReducers } from 'redux';
import UserReducer, { UserState } from './user';

export interface State {
    user: UserState;
}

export const reducers = combineReducers({
    user: UserReducer,
});
