import { TankType } from '@majpage/raspi-tanks-logic';
import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';
import TankForm from '../Tank/TankForm';

interface TankTypeProps {
    editable: boolean;
    namesInUse: string[];
    onChange: (newTankType: TankType) => void;
    onDelete: (tankType: TankType) => void;
    tankType: TankType;
    tankTypesInUse: string[];
}

export default ({ editable, namesInUse, onChange, onDelete, tankType, tankTypesInUse }: TankTypeProps) => {
    return <ListGroup.Item key={tankType.id} className="d-flex justify-content-between align-items-start">
        <div className="ms-2 me-auto">
            {editable ?
                <TankForm item={tankType} renderItem={setModalShown => <span className="btn-link" onClick={() => {
                    setModalShown(true);
                }}>{tankType.name}</span>} onChange={onChange} isType={true} namesInUse={namesInUse} /> :
                tankType.name}
        </div>
        {editable && !tankTypesInUse.includes(tankType.name) ?
            <Button size="sm" variant="danger" onClick={() => {
                onDelete(tankType);
            }}>
                <X>
                    <FormattedMessage id="tanks.button.remove" defaultMessage="Remove element" />
                </X>
            </Button> :
            null}
    </ListGroup.Item>;
};

