import axios, { AxiosRequestConfig } from 'axios';
import { UNAUTHORIZED } from 'http-status-codes';
import { decode } from 'jsonwebtoken';
import { stringify } from 'querystring';
import { getStringOrError } from '../utils/types';
import { SECOND_IN_MS } from './time';

export const CLIENT_JWT_STORAGE_NAME = 'clientJwt';
const REGENERATE_JWT_STORAGE_NAME = 'regenerateJwt';
export const AUTH_TOKEN_STORAGE_NAME = 'token';
const AUTH_BASE_URL = getStringOrError(process.env.REACT_APP_AUTH_URL, 'No REACT_APP_AUTH_URL declared.');
const AUTH_ORGANIZATION = getStringOrError(
    process.env.REACT_APP_AUTH_ORGANIZATION, 'No REACT_APP_AUTH_ORGANIZATION declared.',
);
const AUTH_API_URL = `${AUTH_BASE_URL}/api/v1/${AUTH_ORGANIZATION}/auth`;
const AUTH_URL = `${AUTH_BASE_URL}/auth/${AUTH_ORGANIZATION}`;

interface AuthTokens {
    clientJwt: string;
    regenerateJwt: string;
}

export interface User {
    capabilities: string[];
    email: string;
    name: string | null;
    uuid: string;
}
interface TokenPayload extends User {
    exp: number;
    iat: number;
    specificity: string;
}

function addAuthorizationHeader(config: AxiosRequestConfig): AxiosRequestConfig {
    const headers = config.headers ? { ...config.headers } : {};
    headers['Authorization'] = `Bearer ${sessionStorage.getItem(CLIENT_JWT_STORAGE_NAME)}`;
    return { ...config, headers };
}

export function redirectToAuthService(): void {
    window.location.replace(AUTH_URL);
}

export function getUserFromToken(): User | null {
    const token = sessionStorage.getItem(CLIENT_JWT_STORAGE_NAME);
    if (!token) {
        return null;
    }
    const { exp, iat, specificity, ...user } = decode(token) as TokenPayload;
    return exp > Date.now() / SECOND_IN_MS ? user : null;
}

export enum HttpMethod {
    DELETE = 'delete',
    GET = 'get',
    PATCH = 'patch',
    POST = 'post',
}

class HttpError extends Error {
    public constructor(error?: any, message?: string) {
        super(message || (error ? error.message : undefined));
        if (error) {
            if (this.name) {
                this.name = error.name;
            }
            if (this.stack) {
                this.stack = error.stack;
            }
        }
    }
}

export class RequestError extends HttpError {}

export class ResponseError extends HttpError {
    public readonly status: number;

    public constructor(status: number, error?: unknown, message?: string) {
        super(error, message);
        this.status = status;
    }
}

function throwHttpError(error: { request?: object; response?: { data: { error?: string }, status: number } }) {
    if (error.response) {
        if (error.response.status === UNAUTHORIZED) {
            return;
        }
        throw new ResponseError(
            error.response.status, error, error.response.data ? error.response.data.error : undefined,
        );
    } else if (error.request) {
        throw new RequestError(error);
    } else {
        throw new HttpError(error);
    }
}

export async function authenticatedRequest<U = void>(config: AxiosRequestConfig, signal?: AbortSignal): Promise<U> {
    try {
        const result = await axios({
            ...addAuthorizationHeader(config),
            ...(signal ? { signal } : {}),
        });
        return result.data as U;
    } catch (error) {
        throwHttpError(error);
    }

    try {
        const token = localStorage.getItem(REGENERATE_JWT_STORAGE_NAME);
        const result = await axios({
            headers: { 'Authorization': `Bearer ${token}` },
            method: HttpMethod.POST,
            url: `${AUTH_API_URL}/tokens/regenerate`,
            ...(signal ? { signal } : {}),
        });
        const { clientJwt, regenerateJwt } = result.data as AuthTokens;
        sessionStorage.setItem(CLIENT_JWT_STORAGE_NAME, clientJwt);
        localStorage.setItem(REGENERATE_JWT_STORAGE_NAME, regenerateJwt);
    } catch (error) {
        redirectToAuthService();
        throw new Error();
    }

    try {
        const result = await axios({
            ...addAuthorizationHeader(config),
            ...(signal ? { signal } : {}),
        });
        return result.data as U;
    } catch (error) {
        throwHttpError(error);
        redirectToAuthService();
        throw new Error();
    }
}

export async function authenticatedTraceableRequest<U, I = number | string>(
    config: AxiosRequestConfig, id: I,
): Promise<[U, I]> {
    return [await authenticatedRequest<U>(config), id];
}

export async function tokenGenerateRequest(token: string, signal?: AbortSignal): Promise<string> {
    try {
        const result = await axios({
            data: stringify({ token }),
            method: HttpMethod.POST,
            url: `${AUTH_API_URL}/tokens/generate`,
            withCredentials: true,
            ...(signal ? { signal } : {}),
        });
        const { clientJwt, regenerateJwt } = result.data as AuthTokens;
        sessionStorage.setItem(CLIENT_JWT_STORAGE_NAME, clientJwt);
        localStorage.setItem(REGENERATE_JWT_STORAGE_NAME, regenerateJwt);
        return clientJwt;
    } catch (error) {
        console.warn('token-generate-auth-request:', error);
        throw error;
    }
}

export async function logOutRequest(signal?: AbortSignal): Promise<void> {
    try {
        const token = localStorage.getItem(REGENERATE_JWT_STORAGE_NAME);
        sessionStorage.removeItem(CLIENT_JWT_STORAGE_NAME);
        localStorage.removeItem(REGENERATE_JWT_STORAGE_NAME);
        await axios({
            headers: { 'Authorization': `Bearer ${token}` },
            method: HttpMethod.POST,
            url: `${AUTH_API_URL}/logout`,
            withCredentials: true,
            ...(signal ? { signal } : {}),
        });
    } catch (error) {
        console.warn('log-out-auth-request:', error);
    }
}
