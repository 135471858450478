import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { getInventoryApiUrl } from '../../../helpers/url';
import { useResourcesList } from '../../../hooks/use-resources-list';
import { Inventory as InventoryModel, InventoryProvider } from '../../../types/inventories/model';
import ListTemplate from '../../List/ListTemplate';
import './Inventories.css';
import InventoryToFetch from './InventoryToFetch';

interface InventoriesToFetchProps {
    onFetch?: () => void;
    provider: InventoryProvider;
}

export default ({ onFetch, provider }: InventoriesToFetchProps) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({
        defaultMessage: 'Inventories to fetch from provider "{provider}"', id: 'inventories.to-fetch.title',
    }, { provider });
    const url = useMemo(() => getInventoryApiUrl('providers/:provider/inventories', {
        params: { provider },
    }), [provider]);
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [items, { editItem, error, isLast, loading, total }] = useResourcesList(url, page);

    const renderItem = (inventory: InventoryModel, index: number) =>
        <InventoryToFetch key={inventory.providerInventoryId} inventory={inventory} onFetch={item => {
            editItem(item, index);
            if (onFetch) {
                onFetch();
            }
        }} onDelete={() => { setPage(0) }} />;
    const listProps = {
        error, isLast, items, loading, opened, page, renderItem, setOpened, setPage, title, total,
    };

    return <ListTemplate {...listProps} />;
}
