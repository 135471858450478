import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFormattedDate } from '../../helpers/time';
import './DateSelector.css';

interface DateSelectorProps {
    date: string;
    dateMin: string;
    loading: boolean;
    setDate: (date: string) => void;
}

export default ({ date, dateMin, loading, setDate }: DateSelectorProps) => {
    const [showPrev, datePrev, showNext, dateNext] = getDateControls(date, getFormattedDate(new Date(dateMin)));
    const intl = useIntl();
    const { formatMessage } = intl;

    return <InputGroup className="DateSelector">
        <InputGroup.Prepend>
            {showPrev ?
                <InputGroup.Text className="date-control" onClick={() => {
                    setDate(getFormattedDate(datePrev));
                }}>&laquo;</InputGroup.Text> :
                null}
            <InputGroup.Text id="date-selector">
                <FormattedMessage defaultMessage="Date" id="date-control.date" />
            </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl type="date" value={date} disabled={loading} aria-describedby="date-selector"
            aria-label={formatMessage({ defaultMessage: 'Date', id: 'date-control.date' })}
            onChange={(event: any) => {
                setDate(event.target.value || getFormattedDate(new Date()));
            }} />
        {showNext ?
            <InputGroup.Append>
                <InputGroup.Text className="date-control" onClick={() => {
                    setDate(getFormattedDate(dateNext));
                }}>&raquo;</InputGroup.Text>
            </InputGroup.Append> :
            null}
    </InputGroup>;
}

function getDateControls(dateString: string, dateMinString: string): [boolean, Date, boolean, Date] {
    const dateMin = new Date(`${dateMinString}T00:00:00`);
    const dateTime = `${dateString}T00:00:00`;
    const dateCurrent = new Date(dateTime);
    const datePrev = new Date(dateTime);
    datePrev.setDate(dateCurrent.getDate() - 1);
    const showPrev = datePrev >= dateMin;
    const dateNext = new Date(dateTime);
    dateNext.setDate(dateCurrent.getDate() + 1);
    const showNext = dateNext <= new Date() && dateNext > dateMin;
    return [showPrev, datePrev, showNext, dateNext];
}
