import React from 'react';
import { Badge, Button, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { HttpErrors } from '../../hooks/use-http-error';
import '../../styles/card.css';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Message, { MessageType } from '../Message/Message';
import './ListTemplate.css';

interface ListTemplateProps {
    className?: string;
    error?: HttpErrors;
    isLast: boolean;
    items: any[];
    listPrefix?: JSX.Element | null;
    loading: boolean;
    opened: boolean;
    page: number;
    renderItem: (item: any, index: number) => JSX.Element;
    setOpened?: (opened: boolean) => void;
    setPage: (page: number) => void;
    title: string;
    total: number | null;
}

export default (props: ListTemplateProps) => <Card className={props.className || 'List'}>
    <Card.Header {...(props.setOpened ? { className: props.opened ? 'card-opened' : 'card-closed' } : {})}
        onClick={() => {
            if (props.setOpened) {
                props.setOpened(!props.opened);
                if (!props.page) {
                    props.setPage(1);
                }
            }
        }}>
        {props.title}
        {props.total !== null ?
            <Badge pill variant="info" className="float-right mt-1 mr-4">{props.total}</Badge> :
            null}
    </Card.Header>
    {props.opened ?
        <Card.Body>
            <ErrorMessage error={props.error} />
            {props.listPrefix || null}
            {props.items.length > 0 ?
                <>
                    {props.items.map(props.renderItem)}
                    {!props.isLast ?
                        <Button variant="light" size="sm" onClick={() => { props.setPage(props.page + 1) }} block>
                            <FormattedMessage id="list.more" defaultMessage="More" />
                        </Button> :
                        null}
                </> :
                props.isLast ? <Message type={MessageType.NO_RESULTS} /> :
                    props.loading ? <Message type={MessageType.FETCHING} /> : null}
        </Card.Body> :
        null}
</Card>;
