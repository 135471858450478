export enum Status {
    APPROVED = 'approved',
    OPENED = 'opened',
}

export enum InventoryProvider {
    FAKTUROWNIA = 'fakturownia',
}

export interface InventoryProduct {
    barcode: string | null;
    code: string | null;
    currency: string | null;
    id: number;
    name: string;
    priceGross: number;
    priceNet: number;
    providerActionId: string | null;
    providerProductId: string | null;
    quantityNew: number;
    quantityOld: number;
    quantitySynced: number;
    tax: number;
    unit: string | null;
}

export interface Inventory {
    number: string;
    products?: InventoryProduct[];
    provider: InventoryProvider;
    providerInventoryId: string;
    status: Status;
}

export interface InventoryFetched extends Inventory {
    id: number;
}

export enum ActionType {
    INVENTORY_SAVE = 'inventory-save',
    INVENTORY_SYNC = 'inventory-sync',
    PRODUCT_CREATE = 'product-create',
    PRODUCT_UPDATE = 'product-update',
    QUANTITY_CHANGE = 'quantity-change',
}

export interface InventoryAction {
    id: number | null;
    name: string | null;
    ongoing: boolean;
    time: Date;
    type: ActionType;
    value: number | null;
}
