import React from 'react';
import { getHomeAutomationApiUrl } from '../../../helpers/url';
import { Device, Measurement } from '../../../types/home-automation/model';
import List from '../../List/List';

interface MeasurementsListProps {
    date: string;
    device: Device;
    title: string;
    type: string;
    unit: string;
}

const url = getHomeAutomationApiUrl('measurements');

export default ({ date, device, title, type, unit }: MeasurementsListProps) =>
    <List params={{ date, device: device.slug, type }} title={title} url={url}
        renderItem={(measurement: Measurement) => <div key={measurement.id} className="toast show">
            <div className="toast-header">
                <strong className="mr-auto">
                    {measurement.value}{unit}
                    {measurement.series ? <em className="ml-2">{measurement.series}</em> : null}
                </strong>
                <small className="text-muted">{(new Date(measurement.time)).toLocaleTimeString()}</small>
            </div>
        </div>} />;
