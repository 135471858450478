import { Hygrometer, Relay, Tank, TankType, TankTypeName, Thermometer, TimePeriod } from '@majpage/raspi-tanks-logic';
import { isBoolean } from 'lodash';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessages, Errors, GENERAL_ERROR_TYPE, getErrorsFromMessages, hasErrors } from '../../../helpers/error';
import { generateId } from '../../../helpers/home-automation/ids';
import { getRelayChannelOptions } from '../../../helpers/home-automation/relays';
import { getUnifiedTankConfig } from '../../../helpers/home-automation/tanks';
import { getMinutesFromTime, getTimeFromMinutes } from '../../../helpers/time';
import { MatrixItemColumnType } from '../../../types/home-automation/raspi-tanks';
import { FormatMessage } from '../../../types/react-intl';
import ErrorsComponent from '../../Errors/Errors';
import InputItem from '../FormItem/InputItem';
import MatrixItem from '../FormItem/MatrixItem';
import PeriodMatrixItems from '../FormItem/PeriodMatrixItems';
import SwitchInUseItemStatus from '../FormItem/SwitchInUseItemStatus';

function getRowsFromTimePeriods(timePeriods: TimePeriod[]): [string, string][] {
    return timePeriods.map(
        ({ from, to }) => ([getTimeFromMinutes(from), getTimeFromMinutes(to)]),
    );
}
function getTimePeriodsFromRows(rows: [string, string][]): TimePeriod[] {
    return rows.map(([fromString, toString]) => {
        const from = getMinutesFromTime(fromString);
        const to = Math.max(from, getMinutesFromTime(toString, true));
        return { from, to };
    });
}

function setInitialData(isType: boolean, item?: Tank | TankType): Tank | TankType {
    return item || { id: generateId(isType ? 'tank-type' : 'tank'), name: '' };
}

const emptyError = { defaultMessage: 'Value has to be defined.', id: 'form.error.value-empty' };
function getErrors(
    item: Tank | TankType, relay: Relay | null, isType: boolean, namesInUse: (string | [string, string])[],
    formatMessage: FormatMessage,
): Errors {
    const messages: ErrorMessages = [];
    if (!item.name) {
        messages.push({ ...emptyError, type: 'name' });
    }
    if (namesInUse.includes(item.name)) {
        messages.push({ defaultMessage: 'This name is already in use.', id: 'form.error.name-in-use', type: 'name' });
    }
    if (!isType && !relay) {
        messages.push({ ...emptyError, type: 'relay' });
    }
    if (!isType && 'inUse' in item && !isBoolean(item.inUse)) {
        messages.push({ ...emptyError, type: 'inUse' });
    }
    // @TODO: Add other errors logic!
    return getErrorsFromMessages(messages, formatMessage);
}

interface TankFormProps {
    availableRelays?: Relay[];
    isType: boolean;
    item?: Tank | TankType;
    namesInUse: (string | [string, string])[];
    onChange: (newTank: Tank | TankType, relay: Relay | null) => void;
    relay?: Relay;
    renderItem: (setModalShown: (modalShown: boolean) => void) => ReactNode;
    tankTypes?: TankType[];
}

export default (
    { availableRelays, isType, item, namesInUse, onChange, relay, renderItem, tankTypes }: TankFormProps,
) => {
    const { formatMessage } = useIntl();
    const [modifiedItem, setModifiedItem] = useState<Tank | TankType>(() => setInitialData(isType, item));
    const [errors, setErrors] = useState<Errors>({});
    const [modalShown, setModalShown] = useState<boolean>(false);
    const readonlyItem = useMemo<TankType>(
        () => tankTypes && 'types' in modifiedItem && modifiedItem.types ?
            getUnifiedTankConfig(modifiedItem, tankTypes, false) : { id: '', name: '' },
        [modifiedItem, tankTypes],
    );
    const allRelays = useMemo(() => [
        ...(availableRelays || []),
        ...(relay && (!availableRelays || !availableRelays.find(({ id }) => id === relay.id)) ? [relay] : []),
    ], [availableRelays, relay]);
    const [selectedRelay, setSelectedRelay] = useState<Relay | null>(relay || allRelays[0] || null);

    useEffect(() => {
        setErrors({});
    }, [modifiedItem]);

    useEffect(() => {
        if (modalShown) {
            setModifiedItem(setInitialData(isType, item));
        }
    }, [modalShown, item, isType]);

    return <>
        {renderItem(setModalShown)}
        <Modal show={modalShown} onHide={() => { setModalShown(false) }} animation={false} className="TankModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage
                        id={isType ? (item ? 'form.tank-type-edit' : 'form.tank-type-add') :
                            (item ? 'form.tank-edit' : 'form.tank-add')}
                        defaultMessage={isType ? (item ? 'Edit tank type' : 'Add tank type') :
                            (item ? 'Edit tank' : 'Add tank')} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ErrorsComponent messages={errors[GENERAL_ERROR_TYPE]} onClose={() => setErrors({})} />
                <InputItem {...{
                    errors: errors.name,
                    name: formatMessage({ defaultMessage: 'Name', id: 'form.label.name' }),
                    onChange: name => { setModifiedItem({ ...modifiedItem, name }) },
                    required: true,
                    value: modifiedItem.name,
                }} />
                <Row>
                    <Col>
                        <InputItem {...{
                            disabled: true,
                            name: formatMessage({ defaultMessage: 'ID', id: 'form.label.id' }),
                            required: true,
                            value: modifiedItem.id,
                        }} />
                    </Col>
                    {!isType ?
                        <Col>
                            <SwitchInUseItemStatus<Tank> {...{
                                errors: errors.inUse,
                                id: 'tank-in-use',
                                item,
                                modifiedItem,
                                setModifiedItem,
                            }} />
                        </Col> :
                        null}
                </Row>
                {!isType ?
                    <InputItem {...{
                        errors: errors.relay,
                        name: formatMessage({ defaultMessage: 'Relay', id: 'form.label.relay' }),
                        onChange: id => {
                            setSelectedRelay(allRelays.find(relay => relay.id === id) || null);
                        },
                        options: allRelays.map(({ id, name }) => ({ key: id, name })),
                        value: selectedRelay ? selectedRelay.id : '',
                    }} /> :
                    null}
                <PeriodMatrixItems {...{
                    errors: errors.temperature,
                    measurements: modifiedItem.temperature,
                    measurementsReadonly: readonlyItem.temperature,
                    measurementType: 'temperature',
                    modifiedItem,
                    name: formatMessage({ defaultMessage: 'Temperature [°C]', id: 'form.label.temperature' }),
                    setModifiedItem,
                }} />
                <PeriodMatrixItems {...{
                    errors: errors.humidity,
                    measurements: modifiedItem.humidity,
                    measurementsReadonly: readonlyItem.humidity,
                    measurementType: 'humidity',
                    modifiedItem,
                    name: formatMessage({ defaultMessage: 'Humidity [%]', id: 'form.label.humidity' }),
                    setModifiedItem,
                }} />
                <MatrixItem<TimePeriod[], [string, string]> {...{
                    columns: [
                        {
                            title: formatMessage({ defaultMessage: 'from', id: 'form.value.from' }),
                            type: MatrixItemColumnType.TIME,
                            unique: true,
                        },
                        {
                            title: formatMessage({ defaultMessage: 'to', id: 'form.value.to' }),
                            type: MatrixItemColumnType.TIME,
                            unique: true,
                        },
                    ],
                    errors: errors.lightPeriods,
                    item: modifiedItem.lightPeriods,
                    itemReadonly: readonlyItem.lightPeriods,
                    itemToRows: getRowsFromTimePeriods,
                    onChange: rows => {
                        const { lightPeriods, ...itemParams } = modifiedItem;
                        setModifiedItem(rows.length === 0 ? itemParams : {
                            ...itemParams,
                            lightPeriods: getTimePeriodsFromRows(rows),
                        });
                    },
                    name: formatMessage({ defaultMessage: 'Light periods', id: 'form.label.light-periods' }),
                }} />
                {!isType ?
                    ((tank: Tank) => <>
                        <MatrixItem<TankTypeName[], [TankTypeName]> {...{
                            columns: [
                                {
                                    options: (tankTypes || []).map(({ id, name }) => ({ key: id, name })),
                                    type: MatrixItemColumnType.SELECT,
                                    unique: true,
                                },
                            ],
                            errors: errors.types,
                            item: tank.types,
                            itemToRows: tankTypeIds => tankTypeIds.map(tankTypeId => ([tankTypeId])),
                            name: formatMessage({ defaultMessage: 'Tank types', id: 'form.label.tank-types' }),
                            onChange: rows => {
                                const { types, ...itemParams } = tank;
                                setModifiedItem(rows.length === 0 ? itemParams : {
                                    ...itemParams,
                                    types: rows.map(([tankTypeId]) => tankTypeId),
                                });
                            },
                        }} />
                        <MatrixItem<Thermometer[], [string, string]> {...{
                            columns: [
                                {
                                    title: formatMessage({ defaultMessage: 'name', id: 'form.value.name' }),
                                    type: MatrixItemColumnType.STRING,
                                    unique: true,
                                },
                                {
                                    title: formatMessage({ defaultMessage: 'address', id: 'form.value.address' }),
                                    type: MatrixItemColumnType.STRING,
                                    unique: true,
                                },
                            ],
                            errors: errors.thermometers,
                            item: tank.thermometers,
                            itemToRows: item => item.map(({ address, name }) => ([name, address])),
                            name: formatMessage({ defaultMessage: 'Thermometers', id: 'form.label.thermometers' }),
                            onChange: rows => {
                                const { thermometers, ...itemParams } = tank;
                                setModifiedItem(rows.length === 0 ? itemParams : {
                                    ...itemParams,
                                    thermometers: rows.map(([name, address]) => ({ address, name })),
                                });
                            },
                        }} />
                        <MatrixItem<Hygrometer[], [string, string]> {...{
                            columns: [
                                {
                                    title: formatMessage({ defaultMessage: 'name', id: 'form.value.name' }),
                                    type: MatrixItemColumnType.STRING,
                                    unique: true,
                                },
                                {
                                    // @TODO: Add options here when available!
                                    title: formatMessage({ defaultMessage: 'pin name', id: 'form.value.pin-name' }),
                                    type: MatrixItemColumnType.STRING,
                                    unique: true,
                                },
                            ],
                            errors: errors.hygrometers,
                            item: tank.hygrometers,
                            itemToRows: item => item.map(({ name, pinName }) => ([name, pinName])),
                            name: formatMessage({ defaultMessage: 'Hygrometers', id: 'form.label.hygrometers' }),
                            onChange: rows => {
                                const { hygrometers, ...itemParams } = tank;
                                setModifiedItem(rows.length === 0 ? itemParams : {
                                    ...itemParams,
                                    hygrometers: rows.map(([name, pinName]) => ({ name, pinName })),
                                });
                            },
                        }} />
                        <InputItem {...{
                            errors: errors.heaterRelayChannel,
                            name: formatMessage({
                                defaultMessage: 'Heater relay channel', id: 'form.label.heater-relay-channel',
                            }),
                            onChange: value => {
                                const { heaterRelayChannel, ...itemParams } = tank;
                                const channel = value === '' ? null : `${value}`;
                                setModifiedItem(
                                    channel === null ? itemParams : { ...itemParams, heaterRelayChannel: channel },
                                );
                            },
                            options: getRelayChannelOptions(formatMessage),
                            value: tank.heaterRelayChannel,
                        }} />
                        <InputItem {...{
                            errors: errors.lightRelayChannel,
                            name: formatMessage({
                                defaultMessage: 'Light relay channel', id: 'form.label.light-relay-channel',
                            }),
                            onChange: value => {
                                const { lightRelayChannel, ...itemParams } = tank;
                                const channel = value === '' ? null : `${value}`;
                                setModifiedItem(
                                    channel === null ? itemParams : { ...itemParams, lightRelayChannel: channel },
                                );
                            },
                            options: getRelayChannelOptions(formatMessage),
                            value: tank.lightRelayChannel,
                        }} />
                    </>)(modifiedItem) :
                    null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setModalShown(false);
                }}><FormattedMessage id="modal.close" defaultMessage="Close" /></Button>
                <Button variant="primary" onClick={() => {
                    const errors = getErrors(modifiedItem, selectedRelay, isType, namesInUse, formatMessage);
                    setErrors(errors);
                    if (!hasErrors(errors)) {
                        onChange(modifiedItem, selectedRelay);
                        setModalShown(false);
                    }
                }} disabled={hasErrors(errors)}>
                    {item ?
                        <FormattedMessage id="form.change" defaultMessage="Change"/> :
                        <FormattedMessage id="form.save" defaultMessage="Save"/>}
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
};
