import React from 'react';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { getProductVariantName } from '../../../helpers/warehouse/names';
import { PATH as WAREHOUSE_PRODUCT_VARIANT_PATH } from '../../../routes/warehouse/ProductVariant';
import { ProductVariantModel } from '../../../types/warehouse/model';

interface ProductVariantProps {
    productVariant: ProductVariantModel;
    showProductDescription?: boolean;
}

export default ({ productVariant, showProductDescription }: ProductVariantProps) => <div className="toast show">
    <div className="toast-header">
        <strong className="mr-auto">
            <Link to={{
                pathname: getPath(WAREHOUSE_PRODUCT_VARIANT_PATH, { productVariantId: productVariant.id }),
                state: { productVariant }
            }}>{getProductVariantName(productVariant)}</Link>
        </strong>
    </div>
    {showProductDescription !== false && productVariant.product.description ?
        <div className="toast-body text-break">{productVariant.product.description}</div> : null}
</div>;
