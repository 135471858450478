import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getInventoryApiUrl } from '../../../helpers/url';
import { InventoryFetched } from '../../../types/inventories/model';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';
import Tooltip from '../../Tooltip/Tooltip';

interface InventorySyncModalProps {
    inventory: InventoryFetched;
    onClose?: () => void;
    onSync?: (inventory: InventoryFetched) => void;
    quantities: { [productId: string]: number };
}

export default ({ inventory, onClose, onSync, quantities }: InventorySyncModalProps) => {
    const [error, setError] = useState<unknown>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);
    const disabled = useMemo(() => Object.keys(quantities).length > 0, [quantities]);
    const tooltipRef = useRef<HTMLButtonElement>(null);
    const [tooltipShow, setTooltipShow] = useState(false);
    const url = useMemo(() => getInventoryApiUrl('inventories/:id/quantities', {
        params: { id: inventory.id },
    }), [inventory.id]);
    const onModalClose = useCallback(() => {
        setModalShown(false);
        if (onClose) {
            onClose();
        }
    }, [onClose, setModalShown]);

    return <>
        <button ref={tooltipRef} className={`btn btn-primary${disabled ? ' disabled' : ''}`} onClick={() => {
            if (disabled) {
                setTooltipShow(!tooltipShow);
            } else {
                setError(null);
                setShowError(true);
                setModalShown(true);
                setTooltipShow(false);
            }
        }}>
            <FormattedMessage id="inventories.button.sync" defaultMessage="Sync with provider" />
        </button>
        <Tooltip target={tooltipRef.current} show={disabled && tooltipShow} placement="bottom"
            onMouseOver={() => { setTooltipShow(false) }} variant="warning">
            <FormattedMessage id="inventories.tooltip.sync-disabled"
                defaultMessage="Save quantity changes and recalculate inventory before synchronization." />
        </Tooltip>
        <Modal show={modalShown} onHide={onModalClose} animation={false}
            className="InventorySyncModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="inventories.modal.sync" defaultMessage="Sync with provider" />
                </Modal.Title>
            </Modal.Header>
            {error && showError ?
                <Modal.Body>
                    <FormSubmitError error={error} setShowError={setShowError} showError={showError} />
                </Modal.Body> :
                null}
            <Modal.Footer>
                <Button variant="secondary" onClick={onModalClose}>
                    <FormattedMessage id="modal.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="primary" onClick={async () => {
                    try {
                        setError(null);
                        setShowError(true);
                        const syncedInventory = await authenticatedRequest<InventoryFetched>({
                            method: HttpMethod.PATCH, url,
                        });
                        if (onSync) {
                            onSync(syncedInventory);
                        }
                        onModalClose();
                    } catch (error) {
                        setError(error);
                    }
                }}>
                    <FormattedMessage id="modal.ok" defaultMessage="OK" />
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}
