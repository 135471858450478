import { Relay, sortByName } from '@majpage/raspi-tanks-logic';
import React, { useMemo, useState } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { changeRelay, deleteRelay } from '../../../helpers/home-automation/relays';
import { Control } from '../../../hooks/home-automation/use-device-controls';
import { RelayTankName } from '../../../types/home-automation/raspi-tanks';
import Message, { MessageType } from '../../Message/Message';
import RelayComponent from '../Relay/Relay';
import RelayForm from '../Relay/RelayForm';

interface RelaysProps {
    editable: boolean;
    loaded: boolean;
    relays: Control<Relay[]>;
}

export default ({ editable, loaded, relays }: RelaysProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const relaysList = useMemo(() => (relays.modifiedValue || relays.value || []).sort(sortByName), [relays]);
    const namesInUse = relaysList.map(relay => relay.name);
    const deviceIdsInUse = relaysList.map(relay => relay.deviceId);
    const allTankNames = relaysList.reduce<RelayTankName[]>((tanks, relay) => ([
        ...tanks, ...relay.tanks.map<RelayTankName>(tank => ({ id: tank.id, name: tank.name, relayName: relay.name })),
    ]), []);

    return <Card className="Relays">
        <Card.Header className={opened ? 'card-opened' : 'card-closed'} onClick={() => {
            setOpened(!opened);
        }}><FormattedMessage defaultMessage="Relays" id="table.name.relays" /></Card.Header>
        {opened ?
            <Card.Body>
                {!loaded ?
                    <Message type={MessageType.FETCHING} /> :
                    <>
                        <ListGroup>
                            {relaysList.map(relay => <RelayComponent relay={relay} onChange={newRelay => {
                                relays.change(changeRelay(relaysList, newRelay));
                            }} onDelete={oldRelay => {
                                relays.change(deleteRelay(relaysList, oldRelay));
                            }} namesInUse={namesInUse.filter(name => {
                                return name !== relay.name;
                            })} deviceIdsInUse={deviceIdsInUse.filter(deviceId => {
                                return deviceId !== relay.deviceId;
                            })} allTankNames={allTankNames} editable={editable} key={relay.id} />)}
                        </ListGroup>
                        {editable ?
                            <RelayForm renderItem={setModalShown => <Button variant="primary" size="sm" onClick={() => {
                                setModalShown(true);
                            }} className="mt-2" block>
                                    <FormattedMessage id="form.relay-add" defaultMessage="Add relay" />
                                </Button>} onChange={newRelay => {
                                relays.change(changeRelay(relaysList, newRelay));
                            }} namesInUse={namesInUse} deviceIdsInUse={deviceIdsInUse} allTankNames={allTankNames} /> :
                            null}
                    </>}
            </Card.Body> :
            null}
    </Card>;
};
