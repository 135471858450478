import { omit } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import SwitchItem from './SwitchItem';

interface SwitchInUseItemStatusProps<Item> {
    errors?: string[];
    id: string;
    item?: Item;
    modifiedItem: Item;
    setModifiedItem: (item: Item) => void;
}

export default function SwitchInUseItemStatus<Item extends { inUse?: boolean }>(
    { errors, id, item, modifiedItem, setModifiedItem }: SwitchInUseItemStatusProps<Item>,
) {
    const { formatMessage } = useIntl();
    return <SwitchItem {...{
        errors: errors,
        id,
        label: !item || !('inUse' in item) || item.inUse !== false ?
            formatMessage({ defaultMessage: 'switched on', id: 'item.switched-on' }) :
            formatMessage({ defaultMessage: 'switched off', id: 'item.switched-off' }),
        name: formatMessage({ defaultMessage: 'Status', id: 'form.label.status' }),
        onChange: inUse => { setModifiedItem(
            inUse === false ? { ...modifiedItem, inUse } : omit(modifiedItem, 'inUse') as Item,
        ) },
        value: !('inUse' in modifiedItem) || modifiedItem.inUse !== false,
    }} />
}
