import React from 'react';
import { HttpErrors } from '../../hooks/use-http-error';
import Message, { MessageType } from '../Message/Message';

interface ErrorMessageProps {
    error?: HttpErrors;
}

export default ({ error }: ErrorMessageProps) => {
    if (!error) {
        return null;
    }

    switch (error) {
        case HttpErrors.REQUEST_ERROR:
            return <Message type={MessageType.REQUEST_HTTP_ERROR} />;
        case HttpErrors.RESPONSE_ERROR:
            return <Message type={MessageType.RESPONSE_HTTP_ERROR} />;
        case HttpErrors.NOT_FOUND_ERROR:
            return <Message type={MessageType.NOT_FOUND_HTTP_ERROR} />;
        case HttpErrors.UNKNOWN_ERROR:
            return <Message type={MessageType.UNKNOWN_HTTP_ERROR} />;
        default:
            return null;
    }
};
