import { ProductModel, ProductUnitModel, ProductVariantModel } from '../../types/warehouse/model';

export function getProductName(product: ProductModel) {
    return `${product.name}${product.producer ? ` (${product.producer.name})` : ''}`;
}

export function getProductVariantName(productVariant: ProductVariantModel) {
    const { product } = productVariant;
    return `${product.name}${productVariant.name ? ` (${productVariant.name})` : ''}`;
}

export function getProductUnitName(productUnit: ProductUnitModel) {
    if (productUnit.name) {
        const { productVariant } = productUnit;
        const { product } = productVariant;
        return `${productUnit.name} (${product.name}${productVariant.name ? `, ${productVariant.name}` : ''})`;
    }
    return getProductVariantName(productUnit.productVariant);
}
