import { AnyAction } from 'redux';
import { USER_GONE_BACK, USER_LOGGED_IN, USER_LOGGED_OUT, USER_TOKEN_FETCHING } from '../actions/user';
import { getUserFromToken, User } from '../helpers/auth-request';

export interface UserState {
    loading: boolean;
    noAccess: boolean;
    user: User | null;
}

const user = getUserFromToken();
const INITIAL_STATE = { loading: false, noAccess: false, user };

export default function (state: UserState = INITIAL_STATE, action: AnyAction) {
    switch (action.type) {
        case USER_GONE_BACK:
            return { ...state, noAccess: false };
        case USER_LOGGED_IN:
            return { ...state, loading: false, noAccess: false, user: action.user };
        case USER_LOGGED_OUT:
            return { ...state, loading: false, noAccess: true, user: null };
        case USER_TOKEN_FETCHING:
            return { ...state, loading: true, noAccess: false };
        default:
            return state;
    }
}
