import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTagActualStates } from '../../../hooks/warehouse/use-resources';
import { ActualStateModel, TagModel } from '../../../types/warehouse/model';
import ListTemplate from '../../List/ListTemplate';
import ActualState, { ActualStateContext } from '../ActualState/ActualState';

interface TagActualStatesProps {
    tag: TagModel;
}

export default ({ tag }: TagActualStatesProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [items, { error, isLast, loading, total }] = useTagActualStates(tag.slug, page);

    useEffect(() => {
        setPage(0);
        setOpened(false);
    }, [tag.slug]);

    const { formatMessage } = useIntl();
    const title = formatMessage({ defaultMessage: 'Actual states list', id: 'actual-states.title' });
    const renderItem = (state: ActualStateModel) =>
        <ActualState key={state.id} context={ActualStateContext.TAG} state={state} />;
    const listProps = { error, isLast, items, loading, opened, page, renderItem, setOpened, setPage, title, total };

    return <ListTemplate {...listProps} />;
};
