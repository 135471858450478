export enum Capabilities {
    COMMENTS_ADD = 'comments_add',
    PRODUCERS_MANAGE = 'producers_manage',
    PRODUCTS_MANAGE = 'products_manage',
    RENTALS_MANAGE = 'rentals_manage',
    STATES_MANAGE = 'states_manage',
    STORAGES_MANAGE = 'storages_manage',
    TAGS_MANAGE = 'tags_manage',
    UNITS_MANAGE = 'units_manage',
    VARIANTS_MANAGE = 'variants_manage',
    WAREHOUSE_SHOW = 'warehouse_show',
}
