import './Tooltip.css';
import { Overlay } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import React, { HTMLAttributes } from 'react';

interface TooltipProps extends HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    placement?: Placement;
    show?: boolean;
    target?: React.ReactInstance | Node | ((props: object) => React.ReactInstance | Node) | null;
    variant?: string;
}

interface OverlayProps {
    arrowProps: { style?: React.CSSProperties };
    ref: React.RefObject<any>;
    style?: React.CSSProperties;
}

export default ({ children, placement, show, target, variant, ...props }: TooltipProps) => {
    if (!target) {
        return null;
    }

    const cssClasses = ['Tooltip', 'tooltip'];
    if (placement) {
        cssClasses.push(`bs-tooltip-${placement}`);
    }
    if (variant) {
        cssClasses.push(`tooltip-${variant}`);
    }

    return <Overlay target={target} show={!!show} placement={placement}>
        {({ arrowProps, ref, style }: OverlayProps) => (
            <div {...props} className={cssClasses.join(' ')} role="tooltip" ref={ref} style={style}>
                <div className="arrow" style={arrowProps.style} />
                <div className="tooltip-inner">
                    {children}
                </div>
            </div>
        )}
    </Overlay>;
};
