import { TankStatus } from '@majpage/raspi-tanks-logic';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface TankStatusProps {
    status: TankStatus;
}

export default ({ status }: TankStatusProps) => {
    switch (status) {
        case TankStatus.OK:
            return <Badge variant="success" pill>
                <FormattedMessage id="tanks.measurements.ok" defaultMessage="OK" />
            </Badge>;
        case TankStatus.NO_DATA:
            return <Badge variant="info" pill>
                <FormattedMessage id="tanks.measurements.no-data" defaultMessage="NO DATA" />
            </Badge>;
        case TankStatus.ON_THE_EDGE:
            return <Badge variant="warning" pill>
                <FormattedMessage id="tanks.measurements.on-the-edge" defaultMessage="ON THE EDGE" />
            </Badge>;
        case TankStatus.OUT_OF_SCALE:
            return <Badge variant="danger" pill>
                <FormattedMessage id="tanks.measurements.out-of-scale" defaultMessage="OUT OF SCALE" />
            </Badge>;
        default:
            return null;
    }
};
