import React from 'react';
import { RouteComponentProps } from '../../components/AuthenticatedRoute';
import Menu from '../../components/warehouse/Menu/Menu';
import Tag from '../../components/warehouse/Tag/Tag';
import { Section } from '../../types/section';
import { TagModel } from '../../types/warehouse/model';

export const PATH = `/${Section.WAREHOUSE}/tags/:tagSlug`;

export default ({ location, match }: RouteComponentProps<{ tagSlug: string }, { tag?: TagModel }>) =>
    <div className="WarehouseTagPage">
        <Menu />
        <Tag slug={match.params.tagSlug} tag={location.state ? location.state.tag : undefined} />
    </div>;
