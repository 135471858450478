import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { MainContext } from '../../components/Contexts';
import { IdCodeFamily, IdCodeFormat, IdCodeType } from '../../types/id-code';

export function useIdCodeFormatAndTypeLists(
    format: IdCodeFormat, setFormat: (format: IdCodeFormat) => void, type: IdCodeType,
    setType: (type: IdCodeType) => void, families?: IdCodeFamily[],
): [{ [value: string]: string }, { [value: string]: string }] {
    const { formatMessage } = useIntl();
    const { scanners } = useContext(MainContext);
    const isFamilyActive = useCallback(
        (family: IdCodeFamily) => scanners.includes(family) && (!families || families.includes(family)),
        [families, scanners],
    );

    const formatsList: { [value: string]: string } = {};
    if (isFamilyActive(IdCodeFamily.BARCODE)) {
        formatsList[IdCodeFormat.EAN_13] = formatMessage({
            id: 'id-code.format.ean-13', defaultMessage: 'EAN-13 barcode',
        });
        formatsList[IdCodeFormat.EAN_8] = formatMessage({
            id: 'id-code.format.ean-8', defaultMessage: 'EAN-8 barcode',
        });
    }
    if (isFamilyActive(IdCodeFamily.NFC_TAG)) {
        formatsList[IdCodeFormat.NFC_TAG] = formatMessage({
            id: 'id-code.format.nfc-tag', defaultMessage: 'NFC tag',
        });
    }
    if (isFamilyActive(IdCodeFamily.QR_CODE)) {
        formatsList[IdCodeFormat.QR_CODE] = formatMessage({
            id: 'id-code.format.qr-code', defaultMessage: 'QR code',
        });
    }
    if (isFamilyActive(IdCodeFamily.RFID_TAG)) {
        formatsList[IdCodeFormat.RFID_TAG] = formatMessage({
            id: 'id-code.format.rfid-tag', defaultMessage: 'RFID tag',
        });
    }

    const typesList: { [value: string]: string } = {};
    const formatValues = Object.keys(formatsList) as IdCodeFormat[];
    if (formatValues.length > 0) {
        if (!formatsList[format]) {
            setFormat(format = formatValues[0]);
        }
        switch (format) {
            case IdCodeFormat.EAN_13:
            case IdCodeFormat.EAN_8:
                typesList[IdCodeType.PRODUCT] = formatMessage({
                    id: 'id-code.type.product', defaultMessage: 'product number',
                });
                typesList[IdCodeType.ISBN] = formatMessage({
                    id: 'id-code.type.isbn', defaultMessage: 'ISBN number',
                });
                typesList[IdCodeType.PHONE] = formatMessage({
                    id: 'id-code.type.phone', defaultMessage: 'phone number',
                });
                break;
            case IdCodeFormat.NFC_TAG:
            case IdCodeFormat.RFID_TAG:
                typesList[IdCodeType.TEXT] = formatMessage({
                    id: 'id-code.type.text', defaultMessage: 'text',
                });
                break;
            case IdCodeFormat.QR_CODE:
                typesList[IdCodeType.TEXT] = formatMessage({
                    id: 'id-code.type.text', defaultMessage: 'text',
                });
                typesList[IdCodeType.URL] = formatMessage({
                    id: 'id-code.type.url', defaultMessage: 'URL address',
                });
                break;
        }
        const typeValues = Object.keys(typesList) as IdCodeType[];
        if (typeValues.length > 0 && !typesList[type]) {
            setType(typeValues[0]);
        }
    }

    return [formatsList, typesList];
}
