import React, { useEffect, useState } from 'react';
import { Badge, CardColumns, FormControl, InputGroup, Navbar } from 'react-bootstrap';
import { BoundingBoxCircles, GeoAltFill } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import io, { Socket } from 'socket.io-client';
import { CLIENT_JWT_STORAGE_NAME } from '../../../helpers/auth-request';
import { getFormattedDate, getFormattedTime } from '../../../helpers/time';
import {
    getCommandInfo, getCommandName, getCommandOnClick, isAreaFetchingCommandType,
} from '../../../helpers/tracking/commands';
import { getMessageName, getMessageOnClick } from '../../../helpers/tracking/messages';
import { getTrackingApiUrl, getTrackingSocketsUrl } from '../../../helpers/url';
import { useCommands, useLastPosition, useMessages, usePositions } from '../../../hooks/tracking/use-resources';
import { useTimeLimit } from '../../../hooks/tracking/use-time-limit';
import { Area, Command, Device, Highlight, Message, Position } from '../../../types/tracking/model';
import { Capabilities as TrackingCapabilities } from '../../../types/tracking/user';
import { useUserFromContext } from '../../Contexts';
import DateSelector from '../../DateSelector/DateSelector';
import ListTemplate from '../../List/ListTemplate';
import CommandAddForm from '../CommandAddForm/CommandAddForm';
import './DailyRoutes.css';
import DailyRoutesMap from './DailyRoutesMap';

type RenderFunction = (extraContent?: JSX.Element) => JSX.Element

interface DailyRoutesProps {
    device: Device;
    renderBefore?: RenderFunction;
}

export default ({ device, renderBefore }: DailyRoutesProps) => {
    const user = useUserFromContext();
    const currentDate = getFormattedDate(new Date());
    const [date, setDate] = useState<string>(() => currentDate);
    const changeDate = (date: string) => {
        setCommandsOpened(false);
        setCommandsPage(0);
        setMessagesOpened(false);
        setMessagesPage(0);
        setDate(date);
    };
    const isCurrentDate = date === currentDate;
    const timeFrom = new Date(date);
    timeFrom.setHours(0,0,0,0);
    const [timeLimit, setTimeLimit, time, timeEnd] = useTimeLimit(date);

    const [area, setArea] = useState<Area | null>(null);
    const [highlight, setHighlight] = useState<Highlight | null>(null);
    useEffect(() => {
        setArea(null);
        setHighlight(null);
        setTimeLimit(timeEnd);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    const { formatMessage } = useIntl();
    const [commandsOpened, setCommandsOpened] = useState<boolean>(false);
    const commandsTitle = formatMessage({ defaultMessage: 'Commands', id: 'daily-routes.commands' });
    const [commandsPage, setCommandsPage] = useState<number>(0);
    const [commands, { addItem: addCommand, ...commandsProps }] = useCommands(device, timeFrom, commandsPage);
    const [messagesOpened, setMessagesOpened] = useState<boolean>(false);
    const messagesTitle = formatMessage({ defaultMessage: 'Messages', id: 'daily-routes.messages' });
    const [messagesPage, setMessagesPage] = useState<number>(0);
    const [messages, { addItem: addMessage, ...messagesProps }] = useMessages(device, timeFrom, messagesPage);
    const [positions, { addItem: addPosition, ...positionsProps }] = usePositions(device, timeFrom);
    const currentDateEmpty = positionsProps.isLast && positions.length === 0;
    const [lastPosition] = useLastPosition(device, isCurrentDate && currentDateEmpty ? timeFrom : null);
    if (isCurrentDate && currentDateEmpty && lastPosition) {
        addPosition(lastPosition);
    }

    const [socket] = useState<Socket | null>(() => isCurrentDate ? io(getTrackingSocketsUrl(), {
        auth: { token: `Bearer ${sessionStorage.getItem(CLIENT_JWT_STORAGE_NAME)}` },
    }) : null);
    useEffect(
        onChange<Command>(device, isCurrentDate, socket, 'command', addCommand),
        [isCurrentDate, device.imei, commands]);
    useEffect(
        onChange<Message>(device, isCurrentDate, socket, 'message', addMessage),
        [isCurrentDate, device.imei, messages]);
    useEffect(
        onChange<Position>(device, isCurrentDate, socket, 'position', addPosition),
        [isCurrentDate, device.imei, positions],
    );

    const canSeeTracking = user.capabilities.includes(TrackingCapabilities.TRACKING_SHOW);
    const canManageTracking = user.capabilities.includes(TrackingCapabilities.TRACKING_MANAGE);

    return <>
        {renderBefore ?
            renderBefore(canManageTracking ?
                <CommandAddForm isShowed={isCurrentDate} area={area} setArea={setArea} onShowClick={() => {
                    changeDate(currentDate);
                }} url={getTrackingApiUrl('devices/:imei/commands', { params: { imei: device.imei } })} /> :
                undefined) :
            null}
        {canSeeTracking ?
            <div className="DailyRoutes">
                <Navbar bg="light" className="mb-3">
                    <Navbar.Collapse>
                        <div className="routes-title">
                            <FormattedMessage defaultMessage="Daily routes:" id="daily-routes.title" />
                        </div>
                        <DateSelector {...{ date, dateMin: device.dateStart, loading: false, setDate: changeDate }} />
                        <InputGroup className="mt-1">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FormattedMessage defaultMessage="Time:" id="daily-routes.time" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="range" min="0" max={`${timeEnd}`} onChange={(event: any) => {
                                setTimeLimit(parseInt(event.target.value, 10));
                            }} value={`${timeLimit}`} />
                        </InputGroup>
                    </Navbar.Collapse>
                </Navbar>
                <div style={{ position: 'relative' }}>
                    <DailyRoutesMap area={area} date={date} highlight={highlight} setHighlight={setHighlight}
                        positions={positions.filter(([date]) => date <= time.toISOString())} setArea={setArea}/>
                    <Badge variant="light" style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 500 }}>
                        {getFormattedTime(time, true)}
                    </Badge>
                </div>
                <CardColumns>
                    <ListTemplate {...{
                        error: commandsProps.error, isLast: commandsProps.isLast, items: commands,
                        loading: commandsProps.loading, opened: commandsOpened, page: commandsPage,
                        renderItem: (command: Command) => {
                            const onClick = getCommandOnClick(command, setHighlight);
                            return <div key={`${command.createdAt}:${command.type}`} className="toast show">
                                <div onClick={onClick} style={onClick ? { cursor: 'pointer' } : {}}
                                    className="toast-header">
                                    <strong className="mr-auto">
                                        {getCommandName(command)}
                                        {isAreaFetchingCommandType(command.type) ?
                                            <em className="ml-2"><BoundingBoxCircles /></em> :
                                            null}
                                        {getCommandInfo(command)}
                                    </strong>
                                    <small className="text-muted">
                                        {(new Date(command.createdAt)).toLocaleTimeString()}
                                    </small>
                                </div>
                            </div>;
                        }, setOpened: setCommandsOpened, setPage: setCommandsPage, title: commandsTitle,
                        total: commandsProps.total,
                    }} />
                    <ListTemplate {...{
                        error: messagesProps.error, isLast: messagesProps.isLast, items: messages,
                        loading: messagesProps.loading, opened: messagesOpened, page: messagesPage,
                        renderItem: (message: Message) => {
                            const onClick = getMessageOnClick(message, setHighlight);
                            return <div key={`${message.createdAt}:${message.type}`} className="toast show">
                                <div onClick={onClick} style={onClick ? { cursor: 'pointer' } : {}}
                                    className="toast-header">
                                    <strong className="mr-auto">
                                        {getMessageName(message)}
                                        {(message.cell && message.cell.coords) || message.coords ?
                                            <em className="ml-2"><GeoAltFill /></em> :
                                            null}
                                        {message.time ?
                                            <em className="ml-2">{(new Date(message.time)).toLocaleTimeString()}</em> :
                                            null}
                                    </strong>
                                    <small className="text-muted">
                                        {(new Date(message.createdAt)).toLocaleTimeString()}
                                    </small>
                                </div>
                            </div>;
                        }, setOpened: setMessagesOpened, setPage: setMessagesPage, title: messagesTitle,
                        total: messagesProps.total,
                    }} />
                </CardColumns>
            </div> :
            null}
    </>;
}

function onChange<U>(
    device: Device, isCurrentDate: boolean, socket: Socket | null, event: string, addItem: (item: U) => void,
) {
    return () => {
        if (!socket || !isCurrentDate) {
            return;
        }
        socket.on(event, (imei: string, item: U) => {
            if (imei === device.imei) {
                addItem(item);
            }
        });
        return () => {
            socket.off(event);
        };
    };
}
