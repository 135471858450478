import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Control } from '../../../hooks/home-automation/use-device-controls';
import MetadataSwitch from './MetadataSwitch';

interface MetadataDoorSignalSwitchProps {
    doorSignal: Control<{ melody: string; on: boolean; rate: number; volume: number }>;
    id: string;
}

export default ({ doorSignal, id }: MetadataDoorSignalSwitchProps) => {
    const { formatMessage } = useIntl();
    const [value, setValue] = useState<{ melody: string; on: boolean; rate: number; volume: number }>(doorSignal.value);

    useEffect(() => {
        if (!isEqual(value, doorSignal.value)) {
            setValue(doorSignal.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doorSignal.value]);

    const melodyMessage = formatMessage({ defaultMessage: 'Melody (RTTTL format)', id: 'table.label.melody-rtttl' });
    return <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{melodyMessage}</Tooltip>}>
            <FormControl type="text" value={value.melody} disabled={value.on} onChange={(event: any) => {
                setValue({ ...value, melody: event.target.value as string });
            }} placeholder={melodyMessage} />
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{formatMessage({
            defaultMessage: 'Volume:', id: 'table.label.volume',
        })} ({value.volume}%)</Tooltip>}>
            <FormControl type="range" value={`${value.volume}`} disabled={value.on} onChange={(event: any) => {
                setValue({ ...value, volume: parseInt(event.target.value, 10) });
            }} min={0} max={100} />
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{formatMessage({
            defaultMessage: 'Rate:', id: 'table.label.rate',
        })} (×{value.rate})</Tooltip>}>
            <FormControl type="range" value={`${value.rate}`} disabled={value.on} onChange={(event: any) => {
                setValue({ ...value, rate: parseFloat(event.target.value) });
            }} min={0} max={10} step={0.1} />
        </OverlayTrigger>
        <MetadataSwitch id={id} onChange={on => {
            setValue({ ...value, on });
            doorSignal.change({ ...value, on });
        }} switched={value.on} synced={doorSignal.match}/>
    </>;
};
