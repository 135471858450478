import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { getInventoryApiUrl } from '../../../helpers/url';
import { useResourcesList } from '../../../hooks/use-resources-list';
import { InventoryFetched as InventoryFetchedModel } from '../../../types/inventories/model';
import ListTemplate from '../../List/ListTemplate';
import './Inventories.css';
import InventoryFetched from './InventoryFetched';

interface InventoriesFetchedProps {
    page: number;
    setPage: (page: number) => void;
}

export default ({ page, setPage }: InventoriesFetchedProps) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'inventories.fetched.title', defaultMessage: 'Fetched inventories' });
    const url = useMemo(() => getInventoryApiUrl('inventories'), []);
    const [opened, setOpened] = useState<boolean>(() => page > 0);
    const [items, { editItem, error, isLast, loading, total }] = useResourcesList(url, page);

    useEffect(() => {
        if (opened && page === 0) {
            setOpened(false);
        }
    }, [opened, page]);

    const renderItem = (inventory: InventoryFetchedModel, index: number) =>
        <InventoryFetched key={inventory.id} inventory={inventory} onDelete={() => { setPage(0) }}
            onFetch={item => { editItem(item, index) }} />;
    const listProps = {
        error, isLast, items, loading, opened, page, renderItem, setOpened, setPage, title, total,
    };

    return <ListTemplate {...listProps} />;
}
