import React from 'react';
import { CommentModel } from '../../../types/warehouse/model';

interface CommentProps {
    comment: CommentModel;
}

export default ({ comment }: CommentProps) => <div className="toast show">
    <div className="toast-header">
        <strong className="mr-auto">{comment.userName}</strong>
        {comment.mark ? <span className="badge badge-warning">{comment.mark}</span> : null}
        <small className="text-muted">{(new Date(comment.createdAt)).toLocaleDateString()}</small>
    </div>
    {comment.content ? <div className="toast-body text-break">{comment.content}</div> : null}
</div>;
