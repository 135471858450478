import 'bootstrap/dist/css/bootstrap.min.css';
import { parse } from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore } from 'redux'
import { MainContext } from './components/Contexts';
import { AUTH_TOKEN_STORAGE_NAME } from './helpers/auth-request';
import { getMainContext } from './helpers/main-context';
import { reducers } from './reducers';
import routes from './routes';
import messagesEn from './translations/en.json';
import messagesPl from './translations/pl.json';

const messages: { [language: string]: { [key: string]: string } } = {
    en: messagesEn,
    pl: messagesPl,
};
const language = navigator.language.split(/[-_]/)[0];

// @FIXME: Remove it after some time.
const SOURCE_STORAGE_NAME = 'source';
if (sessionStorage.getItem(SOURCE_STORAGE_NAME)) {
    sessionStorage.removeItem(SOURCE_STORAGE_NAME);
}

const queryParams = parse(window.location.search);
const normalizedToken = (Array.isArray(queryParams.token) ? queryParams.token[0] : queryParams.token) || null;
if (normalizedToken) {
    sessionStorage.setItem(AUTH_TOKEN_STORAGE_NAME, normalizedToken);
}

ReactDOM.render(
    <MainContext.Provider value={getMainContext(queryParams, sessionStorage)}>
        <ReduxProvider store={createStore(reducers)}>
            <IntlProvider locale={language} messages={messages[language]}>
                <Router>
                    {routes}
                </Router>
            </IntlProvider>
        </ReduxProvider>
    </MainContext.Provider>,
    document.getElementById('root'),
);
