import React, { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { uniqueArraysMatch } from '../../../helpers/array';
import { PATH } from '../../../routes/warehouse/Search';
import SearchList, { CONTEXTS, SearchContext } from '../SearchList/SearchList';

interface SearchProps {
    contextsFromRoute?: SearchContext[];
    redirectTo?: string;
    searchFromRoute?: string;
}

interface SearchState {
    contexts: SearchContext[];
    query: string;
}

interface SearchStates {
    accepted: SearchState;
    current: SearchState;
}

export default ({ contextsFromRoute, redirectTo, searchFromRoute }: SearchProps) => {
    const history = useHistory();
    const [{ accepted, current }, setSearchStates] = useState<SearchStates>({
        accepted: { contexts: [], query: '' },
        current: { contexts: [...CONTEXTS], query: '' },
    });
    const [contextTouched, setContextTouched] = useState<boolean>(!!contextsFromRoute && contextsFromRoute.length > 0);
    const isCurrentReady = current.contexts.length > 0 && current.query !== '';
    const searchLinkRef = React.createRef<Link>();
    const intl = useIntl();
    const { formatMessage } = intl;

    useEffect(() => {
        if (contextsFromRoute && contextsFromRoute.length > 0 && searchFromRoute) {
            const stateFromRoute = { contexts: contextsFromRoute, query: searchFromRoute };
            setSearchStates({ accepted: stateFromRoute, current: stateFromRoute });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextsFromRoute, searchFromRoute]);

    function setAcceptedIfReady() {
        if (isCurrentReady) {
            if (!uniqueArraysMatch(accepted.contexts, current.contexts) || accepted.query !== current.query) {
                history.push({ pathname: PATH, state: current });
            }
            setSearchStates({ accepted: current, current });
        }
    }

    return <div>
        <InputGroup size="lg" className="mb-2">
            <FormControl value={current.query} onChange={(event: any) => {
                setSearchStates({ accepted, current: { ...current, query: event.target.value } });
            }} onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key !== 'Enter') {
                    return;
                }
                if (redirectTo) {
                    const link = searchLinkRef.current as any;
                    link.click();
                } else {
                    setAcceptedIfReady();
                }
            }} placeholder={formatMessage({ defaultMessage: 'Enter text to search', id: 'search.placeholder' })} />
            <InputGroup.Append>
                {redirectTo && isCurrentReady ?
                    <Link ref={searchLinkRef} className="btn btn-primary" to={{
                        pathname: redirectTo,
                        state: { contexts: current.contexts, query: current.query }
                    }}><FormattedMessage id="search.button" defaultMessage="Search" /></Link> :
                    <Button variant="primary" disabled={!isCurrentReady} onClick={setAcceptedIfReady}>
                        <FormattedMessage id="search.button" defaultMessage="Search" />
                    </Button>}
            </InputGroup.Append>
        </InputGroup>
        <p>
            <Button variant="secondary" size="sm" className="mt-1" disabled>
                <FormattedMessage id="search.context.title" defaultMessage="Search in:" />
            </Button>
            {CONTEXTS.map(context => <Button key={context} size="sm" className="mt-1 ml-1" onClick={() => {
                if (contextTouched) {
                    setSearchStates({
                        accepted,
                        current: {
                            ...current,
                            contexts: current.contexts.includes(context) ?
                                current.contexts.filter(currentContext => currentContext !== context) :
                                [...current.contexts, context],
                        },
                    });
                } else {
                    setSearchStates({ accepted, current: { ...current, contexts: [context] } });
                    setContextTouched(true);
                }
            }} variant={current.contexts.includes(context) ? 'secondary' : 'outline-secondary'}>
                {getContextName(context, intl)}
            </Button>)}
        </p>
        {redirectTo ?
            null :
            accepted.contexts.map(context => <SearchList key={context} context={context} search={accepted.query} />)}
    </div>;
}

function getContextName(context: SearchContext, { formatMessage }: IntlShape): string {
    switch (context) {
        case SearchContext.PRODUCER:
            return formatMessage({ defaultMessage: 'producers', id: 'search.context.producer' });
        case SearchContext.PRODUCT:
            return formatMessage({ defaultMessage: 'products', id: 'search.context.product' });
        case SearchContext.PRODUCT_UNIT:
            return formatMessage({ defaultMessage: 'product units', id: 'search.context.product-unit' });
        case SearchContext.PRODUCT_VARIANT:
            return formatMessage({ defaultMessage: 'product variants', id: 'search.context.product-variant' });
        case SearchContext.STORAGE:
            return formatMessage({ defaultMessage: 'storages', id: 'search.context.storage' });
        case SearchContext.TAG:
            return formatMessage({ defaultMessage: 'tags', id: 'search.context.tag' });
        default:
            return '';
    }
}
