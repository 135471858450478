import React, { useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { InventoryAction } from '../../../types/inventories/model';
import { getActionMessage } from '../../../helpers/inventories/actions';
import './InventoryActions.css';

interface InventoryActionsProps {
    actions: InventoryAction[];
}

export default ({ actions }: InventoryActionsProps) => {
    const { formatMessage } = useIntl();
    const [opened, setOpened] = useState<boolean>(false);

    return <Card className="InventoryActions">
        <Card.Header className={opened ? 'card-opened' : 'card-closed'} onClick={() => {
            if (opened || actions.length > 0) {
                setOpened(!opened);
            }
        }}>
            <FormattedMessage id="inventories.actions-performed" defaultMessage="Actions performed" />
            <Badge pill variant="info" className="float-right mt-1 mr-4">{actions.length}</Badge>
        </Card.Header>
        {opened ?
            <Card.Body>
                {actions
                    .map(action => ({
                        id: action.time.getTime(),
                        message: getActionMessage(action, formatMessage),
                        time: action.time.toISOString().substring(11, 19),
                    }))
                    .filter(({ message }) => message !== null)
                    .reverse()
                    .map(({ id, message, time }) => <div key={id} className="toast show">
                        <div className="toast-header">{time} - {message}</div>
                    </div>)}
            </Card.Body> :
            null}
    </Card>;
};
