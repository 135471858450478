import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useProductUnitRentals } from '../../../hooks/warehouse/use-resources';
import { ProductUnitRentalModel } from '../../../types/warehouse/model';
import { Capabilities } from '../../../types/warehouse/user';
import { useUserFromContext } from '../../Contexts';
import ListTemplate from '../../List/ListTemplate';
import ProductUnitRental from './ProductUnitRental';
import ProductUnitRentalAddForm from './ProductUnitRentalAddForm';

interface ProductUnitRentalsProps {
    id: number;
}

export default ({ id }: ProductUnitRentalsProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [items, { addItem, editItem, error, isLast, loading, total }] = useProductUnitRentals(id, page);
    const user = useUserFromContext();
    const canManageRentals = user.capabilities.includes(Capabilities.RENTALS_MANAGE);

    const { formatMessage } = useIntl();
    const title = formatMessage({ defaultMessage: 'Product unit rentals', id: 'product-unit-rentals.title' });
    const listPrefix = canManageRentals && !items.find(item => item.endDate === null) ?
        <ProductUnitRentalAddForm id={id} onAdd={productUnitRental => { addItem(productUnitRental) }} /> :
        null;
    const renderItem = (productUnitRental: ProductUnitRentalModel, index: number) =>
        <ProductUnitRental key={productUnitRental.id} onEdit={item => editItem(item, index)}
            productUnitRental={productUnitRental} canManageRentals={canManageRentals} />;
    const listProps = {
        error, isLast, items, listPrefix, loading, opened, page, renderItem, setOpened, setPage, title, total,
    };

    return <ListTemplate {...listProps} />;
};
