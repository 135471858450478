import { Form, FormikProps, withFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PencilFill } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getFormattedDate } from '../../../helpers/time';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { ProductUnitRentalModel } from '../../../types/warehouse/model';
import CheckBoxField from '../../FormField/CheckBoxField';
import TextAreaField from '../../FormField/TextAreaField';
import TextInputField from '../../FormField/TextInputField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';

interface FormValues {
    comments: string;
    isEnd: boolean;
    expectedEndDate: string;
}

const Schema = Yup.object().shape({
    comments: Yup.string(),
    isEnd: Yup.boolean(),
    expectedEndDate: Yup.date(),
});

interface PureFormProps {
    error: string | null;
    onHide: () => void;
    productUnitRental: ProductUnitRentalModel;
    setModalShown: (modalShown: boolean) => void;
    setShowError: (showError: boolean) => void;
    showError: boolean;
}

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();

    return <Form>
        <Modal.Body>
            <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
            <TextAreaField fieldId="comments" touched={touched.comments} error={errors.comments}
                name={formatMessage({ defaultMessage: 'Comments', id: 'form.label.comments' })} />
            <TextInputField fieldId="expectedEndDate" touched={touched.expectedEndDate} error={errors.expectedEndDate}
                name={formatMessage({ defaultMessage: 'Expected end date', id: 'form.label.expected-end-date' })}
                type="date" min={getFormattedDate(new Date(props.productUnitRental.startDate))} />
            <CheckBoxField fieldId="isEnd" touched={touched.isEnd} error={errors.isEnd}
                name={formatMessage({ defaultMessage: 'Is rental end', id: 'form.label.is-rental-end' })} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
                <FormattedMessage id="modal.close" defaultMessage="Close" />
            </Button>
            <Button variant="primary" type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
                <FormattedMessage id="form.save" defaultMessage="Save" />
            </Button>
        </Modal.Footer>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    onEdit: (productUnitRental: ProductUnitRentalModel) => void;
    setError: (text: string | null) => void;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);
            const productUnitRental = await authenticatedRequest<ProductUnitRentalModel>({
                data: {
                    comments: values.comments || null,
                    expectedEndDate: values.expectedEndDate ? getFormattedDate(new Date(values.expectedEndDate)) : null,
                    isEnd: values.isEnd || false,
                },
                method: HttpMethod.PATCH,
                url: getWarehouseApiUrl('rentals/:productUnitRentalId', { params: {
                    productUnitRentalId: props.productUnitRental.id,
                } }),
            });
            resetForm();
            props.onEdit(productUnitRental);
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: ({ productUnitRental }) => ({
        comments: productUnitRental.comments || '',
        expectedEndDate: productUnitRental.expectedEndDate ?
            getFormattedDate(new Date(productUnitRental.expectedEndDate)) : '',
        isEnd: false,

    }),
    validationSchema: Schema,
})(PureForm);

interface ProductUnitRentalAddFormProps {
    onEdit: (productUnitRental: ProductUnitRentalModel) => void;
    productUnitRental: ProductUnitRentalModel;
}

export default ({ onEdit, productUnitRental }: ProductUnitRentalAddFormProps) => {
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(true);
    const [modalShown, setModalShown] = useState<boolean>(false);

    const onHide = () => {
        setModalShown(false);
    };
    const stateProps = {
        error, onHide, productUnitRental, setError, setModalShown, setShowError, showError,
    };

    return <>
        <Button size="sm" onClick={() => {
            setError(null);
            setShowError(true);
            setModalShown(true);
        }} className="ml-1" variant="light">
            <PencilFill>
                <FormattedMessage id="product-unit-rentals.button.edit" defaultMessage="Edit product unit rental" />
            </PencilFill>
        </Button>
        <Modal show={modalShown} onHide={onHide} animation={false} className="ProductUnitRentalModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="product-unit-rentals.modal.edit" defaultMessage="Edit product unit rental" />
                </Modal.Title>
            </Modal.Header>
            <FormikForm onEdit={productUnitRental => {
                onHide();
                onEdit(productUnitRental);
            }} {...stateProps} />
        </Modal>
    </>;
}
