import React from 'react';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { PATH as WAREHOUSE_PRODUCT_PATH } from '../../../routes/warehouse/Product';
import { ProductModel } from '../../../types/warehouse/model';

interface ProductProps {
    product: ProductModel;
}

export default ({ product }: ProductProps) => <div className="toast show">
    <div className="toast-header">
        <strong className="mr-auto">
            <Link to={{
                pathname: getPath(WAREHOUSE_PRODUCT_PATH, { productId: product.id }),
                state: { product }
            }}>{product.name}</Link>
        </strong>
        {product.mark ? <span className="badge badge-warning">{product.mark}</span> : null}
    </div>
    {product.description ? <div className="toast-body text-break">{product.description}</div> : null}
</div>;
