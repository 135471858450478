import React, { createRef } from 'react';
import { Alert, Card, CardColumns, ListGroup, Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { useResource } from '../../../hooks/use-resource';
import { PATH as WAREHOUSE_STORAGE_ADD_PATH } from '../../../routes/warehouse/StorageAdd';
import { PATH as WAREHOUSE_PRODUCT_UNIT_PATH } from '../../../routes/warehouse/ProductUnit';
import { PATH as WAREHOUSE_PRODUCT_VARIANT_PATH } from '../../../routes/warehouse/ProductVariant';
import { PATH as WAREHOUSE_STORAGE_PATH } from '../../../routes/warehouse/Storage';
import { IdCode, IdCodeFormat } from '../../../types/id-code';
import { FormatMessage } from '../../../types/react-intl';
import { ProductUnitModel, ProductVariantModel, StorageModel } from '../../../types/warehouse/model';
import { Capabilities } from '../../../types/warehouse/user';
import { useUserFromContext } from '../../Contexts';
import Message, { MessageType } from '../../Message/Message';
import ProductVariantAddForm from '../ProductVariantAddForm/ProductVariantAddForm';
import StorageAddForm from '../StorageAddForm/StorageAddForm';

interface ResultsProps {
    idCode: IdCode;
}

function isEanCode(format: IdCodeFormat) {
    return [IdCodeFormat.EAN_13, IdCodeFormat.EAN_8].includes(format);
}

function getTypeProps([type, result]: [string, { id: number } | null], formatMessage: FormatMessage) {
    if (!result) {
        return null;
    }
    switch (type) {
        case 'productUnit':
            return {
                name: formatMessage({ defaultMessage: 'Product unit', id: 'results.product-unit-type' }),
                path: getPath(WAREHOUSE_PRODUCT_UNIT_PATH, { productUnitId: result.id }),
                type,
            };
        case 'productVariant':
            return {
                name: formatMessage({ defaultMessage: 'Product variant', id: 'results.product-variant-type' }),
                path: getPath(WAREHOUSE_PRODUCT_VARIANT_PATH, { productVariantId: result.id }),
                type,
            };
        case 'storage':
            return {
                name: formatMessage({ defaultMessage: 'Storage', id: 'results.storage-type' }),
                path: getPath(WAREHOUSE_STORAGE_PATH, { storageId: result.id }),
                type,
            };
        default:
            return null;
    }
}

export default ({ idCode }: ResultsProps) => {
    const user = useUserFromContext();
    const [results, { error, loading }] = useResource<{
        productUnit: ProductUnitModel | null;
        productVariant: ProductVariantModel | null;
        storage: StorageModel | null;
    }>(getWarehouseApiUrl(
        'codes/:family/:value', { params: { family: idCode.family, value: idCode.value } },
    ));
    const { formatMessage } = useIntl();
    const ref = createRef<HTMLHeadingElement>();

    if (!results) {
        // @FIXME: Error is mentioned here only because there could be a situation when results are not there yet but
        //         there is no loading and no error as well - it should be checked and improved inside useResource hook.
        if (loading || !error) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />
            </Alert>;
        }
    }

    const positiveResults = Object.entries(results).filter(([, result]) => !!result);

    if (positiveResults.length === 0) {
        const tabs = [];
        if (user.capabilities.includes(Capabilities.VARIANTS_MANAGE) && isEanCode(idCode.format)) {
            tabs.push(<Tab key="product-variant-tab" eventKey="product-variant-tab" title={
                formatMessage({ defaultMessage: 'Add product variant', id: 'results.product-variant-add' })
            }>
                <ProductVariantAddForm eanCode={idCode.value} />
            </Tab>);
        }
        if (user.capabilities.includes(Capabilities.STORAGES_MANAGE)) {
            tabs.push(<Tab key="storage-tab" eventKey="storage-tab" title={
                formatMessage({ defaultMessage: 'Add storage', id: 'results.storage-add' })
            }>
                <StorageAddForm baseUrl={WAREHOUSE_STORAGE_ADD_PATH} idCode={idCode} />
            </Tab>);
        }

        if (tabs.length > 0) {
            setTimeout(() => {
                if (ref.current) {
                    ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
            }, 100);
        }

        return <>
            <Alert variant="danger">
                <FormattedMessage id="results.no-results-by-id" values={{ format: idCode.format, value: idCode.value }}
                    defaultMessage="No results for {format} with value {value}" />
            </Alert>
            {tabs.length > 0 ?
                <>
                    <h1 ref={ref}><FormattedMessage id="results.resource-add" defaultMessage="Add resource" /></h1>
                    <Tabs id="resource-add-tab" className="mb-3">{tabs}</Tabs>
                </> :
                null}
        </>;
    }

    if (positiveResults.length === 1) {
        const typeProps = getTypeProps(positiveResults[0], formatMessage);
        if (typeProps) {
            return <Redirect to={typeProps.path} />;
        }
        return <Alert variant="danger">
            <FormattedMessage id="results.unknown-result-type" defaultMessage="Unknown result type" />
        </Alert>;
    }

    return <>
        <Alert variant="danger">
            <FormattedMessage id="results.many-results" values={{ value: idCode.value }}
                defaultMessage="Many resource types found for the given value {value}. Select one of it." />
        </Alert>
        <CardColumns className="Results">
            <Card>
                <ListGroup variant="flush">
                    {positiveResults.map(result => getTypeProps(result, formatMessage)).map(typeProps => {
                        if (!typeProps) {
                            return null;
                        }
                        return <ListGroup.Item key={typeProps.type}>
                            <Link to={typeProps.path}>{typeProps.name}</Link>
                        </ListGroup.Item>;
                    })}
                </ListGroup>
            </Card>
        </CardColumns>
    </>;
};
