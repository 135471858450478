import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useProductVariantActualStates } from '../../../hooks/warehouse/use-resources';
import { ActualStateModel, ProductVariantModel } from '../../../types/warehouse/model';
import { Capabilities } from '../../../types/warehouse/user';
import { useIdCodeFromContext, useUserFromContext } from '../../Contexts';
import ListTemplate from '../../List/ListTemplate';
import ActualState, { ActualStateContext } from '../ActualState/ActualState';
import ActualStateAddForm, { ID_CODE_FORM } from '../ActualStateAddForm/ActualStateAddForm';

interface ProductVariantActualStatesProps {
    productVariant: ProductVariantModel;
}

export default ({ productVariant }: ProductVariantActualStatesProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const idCode = useIdCodeFromContext(data => data.form === ID_CODE_FORM);
    const [items, { addItem, error, isLast, loading, total }] = useProductVariantActualStates(productVariant.id, page);
    const user = useUserFromContext();
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (idCode) {
            setOpened(true);
            setPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(idCode)]);

    const title = formatMessage({ defaultMessage: 'Actual states list', id: 'actual-states.title' });
    const listPrefix = user.capabilities.includes(Capabilities.STATES_MANAGE) ?
        <ActualStateAddForm productVariant={productVariant} idCode={idCode} onAdd={addItem} /> : null;
    const renderItem = (state: ActualStateModel) =>
        <ActualState key={state.id} context={ActualStateContext.PRODUCT_VARIANT}
            state={{ ...state, productVariant }} />;
    const listProps = {
        error, isLast, items, listPrefix, loading, opened, page, renderItem, setOpened, setPage, title, total,
    };

    return <ListTemplate {...listProps} />;
};
