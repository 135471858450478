import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { InventoryProduct } from '../../../types/inventories/model';
import FormatAmount from '../../Format/FormatAmount';
import './Inventory.css';

interface InventoryProductsListProps {
    filter: (product: InventoryProduct) => boolean;
    products: InventoryProduct[];
    quantities: { [productId: string]: number };
    setSearch: (product: InventoryProduct) => void;
    setThisProduct: (product: InventoryProduct) => void;
    thisProduct: InventoryProduct | false | null;
    title: string;
}

export default ({
    filter, products, quantities, setSearch, setThisProduct, thisProduct, title,
}: InventoryProductsListProps) => {
    const { formatMessage } = useIntl();
    const filteredProducts = useMemo(() => products.filter(filter), [filter, products]);
    const unitDefault = useMemo(
        () => formatMessage({ defaultMessage: 'pcs', id: 'form.default.unit' }), [formatMessage],
    );
    if (filteredProducts.length === 0) {
        return null;
    }

    return <Card>
        <Card.Header>{title}</Card.Header>
        <Card.Body>
            {filteredProducts.map(product => <div key={product.id} className={
                `toast show${thisProduct && thisProduct.id === product.id ? ' bg-warning' : ''}`
            } onClick={() => {
                setSearch(product);
                setThisProduct(product);
            }}>
                <div className="toast-header">
                    <span className="mr-auto">
                        {product.barcode ? `${product.barcode}: ` : ''}
                        <strong>{product.name}</strong>
                    </span>
                    {product.id in quantities || product.quantityNew !== 0 ?
                        <small className="text-muted">
                            <FormatAmount value={quantities[product.id] || product.quantityNew}
                                unit={product.unit || unitDefault} />
                        </small> :
                        null}
                </div>
            </div>)}
        </Card.Body>
    </Card>;
};
