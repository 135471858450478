import React from 'react';
import { Switch } from 'react-router-dom';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import { Capabilities as HomeAutomationCapabilities } from '../types/home-automation/user';
import { Capabilities as InventoriesCapabilities } from '../types/inventories/user';
import { Capabilities as PriceTagsCapabilities } from '../types/price-tags/user';
import { Capabilities as TrackingCapabilities } from '../types/tracking/user';
import { Capabilities as WarehouseCapabilities } from '../types/warehouse/user';
import Main, { PATH as MAIN_PATH } from './Main';
import NotFound from './NotFound';
import HomeAutomationMain, { PATH as HOME_AUTOMATION_MAIN_PATH } from './home-automation/Main';
import HomeAutomationDevice, { PATH as HOME_AUTOMATION_DEVICE_PATH } from './home-automation/Device';
import InventoriesMain, { PATH as INVENTORIES_MAIN_PATH } from './inventories/Main';
import InventoriesInventory, { PATH as INVENTORIES_INVENTORY_PATH } from './inventories/Inventory';
import PriceTagsMain, { PATH as PRICE_TAGS_MAIN_PATH } from './price-tags/Main';
import TrackingMain, { PATH as TRACKING_MAIN_PATH } from './tracking/Main';
import TrackingDevice, { PATH as TRACKING_DEVICE_PATH } from './tracking/Device';
import WarehouseMain, { PATH as WAREHOUSE_MAIN_PATH } from './warehouse/Main';
import WarehouseProducer, { PATH as WAREHOUSE_PRODUCER_PATH } from './warehouse/Producer';
import WarehouseProduct, { PATH as WAREHOUSE_PRODUCT_PATH } from './warehouse/Product';
import WarehouseProductUnit, { PATH as WAREHOUSE_PRODUCT_UNIT_PATH } from './warehouse/ProductUnit';
import WarehouseProductVariant, { PATH as WAREHOUSE_PRODUCT_VARIANT_PATH } from './warehouse/ProductVariant';
import WarehouseRentals, { PATH as WAREHOUSE_RENTALS_PATH } from './warehouse/Rentals';
import WarehouseResults, { PATH as WAREHOUSE_RESULTS_PATH } from './warehouse/Results';
import WarehouseSearch, { PATH as WAREHOUSE_SEARCH_PATH } from './warehouse/Search';
import WarehouseStorage, { PATH as WAREHOUSE_STORAGE_PATH } from './warehouse/Storage';
import WarehouseStorageAdd, { PATH as WAREHOUSE_STORAGE_ADD_PATH } from './warehouse/StorageAdd';
import WarehouseTag, { PATH as WAREHOUSE_TAG_PATH } from './warehouse/Tag';

// @TODO: Put section endpoints to separate files and don't even attach it if user doesn't have proper capability.
const homeAutomationProps = { capability: HomeAutomationCapabilities.AUTOMATION_SHOW, exact: true };
const inventoriesProps = { capability: InventoriesCapabilities.INVENTORY_SHOW, exact: true };
const priceTagsProps = { capability: PriceTagsCapabilities.PRICE_TAGS_SHOW, exact: true };
const trackingProps = { capability: TrackingCapabilities.TRACKING_DEVICE_SHOW, exact: true };
const warehouseProps = { capability: WarehouseCapabilities.WAREHOUSE_SHOW, exact: true };

export default <Switch>
    <AuthenticatedRoute path={MAIN_PATH} exact component={Main} />

    <AuthenticatedRoute path={HOME_AUTOMATION_MAIN_PATH} component={HomeAutomationMain} {...homeAutomationProps} />
    <AuthenticatedRoute path={HOME_AUTOMATION_DEVICE_PATH} component={HomeAutomationDevice} {...homeAutomationProps} />

    <AuthenticatedRoute path={INVENTORIES_MAIN_PATH} component={InventoriesMain} {...inventoriesProps} />
    <AuthenticatedRoute path={INVENTORIES_INVENTORY_PATH} component={InventoriesInventory} {...inventoriesProps} />

    <AuthenticatedRoute path={PRICE_TAGS_MAIN_PATH} component={PriceTagsMain} {...priceTagsProps} />

    <AuthenticatedRoute path={TRACKING_MAIN_PATH} component={TrackingMain} {...trackingProps} />
    <AuthenticatedRoute path={TRACKING_DEVICE_PATH} component={TrackingDevice} {...trackingProps} />

    <AuthenticatedRoute path={WAREHOUSE_MAIN_PATH} component={WarehouseMain} {...warehouseProps} />
    <AuthenticatedRoute path={WAREHOUSE_PRODUCER_PATH} component={WarehouseProducer} {...warehouseProps} />
    <AuthenticatedRoute path={WAREHOUSE_PRODUCT_PATH} component={WarehouseProduct} {...warehouseProps} />
    <AuthenticatedRoute path={WAREHOUSE_PRODUCT_UNIT_PATH} component={WarehouseProductUnit} {...warehouseProps} />
    <AuthenticatedRoute path={WAREHOUSE_PRODUCT_VARIANT_PATH} component={WarehouseProductVariant} {...warehouseProps} />
    <AuthenticatedRoute path={WAREHOUSE_RENTALS_PATH} component={WarehouseRentals} {...warehouseProps} />
    <AuthenticatedRoute path={WAREHOUSE_RESULTS_PATH} component={WarehouseResults} {...warehouseProps} />
    <AuthenticatedRoute path={WAREHOUSE_SEARCH_PATH} component={WarehouseSearch} {...warehouseProps} />
    <AuthenticatedRoute path={WAREHOUSE_STORAGE_ADD_PATH} component={WarehouseStorageAdd} {...warehouseProps} />
    <AuthenticatedRoute path={WAREHOUSE_STORAGE_PATH} component={WarehouseStorage} {...warehouseProps} />
    <AuthenticatedRoute path={WAREHOUSE_TAG_PATH} component={WarehouseTag} {...warehouseProps} />

    <AuthenticatedRoute component={NotFound} />
</Switch>;
