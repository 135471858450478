import React, { useState } from 'react';
import { Badge, Button, Card } from 'react-bootstrap';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { useResourcesList } from '../../../hooks/use-resources-list';
import '../../../styles/card.css';
import {
    ResourceModel, ProducerModel, ProductModel, ProductUnitModel, ProductVariantModel, StorageModel, TagModel,
} from '../../../types/warehouse/model';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Message, { MessageType } from '../../Message/Message';
import Producer from '../Producers/Producer';
import Product from '../Products/Product';
import ProductUnit from '../ProductUnits/ProductUnit';
import ProductVariant from '../ProductVariants/ProductVariant';
import Storage from '../Storages/Storage';
import Tag from '../Tags/Tag';
import './SearchList.css';

interface SearchListProps {
    context: SearchContext;
    search: string;
}

export enum SearchContext {
    PRODUCER = 'producer',
    PRODUCT = 'product',
    PRODUCT_UNIT = 'product-unit',
    PRODUCT_VARIANT = 'product-variant',
    STORAGE = 'storage',
    TAG = 'tag',
}
export const CONTEXTS = [
    SearchContext.PRODUCER, SearchContext.PRODUCT, SearchContext.PRODUCT_VARIANT, SearchContext.PRODUCT_UNIT,
    SearchContext.STORAGE, SearchContext.TAG,
];

export default (props: SearchListProps) => {
    const [{ page, search }, setSearchAndPage] = useState<{ page: number, search: string }>({ page: 0, search: '' });
    const intl = useIntl();
    const { renderItem, title, url } = getForContext(props.context, intl);
    const [opened, setOpened] = useState<boolean | null>(null);
    const [items, { error, isLast, loading, total }] = useResourcesList<ResourceModel>(url, page, { search });

    const isOpened = (opened === null && items.length > 0) || opened === true;
    if (props.search !== '' && props.search !== search) {
        setSearchAndPage({ page: 1, search: props.search });
        setOpened(null);
    }
    const isFirstLoading = loading && items.length === 0;

    return <Card className="SearchList mb-3">
        <Card.Header className={isOpened ? 'card-opened' : 'card-closed'} onClick={() => {
            setOpened(!isOpened);
        }}>
            {title}
            <Badge pill variant={isFirstLoading ? 'secondary' : error ? 'danger' : 'info'}
                className="float-right mt-1 mr-4">
                {isFirstLoading ?
                    <FormattedMessage id="list.fetching.badge" defaultMessage="fetching" /> :
                    error ?
                        <FormattedMessage id="error.label" defaultMessage="error" /> :
                        total || items.length}
            </Badge>
        </Card.Header>
        {isOpened ?
            <Card.Body>
                <ErrorMessage error={error} />
                {items.length > 0 ?
                    <>
                        {items.map(item => renderItem(item))}
                        {!isLast ?
                            <Button variant="light" size="sm" onClick={() => {
                                setSearchAndPage({ page: page + 1, search });
                            }} block>
                                <FormattedMessage id="list.more" defaultMessage="More" />
                            </Button> :
                            null}
                    </> :
                    isLast ? <Message type={MessageType.NO_RESULTS} /> :
                        loading ? <Message type={MessageType.FETCHING} /> : null}
            </Card.Body> :
            null}
    </Card>;
}

function getForContext(context: SearchContext, { formatMessage }: IntlShape): {
    renderItem: (item: any) => React.ReactNode, title: string, url: string | null,
} {
    switch (context) {
        case SearchContext.PRODUCER:
            return {
                renderItem: (producer: ProducerModel) => <Producer key={producer.id} producer={producer} />,
                title: formatMessage({ defaultMessage: 'Producers', id: 'producers.title' }),
                url: getWarehouseApiUrl('producers'),
            };
        case SearchContext.PRODUCT:
            return {
                renderItem: (product: ProductModel) => <Product key={product.id} product={product} />,
                title: formatMessage({ defaultMessage: 'Products', id: 'products.title' }),
                url: getWarehouseApiUrl('products'),
            };
        case SearchContext.PRODUCT_UNIT:
            return {
                renderItem: (productUnit: ProductUnitModel) =>
                    <ProductUnit key={productUnit.id} productUnit={productUnit} />,
                title: formatMessage({ defaultMessage: 'Product units', id: 'product-units.title' }),
                url: getWarehouseApiUrl('product-units'),
            };
        case SearchContext.PRODUCT_VARIANT:
            return {
                renderItem: (productVariant: ProductVariantModel) =>
                    <ProductVariant key={productVariant.id} productVariant={productVariant} />,
                title: formatMessage({ defaultMessage: 'Product variants', id: 'product-variants.title' }),
                url: getWarehouseApiUrl('product-variants'),
            };
        case SearchContext.STORAGE:
            return {
                renderItem: (storage: StorageModel) => <Storage key={storage.id} storage={storage} />,
                title: formatMessage({ defaultMessage: 'Storages', id: 'storages.title' }),
                url: getWarehouseApiUrl('storages'),
            };
        case SearchContext.TAG:
            return {
                renderItem: (tag: TagModel) => <Tag key={tag.id} tag={tag} />,
                title: formatMessage({ defaultMessage: 'Tags', id: 'tags.title' }),
                url: getWarehouseApiUrl('tags'),
            };
        default:
            return {
                renderItem: () => <></>,
                title: '',
                url: null,
            };
    }
}
