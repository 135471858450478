import { RelayName, TankId, TankName } from '@majpage/raspi-tanks-logic';

export enum MatrixItemColumnType {
    INTEGER = 'integer',
    NUMBER = 'number',
    SELECT = 'select',
    STRING = 'string',
    TIME = 'time',
}
export interface RelayTankName {
    id: TankId;
    name: TankName;
    relayName: RelayName;
}
