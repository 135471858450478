import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getPath } from '../../../helpers/path';
import { ifNotAborted } from '../../../helpers/request';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { PATH as WAREHOUSE_TAG_PATH } from '../../../routes/warehouse/Tag';
import { TagModel } from '../../../types/warehouse/model';
import { Capabilities } from '../../../types/warehouse/user';
import { useUserFromContext } from '../../Contexts';
import Message, { MessageType } from '../../Message/Message';
import TagSelector from '../Selector/TagSelector';
import './ProductTags.css';

interface ProductTagsProps {
    productId: number;
}
interface ProductTagsState {
    isLast: boolean;
    isTouched: boolean;
    items: TagModel[];
    loading: boolean;
    submitting: boolean;
}

export default ({ productId }: ProductTagsProps) => {
    const [state, setState] = useState<ProductTagsState>({
        isLast: false, isTouched: false, items: [], loading: false, submitting: false,
    });
    const updateState = (partial: Partial<ProductTagsState>) => setState({ ...state, ...partial });
    const { isLast, isTouched, items, loading, submitting } = state;
    const user = useUserFromContext();
    const canManageProductTags = user.capabilities.includes(Capabilities.PRODUCTS_MANAGE);

    function addTag(tag: TagModel) {
        updateState({ items: [...items, tag], isTouched: true });
    }
    function removeTag(tag: TagModel) {
        updateState({ items: items.filter(item => item.id !== tag.id), isTouched: true });
    }
    async function saveTags() {
        if (submitting || !isTouched) {
            return;
        }
        updateState({ submitting: true });
        try {
            const tagIds = items.map(item =>item.id);
            await authenticatedRequest<TagModel>({
                data: { tagIds },
                method: HttpMethod.PATCH,
                url: getWarehouseApiUrl('products/:productId', { params: { productId } }),
            });
            updateState({ submitting: false });
            resetTagsList();
        } catch (error) {
            updateState({ submitting: false });
        }
    }
    function resetTagsList() {
        updateState({ isLast: false, isTouched: false, items: [] });
    }

    useEffect(resetTagsList, [productId]);
    useEffect(() => {
        if (loading || isLast) {
            return;
        }
        updateState({ loading: true });
        const request = new AbortController();
        authenticatedRequest<TagModel[]>({
            method: HttpMethod.GET,
            params: { productId },
            url: getWarehouseApiUrl('tags'),
        }, request.signal).then(ifNotAborted<(list: TagModel[]) => void>(request, list => {
            updateState({ isLast: true, items: [...items, ...list], loading: false });
        })).catch(ifNotAborted(request, () => {
            updateState({ loading: false });
        }));
        return () => request.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLast, productId]);

    return <Card className="ProductTags">
        <Card.Header>
            <FormattedMessage id="product-tags.title" defaultMessage="Product tags" />
            {canManageProductTags ?
                <>
                    <Button variant={isTouched ? 'primary' : 'secondary'} size="sm" disabled={!isTouched}
                        onClick={saveTags}>
                        <FormattedMessage id="form.save" defaultMessage="Save" />
                    </Button>
                    <TagSelector onSelect={addTag} excludedIds={items.map(item => item.id)} />
                </> :
                null}
        </Card.Header>
        <Card.Body>
            {items.length > 0 ?
                items.map(tag => <span key={tag.id} className="btn btn-secondary disabled">
                    <Link to={{
                        pathname: getPath(WAREHOUSE_TAG_PATH, { tagSlug: tag.slug }),
                        state: { tag }
                    }}>{tag.name}</Link>
                    {canManageProductTags ?
                        <>
                            {' '}
                            <span className="badge badge-dark" onClick={() => { removeTag(tag) }}>×</span>
                        </> :
                        null}
                </span>) :
                isLast ? <Message type={MessageType.NO_RESULTS} /> :
                    loading ? <Message type={MessageType.FETCHING} /> : null}
        </Card.Body>
    </Card>;
}
