import { Form, FormikProps, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal, Toast } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { authenticatedRequest, HttpMethod } from '../../../helpers/auth-request';
import { getPath } from '../../../helpers/path';
import { getWarehouseApiUrl } from '../../../helpers/url';
import {
    AmountInfo, getAmountInfo, getCustomCapacityAmountInfo, MAX_AMOUNT,
} from '../../../helpers/warehouse/actual-states';
import { useStorageByIdCode } from '../../../hooks/warehouse/use-resources';
import { PATH as WAREHOUSE_PRODUCT_VARIANT_PATH } from '../../../routes/warehouse/ProductVariant';
import { IdCode } from '../../../types/id-code';
import { ActualStateModel, ProductVariantModel, StorageModel } from '../../../types/warehouse/model';
import { getDataFromIdCode, getDataSetFromIdCode, getIdCodeIfData } from '../../Contexts';
import AmountInputField from '../../FormField/AmountInputField';
import TextInputField from '../../FormField/TextInputField';
import FormSubmitError from '../../FormSubmitError/FormSubmitError';
import IdCodeScanner from '../../IdCodeScanner/IdCodeScanner';
import StorageSelector from '../Selector/StorageSelector';
import './ActualStateAddForm.css';

export const ID_CODE_FORM = 'actual-state';
const STORAGE_TARGET = 'storage';
interface IdCodeData {
    form: string;
    storage?: StorageModel | false | null;
    target: string;
}

interface FormValues {
    amount: number;
    expirationDate: string;
}

const getSchema = (maxAmount: number) => Yup.object().shape({
    amount: Yup.number().typeError('form.number.type-incorrect')
        .min(0, 'form.number.too-low')
        .max(maxAmount, maxAmount !== MAX_AMOUNT ? 'form.amount.capacity-limit' : 'form.number.too-high')
        .required('form.element.required'),
    expirationDate: Yup.string()
        .matches(/^([0-9]{4}(-[0-9]{2}){2})?$/, 'form.string.doesnt-match'),
});

interface PureFormProps {
    amountInfo: AmountInfo;
    error: string | null;
    idCode: IdCode | null;
    maxAmount: number;
    onHide: () => void;
    productVariant: ProductVariantModel;
    setMaxAmount: (maxAmount: number) => void;
    setModalShown: (modalShown: boolean) => void;
    setShowError: (showError: boolean) => void;
    setShowRelationError: (showError: boolean) => void;
    setStorage: (storage: StorageModel | false | null) => void;
    showError: boolean;
    showRelationError: boolean;
    storage: StorageModel | false | null;
}

const PureForm = ({ touched, errors, ...props }: PureFormProps & FormikProps<FormValues>) => {
    const { formatMessage } = useIntl();
    const codeScannerData = {
        ...props.values,
        storage: props.storage,
        form: ID_CODE_FORM,
    };

    useEffect(() => {
        if (props.values.amount > props.amountInfo.currentMax) {
            props.setFieldValue('amount', props.amountInfo.currentMax);
        } else {
            const amountSteps = props.values.amount / props.amountInfo.step;
            if (amountSteps % 1) {
                props.setFieldValue('amount', Math.round(amountSteps) * props.amountInfo.step);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.amountInfo)]);

    return <Form>
        <Modal.Body>
            <FormSubmitError error={props.error} setShowError={props.setShowError} showError={props.showError} />
            {props.idCode && props.storage === false ?
                <Alert variant="danger" show={props.showRelationError}
                    onClose={() => props.setShowRelationError(false)} dismissible>
                    <FormattedMessage id="form.error.code-not-exists" values={{ code: props.idCode.value }}
                        defaultMessage={`Code "{code}" doesn't exist.`} />
                </Alert> :
                null}
            <TextInputField fieldId="storage" disabled
                value={props.storage ? props.storage.name : ''}
                name={formatMessage({ defaultMessage: 'Storage', id: 'form.label.storage' })}
                renderAppend={<>
                    <StorageSelector onSelect={props.setStorage} params={{ extended: true, states: true }}
                        buttonTitle={formatMessage({ defaultMessage: 'Select', id: 'selector.button' })} />
                    <IdCodeScanner
                        result={getPath(WAREHOUSE_PRODUCT_VARIANT_PATH, { productVariantId: props.productVariant.id })}
                        data={{ ...codeScannerData, target: STORAGE_TARGET }} />
                    {props.storage ?
                        <Button onClick={() => { props.setStorage(null) }}>×</Button> :
                        null}
                </>} />
            {props.storage ?
                <Toast>
                    <Toast.Body>
                        <div>
                            <strong>
                                <FormattedMessage id="table.label.name" defaultMessage="Name:" />
                            </strong>
                            {' '}{props.storage.name}
                        </div>
                        {props.storage.description ?
                            <div>
                                <strong>
                                    <FormattedMessage id="table.label.description" defaultMessage="Description:" />
                                </strong>
                                {' '}{props.storage.description}
                            </div> :
                            null}
                        {props.storage.parent ?
                            <div>
                                <strong>
                                    <FormattedMessage id="table.label.parent" defaultMessage="Parent:" />
                                </strong>
                                {' '}{props.storage.parent.name}
                            </div> :
                            null}
                        {props.storage.fixedCapacity ?
                            <div>
                                <strong>
                                    <FormattedMessage id="table.label.capacity" defaultMessage="Capacity:" />
                                </strong>
                                {' '}{props.storage.amount} {props.storage.unit}
                            </div> :
                            null}
                        {props.storage.fixedCapacity && props.storage.amount && props.storage.amountInUse !== null ?
                            <div>
                                <strong>
                                    <FormattedMessage id="table.label.capacity-in-use"
                                        defaultMessage="Capacity in use:" />
                                </strong>
                                {' '}{props.storage.amountInUse} {props.storage.unit}{' '}
                                ({Math.round(props.storage.amountInUse / props.storage.amount * 100)}%)
                            </div> :
                            null}
                    </Toast.Body>
                </Toast>:
                null}
            <AmountInputField fieldId="amount" touched={touched.amount} error={errors.amount} type="range"
                max={props.amountInfo.fixedMax ? props.amountInfo.currentMax : props.maxAmount}
                step={props.amountInfo.step} unit={`${props.amountInfo.unit}${props.amountInfo.unitSuffix}`}
                name={formatMessage({ defaultMessage: 'Amount', id: 'form.label.amount' })}
                nameSuffix={formatMessage(
                    { defaultMessage: ' (currently: {amount})', id: 'form.label.amount-current' },
                    {
                        amount: `${props.values.amount} ${props.amountInfo.unit}` + (!props.amountInfo.fixedMax ? '' :
                            ` = ${Math.round(props.values.amount / props.amountInfo.storageAmount! * 100)}%`),
                    },
                )}
                onIncrease={props.amountInfo.fixedMax ? undefined : () => {
                  if (props.maxAmount < MAX_AMOUNT && props.productVariant) {
                      const amountInfo = getCustomCapacityAmountInfo(props.maxAmount, props.productVariant);
                      props.setMaxAmount(amountInfo.currentMax);
                  }
                }} />
            <TextInputField fieldId="expirationDate" touched={touched.expirationDate} error={errors.expirationDate}
                name={formatMessage({ defaultMessage: 'Expiration date', id: 'form.label.expiration-date' })}
                type="date"/>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
                <FormattedMessage id="modal.close" defaultMessage="Close" />
            </Button>
            <Button variant="primary" type="submit" onSubmit={props.validateForm} disabled={props.isSubmitting}>
                <FormattedMessage id="form.save" defaultMessage="Save" />
            </Button>
        </Modal.Footer>
    </Form>;
};

interface FormikFormProps extends PureFormProps {
    formValues: FormValues;
    onAdd: (state: ActualStateModel) => void;
    setError: (text: string | null) => void;
}

const FormikForm = withFormik<FormikFormProps, FormValues>({
    handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
        try {
            props.setError(null);
            props.setShowError(true);
            props.setShowRelationError(true);
            const state = await authenticatedRequest<ActualStateModel>({
                data: {
                    amount: values.amount,
                    expirationDate: values.expirationDate || null,
                    storageId: props.storage ? props.storage.id : null,
                },
                method: HttpMethod.POST,
                url: getWarehouseApiUrl('product-variants/:productVariantId/actual-states', { params: {
                    productVariantId: props.productVariant.id,
                } }),
            });
            resetForm();
            props.onAdd(state);
        } catch (error) {
            props.setError(error);
            setSubmitting(false);
        }
    },
    mapPropsToValues: ({ formValues }) => formValues,
    validationSchema: (props: FormikFormProps) => getSchema(props.amountInfo.max),
})(PureForm);

interface ActualStateAddFormProps {
    idCode: IdCode<IdCodeData> | null;
    onAdd: (state: ActualStateModel) => void;
    productVariant: ProductVariantModel;
}

export default ({ idCode, productVariant, onAdd }: ActualStateAddFormProps) => {
    const [error, setError] = useState<string | null>(null);
    const [showError, setShowError] = useState(true);
    const [showRelationError, setShowRelationError] = useState(true);
    const [currentIdCode, setCurrentIdCode] = useState<IdCode<IdCodeData> | null>(idCode);
    const [modalShown, setModalShown] = useState<boolean>(!!currentIdCode);
    const { formatMessage } = useIntl();

    const formValues = getDataSetFromIdCode<FormValues>(
        currentIdCode, ['amount', 'expirationDate'], { amount: 1, expirationDate: '' },
    );

    const [storage, setStorage] = useState<StorageModel | false | null>(
        getDataFromIdCode(currentIdCode, 'storage'),
    );
    const [storageFromIdCode] = useStorageByIdCode(
        getIdCodeIfData(currentIdCode, data => data.target === STORAGE_TARGET),
    );

    const onHide = () => {
        setModalShown(false);
        setStorage(null);
    };
    const amountInfo = getAmountInfo(formValues.amount, productVariant, storage || null);
    const [maxAmount, setMaxAmount] = useState(amountInfo.currentMax);
    const stateProps = {
        amountInfo, error, formValues, idCode: currentIdCode, maxAmount, onHide, setError, setModalShown, setMaxAmount,
        setShowError, setShowRelationError, setStorage, showError, showRelationError, storage,
    };

    useEffect(() => {
        if (storageFromIdCode !== null) {
            if (!storageFromIdCode) {
                setStorage(false);
            } else if (!storageFromIdCode.statesAvailable) {
                setStorage(false);
                setError(formatMessage({
                    defaultMessage: 'This storage does not accept actual states.', id: 'storage.no-actual-states',
                }));
            } else {
                setStorage(storageFromIdCode);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(storageFromIdCode)]);

    return <>
        <Button size="sm" className="mb-3" block onClick={() => {
            setError(null);
            setShowError(true);
            setShowRelationError(true);
            setCurrentIdCode(null);
            setModalShown(true);
        }}>
            <FormattedMessage id="actual-states.button.add" defaultMessage="Add actual state" />
        </Button>
        <Modal show={modalShown} onHide={onHide} animation={false} className="ActualStateModal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="actual-states.modal.add" defaultMessage="Add actual state" />
                </Modal.Title>
            </Modal.Header>
            <FormikForm productVariant={productVariant} onAdd={state => {
                onHide();
                onAdd(state);
            }} {...stateProps} />
        </Modal>
    </>;
}
