import React from 'react';
import { useIntl } from 'react-intl';
import { ProducerModel } from '../../../types/warehouse/model';
import Selector from './Selector';

interface ProducerSelectorProps {
    buttonTitle?: string;
    excludedIds?: number[];
    onImport?: (value: string) => void;
    onSelect: (item: ProducerModel) => void;
    params?: { [key: string]: any };
}

export default ({ buttonTitle, excludedIds, onImport, onSelect, params }: ProducerSelectorProps) => {
    const { formatMessage } = useIntl();
    const props = {
        buttonTitle: buttonTitle || formatMessage({
            defaultMessage: 'Select producer',
            id: 'producer-selector.button',
        }),
        excludedIds,
        modalTitle: formatMessage({
            defaultMessage: 'Select producer',
            id: 'producer-selector.name',
        }),
        onImport,
        onSelect,
        params,
        placeholder: formatMessage({
            defaultMessage: 'Search for producer',
            id: 'producer-selector.placeholder',
        }),
    };

    return <Selector path="producers" {...props} />;
}
