import { createContext, useContext } from 'react';
import { User } from '../helpers/auth-request';
import { Measurement } from '../types/home-automation/model';
import { IdCode, IdCodeFamily } from '../types/id-code';

export interface MainContextValue {
    backButton: boolean;
    optionsButton: boolean;
    scannerLink: boolean;
    scanners: IdCodeFamily[];
    source: string | null;
}
export const MAIN_CONTEXT_DEFAULT_VALUE: MainContextValue = {
    backButton: false, optionsButton: false, scannerLink: false,
    scanners: [IdCodeFamily.BARCODE, IdCodeFamily.NFC_TAG, IdCodeFamily.QR_CODE, IdCodeFamily.RFID_TAG], source: null,
};
export const MainContext = createContext<MainContextValue>(MAIN_CONTEXT_DEFAULT_VALUE);

interface UserContextValue {
    user: User;
}
export const UserContext = createContext<UserContextValue>({
    user: { capabilities: [], email: '', name: null, uuid: '' },
});
export function useUserFromContext() {
    const { user } = useContext(UserContext);
    return user;
}

interface IdCodeContextValue<D = any> {
    idCode: IdCode<D> | null;
}
export const IdCodeContext = createContext<IdCodeContextValue>({ idCode: null });
export function getIdCodeIfData<D = any>(idCode: IdCode<D> | null, dataCondition?: (data: D) => boolean) {
    return idCode && (!dataCondition || (idCode.data && dataCondition(idCode.data))) ? idCode : null;
}
export function getIdCodeValueIfDataTarget(
    idCode: IdCode<{ target?: string }> | null, target: string, defaultValue: string,
) {
    const validIdCode = getIdCodeIfData(idCode, data => data.target === target);
    return validIdCode && validIdCode.value ? validIdCode.value : defaultValue;
}
export function getDataFromIdCode(idCode: IdCode | null, key: string) {
    return idCode && idCode.data && idCode.data[key] ? idCode.data[key] : null;
}
export function getDataSetFromIdCode<V extends { [key: string]: any }>(
    idCode: IdCode | null, keys: string[], defaultValues: V,
): V {
    const idCodeData = idCode && idCode.data ? idCode.data : {};
    return keys.reduce<any>((values, key) => {
        if (key in idCodeData) {
            values[key] = idCodeData[key];
        } else if (key in defaultValues) {
            values[key] = defaultValues[key];
        }
        return values;
    }, {});
}
export function useIdCodeFromContext<D = any>(dataCondition?: (data: D) => boolean) {
    const { idCode } = useContext<IdCodeContextValue<D>>(IdCodeContext);
    return getIdCodeIfData(idCode, dataCondition);
}

interface HomeAutomationMeasurementsContextValue {
    homeAutomationMeasurements: { last: number, list: Measurement[] };
}
export const HomeAutomationMeasurementsContext = createContext<HomeAutomationMeasurementsContextValue>({
    homeAutomationMeasurements: { last: 0, list: [] },
});
export function useHomeAutomationMeasurementsFromContext() {
    const { homeAutomationMeasurements } = useContext(HomeAutomationMeasurementsContext);
    return homeAutomationMeasurements;
}

interface HomeAutomationTimeOffsetContextValue {
    homeAutomationTimeOffset: number;
}
export const HomeAutomationTimeOffsetContext = createContext<HomeAutomationTimeOffsetContextValue>({
    homeAutomationTimeOffset: 0,
});
export function useHomeAutomationTimeOffsetFromContext() {
    const { homeAutomationTimeOffset } = useContext(HomeAutomationTimeOffsetContext);
    return homeAutomationTimeOffset;
}
