import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { PlainObject } from '../../types/collection';
import { Command, CommandType, Highlight, HighlightType } from '../../types/tracking/model';
import { getFormattedDate } from '../time';

function range(start: number, stop: number, step: number): number[] {
    let value = start;
    const range = [];
    while (value < stop) {
        range.push(value);
        value += step;
    }
    return range;
}
interface Translation {
    defaultMessage: string;
    id: string;
}

const commands: PlainObject<{
    defaultValues?: PlainObject, fetchArea?: true, fetchDate?: true,
    getOnClick?: (command: Command, setHighlight: (highlight: Highlight | null) => void) => (event: any) => void,
    name: Translation, options?: {
        defaultValue: any, key: string, name: Translation, validation: Yup.Schema<any>,
        values: { name: string, value: any }[],
    }[], validation?: PlainObject<Yup.Schema<any>>,
}> = {
    [CommandType.ALARM_GEOFENCE_CANCEL]: {
        name: { defaultMessage: 'Cancel geofence alarm', id: 'tracking-commands.alarm-geofence-cancel' },
    },
    [CommandType.ALARM_GEOFENCE_SET]: {
        fetchArea: true,
        getOnClick: (command, setHighlight) => {
            return () => {
                setHighlight({
                    area: [
                        [command.params!.latA, command.params!.lonA],
                        [command.params!.latB, command.params!.lonB],
                    ],
                    time: new Date(command.createdAt),
                    type: HighlightType.COMMAND_AREA,
                });
            };
        },
        name: { defaultMessage: 'Set geofence alarm', id: 'tracking-commands.alarm-geofence-set' },
    },
    [CommandType.ALARM_MOVE_CANCEL]: {
        name: { defaultMessage: 'Cancel move alarm', id: 'tracking-commands.alarm-move-cancel' },
    },
    [CommandType.ALARM_MOVE_SET]: {
        name: { defaultMessage: 'Set move alarm', id: 'tracking-commands.alarm-move-set' },
        options: [
            (() => {
                const values = [200, 300, 500, 800, 1000, 2000, 5000, 10000];
                return {
                    defaultValue: 200,
                    key: 'distance',
                    name: { defaultMessage: 'Distance', id: 'tracking-commands.alarm-move-set.options.distance' },
                    validation: Yup.number().oneOf(values).required('form.element.required'),
                    values: values.map(value => ({
                        name: value / 1000 >= 1 ? `${value / 1000} km` : `${value} m`,
                        value,
                    })),
                };
            })(),
        ],
    },
    [CommandType.ALARM_OVER_SPEED_SET]: {
        name: { defaultMessage: 'Set over speed alarm', id: 'tracking-commands.alarm-over-speed-set' },
        options: [
            (() => {
                const values = [10, 20, 30, 40, 50, 60, 80, 100, 120, 150, 180, 200, 220, 250, 300];
                return {
                    defaultValue: 10,
                    key: 'speed',
                    name: { defaultMessage: 'Speed', id: 'tracking-commands.alarm-over-speed-set.options.speed' },
                    validation: Yup.number().oneOf(values).required('form.element.required'),
                    values: values.map(value => ({
                        name: `${value} km/h`,
                        value,
                    })),
                };
            })(),
        ],
    },
    [CommandType.ALARM_STOP]: {
        name: { defaultMessage: 'Stop alarm', id: 'tracking-commands.alarm-stop' },
    },
    [CommandType.ALARM_VIBRATION_CANCEL]: {
        name: { defaultMessage: 'Cancel vibration alarm', id: 'tracking-commands.alarm-vibration-cancel' },
    },
    [CommandType.ALARM_VIBRATION_SET]: {
        name: { defaultMessage: 'Set vibration alarm', id: 'tracking-commands.alarm-vibration-set' },
    },
    [CommandType.ARM]: {
        name: { defaultMessage: 'Arm', id: 'tracking-commands.arm' },
    },
    [CommandType.DATA_UPLOAD_CANCEL]: {
        name: { defaultMessage: 'Cancel data upload', id: 'tracking-commands.data-upload-cancel' },
    },
    [CommandType.DATA_UPLOAD_SET]: {
        defaultValues: { date: new Date() },
        fetchDate: true,
        name: { defaultMessage: 'Set data upload', id: 'tracking-commands.data-upload-set' },
        validation: {
            date: Yup.date().required('form.element.required'),
        },
    },
    [CommandType.DISARM]: {
        name: { defaultMessage: 'Disarm', id: 'tracking-commands.disarm' },
    },
    [CommandType.ENGINE_START]: {
        name: { defaultMessage: 'Start engine', id: 'tracking-commands.engine-start' },
    },
    [CommandType.ENGINE_STOP]: {
        name: { defaultMessage: 'Stop engine', id: 'tracking-commands.engine-stop' },
    },
    // [CommandType.PHOTO_REQUEST]: {
    //     name: { defaultMessage: 'Request photo', id: 'tracking-commands.photo-request' },
    // },
    [CommandType.SAVE_GPRS_CANCEL]: {
        name: { defaultMessage: 'Cancel GPRS save', id: 'tracking-commands.save-gprs-cancel' },
    },
    [CommandType.SAVE_GPRS_SET]: {
        name: { defaultMessage: 'Set GPRS save', id: 'tracking-commands.save-gprs-set' },
    },
    [CommandType.TIME_SET]: {
        name: { defaultMessage: 'Set time', id: 'tracking-commands.time-set' },
        options: [
            (() => {
                const values = range(-12, 12.1, 0.5);
                return {
                    defaultValue: 0,
                    key: 'timeOffset',
                    name: { defaultMessage: 'Time offset', id: 'tracking-commands.time-set.options.time-offset' },
                    validation: Yup.number().oneOf(values).required('form.element.required'),
                    values: values.map(value => {
                        let minutes = `${Math.abs(60 * (value % 1))}`;
                        while (minutes.length < 2) {
                            minutes = `0${minutes}`;
                        }
                        return {
                            name: [
                                value > 0 ? `+${Math.floor(value)}` :
                                    value < 0 ? `-${Math.abs(Math.ceil(value))}` : '0',
                                minutes
                            ].join(':'),
                            value,
                        };
                    }),
                };
            })(),
        ],
    },
    [CommandType.TRACKING_CANCEL]: {
        name: { defaultMessage: 'Cancel tracking', id: 'tracking-commands.tracking-cancel' },
    },
    [CommandType.TRACKING_DISTANCE_SET]: {
        name: { defaultMessage: 'Set distance tracking', id: 'tracking-commands.tracking-distance-set' },
        options: [
            (() => {
                const values = [50, 100, 200, 300, 500, 800, 1000, 2000, 5000, 10000];
                return {
                    defaultValue: 50,
                    key: 'distance',
                    name: {
                        defaultMessage: 'Distance', id: 'tracking-commands.tracking-distance-set.options.distance',
                    },
                    validation: Yup.number().oneOf(values).required('form.element.required'),
                    values: values.map(value => ({
                        name: value / 1000 >= 1 ? `${value / 1000} km` : `${value} m`,
                        value,
                    })),
                };
            })(),
        ],
    },
    [CommandType.TRACKING_SINGLE_SET]: {
        name: { defaultMessage: 'Set single tracking', id: 'tracking-commands.tracking-single-set' },
    },
    [CommandType.TRACKING_TIME_SET]: {
        name: { defaultMessage: 'Set time tracking', id: 'tracking-commands.tracking-time-set' },
        options: [
            (() => {
                const values = ['10s', '15s', '20s', '30s', '01m', '05m', '10m', '30m', '01h', '05h', '10h', '24h'];
                return {
                    defaultValue: '10s',
                    key: 'time',
                    name: { defaultMessage: 'Time', id: 'tracking-commands.tracking-time-set.options.time' },
                    validation: Yup.string().oneOf(values).required('form.element.required'),
                    values: values.map(value => ({
                        name: value.replace(/^0*([1-9][0-9]+)([^0-9]+)$/, '$1 $2'),
                        value,
                    })),
                };
            })(),
        ],
    },
};

export function getCommandName(command: Command): JSX.Element {
    return commands[command.type] ? <FormattedMessage {...commands[command.type].name} /> : <>{command.type}</>;
}

export function getCommandTypeOptions(): JSX.Element[] {
    return Object.entries(commands).map(([key, { name }]) => <FormattedMessage key={key} id={name.id}
        defaultMessage={name.defaultMessage}>
        {(message) => <option value={key}>{message}</option>}
    </FormattedMessage>);
}

export function getCommandInfo(command: Command): JSX.Element[] {
    let info: JSX.Element[] = [];
    const settings = commands[command.type];
    if (settings) {
        if (settings.options) {
            info = settings.options.filter(option => command.params && command.params[option.key])
                .map(option => {
                    const rawValue = command.params![option.key];
                    const valueInfo = option.values.find(({ value }) => value === rawValue);
                    return <em key={option.key} style={{ textTransform: 'lowercase' }} className="ml-2">
                        <FormattedMessage {...option.name} />: {valueInfo ? valueInfo.name : rawValue}
                    </em>;
                });
        }
        if (settings.fetchDate && command.params && command.params.date) {
            info.push(<em key="fetchDate" style={{ textTransform: 'lowercase' }} className="ml-2">
                <FormattedMessage id="tracking-commands.data-upload-set.options.date" defaultMessage="Date" />:{' '}
                {getFormattedDate(new Date(command.params.date))}
            </em>);
        }
    }
    return info;
}

export function getCommandRequestValidator(commandType: CommandType | null): Yup.Schema<any> {
    const shape = { type: Yup.string().oneOf(Object.keys(commands)).required('form.element.required') };

    const settings = commandType ? commands[commandType] : null;
    if (!settings) {
        return Yup.object().shape(shape);
    }

    return Yup.object().shape({
        ...shape,
        ...(settings.validation ? settings.validation : {}),
        ...(settings.options ? settings.options.reduce<PlainObject<Yup.Schema<any>>>((options, { key, validation }) => {
            options[key] = validation;
            return options;
        }, {}) : {}),
    });
}

export function getCommandRequestValues(commandType: CommandType | null): PlainObject {
    const settings = commandType ? commands[commandType] : null;
    if (!settings) {
        return { type: '' };
    }

    return {
        type: '',
        ...(settings.defaultValues ? settings.defaultValues : {}),
        ...(settings.options ? settings.options.reduce<PlainObject>((options, { defaultValue, key }) => {
            options[key] = defaultValue;
            return options;
        }, {}) : {}),
    };
}

export function renderCommandTypeFields(
    commandType: CommandType | null,
    renderOption: (name: Translation, key: string, values: { name: string, value: any }[]) => JSX.Element,
    renderArea: (name: Translation, key: string) => JSX.Element,
    renderDate: (name: Translation, key: string) => JSX.Element,
): JSX.Element | JSX.Element[] | null {
    const settings = commandType ? commands[commandType] : null;
    if (settings) {
        if (settings.fetchArea) {
            return renderArea({ defaultMessage: 'Area', id: 'form.label.area' }, 'area');
        }
        if (settings.fetchDate) {
            return renderDate({ defaultMessage: 'Date', id: 'form.label.date' }, 'date');
        }
        if (settings.options) {
            return settings.options.map(({ key, name, values }) => renderOption(name, key, values));
        }
    }
    return null;
}

export function isAreaFetchingCommandType(commandType: CommandType | null): boolean {
    return !!commandType && commands[commandType] && commands[commandType]['fetchArea'] === true;
}

export function getCommandOnClick(
    command: Command, setHighlight: (highlight: Highlight | null) => void,
): ((event: any) => void) | undefined {
    const settings = command.type ? commands[command.type] : null;
    if (settings && settings.getOnClick) {
        return settings.getOnClick(command, setHighlight);
    }
}
