import React from 'react';
import { useIntl } from 'react-intl';
import { ProductModel } from '../../../types/warehouse/model';
import Selector from './Selector';

interface ProductSelectorProps {
    buttonTitle?: string;
    excludedIds?: number[];
    onSelect: (item: ProductModel) => void;
    onImport?: (value: string) => void;
    params?: { [key: string]: any };
}

export default ({ buttonTitle, excludedIds, onImport, onSelect, params }: ProductSelectorProps) => {
    const { formatMessage } = useIntl();
    const props = {
        buttonTitle: buttonTitle || formatMessage({
            defaultMessage: 'Select product',
            id: 'product-selector.button',
        }),
        excludedIds,
        modalTitle: formatMessage({
            defaultMessage: 'Select product',
            id: 'product-selector.name',
        }),
        onImport,
        onSelect,
        params,
        placeholder: formatMessage({
            defaultMessage: 'Search for product',
            id: 'product-selector.placeholder',
        }),
        renderItemName: (product: ProductModel) => product.producer ?
            <>{product.name} <small>({product.producer.name})</small></> : product.name,
    };

    return <Selector path="products" {...props} />;
}
