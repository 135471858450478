import React, { useState } from 'react';
import { Button, FormControl, InputGroup, ListGroup, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { authenticatedRequest, authenticatedTraceableRequest, HttpMethod } from '../../../helpers/auth-request';
import { getWarehouseApiUrl } from '../../../helpers/url';
import { Pagination } from '../../../types/pagination';
import { TagModel } from '../../../types/warehouse/model';
import { Capabilities } from '../../../types/warehouse/user';
import { useUserFromContext } from '../../Contexts';
import './Selector.css';

interface TagSelectorProps {
    excludedIds?: number[];
    onSelect: (item: TagModel) => void;
}

export default (props: TagSelectorProps) => {
    const [modalShown, setModalShown] = useState<boolean>(false);
    const [list, setList] = useState<TagModel[]>([]);
    const [value, setValue] = useState<string>('');
    const [requestNo, setRequestNo] = useState<number>(0);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const user = useUserFromContext();
    const canAddTag = user.capabilities.includes(Capabilities.TAGS_MANAGE);
    const currentTag = list.find(item => item.name === value);
    const { formatMessage } = useIntl();
    const excludedIds = props.excludedIds || [];

    function showModal(): void {
        setList([]);
        setModalShown(true);
    }
    function hideModal(): void {
        setModalShown(false);
    }
    async function fetchTags(event: any): Promise<void> {
        const search = event.target.value;
        setValue(search);
        if (search.length < 2) {
            setList([]);
            return;
        }
        try {
            setRequestNo(requestNo + 1);
            const [paginator, id] = await authenticatedTraceableRequest<Pagination<TagModel>, number>({
                method: HttpMethod.GET,
                params: { pack: 10 + Math.min(excludedIds.length, 20), page: 1, search },
                url: getWarehouseApiUrl('tags'),
            }, requestNo);
            if (id === requestNo) {
                setList(paginator.list.filter(item => !excludedIds.includes(item.id)));
            }
        } catch (error) {
            // nothing to do
        }
    }
    async function addTag(): Promise<void> {
        if (submitting) {
            return;
        }
        setSubmitting(true);
        try {
            const tag = await authenticatedRequest<TagModel>({
                data: { name: value },
                method: HttpMethod.POST,
                url: getWarehouseApiUrl('tags'),
            });
            selectTag(tag);
        } catch (error) {
            // nothing to do
        }
        setSubmitting(false);
    }
    function selectTag(tag: TagModel): void {
        setValue('');
        props.onSelect(tag);
        hideModal();
    }

    return <>
        <Button variant="primary" size="sm" onClick={showModal}>
            <FormattedMessage id="tag-selector.button" defaultMessage="Add" />
        </Button>
        <Modal show={modalShown} onHide={hideModal} animation={false} className="SelectorModal">
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id="tag-selector.name" defaultMessage="Select tag" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup>
                    <FormControl type="text" value={value} placeholder={formatMessage({
                        defaultMessage: 'Search for tag', id: 'tag-selector.placeholder',
                    })} onChange={fetchTags} autoFocus />
                    {canAddTag ?
                        <InputGroup.Append>
                            <Button onClick={addTag} disabled={!!currentTag || submitting}>
                                <FormattedMessage id="form.new-add" defaultMessage="Add new" />
                            </Button>
                        </InputGroup.Append> :
                        null}
                </InputGroup>
                {list.length > 0 ?
                    <ListGroup>
                        {list.map(item => <ListGroup.Item key={item.id} onClick={() => { selectTag(item) }}>
                            {item.name}
                        </ListGroup.Item>)}
                    </ListGroup> :
                    null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>
                    <FormattedMessage id="modal.close" defaultMessage="Close" />
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
};
