import { getTrackingApiUrl } from '../../helpers/url';
import { useResource } from '../use-resource';
import { useResourcesList } from '../use-resources-list';
import { Command, Device, Message, Position } from '../../types/tracking/model';

export function useCommands(device: Device, timeFrom: Date, page: number = 0) {
    const url = getTrackingApiUrl('devices/:imei/commands', { params: { imei: device.imei } });
    return useResourcesList<Command>(url, page, { timeFrom });
}

export function useMessages(device: Device, timeFrom: Date, page: number = 0) {
    const url = getTrackingApiUrl('devices/:imei/messages', { params: { imei: device.imei } });
    return useResourcesList<Message>(url, page, { timeFrom });
}

export function usePositions(device: Device, timeFrom: Date) {
    const url = getTrackingApiUrl('devices/:imei/positions', { params: { imei: device.imei } });
    return useResourcesList<Position>(url, 1, { timeFrom });
}

export function useLastPosition(device: Device, timeTo: Date | null) {
    const url = timeTo ? getTrackingApiUrl('devices/:imei/positions/last', { params: { imei: device.imei } }) : null;
    return useResource<Position>(url, { timeTo });
}
