export function getPath(path: string, params: { [key: string]: string | number | boolean } = {}) {
    const divider = '/';
    return path
        .split(divider)
        .map(part => {
            if (!part.startsWith(':')) {
                return part;
            }
            const value = params[part.substr(1)];
            if (!value) {
                return part;
            }
            return encodeURIComponent(value);
        })
        .join(divider);
}
