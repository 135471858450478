import React, { useState } from 'react';
import { useComments } from '../../../hooks/warehouse/use-resources';
import { CommentModel } from '../../../types/warehouse/model';
import { Capabilities } from '../../../types/warehouse/user';
import { useUserFromContext } from '../../Contexts';
import ListTemplate from '../../List/ListTemplate';
import CommentAddForm from '../CommentAddForm/CommentAddForm';
import Comment from './Comment';

interface CommentsProps {
    onAdd: () => void;
    title: string;
    url: string;
}

export default ({ onAdd, title, url }: CommentsProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [items, { addItem, error, isLast, loading, total }] = useComments(url, page);
    const user = useUserFromContext();

    const listPrefix = user.capabilities.includes(Capabilities.COMMENTS_ADD) ?
        <CommentAddForm url={url} onAdd={comment => { addItem(comment); onAdd() }} /> :
        null;
    const renderItem = (comment: CommentModel) => <Comment key={comment.id} comment={comment} />;
    const listProps = {
        error, isLast, items, listPrefix, loading, opened, page, renderItem, setOpened, setPage, title, total,
    };

    return <ListTemplate {...listProps} />;
};
