import isUndefined from 'lodash/isUndefined';
import { ParsedQuery } from 'query-string';
import { MAIN_CONTEXT_DEFAULT_VALUE, MainContextValue } from '../components/Contexts';
import { IdCodeFamily } from '../types/id-code';

export enum Source {
    ANDROID = 'android',
}

export const MAIN_CONTEXT_STORAGE_NAME = 'main-context';
export const MAIN_CONTEXT_ANDROID_VALUE: MainContextValue = {
    backButton: true, optionsButton: true, scannerLink: true,
    scanners: [IdCodeFamily.BARCODE, IdCodeFamily.NFC_TAG, IdCodeFamily.QR_CODE], source: Source.ANDROID,
};

export function getMainContext({ scanners, source }: ParsedQuery<string>, storage: Storage): MainContextValue {
    const valueFromStorage = storage.getItem(MAIN_CONTEXT_STORAGE_NAME);
    let value = valueFromStorage === null ? MAIN_CONTEXT_DEFAULT_VALUE : JSON.parse(valueFromStorage);
    if (source === Source.ANDROID) {
        value = { ...value, ...MAIN_CONTEXT_ANDROID_VALUE };
    }
    if (!isUndefined(scanners) && scanners !== null) {
        const normalizedScanners = Array.isArray(scanners) ? scanners :
            scanners.split(',').map(scanner => scanner.trim());
        value.scanners = normalizedScanners.map(scanner => {
            switch (scanner) {
                case 'bar':
                    return IdCodeFamily.BARCODE;
                case 'nfc':
                    return IdCodeFamily.NFC_TAG;
                case 'qr':
                    return IdCodeFamily.QR_CODE;
                case 'rfid':
                    return IdCodeFamily.RFID_TAG;
                default:
                    return null;
            }
        }).filter(family => family !== null);
    }
    const valueToStorage = JSON.stringify(value);
    if (valueFromStorage !== valueToStorage) {
        storage.setItem(MAIN_CONTEXT_STORAGE_NAME, valueToStorage);
    }
    return value;
}
