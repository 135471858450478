import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Card, CardColumns, Form, FormControl, InputGroup, Table } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { getInventoryApiUrl } from '../../../helpers/url';
import { HttpErrors } from '../../../hooks/use-http-error';
import { useResource } from '../../../hooks/use-resource';
import { ActionType, InventoryAction, InventoryFetched, InventoryProduct } from '../../../types/inventories/model';
import { Capabilities } from '../../../types/inventories/user';
import { useUserFromContext } from '../../Contexts';
import FormatAmount from '../../Format/FormatAmount';
import FormatPrice from '../../Format/FormatPrice';
import Message, { MessageType } from '../../Message/Message';
import InventoryActions from '../InventoryActions/InventoryActions';
import './Inventory.css';
import InventoryProductsList from './InventoryProductsList';
import InventorySaveModal from './InventorySaveModal';
import InventorySyncModal from './InventorySyncModal';
import ProductForm from './ProductForm';

interface InventoryProps {
    id: string;
}

export default ({ id }: InventoryProps) => {
    const { formatMessage } = useIntl();
    const user = useUserFromContext();
    const url = useMemo(() => getInventoryApiUrl('inventories/:id', { params: { id } }), [id]);
    const unitDefault = useMemo(
        () => formatMessage({ defaultMessage: 'pcs', id: 'form.default.unit' }), [formatMessage],
    );
    const [inventory, { error, loading }, reload] = useResource<InventoryFetched>(url);
    const canManageInventory = user.capabilities.includes(Capabilities.INVENTORY_MANAGE);
    const [quantities, setQuantities] = useState<{ [productId: string]: number }>({});
    const [sumScans, setSumScans] = useState<boolean>(false);
    const [thisProduct, setThisProduct] = useState<InventoryProduct | false | null>(null);
    const [actions, setActions] = useState<InventoryAction[]>([]);
    const addAction = useCallback((
        type: ActionType, name: string | null = null, id: number | null = null, value: number | null = null,
        ongoing: boolean = false,
    ) => {
        const newAction = { id, name, ongoing, time: new Date(), type, value };
        const lastIndex = actions.length - 1;
        if (
            ongoing && lastIndex >= 0 && actions[lastIndex].id === id && actions[lastIndex].type === type &&
            actions[lastIndex].ongoing === ongoing
        ) {
            setActions([...actions.slice(0, lastIndex), newAction]);
        } else {
            setActions([...actions, newAction]);
        }
    }, [actions, setActions]);
    const [changeCounter, setChangeCounter] = useState<number>(0);
    const onChange = useCallback(() => {
        setChangeCounter(changeCounter + 1);
    }, [changeCounter, setChangeCounter]);
    const getQuantity = useCallback(
        (product: InventoryProduct) => product.id in quantities ? quantities[product.id] : product.quantityNew,
        [quantities],
    );
    const quantity = useMemo(() => thisProduct ? getQuantity(thisProduct) : 0, [thisProduct, getQuantity]);
    const setQuantity = useCallback((product: InventoryProduct, quantity: number, ongoing: boolean = false) => {
        setQuantities({ ...quantities, [product.id]: quantity });
        addAction(ActionType.QUANTITY_CHANGE, product.name, product.id, quantity, ongoing);
    }, [addAction, quantities, setQuantities]);
    const addToQuantity = useCallback((product: InventoryProduct, quantityToAdd: number) => {
        const round = quantityToAdd > 0 ? Math.floor : Math.ceil;
        setQuantity(product, round(getQuantity(product)) + quantityToAdd);
    }, [getQuantity, setQuantity]);
    const [search, setSearch] = useState<string>('');
    const doSearch = useCallback((enterInUse: boolean) => {
        const foundProduct = inventory && Array.isArray(inventory.products) ?
            inventory.products.find(({ barcode }) => search === barcode) ||
            inventory.products.find(({ name }) => name.toLowerCase().includes(search.toLowerCase())) ||
            false :
            null
        setThisProduct(foundProduct);
        if (foundProduct && sumScans && enterInUse && search === foundProduct.barcode) {
            addToQuantity(foundProduct, 1);
        }
        onChange();
    }, [inventory, search, sumScans, setThisProduct, addToQuantity, onChange]);

    // @FIXME: Use proper React way to focus and select search input.
    useEffect(() => {
        const searchInput =
            document.getElementById('searchInput') as unknown as { focus: () => void; select: () => void };
        if (searchInput && searchInput.focus && searchInput.select) {
            searchInput.focus();
            searchInput.select();
        }
    }, [changeCounter]);

    if (!inventory) {
        if (loading) {
            return <Message type={MessageType.FETCHING} />;
        } else {
            return <Alert variant="danger">
                {error === HttpErrors.NOT_FOUND_ERROR ?
                    <FormattedMessage id="inventories.no-inventory" defaultMessage="There is no such inventory." /> :
                    <FormattedMessage id="list.fetching-error" defaultMessage="An error occurred during fetching." />}
            </Alert>;
        }
    } else if (!Array.isArray(inventory.products)) {
        return <Alert variant="warning">
            <FormattedMessage id="inventories.no-inventory" defaultMessage="Inventory does not contain products." />
        </Alert>;
    }
    const products = inventory.products;

    return <>
        <CardColumns className="InventoryWide">
            <Card>
                <Card.Body>
                    <Card.Title>
                        <FormattedMessage id="inventories.title" defaultMessage={'Inventory "{number}"'} values={{
                            number: inventory.number,
                        }} />
                    </Card.Title>
                    {canManageInventory ?
                        // @TODO: Use inventory from onSave and onSync callbacks instead of triggering reload.
                        <Table className="text-center">
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Check type="switch" id="custom-switch" onChange={(event: any) => {
                                            setSumScans(event.target.checked);
                                            onChange();
                                        }} checked={sumScans} label={formatMessage({
                                            defaultMessage: 'Sum scans', id: 'inventories.sum-scans',
                                        })} />
                                    </td>
                                    <td>
                                        <InventorySaveModal inventory={inventory} onClose={onChange} onSave={() => {
                                            reload();
                                            setThisProduct(null);
                                            setQuantities({});
                                            addAction(ActionType.INVENTORY_SAVE);
                                        }} quantities={quantities} />
                                        {' '}
                                        <InventorySyncModal inventory={inventory} onClose={onChange} onSync={() => {
                                            reload();
                                            setThisProduct(null);
                                            addAction(ActionType.INVENTORY_SYNC);
                                        }} quantities={quantities} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table> :
                        null}
                </Card.Body>
            </Card>
        </CardColumns>
        <InputGroup size="lg" className="mb-3">
            <FormControl id="searchInput" value={search} autoFocus onFocus={onChange} onChange={(event: any) => {
                setSearch(event.target.value);
                setThisProduct(null);
            }} onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key !== 'Enter') {
                    return;
                }
                doSearch(true);
            }} placeholder={formatMessage({ defaultMessage: 'Enter text to search', id: 'search.placeholder' })} />
            <InputGroup.Append>
                <Button variant="primary" onClick={() => { doSearch(false) }}>
                    <FormattedMessage id="search.button" defaultMessage="Search" />
                </Button>
            </InputGroup.Append>
        </InputGroup>
        {canManageInventory && thisProduct === false ?
            <Alert variant="warning">
                <Alert.Heading>
                    <FormattedMessage id="inventories.no-product" defaultMessage="There is no such product." />
                </Alert.Heading>
                <p className="text-center">
                    {/* @TODO: Use product from onSave callback instead of triggering reload. */}
                    <ProductForm inventoryId={inventory.id} search={search} onClose={onChange} onSave={product => {
                        reload();
                        setThisProduct(product);
                        addAction(ActionType.PRODUCT_CREATE, product.name, product.id);
                    }} buttonVariant="warning" buttonTitle={
                        <FormattedMessage id="inventories.button.product-add" defaultMessage="Add product" />
                    } />
                </p>
            </Alert> :
            canManageInventory && thisProduct ?
                <CardColumns className="InventoryWide">
                    <Card>
                        <Card.Header>
                            {/* @TODO: Use product from onSave callback instead of triggering reload. */}
                            <ProductForm inventoryId={inventory.id} product={thisProduct} onSave={product => {
                                reload();
                                setThisProduct(product);
                                addAction(ActionType.PRODUCT_UPDATE, product.name, product.id);
                            }} onClose={onChange} buttonVariant="link" buttonTitle={<>
                                {thisProduct.barcode ? `${thisProduct.barcode}: ` : ''}{thisProduct.name}{' '}
                                <PencilSquare />
                            </>} />
                        </Card.Header>
                        <Card.Body className="text-center">
                            <InputGroup size="lg">
                                <InputGroup.Prepend>
                                    <Button variant="primary" disabled={quantity <= 0} onClick={() => {
                                        if (thisProduct && quantity > 0) {
                                            addToQuantity(thisProduct, -1);
                                            onChange();
                                        }
                                    }}>{' '}-{' '}</Button>
                                </InputGroup.Prepend>
                                <FormControl value={`${quantity}`} type="number" min="0" onChange={(event: any) => {
                                    if (thisProduct) {
                                        setQuantity(thisProduct, Math.max(0, event.target.value), true);
                                    }
                                }} />
                                <InputGroup.Append>
                                    <InputGroup.Text>{thisProduct.unit || unitDefault}</InputGroup.Text>
                                    <Button variant="primary" onClick={() => {
                                        if (thisProduct) {
                                            addToQuantity(thisProduct, 1);
                                            onChange();
                                        }
                                    }}>{' '}+{' '}</Button>
                                </InputGroup.Append>
                            </InputGroup>
                            <Table className="borderless">
                                <tbody>
                                    <tr>
                                        <td>
                                            <FormatAmount value={thisProduct.quantityOld}
                                                unit={thisProduct.unit || unitDefault} />
                                        </td>
                                        <td>
                                            <FormatPrice value={thisProduct.priceGross}
                                                unit={thisProduct.unit || unitDefault}
                                                currency={thisProduct.currency || ''} />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </CardColumns> :
                null}
        <CardColumns className="InventoryWide">
            <InventoryActions actions={actions} />
        </CardColumns>
        <CardColumns className="InventoryProducts">
            <InventoryProductsList {...{
                filter: ({ barcode }) => !!barcode,
                products,
                quantities,
                setSearch: ({ barcode }) => { setSearch(barcode || ''); onChange() },
                setThisProduct,
                thisProduct,
                title: formatMessage({
                    defaultMessage: 'Products with barcodes', id: 'inventories.products.with-barcodes',
                }),
            }} />
            <InventoryProductsList {...{
                filter: ({ barcode }) => !barcode,
                products,
                quantities,
                setSearch: ({ name }) => { setSearch(name); onChange() },
                setThisProduct,
                thisProduct,
                title: formatMessage({
                    defaultMessage: 'Products without barcodes', id: 'inventories.products.without-barcodes',
                }),
            }} />
        </CardColumns>
    </>;
};
