import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { PersonLinesFill, Search, UpcScan } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PATH as WAREHOUSE_RENTALS_PATH } from '../../../routes/warehouse/Rentals';
import { PATH as WAREHOUSE_RESULT_PATH } from '../../../routes/warehouse/Results';
import { PATH as WAREHOUSE_SEARCH_PATH } from '../../../routes/warehouse/Search';
import IdCodeScanner from '../../IdCodeScanner/IdCodeScanner';

export default () => <div className="menu warehouse-menu">
    <InputGroup size="lg" className="mt-3 mb-3">
        <InputGroup.Prepend>
            <InputGroup.Text><FormattedMessage id="menu.warehouse" defaultMessage="Warehouse" /></InputGroup.Text>
        </InputGroup.Prepend>
        <InputGroup.Append>
            <IdCodeScanner result={WAREHOUSE_RESULT_PATH}
                renderButton={onClick => <span role="button" onClick={onClick} className="btn btn-secondary btn-lg">
                    <UpcScan>
                        <FormattedMessage id="menu.warehouse.scan" defaultMessage="Scan" />
                    </UpcScan>
                </span>}
                renderLink={url => <a href={url} role="button" className="btn btn-secondary btn-lg">
                    <UpcScan>
                        <FormattedMessage id="menu.warehouse.scan" defaultMessage="Scan" />
                    </UpcScan>
                </a>} />
            <Link to={WAREHOUSE_SEARCH_PATH} role="button" className="btn btn-secondary btn-lg">
                <Search>
                    <FormattedMessage id="menu.warehouse.search" defaultMessage="Search" />
                </Search>
            </Link>
            <Link to={WAREHOUSE_RENTALS_PATH} role="button" className="btn btn-secondary btn-lg">
                <PersonLinesFill>
                    <FormattedMessage id="menu.warehouse.rentals" defaultMessage="Rentals" />
                </PersonLinesFill>
            </Link>
        </InputGroup.Append>
    </InputGroup>
</div>;
