import React from 'react';
import { Link } from 'react-router-dom';
import { getPath } from '../../../helpers/path';
import { PATH as WAREHOUSE_TAG_PATH } from '../../../routes/warehouse/Tag';
import { TagModel } from '../../../types/warehouse/model';

interface TagProps {
    tag: TagModel;
}

export default ({ tag }: TagProps) => <div className="toast show">
    <div className="toast-header">
        <strong className="mr-auto">
            <Link to={{
                pathname: getPath(WAREHOUSE_TAG_PATH, { tagSlug: tag.slug }),
                state: { tag }
            }}>{tag.name}</Link>
        </strong>
        <small className="text-muted">{tag.slug}</small>
    </div>
    {tag.description ? <div className="toast-body text-break">{tag.description}</div> : null}
</div>;
